<template>
  <div>
    <v-form ref="form" v-model="valid" aria-autocomplete>
      <v-dialog
        v-model="show"
        scrollable
        v-bind:retain-focus="true"
        persistent
        no-click-animation
      >
        <v-card :style="dlgStyle">
          <v-card-title :class="headlineclass" style="position: relative">
            <span class="mksheadline" :dialogid="$el ? $el.__vue__._uid : ''">{{
              headline
            }}</span>

            <v-chip
              v-if="blockedBy !== ''"
              class="ma-2"
              color="orange"
              text-color="white"
              >{{ blockedBy }}</v-chip
            >

            <v-spacer></v-spacer>
            <div>
              <v-tooltip bottom v-if="mailBtnVisible" open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    :color="reorderFieldsActive ? 'primary' : undefined"
                    @click="reorderFieldsActive = !reorderFieldsActive"
                    >low_priority</v-icon
                  >
                  <!-- <v-switch
                    dense
                    :dark="$vuetify.theme.dark"
                    v-model="reorderFieldsActive"
                    prepend-icon="low_priority"
                  ></v-switch> 
                </div>-->
                </template>
                <span
                  v-html="
                    !reorderFieldsActive
                      ? 'Activate Reorder Fields Mode'
                      : 'Reorder Fields<br />(drag with Mouse)<br />Click here to save your changes'
                  "
                ></span>
              </v-tooltip>
              <v-tooltip bottom v-if="reorderFieldsActive" open-delay="700">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    color="primary"
                    v-on="on"
                    @click="confirm('resetfieldorder')"
                    >settings_backup_restore</v-icon
                  >
                </template>
                <span>Reset Field Order to Default</span>
              </v-tooltip>

              <v-tooltip bottom v-if="historyBtnVisible" open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-icon
                    ref="historybtn"
                    :dark="$vuetify.theme.dark"
                    top
                    right
                    v-on="on"
                    @click="showHistory"
                  >
                    history</v-icon
                  >
                </template>
                <span>Show Change History</span>
              </v-tooltip>
              <v-tooltip bottom v-if="mailBtnVisible" open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-icon
                    ref="copyLinkBtn"
                    :dark="$vuetify.theme.dark"
                    top
                    right
                    v-on="on"
                    @click="copyLink"
                    >link</v-icon
                  >
                </template>
                <span>Copy item link to clipboard</span>
              </v-tooltip>
              <v-tooltip bottom v-if="mailBtnVisible" open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-icon
                    ref="mailbtn"
                    :dark="$vuetify.theme.dark"
                    top
                    right
                    v-on="on"
                    @click="createMail"
                    >mail_outline</v-icon
                  >
                </template>
                <span>Create Email with Link to this Item</span>
              </v-tooltip>
              <v-tooltip bottom v-if="mailBtnVisible" open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-icon
                    ref="printbtn"
                    :dark="$vuetify.theme.dark"
                    top
                    right
                    v-on="on"
                    @click="print"
                    :disabled="editquery.id < 1 || UnsavedChanges"
                    >print</v-icon
                  >
                </template>
                <span>Print Item Details</span>
              </v-tooltip>
              <v-tooltip bottom v-if="showUnlockBtn()" open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-icon
                    ref="userCanUnlockBtn"
                    :dark="$vuetify.theme.dark"
                    top
                    right
                    style="margin-right: 1em"
                    @click="detailsUnlock"
                    v-on="on"
                    :disabled="disableUnlockBtn"
                  >
                    mdi-lock-open</v-icon
                  >
                </template>
                <span>Unlock blocked fields (admin only)</span>
              </v-tooltip>
            </div>
            <v-tooltip bottom v-if="scrollbtnvisible" open-delay="700">
              <template v-slot:activator="{ on }">
                <v-icon
                  ref="jumpbtn"
                  :dark="$vuetify.theme.dark"
                  top
                  right
                  style="margin-right: 1em"
                  @click="jumptoend"
                  v-on="on"
                >
                  {{ scrldwn ? "arrow_downward" : "arrow_upward" }}</v-icon
                >
              </template>
              <span>Scroll to End or Top of Page</span>
            </v-tooltip>

            <v-icon class="pnt" @click="confirm('cancel')">mdi-close</v-icon>
          </v-card-title>

          <v-card-text ref="outer" :class="detailsclass">
            <v-container ref="inner">
              <template>
                <div ref="jumpdummytop"></div>
              </template>
              <template v-if="showLoadingCircle && !resetting">
                <v-row align="center" justify="center">
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-row>
              </template>
              <template v-else>
                <table class="tablelayout" v-if="showTableLayout">
                  <tr>
                    <td
                      v-for="colnr in tablelayoutcolumnitemproperties.length"
                      :key="'sur' + colnr"
                    >
                      <table>
                        <tr
                          v-for="(
                            item, iindex
                          ) in tablelayoutcolumnitemproperties[colnr - 1]"
                          :key="'tr' + iindex"
                        >
                          <td>{{ item.a }}</td>
                          <td>{{ item.v }}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <v-row dense v-else>
                  <draggable
                    class="row row--dense"
                    :disabled="!reorderFieldsActive"
                  >
                    <template v-for="(field, index) in itemproperties">
                      <template v-if="field.t === 'PHOTO'">
                        <v-col
                          :class="vColClass(field)"
                          v-bind:key="index"
                          :fn="field.n"
                        >
                          <mksphoto
                            :key="refreshphoto"
                            ref="Photo"
                            :editquery="editquery"
                            :field="field"
                            :alternatelayout="AlternativeDetailsLayout"
                            v-on:photoChanged="refreshfilesfunc"
                          ></mksphoto>
                        </v-col>
                      </template>
                      <template v-else-if="field.n === '_requestOpenVPN'">
                        <v-col
                          :class="vColClass(field)"
                          v-bind:key="index"
                          :fn="field.n"
                        >
                          <v-menu offset-y :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on"
                                ><v-icon>construction</v-icon> tools
                              </v-btn>
                            </template>
                            <v-list dense>
                              <v-list-item-group color="primary">
                                <!-- Main Menu Item with Submenu -->
                                <v-menu
                                  v-model="submenu1"
                                  offset-x
                                  :close-on-content-click="false"
                                >
                                  <!-- Activator slot binds the v-list-item as the activator -->
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="sendOpenVPN()"
                                      :disabled="openVPNLoading"
                                    >
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          Send OpenVPN config
                                        </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-action>
                                        <v-progress-circular
                                          v-if="openVPNLoading"
                                          size="30"
                                          indeterminate
                                          color="primary"
                                        ></v-progress-circular>
                                        <v-icon v-else>mail</v-icon>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </template>

                                  <!-- Submenu opens to the right -->
                                  <v-list>
                                    <v-list-item v-for="vpn in vpnlist" :key="'vpn_' + vpn.VLAN_ID"
                                      @click="sendOpenVPN(false, vpn.VLAN_ID)">
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >{{ vpn.Type }} - {{ vpn.Title }}</v-list-item-title
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>

                                <v-list-item
                                  @click="resetOTP()"
                                  :disabled="resetOTPLoading"
                                >
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      Reset OTP
                                    </v-list-item-title>
                                  </v-list-item-content>

                                  <v-list-item-action>
                                    <v-progress-circular
                                      v-if="resetOTPLoading"
                                      size="30"
                                      indeterminate
                                      color="primary"
                                    ></v-progress-circular>
                                    <v-icon v-else>phone_iphone</v-icon>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </template>
                      <template
                        v-else-if="field.t === 'REMARKS' && editquery.id !== 0"
                      >
                        <v-col
                          class="col-12"
                          :fn="field.n"
                          v-bind:key="'remarks_' + index"
                        >
                          <mksremarks
                            ref="mksremarks"
                            :editquery="editquery"
                            :className="detailsclass"
                            :alternatelayout="AlternativeDetailsLayout"
                            :bgcolor="dlgStyle"
                            :print="field.print"
                            :itemrights="itemrights"
                            :IconButtons="IconButtons"
                          ></mksremarks>
                        </v-col>
                      </template>
                      <template
                        v-else-if="
                          field.t === 'ATTACHEDFILES' && filesvisible
                          //&& editquery.id !== 0
                        "
                      >
                        <v-col
                          class="col-12"
                          :fn="field.n"
                          v-bind:key="'files_' + index"
                        >
                          <mksfiles
                            ref="mksfiles"
                            :refreshfile="refreshfile"
                            :editquery="editquery"
                            :AttachmentTypes="AttachmentTypes"
                            :className="detailsclass"
                            :alternatelayout="AlternativeDetailsLayout"
                            v-on:filesChanged="
                              UnsavedChanges = true;
                              refreshphotofunc();
                            "
                            v-on:userinput="userinput"
                          ></mksfiles>
                        </v-col>
                      </template>
                      <template
                        v-else-if="field.t === 'COMBO' && checkIncl(field)"
                      >
                        <v-col
                          :class="vColClass(field)"
                          v-bind:key="index"
                          :fn="field.s"
                        >
                          <mksautocomplete
                            :ref="field.n"
                            :editquery="editquery"
                            :field="field"
                            :alternatelayout="AlternativeDetailsLayout"
                            v-on:userinput="userinput"
                            @closeSubDetails="closeSubDetails"
                            @setIncludedProperties="setIncludedProperties"
                          >
                          </mksautocomplete>
                        </v-col>
                      </template>
                      <template
                        v-else-if="
                          field.t === 'LOCATION_development' && checkIncl(field)
                        "
                      >
                        <v-col
                          :class="vColClass(field)"
                          v-bind:key="index"
                          :fn="field.s"
                        >
                          <mkslocation
                            :ref="field.n"
                            :editquery="editquery"
                            :field="field"
                            :alternatelayout="AlternativeDetailsLayout"
                            v-on:userinput="userinput"
                          ></mkslocation>
                        </v-col>
                      </template>
                      <template v-else-if="field.n === '_bgcolor'"></template>
                      <template
                        v-else-if="field.t === 'datetime' && checkIncl(field)"
                      >
                        <v-col
                          :class="vColClass(field)"
                          v-bind:key="index"
                          :fn="field.n"
                        >
                          <mksdate
                            :ref="field.n"
                            :field="field"
                            :alternatelayout="AlternativeDetailsLayout"
                            v-on:userinput="userinput"
                          ></mksdate>
                        </v-col>
                      </template>
                      <template
                        v-else-if="field.t === 'boolean' && checkIncl(field)"
                      >
                        <v-col
                          :class="vColClass(field)"
                          v-bind:key="index"
                          :fn="field.n"
                        >
                          <mksbooleaninput
                            :ref="field.n"
                            :field="field"
                            :alternatelayout="AlternativeDetailsLayout"
                            v-on:userinput="userinput"
                          ></mksbooleaninput>
                        </v-col>
                      </template>
                      <template
                        v-else-if="field.t === 'SUBTABLE' && checkIncl(field)"
                      >
                        <v-col
                          :key="index"
                          cols="12"
                          :fn="field.n"
                          v-if="editquery.id > 0"
                        >
                          <mkssubquery
                            v-bind:key="index"
                            :ref="field.n"
                            :editquery="editquery"
                            :field="field"
                            :alternatelayout="AlternativeDetailsLayout"
                            :bgcolor="dlgStyle"
                            v-on:userinput="userinput"
                            @closeSubDetails="closeSubDetails"
                          ></mkssubquery>
                        </v-col>
                      </template>
                      <template
                        v-else-if="
                          field.t.startsWith('int') && checkIncl(field)
                        "
                      >
                        <v-col
                          :class="vColClass(field)"
                          v-bind:key="index"
                          :fn="field.n"
                        >
                          <mksnumberinput
                            :ref="field.n"
                            :field="field"
                            :alternatelayout="AlternativeDetailsLayout"
                            v-on:userinput="userinput"
                          ></mksnumberinput>
                        </v-col>
                      </template>
                      <template
                        v-else-if="field.t === 'double' && checkIncl(field)"
                      >
                        <v-col
                          :class="vColClass(field)"
                          v-bind:key="index"
                          :fn="field.n"
                        >
                          <mksdoubleinput
                            :ref="field.n"
                            :field="field"
                            :alternatelayout="AlternativeDetailsLayout"
                            v-on:userinput="userinput"
                          ></mksdoubleinput>
                        </v-col>
                      </template>
                      <template
                        v-else-if="
                          (field.a === 'Record' ||
                            (field.a === 'Description' &&
                              editquery.tablename === 'Resources Bundles')) &&
                          checkIncl(field)
                        "
                      >
                        <v-col
                          :class="vColClass(field)"
                          v-bind:key="index"
                          :fn="field.n"
                        >
                          <mkshtmleditor
                            :ref="field.n"
                            :field="field"
                            :alternatelayout="AlternativeDetailsLayout"
                            v-on:userinput="userinput"
                          ></mkshtmleditor>
                        </v-col>
                      </template>
                      <template
                        v-else-if="field.t === 'string' && checkIncl(field)"
                      >
                        <v-col
                          v-bind:key="'bundlebtn_' + index"
                          cols="2"
                          v-if="
                            field.a == 'Bundle Title' &&
                            itemrights.write &&
                            editquery.id > 0
                          "
                        >
                          <v-tooltip
                            top
                            :open-delay="
                              editquery.id < 1 || UnsavedChanges ? 200 : 1000
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                @click="resourcebundle = true"
                                style="width: 90%"
                                ><v-icon>account_tree</v-icon
                                >&nbsp;&nbsp;+&nbsp;&nbsp;
                                <v-icon>edit_calendar</v-icon></v-btn
                              >
                            </template>
                            <span
                              >Open resource compilation<br />and reservation
                              management</span
                            >
                          </v-tooltip>
                        </v-col>
                        <v-col
                          :class="vColClass(field)"
                          v-bind:key="index"
                          :fn="field.n"
                        >
                          <mkstextinput
                            :ref="field.n"
                            :field="field"
                            :alternatelayout="AlternativeDetailsLayout"
                            :id="editquery.id"
                            :id_link="editquery.id_link"
                            :detailsLink="detailslink()"
                            v-on:userinput="userinput"
                            v-on:checkcond="checkcond"
                            v-on:newPatchState="newPatchState"
                          >
                          </mkstextinput>
                        </v-col>
                      </template>
                    </template>
                    <v-col class="col-sm-4 col-md-4 col-lg-2 col-12">
                      <mkstextinput
                        v-if="ShowIdentifierColumn && editquery.id > 0"
                        :field="{
                          a: editquery.identifier,
                          n: editquery.identifier,
                          v: this.editquery.id,
                          d: true,
                          why: 'Identifier field is not editable',
                        }"
                        :alternatelayout="AlternativeDetailsLayout"
                      ></mkstextinput>
                    </v-col>
                  </draggable>
                </v-row>
              </template>
              <template>
                <div ref="jumpdummybottom"></div>
              </template>
            </v-container>
          </v-card-text>
          <v-card-actions v-show="!showLoadingCircle" :class="detailsclass">
            <v-tooltip
              top
              :open-delay="editquery.id < 1 || UnsavedChanges ? 200 : 1000"
              v-if="AttachmentTypes.length > 0 && itemrights.print"
            >
              <template v-slot:activator="{ on, attrs }"
                ><v-col v-bind="attrs" v-on="on" cols="12" md="4" sm="3">
                  <v-autocomplete
                    :disabled="editquery.id < 1 || UnsavedChanges"
                    v-model="selectedExport"
                    :items="AttachmentTypesTemplatesOnly"
                    hide-no-data
                    hide-selected
                    item-text="Attachmenttype"
                    item-value="ID_Attachmenttype"
                    :full-width="true"
                    ref="exportfile"
                    style="min-width: none"
                    label="Create prefilled Form ..."
                    hide-details
                    :outlined="!AlternativeDetailsLayout"
                    dense
                    :prepend-icon="
                      !AlternativeDetailsLayout ? '' : 'file-download-outline'
                    "
                    :prepend-inner-icon="
                      AlternativeDetailsLayout ? '' : 'file-download-outline'
                    "
                  ></v-autocomplete
                ></v-col>
              </template>
              <span
                v-html="
                  editquery.id < 1 || UnsavedChanges
                    ? 'Save your changes before exporting data'
                    : 'Create prefilled Form'
                "
              ></span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    editquery.id < 1 ||
                    loading ||
                    navigatedisabled ||
                    !hasPrevious
                  "
                  :dark="$vuetify.theme.dark"
                  class="mr-2"
                  @click="
                    navigatedisabled = true;
                    navigate(true);
                  "
                  ><v-icon>navigate_before</v-icon></v-btn
                >
              </template>
              <span>Previous Item</span>
            </v-tooltip>
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    editquery.id < 1 || loading || navigatedisabled || !hasNext
                  "
                  :dark="$vuetify.theme.dark"
                  class="mr-2"
                  @click="
                    navigatedisabled = true;
                    navigate(false);
                  "
                  ><v-icon>navigate_next</v-icon></v-btn
                >
              </template>
              <span>Next Item</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="editquery.id < 1 || !itemrights.delete"
                  color="error"
                  class="mr-2"
                  @click="confirm('delete')"
                >
                  <v-icon v-if="IconButtons" left>delete</v-icon>
                  <span v-else>Delete</span>
                </v-btn>
              </template>
              <span>Delete Item</span>
            </v-tooltip>

            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    editquery.id < 1 || !itemrights.write || UnsavedChanges
                  "
                  color="accent"
                  class="mr-2"
                  @click="newitem"
                >
                  <v-icon v-if="IconButtons" left>add</v-icon>
                  <span v-else>New</span>
                </v-btn>
              </template>
              <span>New Item</span>
            </v-tooltip>

            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    editquery.id < 1 || !itemrights.write || UnsavedChanges
                  "
                  color="accent"
                  class="mr-2"
                  @click="copyitem"
                >
                  <v-icon v-if="IconButtons" left>content_copy</v-icon>
                  <span v-else>Copy</span>
                </v-btn>
              </template>
              <span>Copy Item</span>
            </v-tooltip>

            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!itemrights.write"
                  :dark="$vuetify.theme.dark"
                  color="info"
                  class="mr-2"
                  @click="confirm('reset')"
                >
                  <v-icon v-if="IconButtons" left
                    >settings_backup_restore</v-icon
                  >
                  <span v-else>reset</span>
                </v-btn>
              </template>
              <span>Reset Item</span>
            </v-tooltip>

            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!itemrights.write"
                  :dark="$vuetify.theme.dark"
                  color="warning"
                  class="mr-2"
                  @click="confirm('cancel')"
                >
                  <v-icon v-if="IconButtons" left>cancel</v-icon>
                  <span v-else>cancel</span></v-btn
                >
              </template>
              <span>Cancel Changes and Close</span>
            </v-tooltip>

            <template v-if="SaveAndClose">
              <v-tooltip top open-delay="1000">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="saving"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!valid || !itemrights.write"
                    :dark="$vuetify.theme.dark"
                    color="success"
                    class="mr-2"
                    @click="saveChanges"
                  >
                    <v-icon v-if="IconButtons" left>save</v-icon>
                    <span v-else>Save</span>
                  </v-btn>
                </template>
                <span>Save Changes</span>
              </v-tooltip>

              <v-tooltip top open-delay="1000">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="saving"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!valid || !itemrights.write"
                    :dark="$vuetify.theme.dark"
                    color="success"
                    class="mr-2"
                    @click="saveChanges(true)"
                  >
                    <span v-if="IconButtons"
                      ><v-icon>save</v-icon> +<v-icon>close</v-icon></span
                    >

                    <span v-else>Save + Close</span>
                  </v-btn>
                </template>
                <span>Save Changes and Close</span>
              </v-tooltip>
            </template>
            <template v-else>
              <v-tooltip top open-delay="1000">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :loading="saving"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="!valid || !itemrights.write"
                    :dark="$vuetify.theme.dark"
                    color="success"
                    class="mr-2"
                    @click="saveChanges"
                  >
                    <v-icon v-if="IconButtons" left>save</v-icon>
                    <span v-else>Save</span>
                  </v-btn>
                </template>
                <span>Save Changes</span>
              </v-tooltip>
            </template>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <mksdialogconfirm
      :confirmDialog="confirmDialog"
      @close="confirmResult"
    ></mksdialogconfirm>
    <v-dialog
      v-model="saveModulsDialogShow"
      content-class="v-dialog-mks"
      persistent
      no-click-animation
    >
      <v-card v-if="saveModulsDialogShow">
        <v-card-title>
          <span class="mksheadline"
            >Select which Changes should be copied to the bound
            {{ elementCount }} Moduls</span
          >

          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="saveModulsDialogShow = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text
          ><v-list dense>
            <v-subheader>Select all that apply:</v-subheader>
            <v-list-item-group color="primary" multiple active-class="">
              <v-list-item
                v-for="(item, i) in changedElementsForModulCheck"
                :key="i"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      :value="item.n"
                      v-model="ModulChanges"
                    ></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-content @click="toggleModulChanges(item.n)">
                    <v-list-item-title
                      >{{ getChangeVisualizationPart1(item) }}
                      <v-icon>arrow_forward</v-icon>
                      {{ getChangeVisualizationPart2(item) }}</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="saving"
            color="success"
            class="mr-4"
            @click="saveChanges(saveandcloseclicked)"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <mksdependencies
      :visible="showDependencies"
      :editquery="editquery"
      @close="closeDependencies"
    ></mksdependencies>
    <mkshistoryglob
      :visible="historyVisible"
      :editquery="editquery"
      @close="closeHistory"
    ></mkshistoryglob>
    <mksfastjumpdialog
      ref="mksfastjumpdialog"
      :visible="showFastJumpDialog"
      :properties="itemproperties"
      @close="jumpToProp"
    ></mksfastjumpdialog>
    <mksresourcebundle
      v-if="resourcebundle"
      :editquery="editquery"
      @close="closeResourcebundle"
    >
    </mksresourcebundle>
  </div>
</template>

<style scoped>
.btnend {
  top: 50px;
}

.v-input--switch {
  margin-top: 4px !important;
  padding: 0px 0px 0px 0px !important;
}

.hightlightscrolltarget {
  background-color: paleturquoise;
}

table.tablelayout > tr {
  vertical-align: top;
}
</style>

<script>
import mksautocomplete from "@/components/mksautocomplete.vue";
import mkslocation from "@/components/mkslocation.vue";
import mkssubquery from "@/components/mkssubquery.vue";
import mksdate from "@/components/mksdate.vue";
import mkstextinput from "@/components/mkstextinput.vue";
import mksphoto from "@/components/mksphoto.vue";
import mksnumberinput from "@/components/mksnumberinput.vue";
import mksdoubleinput from "@/components/mksdoubleinput.vue";
import mksfiles from "@/components/mksfiles.vue";
import mksremarks from "@/components/mksremarks.vue";
import mksbooleaninput from "@/components/mksbooleaninput.vue";
import mksdependencies from "@/components/mksdependencies.vue";
import mkshistoryglob from "@/components/mkshistoryglob.vue";
import mksdialogconfirm from "@/components/mksdialogconfirm.vue";
import mksfastjumpdialog from "@/components/mksfastjumpdialog.vue";
import mkshtmleditor from "@/components/mkshtmleditor.vue";
import mksresourcebundle from "@/components/mksresourcebundle.vue";

import draggable from "vuedraggable";

import { mapState } from "vuex";

var resetIgnoreinputtimer = null;

const propComparator = (searchForProp) => (a, b) => {
  var nameA = a.a.toLowerCase(); // Groß-/Kleinschreibung ignorieren
  var nameB = b.a.toLowerCase(); // Groß-/Kleinschreibung ignorieren

  if (searchForProp !== "") {
    return nameA.indexOf(searchForProp) < nameB.indexOf(searchForProp) ? -1 : 1;
  }

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // Namen müssen gleich sein
  return 0;
};

export default {
  name: "mksdetails",
  autofocusFirstElement: true,

  components: {
    mksautocomplete,
    mkslocation,
    mkssubquery,
    mksdate,
    mkstextinput,
    mksphoto,
    mksnumberinput,
    mksdoubleinput,
    mksbooleaninput,
    mksdependencies,
    mkshistoryglob,
    mksfiles,
    mksremarks,
    mksdialogconfirm,
    mksfastjumpdialog,
    draggable,
    mkshtmleditor,
    mksresourcebundle,
  },

  data: () => ({
    submenu1: null,
    vpnlist: [],
    resourcebundle: false,
    showTableLayout: false,
    TableLayoutColumnNumber: 3,
    propCounter: 0,
    ModulChanges: [],
    included_props: [],
    fieldorder: null,
    reorderFieldsActive: false,
    resetOrderFieldsActive: false,
    hasPrevious: false,
    hasNext: false,
    searchForProp: "",
    valid: true,
    loading: false,
    saving: false,
    showLoadingCircle: false,
    resetting: false,
    ignoreinput: false,
    itemproperties: [],
    tablelayoutcolumnitemproperties: [],
    itemrights: {},
    grphsh: "",
    detailshashOrig: "",
    requiredRule: [(v) => !!v || "missing"],
    Photo: null,
    bgcolor: "",
    showDependencies: false,
    showFastJumpDialog: false,
    openVPNLoading: false,
    resetOTPLoading: false,
    confirmDialog: { show: false },
    confirmDialogTemplate: [
      {
        show: false,
        action: "",
        header: "",
        text: "",
        buttonconfirmtext: "",
        buttoncanceltext: "",
      },
      {
        show: true,
        action: "cancel",
        header: "You have unsaved changes!",
        text: "Close anyway?",
        buttonconfirmtext: "Close Item and discard Changes",
        buttoncanceltext: "return to item",
      },
      {
        show: true,
        action: "reset",
        header: "You have unsaved Changes!",
        text: "Reset anyway?",
        buttonconfirmtext: "reset item",
        buttoncanceltext: "cancel",
      },
      {
        show: true,
        action: "resetfieldorder",
        header: "You have unsaved Changes!",
        text: "Reset Field Order anyway? (Item has to be closed and reopened.)",
        buttonconfirmtext: "Reset Field Order",
        buttoncanceltext: "cancel",
      },
      {
        show: true,
        action: "setfieldorder",
        header: "You have unsaved Changes!",
        text: "Set Field Order anyway? (Item has to be closed and reopened.)",
        buttonconfirmtext: "Set Field Order",
        buttoncanceltext: "cancel",
      },
      {
        show: true,
        action: "delete",
        header: "Delete this Item",
        text: "Are you sure you want to delete this Item?",
        buttonconfirmtext: "delete",
        buttoncanceltext: "cancel",
      },
      {
        show: true,
        action: "navigate_next",
        header: "You have unsaved changes!",
        text: "Discard Changes and jump to next Item?",
        buttonconfirmtext: "jump to next item",
        buttoncanceltext: "stay here",
      },
      {
        show: true,
        action: "navigate_prev",
        header: "You have unsaved changes!",
        text: "Discard Changes and jump to previous Item?",
        buttonconfirmtext: "jump to previous item",
        buttoncanceltext: "stay here",
      },
    ],
    saveModulsDialogShow: false,
    scrollbtnvisible: false,
    hasHistory: false,
    historyBtnVisible: false,
    historyVisible: false,
    mailBtnVisible: false,
    exportmodel: true,
    exportitems: [],
    AttachmentTypes: [],
    selectedExport: null,
    AlternativeDetailsLayout: false,
    IconButtons: false,
    SaveAndClose: false,
    ShowIdentifierColumn: false,
    UnsavedChanges: false,
    // photoindex: -1,
    refreshphoto: 0,
    refreshfile: 0,
    blockedBy: "",
    headline: "",
    headlineclass: "",
    detailsclass: "",
    resetElements: [],
    filesvisible: false,
    firstcall: true,
    fastjumpmodel: null,
    navigatedisabled: false,
    tobecheckedorigid: null,
    scrldwn: true,
    elementCount: 100,
    saveandcloseclicked: false,
    surnameFieldName: "",
    firstnamesFieldName: "",
    changedElementsForModulCheck: [],
    detailsSaved: false,
    disableUnlockBtn: false,
  }),

  props: {
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    idfilter: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    calledFromDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  watch: {
    reorderFieldsActive: {
      handler() {
        if (!this.resetOrderFieldsActive && !this.reorderFieldsActive) {
          this.confirm("setfieldorder");
        }
        this.resetOrderFieldsActive = false;
      },
      deep: true,
    },
    prefs: {
      handler() {
        this.refreshSettings();
      },
      deep: true,
    },

    editquery: {
      handler(newval, oldval) {
        if (newval.search && oldval.search) {
          return;
        }
        if (!this.loading && this.visible) {
          if (this.editquery.id_query !== 0) {
            this.getDetails(this.firstcall);
          }
          this.firstcall = false;
          this.ModulChanges = [];
          this.vpnlist = [];
          this.submenu1 = null;
        }
      },
      deep: true,
    },

    visible: {
      handler() {
        if (this.visible) {
          // this.getDetails(true);
        } else {
          this.loading = false;
          this.showLoadingCircle = true;
          this.resetting = false;
          this.itemproperties = [];
          this.itemrights = {};
          this.selectedExport = null;
          this.AttachmentTypes = [];
          this.bgcolor = null;
          this.filesvisible = false;
          this.firstcall = false;
          this.scrldwn = true;
          this.resetOrderFieldsActive = true;
          this.reorderFieldsActive = false;
        }
      },
    },

    selectedExport(val) {
      if (val != null) {
        this.exportfile();
      }
    },

    lastKeyUp: {
      handler() {
        if (this.show) {
          if (this.lastKeyUp.altKey) {
            //check if dialog is topmost
            var dia2 = this.$getHighest(".v-dialog--active");
            dia2 = dia2 && dia2.querySelector("[dialogid]");
            if (
              dia2 &&
              parseInt(dia2.getAttribute("dialogid")) === this.$el.__vue__._uid
            ) {
              switch (this.lastKeyUp.keyCode) {
                case 74: // Alt + J = Show Field List for Fast Jump
                  this.showFastJumpDialog = true;

                  break;
                case 83: // Alt + S = Save
                  this.saveChanges();
                  break;
              }
            }
          }
        }
      },
    },
  },

  computed: {
    ...mapState(["currentUser", "currenthubsubscriptions", "lastKeyUp"]),

    prefs() {
      return this.currentUser.prefs;
    },

    AttachmentTypesTemplatesOnly() {
      return this.AttachmentTypes.filter(
        (x) =>
          x.TemplateFilename !== null &&
          x.TemplateFilename.indexOf(".dmy") === -1
      );
    },

    isfiltered: {
      get() {
        return !(
          Object.keys(this.idfilter).length === 0 &&
          this.idfilter.constructor === Object
        );
      },
    },
    dlgStyle: {
      get() {
        return this.reorderFieldsActive
          ? "background:linear-gradient(90deg, rgba(255, 255, 0, 1) 0%, rgba(0, 188, 212, 1) 50%, rgba(238, 130, 238, 1) 100%);"
          : this.bgcolor !== ""
          ? "background-color:" + this.bgcolor
          : "";
      },
    },

    getFieldsForJumpList: {
      get() {
        var jfields = Object.values(this.itemproperties).filter((obj) => {
          return obj;
        });

        jfields.sort(
          propComparator(
            this.searchForProp ? this.searchForProp.toLowerCase() : ""
          )
        );
        return jfields;
      },
    },
    detailshash: {
      get() {
        return this.grphsh + "_" + this.editquery.id;
      },
    },

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          if (this.calledFromDetails && this.editquery.id_link === 410) {
            this.$emit("close", {
              pm: this.itemproperties.find((x) => x.a === "Patch Mode").v,
              ps: this.itemproperties.find((x) => x.a === "Patch Status").v,
            });
          } else {
            this.$emit("close", {
              detailsSaved: this.detailsSaved,
              id: this.editquery.id,
            });
          }

          this.$changesHub.leaveDetailsGroup(this.detailshash);

          this.$options.autofocusFirstElement = true;
          this.scrollbtnvisible = false;
          this.Photo = null;
          this.blockedBy = "";
          this.grphsh = "";
          this.headline = "";
          this.headlineclass = "";
          this.detailsclass = "";
        } else {
          this.$el.click();
        }
      },
    },
  },

  methods: {
    closeResourcebundle: function () {
      this.resourcebundle = false;
    },
    print() {
      // var printwindow =
      window.open(
        this.detailslink("p=1"),
        "Print Details",
        "fullscreen=no,location=no,menubar=no,resizable=no,scrollbars=no,status=yes,titlebar=no,toolbar=no"
      );
      // printwindow.document.write("<p>This is 'MsgWindow'. I am 200px wide and 100px tall!</p>");
    },
    showUnlockBtn() {
      if (this.currentUser.canUnlock) {
        return this.itemproperties.filter((p) => p.d === true).length;
      }
      return false;
    },
    detailsUnlock() {
      this.disableUnlockBtn = true;
      this.itemproperties
        .filter(
          (p) =>
            p.d === true &&
            p.n !== "edited" &&
            p.n !== "uid_edited" &&
            p.n !== "created" &&
            p.n !== "edited" &&
            p.why !== "calculated field"
        )
        .forEach((p) => {
          p.d = false;
        });
    },
    sendOpenVPN(listonly = true, VLAN_ID = 0) {
      this.openVPNLoading = true;
      this.$http
        .post("api/requestOpenVPNConfig", {
          query: this.editquery,
          listonly: listonly,
          VLAN_ID: VLAN_ID,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            if (listonly) {
              this.vpnlist = response.data;
              if (this.vpnlist.length === 0) {
                this.submenu1 = null;
                this.$showMessage({
                  content: "No VLANs configured or allowed for this user",
                  color: "warning",
                  timeout: 4000,
                });
              }
            } else {
              this.$showMessage({
                content: response.data.message,
                color: response.data.success ? "success" : "error",
                timeout: 4000,
              });

              if (response.data.success) {
                this.submenu1 = null; // close submenu
              }
            }
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.submenu1 = null;
        })
        .finally(() => {
          this.openVPNLoading = false;
        });
    },
    resetOTP() {
      this.resetOTPLoading = true;
      this.$http
        .post("api/requestKCAction", {
          query: this.editquery,
          action: "resetOTP",
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.$showMessage({
              content: response.data.message,
              color: response.data.success ? "success" : "warning",
              timeout: 4000,
            });
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        })
        .finally(() => {
          this.resetOTPLoading = false;
        });
    },
    detailslink(params = "") {
      return (
        window.location.href.slice(0, -1) +
        "?sl=" +
        Buffer.from(
          "?q=" +
            this.editquery.id_query +
            "&l=" +
            this.editquery.id_link +
            "&v=" +
            this.editquery.id +
            (params !== "" ? "&" + params : "")
        ).toString('base64')
      );
    },
    copyLink() {
      navigator.clipboard.writeText(this.detailslink());
      this.$showMessage({
        content: "Link copied to clipboard",
        color: "success",
        timeout: 1500,
      });
    },
    createMail() {
      window.open(
        "mailto:?subject=" +
          encodeURIComponent(this.editquery.tablename) +
          "&body=" +
          encodeURIComponent("Link to Item:\r\n" + this.detailslink()) //eslint-disable-line
      );
    },
    showHistory() {
      this.historyVisible = true;
    },
    closeHistory() {
      this.historyVisible = false;
    },
    closeDependencies() {
      this.showDependencies = false;
    },
    jumpToProp(jumpto) {
      this.showFastJumpDialog = false;
      if (jumpto && jumpto.n) {
        this.$refs.form.$el
          .querySelectorAll(".v-input--is-focused")
          .forEach((obj) => obj.classList.remove("v-input--is-focused"));
        this.$refs[jumpto.n][0].setFocus();

        var tempprop = this.itemproperties.find((x) => x.n == jumpto.n);

        if (tempprop) {
          var tempdisabledvalue = tempprop.d;
          tempprop.d = false;

          this.$nextTick(() => {
            var elem = this.$refs[jumpto.n][0].$el;
            elem.scrollIntoView({
              behavior: "instant",
              block: "center",
            });
            elem.parentElement.classList.add("hightlightscrolltarget");
            window.setTimeout(function () {
              elem.parentElement.classList.remove("hightlightscrolltarget");
            }, 2000);

            tempprop.d = tempdisabledvalue;
          });
        }
      }
    },
    setIncludedProperties(includedProps) {
      if (includedProps == null) {
        return;
      }
      this.included_props = includedProps
        .replace(/\r?\n|\r/g, "")
        .split(/,|\[|\]/)
        .filter((element) => element.trim() !== "");
      this.included_props.push(...this.included_props.map((i) => "_" + i));
    },
    checkIncl(field) {
      if (this.included_props.length < 1) {
        return true;
      } else if (this.included_props.indexOf(field.n) > -1) {
        return true;
      } else if (this.included_props.indexOf(field.n) > -1) {
        return true;
      }
      return false;
    },
    closeSubDetails(subdetailssaved) {
      if (subdetailssaved.detailsSaved) {
        if (this.UnsavedChanges) {
          this.$showMessage({
            content:
              "Please note: the complete changes in the sub-list will only be visible when you save your changes and open the record again.",
            color: "warning",
            timeout: 7000,
          });
        } else {
          this.ignoreinput = true;
          this.getDetails(false, false);
        }
      }
    },
    onOuterScroll() {
      this.scrldwn =
        this.$refs.outer.scrollTop < this.$refs.inner.scrollHeight / 3;
    },
    getChangeVisualizationPart1(property) {
      if (typeof this.itemproperties.length !== "undefined") {
        var baseProperty = this.itemproperties.find(
          (x) => x.s === property.n || x.n === property.n
        );
        return baseProperty ? baseProperty.a : property.n;
      }
      return null;
    },
    getChangeVisualizationPart2(property) {
      return property.show ? property.show : property.v;
    },
    toggleModulChanges(propertyName) {
      var index = this.ModulChanges.indexOf(propertyName);
      if (-1 !== index) {
        this.ModulChanges.splice(index, 1);
      } else {
        this.ModulChanges.push(propertyName);
      }
    },
    resetFieldOrder() {
      this.resetOrderFieldsActive = true;

      this.$setPref({
        TabName: "FieldOrder",
        PrefID: this.editquery.id_query.toString(),
        Value: "",
        Save: true,
      }).then(() => {
        this.$showMessage({
          content: "field order reset - Item will be reopened",
          color: "success",
          timeout: 2000,
        });

        this.$emit("reopen");
      });
    },
    setFieldOrder() {
      var fieldelements = this.$refs.inner.querySelectorAll(".col[fn]");

      var newfieldorder = [];
      fieldelements.forEach(function (fieldelement) {
        newfieldorder.push(fieldelement.getAttribute("fn"));
      });
      var newfieldorderString = newfieldorder.join("|");

      this.$setPref({
        TabName: "FieldOrder",
        PrefID: this.editquery.id_query.toString(),
        Value: newfieldorderString,
        Save: true,
      }).then(() => {
        this.$showMessage({
          content: "field order set - Item will be reopened",
          color: "success",
          timeout: 2000,
        });
        this.$emit("reopen");
      });
    },
    getProps(item, queryText, itemText) {
      return itemText
        .toLocaleLowerCase()
        .startsWith(queryText.toLocaleLowerCase());
    },
    navigate(previous) {
      this.confirm("navigate_" + (previous ? "prev" : "next"));
    },
    setHeadline() {
      if (this.editquery.id === 0) {
        this.headlineclass =
          "green " + (!this.$vuetify.theme.dark ? "lighten" : "darken") + "-1";
        //this.detailsclass = "green " + (!this.$vuetify.theme.dark ? "lighten" : "darken") + "-4";
        this.headline = this.editquery.headline + ": Create New Item";
      } else {
        this.headlineclass =
          this.editquery.id < 0
            ? "lime " +
              (!this.$vuetify.theme.dark ? "lighten" : "darken") +
              "-1"
            : "";
        this.detailsclass =
          this.editquery.id < 0
            ? "lime " +
              (!this.$vuetify.theme.dark ? "lighten" : "darken") +
              "-4"
            : "";
        this.headline =
          this.editquery.headline +
          " " +
          (this.editquery.id < 0 ? "Create Copy" : "");
        this.blockedBy =
          this.blockedBy != ""
            ? "" + this.blockedBy + " is currently editing this record."
            : "";
      }

      // if (this.ShowIdentifierColumn) {
      //   this.headline +=
      //     " (" + this.editquery.identifier + ": " + this.editquery.id + ")";
      // }
    },
    newPatchState(ps) {
      var PatchStatusProp = this.itemproperties.find(
        (x) => x.a === "Patch Status"
      );
      if (PatchStatusProp) {
        PatchStatusProp.v = ps;
      }
    },
    checkcond(args) {
      var tobechecked = "";
      switch (args.fncname) {
        case "emplname":
          if (
            this.$refs[this.surnameFieldName] &&
            this.$refs[this.firstnamesFieldName] &&
            this.$refs[this.surnameFieldName].length === 1 &&
            this.$refs[this.firstnamesFieldName].length === 1
          ) {
            tobechecked =
              this.$refs[this.surnameFieldName][0].returnValue.v +
              ", " +
              this.$refs[this.firstnamesFieldName][0].returnValue.v;
          }
          break;
      }

      var tempthis = this;

      if (tobechecked !== "") {
        this.$http
          .post("api/checkcond", {
            query: this.editquery,
            fnc: args.fncname,
            cond: tobechecked,
          })
          .then((response) => {
            this.tobecheckedorigid = this.editquery.id;
            if (typeof response.data === "string") {
              throw "malformatted JSON Data";
            }

            if (response.data.error) {
              throw response.data.error;
            } else if (response.data.results) {
              var message = "";
              switch (args.fncname) {
                case "emplname":
                  if (
                    response.data.results.tables &&
                    response.data.results.tables[0].Table.data[0].Anz !== "0"
                  ) {
                    tempthis.$refs[
                      this.surnameFieldName
                    ][0].$refs.txtfld.$el.style.backgroundColor = "orange";
                    this.resetElements.push(
                      tempthis.$refs[this.surnameFieldName][0].$refs.txtfld.$el
                    );
                    tempthis.$refs[
                      this.firstnamesFieldName
                    ][0].$refs.txtfld.$el.style.backgroundColor = "orange";
                    this.resetElements.push(
                      tempthis.$refs[this.firstnamesFieldName][0].$refs.txtfld
                        .$el
                    );
                    message =
                      "Name existiert bereits! Bitte prüfen Sie, ob Sie wirklich eine weitere Person gleichen Namens in der Datenbank hinterlegen wollen.";
                  } else {
                    tempthis.$refs[
                      this.surnameFieldName
                    ][0].$refs.txtfld.$el.style.backgroundColor = null;
                    tempthis.$refs[
                      this.firstnamesFieldName
                    ][0].$refs.txtfld.$el.style.backgroundColor = null;
                  }
                  break;
              }

              if (message !== "") {
                this.$showMessage({
                  content: message,
                  color: "error",
                  timeout: 4000,
                });
              }
            }
          })
          .catch((e) => {
            var message =
              e.response && e.response.data
                ? e.response.data
                : e.message
                ? e.message
                : e;
            this.$showMessage({ content: message, color: "error" });
            this.loading = false;
            this.showLoadingCircle = false;
            this.resetting = false;
            this.show = false;
          });
      }
    },

    vColClass(field) {
      var flength =
        field.t === "boolean"
          ? field.a.length + 7
          : (field.avrlen >
            (field.v && field.v !== null && field.v.length
              ? field.v.length
              : 20)
              ? field.avrlen
              : field.v && field.v !== null && field.v.length
              ? field.v.length
              : 20) +
            (field.t === "COMBO" ? (field.id_link !== null ? 12 : 8) : 4);
      var colwdthstl = "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2";

      if (field.a.toLowerCase() === "bundle title") {
        colwdthstl = "col-10 col-sm-10 col-md-10 col-lg-10 col-xl-6";
      } else if (
        flength > 120 ||
        field.a.toLowerCase().indexOf("remark") === 0 ||
        field.a.toLowerCase().indexOf("record") === 0
      ) {
        colwdthstl = "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8";
      } else if (flength > 80) {
        colwdthstl = "col-12 col-sm-12 col-md-8 col-lg-9 col-xl-6";
      } else if (flength > 40) {
        colwdthstl = "col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4";
      }

      return colwdthstl;
    },

    field(index) {
      return this.itemproperties && this.itemproperties[index]
        ? this.itemproperties[index]
        : { t: "" };
    },

    refreshphotofunc() {
      this.refreshphoto++;
    },

    refreshfilesfunc() {
      this.refreshfile++;
    },

    Changes(params) {
      if (
        params["params"] &&
        params["params"].grpId === this.grphsh + "_" + this.editquery.id
      ) {
        switch (params["params"].what) {
          case "item blocked":
            for (let field in this.itemproperties) {
              this.itemproperties[field].d = true;
            }
            this.itemrights.write = false;
            this.itemrights.delete = false;
            this.blockedBy = params["params"].content;

            this.setHeadline();
            break;

          case "item could not be blocked":
            this.$showMessage({
              content: params["params"].content,
              color: "error",
              timeout: 4000,
            });
            break;
          case "item unblocked":
            this.blockedBy = "";
            this.ignoreinput = true;
            this.getDetails();
            if (this.$refs["Photo"]) {
              this.$refs["Photo"][0].getPhoto();
            }
            break;
          case "item deleted":
            this.blockedBy = "";
            this.show = false;
            if (
              this.currentUser.account.toLowerCase() !==
              params["params"].content.toLowerCase()
            ) {
              this.$showMessage({
                content: "Item has been deleted by " + params["params"].content,
                color: "error",
                timeout: 10000,
              });
            }
            break;
          case "item unblocked after inactivity":
            if (params["params"].content === this.currentUser.account) {
              this.show = false;
            } else {
              this.blockedBy = "";
              this.getDetails();
              if (this.$refs["Photo"]) {
                this.$refs["Photo"][0].getPhoto();
              }
            }
            break;
          case "photo changed":
            if (this.$refs["Photo"] && this.$refs["Photo"].length === 1) {
              if (this.$refs["Photo"]) {
                this.$refs["Photo"][0].getPhoto();
              }
            }
            break;
        }
      }
    },
    checkcondonclientside(source) {
      if (this.editquery.tablename.indexOf("ardware") > -1) {
        if (
          typeof source === "undefined" ||
          source.n === "Name" ||
          source.n === "HW_Status"
        ) {
          var Name = this.getPropertyValue("Name", false);
          var HW_Status = this.getPropertyValue("HW_Status", false);
          var message = "";
          if (Name !== null && HW_Status !== null) {
            if (
              Name.show !== "allgemein, allgemein" &&
              Name.show !== "" &&
              HW_Status.show === "im Lager"
            ) {
              this.$refs.Name[0].$refs.txtfld.$el.style.backgroundColor =
                "orange";
              this.resetElements.push(this.$refs.Name[0].$refs.txtfld.$el);
              this.$refs.HW_Status[0].$refs.txtfld.$el.style.backgroundColor =
                "orange";
              this.resetElements.push(this.$refs.HW_Status[0].$refs.txtfld.$el);
              message =
                "Hardware ist angeblich bei Nutzer, der Status lautet aber 'im Lager'. \nEntweder Namen auf 'allgemein, allgemein' setzen oder den Status korrigieren.";
              this.$showMessage({
                content: message,
                color: "warning",
                timeout: 8000,
              });
            } 
            // else if (
            //   Name.show === "allgemein, allgemein" &&
            //   HW_Status.show === "bei Nutzer"
            // ) {
            //   this.$refs.Name[0].$refs.txtfld.$el.style.backgroundColor =
            //     "orange";
            //   this.resetElements.push(this.$refs.Name[0].$refs.txtfld.$el);
            //   this.$refs.HW_Status[0].$refs.txtfld.$el.style.backgroundColor =
            //     "orange";
            //   this.resetElements.push(this.$refs.HW_Status[0].$refs.txtfld.$el);
            //   message =
            //     "Hardware ist angeblich bei 'allgemein, allgemein', der Status lautet aber 'bei Nutzer'. \nEntweder Namen auf echten Nutzer setzen oder den Status korrigieren.";
            //   this.$showMessage({
            //     content: message,
            //     color: "warning",
            //     timeout: 8000,
            //   });
            // } 
            else if (
              this.$refs.Name !== null &&
              this.$refs.HW_Status !== null
            ) {
              this.$refs.Name[0].$refs.txtfld.$el.style.backgroundColor = "";
              this.$refs.HW_Status[0].$refs.txtfld.$el.style.backgroundColor =
                "";
            }
          }
        }
      }
    },

    resetIgnoreinput() {
      this.ignoreinput = false;
    },

    userinput(source, included_fields) {
      if (included_fields) {
        this.setIncludedProperties(included_fields);
      }

      this.checkcondonclientside(source);

      var dependingCombos = this.itemproperties.filter(
        (x) =>
          x.cff != null &&
          x.cff.toLocaleLowerCase() === source.s.toLocaleLowerCase()
      );

      dependingCombos.forEach(
        (dCombo) =>
          (dCombo.cffValue = parseInt(this.$refs[source.n][0].returnValue.v))
      );

      if (!this.ignoreinput) {
        this.$changesHub.detailsChanged(this.detailshash);
        this.UnsavedChanges = true;
      }

      this.ignoreinput = true;

      if (resetIgnoreinputtimer) {
        window.clearTimeout(resetIgnoreinputtimer);
      }

      resetIgnoreinputtimer = window.setTimeout(this.resetIgnoreinput, 60000);
    },

    refreshSettings() {
      this.AlternativeDetailsLayout = this.$getPref({
        TabName: "Options",
        PrefID: "AlternativeDetailsLayout",
      });
      if (this.AlternativeDetailsLayout == null) {
        this.AlternativeDetailsLayout = false;
      }
      this.IconButtons = this.$getPref({
        TabName: "Options",
        PrefID: "IconButtons",
      });
      if (this.IconButtons == null) {
        this.IconButtons = false;
      }
      this.SaveAndClose = this.$getPref({
        TabName: "Options",
        PrefID: "SaveAndClose",
      });
      if (this.SaveAndClose == null) {
        this.SaveAndClose = false;
      }

      var temp = this.$getPref({
        TabName: "Options",
        PrefID: "ShowIdentifierColumn",
      });
      if (temp !== null && temp !== this.ShowIdentifierColumn) {
        this.ShowIdentifierColumn = temp;
      }
      if (this.ShowIdentifierColumn == null) {
        this.ShowIdentifierColumn = false;
      }
    },

    getDetails(showloading = false, focusOnFirstfield = true) {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.historyBtnVisible = false;
      this.mailBtnVisible = false;
      this.disableUnlockBtn = false;

      if (this.resetElements.length > 0) {
        this.resetElements.forEach((x) => {
          x.style.backgroundColor = "";
        });
      }

      if (showloading) {
        this.showLoadingCircle = true;
      }
      let tempthis = this;

      this.$http
        .post("api/detail", {
          query: this.editquery,
          idfilter: this.idfilter,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            tempthis.itemproperties = response.data.item;
            if (tempthis.itemproperties[0] === null) {
              tempthis.show = false;
              return;
            }
            var jumptodetail = tempthis.itemproperties.find(
              (p) => p.a === "jumptodetail"
            );
            if (jumptodetail && jumptodetail.v) {
              var parts = jumptodetail.v.split("_");
              window.location =
                "?q=" + parts[0] + "&l=" + parts[1] + "&v=" + parts[2];
              return;
            }

            if (tempthis.editquery.newvalfieldname) {
              var newProp = tempthis.itemproperties.find(
                (x) => x.n.toLowerCase() === tempthis.editquery.newvalfieldname
              );
              if (newProp) {
                newProp.v = tempthis.editquery.newvalfieldvalue;
              }
            }

            tempthis.UnsavedChanges = false;

            if (tempthis.showTableLayout) {
              var icnt = -1;
              var bpnt = Math.ceil(
                tempthis.itemproperties.length /
                  tempthis.TableLayoutColumnNumber
              );
              for (var i = 0; i < tempthis.itemproperties.length; i++) {
                if (i % bpnt === 0) {
                  icnt++;
                  tempthis.tablelayoutcolumnitemproperties[icnt] = [];
                }

                tempthis.tablelayoutcolumnitemproperties[icnt].push(
                  tempthis.itemproperties[i]
                );
              }
            }

            var isdark = tempthis.$vuetify.theme.dark;
            tempthis.itemproperties.forEach((p) => {
              if (typeof p.bc !== "undefined" && p.bc !== "") {
                p.bc = this.$getColor(isdark, p.bc);
              }
            });
            tempthis.fieldorder = Object.keys(tempthis.itemproperties);
            tempthis.itemrights = response.data.rights;
            tempthis.filesvisible = response.data.hasAttachments;
            tempthis.editquery.id = parseInt(response.data.id);
            tempthis.editquery.identifier = response.data.identifier;
            tempthis.editquery.headline = response.data.headline;

            tempthis.hasPrevious = this.editquery.hasPrevious;
            tempthis.hasNext = this.editquery.hasNext;

            tempthis.grphsh = response.data.grphsh;
            if (tempthis.detailshashOrig != tempthis.detailshash) {
              if (tempthis.detailshashOrig !== "") {
                this.$changesHub.leaveDetailsGroup(tempthis.detailshashOrig);
              }
              tempthis.detailshashOrig = tempthis.detailshash;
            }
            this.$changesHub.joinDetailsGroup(this.detailshash);

            tempthis.blockedBy = response.data.blockedBy;
            tempthis.hasHistory = response.data.hasHistory;

            if (tempthis.isfiltered) {
              let fixedValueFor = tempthis.itemproperties.find(
                (o) => o.s === tempthis.idfilter.identifier.toLowerCase()
              );
              if (fixedValueFor) {
                fixedValueFor.d = true;
                fixedValueFor.why = "fixed value when opened from parent item";
                fixedValueFor.v = "";
                fixedValueFor.forceload = true;
                fixedValueFor.sid = tempthis.idfilter.id;

                tempthis.itemproperties.unshift(
                  tempthis.itemproperties.splice(
                    tempthis.itemproperties.findIndex(
                      (item) => item.n === fixedValueFor.n
                    ),
                    1
                  )[0]
                );
              }
            }

            let tbgcolor = tempthis.itemproperties.find(
              (x) => x.n === "_bgcolor"
            );
            if (tbgcolor && tbgcolor.v) {
              tempthis.bgcolor = this.$getColor(
                tempthis.$vuetify.theme.dark,
                tbgcolor.v
              );
              // tbgcolor.v =
              //   "#" +
              //   (Number(`0x1${tbgcolor.v.substr(1)}`) ^ 0xffffff)
              //     .toString(16)
              //     .substr(1)
              //     .toUpperCase();
            } else {
              tempthis.bgcolor = "";
            }

            this.loading = false;
            this.showLoadingCircle = false;
            this.resetting = false;
            this.navigatedisabled = false;

            this.$nextTick(() => {
              this.setHeadline();
              this.historyBtnVisible = this.hasHistory && this.editquery.id > 0;
              this.mailBtnVisible = this.editquery.id > 0;
              var checkElem = null;
              for (var ref in this.$refs) {
                if (this.$refs[ref]) {
                  checkElem = this.$refs[ref][0];
                  if (checkElem) {
                    if (this.editquery.id < 0) {
                      if (checkElem.setUnsaved) {
                        checkElem.setUnsaved();
                      }
                    }
                    if (checkElem.initialize) {
                      checkElem.initialize();
                    }
                  }
                }
              }

              var preloadcombos = tempthis.itemproperties.filter(
                (p) => p.forcepreload === true
              );
              preloadcombos.forEach(function (combo) {
                if (tempthis.$refs[combo.n] && tempthis.$refs[combo.n][0]) {
                  tempthis.$refs[combo.n][0].getOptions("", 0);
                }
              });

              this.$refs.form.validate(); // forces field validation especially on new items
              this.checkcond({ fncname: "emplname" });

              window.setTimeout(function () {
                tempthis.validateThis(focusOnFirstfield);
                tempthis.ignoreinput = false;
              }, 500);
            });

            this.$http
              .post("api/getAttachmentTypes", {
                query: this.editquery,
              })
              .then((result) => {
                this.AttachmentTypes = result.data.data;
                this.refreshfilesfunc();
              })
              .catch((e) => {
                var message =
                  e.response && e.response.data
                    ? e.response.data
                    : e.message
                    ? e.message
                    : e;
                this.$showMessage({ content: message, color: "error" });
              });
          }

          tempthis.$refs.outer.addEventListener(
            "scroll",
            tempthis.onOuterScroll
          );
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
          this.resetting = false;
          this.show = false;
        });
    },

    validateThis(focusOnFirstfield) {
      this.$refs.form.validate();

      if (this.$refs.inner) {
        this.scrollbtnvisible =
          this.$refs.inner.offsetHeight >
          this.$refs.inner.parentElement.offsetHeight;
      }

      // set Focus to first element
      if (focusOnFirstfield) {
        if (this.editquery.j) {
          var jumpProp = this.itemproperties.find(
            (p) => p.n === this.editquery.j
          );
          if (jumpProp) {
            this.jumpToProp(jumpProp);
          }
        } else {
          for (var cnt = 0; cnt < this.itemproperties.length; cnt++) {
            if (
              !this.itemproperties[cnt].d &&
              this.$refs[this.itemproperties[cnt].n] &&
              this.$refs[this.itemproperties[cnt].n][0] &&
              this.$refs[this.itemproperties[cnt].n][0].setFocus
            ) {
              this.$refs[this.itemproperties[cnt].n][0].setFocus();
              break;
            }
          }
        }
      }

      this.checkcondonclientside();

      // initiate depending combos

      var sources = this.itemproperties.filter((x) => x.t === "COMBO");

      sources.forEach((source) => {
        var dependingCombos = this.itemproperties.filter(
          (x) =>
            x.cff != null &&
            x.cff.toLocaleLowerCase() === source.s.toLocaleLowerCase()
        );

        dependingCombos.forEach(
          (dCombo) =>
            (dCombo.cffValue = parseInt(this.$refs[source.n][0].returnValue.v))
        );
      });
    },

    getPropertyValue(refname, onlyChanged = true) {
      if (this.$refs[refname]) {
        var checkElem = this.$refs[refname][0];
        if (
          checkElem &&
          checkElem.field &&
          (!checkElem.field.d ||
            (this.editquery.id < 1 &&
              (checkElem.field.sid !== 0 || checkElem.field.forceload))) &&
          checkElem.returnValue &&
          (!onlyChanged ||
            (onlyChanged && checkElem.changed) ||
            this.editquery.id < 1)
        ) {
          if (refname === "Photo") {
            return checkElem.returnValue.v;
          } else {
            return checkElem.returnValue;
          }
        }
      }
      return null;
    },
    getChangedElements() {
      this.Photo = null;
      var keyvaluepairs = [];
      var pvalue = null;
      for (var ref in this.$refs) {
        if (ref === "Photo") {
          this.Photo = this.getPropertyValue(ref);
        } else {
          pvalue = this.getPropertyValue(ref);
          if (pvalue !== null) {
            keyvaluepairs.push(pvalue);
          }
        }
      }
      return keyvaluepairs;
    },
    getChangedElementsForModulCheck() {
      var changedElems = this.getChangedElements();

      if (
        this.editquery &&
        this.editquery.tablename &&
        this.editquery.tablename.indexOf("Assets") > -1
      ) {
        var fieldpos = changedElems.findIndex((x) => x.n === "Description");
        if (fieldpos > -1) {
          changedElems.splice(fieldpos, 1);
        }
      } else if (
        this.editquery &&
        this.editquery.tablename &&
        this.editquery.tablename.indexOf("Hardware") > -1
      ) {
        var fieldpos2 = changedElems.findIndex((x) => x.n === "Bezeichnung");
        if (fieldpos2 > -1) {
          changedElems.splice(fieldpos2, 1);
        }
      }
      return changedElems;
    },
    saveChanges(closeDetails) {
      this.saveandcloseclicked = closeDetails;
      document.activeElement.blur();
      this.$nextTick(() => {
        var keyvaluepairs = this.getChangedElements();
        if (keyvaluepairs.length === 0 && !this.Photo) {
          this.$showMessage({
            content: "no changes detected. nothing saved.",
            color: "info",
          });
          if (closeDetails === true) {
            this.show = false;
          }
          return;
        }
        if (!this.saveModulsDialogShow) {
          var Module = this.itemproperties.find(
            (x) => x.n.substr(0, 6) === "Module"
          );
          if (Module && this.$refs[Module.n]) {
            if (this.$refs[Module.n].length > 0) {
              this.elementCount = this.$refs[Module.n][0].elementCount;
              if (this.elementCount > 0) {
                this.changedElementsForModulCheck =
                  this.getChangedElementsForModulCheck();
                if (this.changedElementsForModulCheck.length > 0) {
                  this.saveModulsDialogShow = true;
                  return;
                }
              }
            }
          }
        }
        this.saveModulsDialogShow = false;

        let formData = new FormData();
        formData.append("editquery", JSON.stringify(this.editquery));

        var changedFileNotes = [];
        var pairsWithFilenotes = keyvaluepairs.filter(
          (p) => p.ChangedFileNotes
        );
        if (pairsWithFilenotes && pairsWithFilenotes.length > 0) {
          pairsWithFilenotes.forEach((f_array) => {
            changedFileNotes.push(...f_array.ChangedFileNotes);
          });
          keyvaluepairs.push({ n: "file notes", v: changedFileNotes });
        }

        formData.append("values", JSON.stringify(keyvaluepairs));

        var filenotes = [];
        var pairsWithFiles = keyvaluepairs.filter((p) => p.files);
        if (pairsWithFiles && pairsWithFiles.length > 0) {
          pairsWithFiles.forEach((f_array) => {
            var hasNoNotes = typeof f_array.FileNotes === "undefined";

            f_array.files.forEach((file) => {
              if (file && file.name) {
                formData.append("0|" + file.name, file);

                if (hasNoNotes) {
                  filenotes.push({
                    attype: 0,
                    name: file.name,
                    size: file.size,
                    scaledown: true,
                    isLargeImage: false,
                  });
                }
              }
            });

            if (!hasNoNotes) {
              filenotes.push(...f_array.FileNotes);
            }
          });
          formData.append("filenotes", JSON.stringify(filenotes));
        }

        if (this.ModulChanges.length > 0) {
          formData.append("modulchanges", JSON.stringify(this.ModulChanges));
        }

        if (
          this.$refs.mksfiles &&
          this.$refs.mksfiles[0] &&
          this.$refs.mksfiles[0].getcopyfiles
        ) {
          formData.append(
            "copyfiles",
            JSON.stringify(this.$refs.mksfiles[0].getcopyfiles)
          );
        }

        if (this.Photo) {
          formData.append("26_" + this.Photo.name, this.Photo);
        }

        let tempthis = this;
        this.saving = true;

        this.$http
          .post("api/saveChanges", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((result) => {
            this.editquery.id = parseInt(result.data);
            this.detailsSaved = true;
            this.$showMessage({
              content: "values saved successfully",
              color: "success",
              timeout: 1000,
            });

            if (closeDetails === true) {
              this.show = false;
            } else {
              this.resetdata(true);
            }
          })
          .catch((e) => {
            var message =
              e.response && e.response.data
                ? e.response.data
                : e.message
                ? e.message
                : e;

            if (tempthis.resetElements.length > 0) {
              tempthis.resetElements.forEach((x) => {
                x.style.backgroundColor = "";
              });
            }

            message = this.highlightPropsWithErrors(message);

            this.$showMessage({ content: message, color: "error" });
          })
          .finally(() => {
            this.saving = false;
            // this.detailsMode = "default";
          });
      });
    },
    highlightPropsWithErrors(message) {
      var messageParts = message.split("|");
      if (messageParts.length > 1) {
        message = messageParts[0];

        var propNames = messageParts[1].split(",");
        var elem;

        if (propNames[0] === "itemhasdependencies") {
          this.showDependencies = true;
        } else if (propNames[0] === "err001") {
          propNames = [];
          this.itemproperties
            .filter((x) => x.l > 0 && x.t === "string")
            .forEach((property) => {
              var val = this.getPropertyValue(property.n);
              if (val && val.v && val.v.length > property.l) {
                propNames.push(property.n);
              }
            });
        }
        propNames.forEach((propName) => {
          elem = null;
          if (this.$refs[propName] && this.$refs[propName][0]) {
            if (this.$refs[propName][0].$refs.txtfld) {
              elem = this.$refs[propName][0].$refs.txtfld.$el;
            } else if (this.$refs[propName][0].$el) {
              elem = this.$refs[propName][0].$el;
            }
          }
          if (!elem) {
            propName = propName.toLowerCase();
            propName = this.itemproperties.find((x) => x.s === propName);
            if (propName) {
              propName = propName.n;
            }
            if (this.$refs[propName] && this.$refs[propName][0]) {
              if (this.$refs[propName][0].$refs.txtfld) {
                elem = this.$refs[propName][0].$refs.txtfld.$el;
              } else if (this.$refs[propName][0].$el) {
                elem = this.$refs[propName][0].$el;
              }
            }
          }
          if (elem) {
            elem.style.backgroundColor = "orange";
            elem.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            this.resetElements.push(elem);

            var tabheader = elem.querySelector("header");
            if (tabheader) {
              tabheader.style.backgroundColor = "orange";
              this.resetElements.push(tabheader);
            }
          }
        });
      }
      return message;
    },
    resetdata(force = false) {
      if (!force && this.UnsavedChanges) {
        this.showconfirmreset = true;
      } else {
        this.loading = false;
        this.resetting = true;
        this.showLoadingCircle = false;
        this.ignoreinput = true;
        this.UnsavedChanges = false;

        this.$changesHub.leaveDetailsGroup(this.detailshash);
        this.grphsh = "";
        this.getDetails(false, false);

        this.refreshfilesfunc();
      }
    },
    confirm(action) {
      var showConfirmationDialog = this.UnsavedChanges || action === "delete";

      if (showConfirmationDialog) {
        this.confirmDialog = this.confirmDialogTemplate.find(
          (x) => x.action === action
        );
        this.confirmDialog.show = true;
      } else {
        this.confirmResult(action);
      }
    },
    confirmResult(action) {
      switch (action) {
        case "closeconfirmdialog":
          this.confirmDialog = this.confirmDialogTemplate[0];
          break;
        case "cancel":
          this.show = false;
          break;
        case "reset":
          this.resetdata(true);
          break;
        case "resetfieldorder":
          this.resetFieldOrder();
          break;
        case "setfieldorder":
          this.setFieldOrder();
          break;
        case "delete":
          this.deleteitem();
          break;
        case "navigate_prev":
          this.ignoreinput = true;
          this.scrldwn = true;
          this.$emit("navigate", true);
          break;
        case "navigate_next":
          this.ignoreinput = true;
          this.scrldwn = true;
          this.$emit("navigate", false);
          break;

        default:
      }
      this.confirmDialog = this.confirmDialogTemplate[0];
    },
    jumptoend() {
      if (this.scrldwn) {
        this.$refs.jumpdummybottom.scrollIntoView({
          behavior: "smooth",
        });
      } else {
        this.$refs.jumpdummytop.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    newitem() {
      this.editquery.id = 0;
    },
    copyitem() {
      this.editquery.id = -this.editquery.id;
    },
    deleteitem() {
      let formData = new FormData();
      formData.append("editquery", JSON.stringify(this.editquery));
      formData.append("deleteItem", true);

      this.$http
        .post("api/saveChanges", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.show = false;

          this.$showMessage({
            content: "item deleted successfully",
            color: "success",
            timeout: 5000,
          });
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;

          message = this.highlightPropsWithErrors(message);

          this.$showMessage({ content: message, color: "error" });
        });
    },
    getAutofocus(fielddisabled) {
      if (!fielddisabled && this.$options.autofocusFirstElement) {
        this.$options.autofocusFirstElement = false;
        return true;
      }
      return false;
    },

    // exportfileVar2() {
    //   var winURL = "api/getExportContentGet";

    //   var params =
    //     this.editquery.id_query +
    //     "." +
    //     this.editquery.id_link +
    //     "." +
    //     this.editquery.id +
    //     "." +
    //     this.editquery.identifier +
    //     "." +
    //     this.selectedExport;

    //   // window.open(winURL + "?exp=" + params, 'FileExport');

    //   window.location.href = winURL + "?exp=" + params;

    //   this.$nextTick(() => {
    //     this.selectedExport = null;
    //   });
    // },

    exportfile() {
      document.body.classList.add("busy-cursor");

      this.$http
        .post("api/getExportFile", {
          query: this.editquery,
          atype: this.selectedExport,
        })
        .then((response) => {
          if (response.data !== "") {
            let fileName = decodeURI(
              response.headers["content-disposition"]
                .split("filename=")[1]
                .split(";")[0]
            );
            var tempthis = this;
            var req = new XMLHttpRequest();
            req.open("GET", "data:application/octet;base64," + response.data);
            req.responseType = "arraybuffer";
            req.onload = function fileLoaded(e) {
              var byteArray = new Int8Array(e.target.response);
              tempthis.downloadFile(
                byteArray,
                fileName,
                response.headers["content-type"]
              );
            };
            req.send();
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          document.body.classList.remove("busy-cursor");
        });

      this.$nextTick(() => {
        this.selectedExport = null;
      });
    },

    downloadFile(data, fileName, type = "text/plain") {
      // Create an invisible A element
      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);

      // Set the HREF to a Blob representation of the data to be downloaded
      a.href = window.URL.createObjectURL(new Blob([data], { type }));

      // Use download attribute to set set desired file name
      a.setAttribute("download", fileName);

      // Trigger the download by simulating click
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
      document.body.classList.remove("busy-cursor");
    },
  },
  created() {
    this.refreshSettings();

    this.$changesHub.$on("Changes", this.Changes);

    this.surnameFieldName =
      process.env.VUE_APP_TITLE === "HomeDB2.0" ||
      this.currentUser.webname.indexOf("HomeDB") > -1 ||
      this.currentUser.webname.indexOf("TLabs_Data") > -1
        ? "Nachname"
        : "Surname";

    this.firstnamesFieldName =
      process.env.VUE_APP_TITLE === "HomeDB2.0" ||
      this.currentUser.webname.indexOf("HomeDB") > -1 ||
      this.currentUser.webname.indexOf("TLabs_Data") > -1
        ? "Vornamen"
        : "First_Names";

    if (this.editquery.print) {
      this.getDetails(this.firstcall);
    }
  },
  beforeDestroy() {
    this.$changesHub.$off("Changes", this.Changes);
  },
};
</script>
