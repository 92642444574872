<template>
  <div>
    <v-dialog
      v-model="show"
      scrollable
      v-bind:retain-focus="true"
      persistent
      no-click-animation
    >
      <v-card :style="dlgStyle">
        <v-card-title :class="headlineclass" style="position: relative">
          <span class="mksheadline" :dialogid="$el ? $el.__vue__._uid : ''">
            Set exception for {{ title }}
          </span>
          <v-spacer></v-spacer>

          <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle> Exception from series </v-card-subtitle>

        <v-card-text>
          <v-container>
            <v-row class="align-start">
              <v-col cols="8">
                <v-row class="align-center">
                  <v-col cols="3">Start time </v-col>
                  <v-col cols="4">
                    <mksdatesimple
                      ref="start_date1"
                      :date="currentSlot.start_date"
                      :fieldname="'start_date'"
                      :elemError="elemErrors['start_date1']"
                      @updatedate="updatedate"
                    >
                    </mksdatesimple>
                  </v-col>
                  <v-col cols="2">
                    <mksslottimeonly
                      :disabled="currentSlot.all_day"
                      ref="from"
                      label=""
                      :elemError="elemErrors['from']"
                      :required="true"
                      :timeonlyval="currentSlot.from"
                      @changed="prepDurationFrom"
                    >
                    </mksslottimeonly>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      @change="alldaytoggle()"
                      dense
                      v-model="currentSlot.all_day"
                      label="All day"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="3"> </v-col>
                  <v-col cols="4"> </v-col>
                  <v-col cols="2">
                    <mksslottimeonly
                      :disabled="currentSlot.all_day"
                      ref="to"
                      label=""
                      :elemError="elemErrors['to']"
                      :required="false"
                      :timeonlyval="currentSlot.to"
                      @changed="prepDurationTo"
                    >
                    </mksslottimeonly>
                  </v-col>
                </v-row>
                <v-row class="align-center">
                  <v-col cols="3"> </v-col>
                  <v-col cols="6">
                    <v-checkbox
                      dense
                      v-model="currentSlot.canceled"
                      :label="'Does not take place on ' + notonday"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              reservationslot.start_orig !== null &&
              reservationslot.start_orig !== '0001-01-01T00:00:00'
            "
            class="mr-4 selected"
            color="info"
            @click="resetSlot"
            >reset values
          </v-btn>
          <v-btn class="mr-4 selected" color="warning" @click="show = false"
            >cancel
          </v-btn>
          <v-btn
            class="mr-4"
            color="success"
            @click="saveSlot"
            :loading="saving"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mksslottimeonly from "@/components/mksslottimeonly.vue";
import mksdatesimple from "@/components/mksdatesimple.vue";

export default {
  name: "mksresourcereservationexception",

  components: {
    mksslottimeonly,
    mksdatesimple,
  },

  data: () => ({
    saving: false,
    comp: {},
    alldaytmpvalues: [],
    // confirmDialogShow: false,
    headlineclass: "",
    currentSlot: {
      start_date: "",
      from: "00:00",
      to: "00:00",
    },
    elemErrors: [],
  }),

  props: {
    title: {
      type: String,
      required: true,
      default: () => "",
    },
    reservationslot: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  mounted() {
    this.currentSlot.bookingId = this.reservationslot.bookingId;
    this.currentSlot.start_date =
      this.reservationslot.start.substr(0, 10) + "T00:00:00";

    this.currentSlot.from = this.reservationslot.start_wst
      ? this.reservationslot.start_wst.substr(11, 5)
      : this.reservationslot.start.substr(11, 5);

    this.currentSlot.to = this.reservationslot.end_wtt
      ? this.reservationslot.end_wtt.substr(11, 5)
      : this.reservationslot.end.substr(11, 5);

    this.currentSlot.canceled = this.reservationslot.canceled;

    this.comp = { ...this.currentSlot };
  },

  methods: {
    saveSlot() {
      if (this.checkChange()) {
        // this.$emit('close', { "old": this.comp, "new": this.currentSlot });
        this.saveException();
      } else {
        this.show = false;
      }
    },
    resetSlot() {
      this.currentSlot.start_date = this.reservationslot.start_orig.substr(
        0,
        10
      );
      this.currentSlot.from = this.reservationslot.start_orig.substr(11, 5);
      this.currentSlot.to = this.reservationslot.end_orig.substr(11, 5);
      this.currentSlot.reset = true;
    },
    saveException() {
      var tempthis = this;
      this.saving = true;

      this.$http
        .post("api/ResourceReservationExceptionSave", {
          query: this.editquery,
          exception: {
            start: this.currentSlot.start_date.substr(0,10) + "T" + this.currentSlot.from,
            end: this.currentSlot.start_date.substr(0,10) + "T" + this.currentSlot.to,
            start_orig: this.reservationslot.ide === 0 ? this.reservationslot.start_wst : this.reservationslot.start_orig,
            bookingId: this.reservationslot.bookingId,
            ide: this.reservationslot.ide,
            canceled: this.currentSlot.canceled,
            reset: this.currentSlot.reset,
          }
        })
        // .post("api/ResourceReservationExceptionSave", {
        //   query: this.editquery,
        //   d_orig: {
        //     start: this.reservationslot.start_wst
        //       ? this.reservationslot.start_wst
        //       : this.reservationslot.start,
        //     end: this.reservationslot.end_wtt
        //       ? this.reservationslot.end_wtt
        //       : this.reservationslot.end,
        //     bookingId: this.reservationslot.bookingId,
        //     ide: this.reservationslot.ide,
        //   },
        //   d_new: {
        //     start: this.currentSlot.start_date.substr(0,10) + "T" + this.currentSlot.from,
        //     end: this.currentSlot.start_date.substr(0,10) + "T" + this.currentSlot.to,
        //     bookingId: this.currentSlot.bookingId,
        //     ide: this.currentSlot.ide,
        //     canceled: this.currentSlot.canceled,
        //     reset: this.currentSlot.reset,
        //   },
        // })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.show = false;
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;

          var parts = message.split("|");
          if (parts.length > 1) {
            var elems = Object.keys(tempthis.$refs).filter(
              (k) => k.substr(0, parts[1].length) === parts[1]
            );
            // var elem = tempthis.$refs[parts[1]];
            for (var i = 0; i < elems.length; i++) {
              var elem = tempthis.$refs[elems[i]];
              if (
                elem &&
                elem.$refs &&
                elem.$refs.txtfld &&
                elem.$refs.txtfld.$refs.input
              ) {
                elem.$refs.txtfld.$refs.input.focus();
              }
            }
          }
          this.$showMessage({ content: parts[0], color: "error" });
        })
        .finally(() => {
          this.loading = false;
          this.saving = false;
        });
    },
    alldaytoggle() {
      if (this.currentSlot.all_day) {
        this.alldaytmpvalues[this.selectedSlotIndex] = {
          from: this.currentSlot.from,
          to: this.currentSlot.to,
        };
        this.currentSlot.from = "00:00";
        this.currentSlot.to = "00:00";
      } else {
        if (this.alldaytmpvalues[this.selectedSlotIndex]) {
          this.currentSlot.from =
            this.alldaytmpvalues[this.selectedSlotIndex].from;
          this.currentSlot.to = this.alldaytmpvalues[this.selectedSlotIndex].to;
        }
      }
    },
    prepDurationFrom(value) {
      var diff = this.timeDifferenceInMinutes(this.currentSlot.from, value);
      this.currentSlot.from = value;
      this.currentSlot.to = this.addMinutesToTime(this.currentSlot.to, diff);
      this.currentSlot.reset = false;
    },
    prepDurationTo(value) {
      if (this.timeDifferenceInMinutes(this.currentSlot.from, value) < 0) {
        this.currentSlot.to = this.currentSlot.from;
      } else {
        this.currentSlot.to = value;
      }
      this.currentSlot.reset = false;
    },
    timeDifferenceInMinutes(timeStr1, timeStr2) {
      // Parse time strings to Date objects
      const time1 = new Date("1970-01-01T" + timeStr1 + "Z");
      const time2 = new Date("1970-01-01T" + timeStr2 + "Z");

      // Calculate time difference in milliseconds
      const timeDifference = time2 - time1;

      // Convert time difference to minutes
      const minutesDifference = timeDifference / (1000 * 60);

      return minutesDifference;
    },
    addMinutesToTime(timeStr, minutesToAdd) {
      // Parse time string to Date object
      const baseTime = new Date("1970-01-01T" + timeStr + "Z");

      // Add minutes to the base time
      const newTime = new Date(baseTime.getTime() + minutesToAdd * 60 * 1000);

      // Format the new time as a time-only string
      const formattedTime = newTime.toISOString().substr(11, 8);

      return formattedTime;
    },

    checkChange() {
      return (
        this.comp.start_date != this.currentSlot.start_date ||
        this.comp.from != this.currentSlot.from ||
        this.comp.to != this.currentSlot.to ||
        this.comp.canceled != this.currentSlot.canceled
      );
    },
    updatedate(newdateinfo) {
      this.currentSlot[newdateinfo.fieldname] = newdateinfo.date;
      if (newdateinfo.fieldname === "start_date") {
        var sdate = new Date(this.currentSlot["start_date"]);
        var edate = new Date(this.currentSlot["end_date"]);

        if (
          (this.currentSlot.recurrence_pattern === "none" ||
            this.currentSlot.range_type === 0) &&
          sdate > edate
        ) {
          this.currentSlot["end_date"] = this.currentSlot["start_date"];
        }
      }
      this.currentSlot.reset = false;
    },
  },

  computed: {
    ...mapState(["currentUser", "currenthubsubscriptions", "lastKeyUp"]),

    // hasChanges() {
    //     return this.noOfChanges > 0;
    // },

    daysPerMonth() {
      var days = [];
      var yearly_on_month = this.months.find(
        (m) => m.v === this.currentSlot.yearly_on_month
      );
      if (yearly_on_month) {
        var maxdays = yearly_on_month.m;

        for (var i = 1; i <= maxdays; i++) {
          days.push({ v: i });
        }
        return days;
      } else {
        return null;
      }
    },
    dlgStyle: {
      get() {
        return this.bgcolor !== "" ? "background-color:" + this.bgcolor : "";
      },
    },

    show: {
      get() {
        return true;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },

    notonday: {
      get() {
        if (this.comp.start_date) {
          var s = new Date(this.comp.start_date);
          return s.toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });
        } else {
          return "";
        }
      },
    },
  },
  watch: {
    reservationslot: {
      handler() {
        this.currentSlot = { ...this.reservationslot };
        this.comp = { ...this.reservationslot };
      },
    },
  },
};
</script>
