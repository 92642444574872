import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

var mytheme_light = {};
var mytheme_dark = {};

if (process.env.VUE_APP_COLOR_THEME_LIGHT) {
    mytheme_light = JSON.parse(process.env.VUE_APP_COLOR_THEME_LIGHT)
}
if (process.env.VUE_APP_COLOR_THEME_LIGHT) {
    mytheme_dark = JSON.parse(process.env.VUE_APP_COLOR_THEME_DARK)
}
export default new Vuetify({
    theme: {
        themes: {
            light: mytheme_light,
            dark: mytheme_dark,
        },
    },
})
