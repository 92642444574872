<template>
    <div>
        <div v-show="previewActive && previewtype === 'html'" ref="htmlprev" class="previewHtml" v-html="htmlprevcontent"
            @mouseleave="mouseleave" :prevId="lastItemid">
        </div>

        <img v-show="previewActive && previewtype === 'img'" ref="previewimage" :src="previewsrc" class="previewImage"
            v-on:load="loadfinished" @click="previewimageclick" />

    </div>
</template>


<style scoped>
div.previewHtml {
    position: absolute;
    top: 2em;
    left: 9em;
    padding: 2em;
    width: 50%;
    height: 90%;
    z-index: 7500;
    overflow: auto;
    background-color: white;
    color: black;

    -webkit-box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
    box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
}

img.previewImage {
    position: absolute;
    top: 0px;
    left: 0px;

    border: 1px solid black;
    z-index: 7500;
    -webkit-box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
    box-shadow: 2px 0px 19px 5px rgba(0, 0, 0, 0.48);
}
</style>


<script>

export default {
    name: "mkspreview",

    props: {
        clientX: {
            type: Number,
            required: true,
        },
        clientY: {
            type: Number,
            required: true,
        },

    },

    data: () => ({
        previewActive: false,
        previewsrc: "",
        lastItemid: 0,
        htmlprevcontent: "",
        previewtype: null,
        previewdata: {},
        MaxPreviewDimension: 300,
        previewloading: false,
    }),

    mounted() {
    },
    computed: {
        owl() {
            return require("@/assets/owl2.png");
        },
        itemid() {
            return this.previewdata && this.previewdata.ID_Attachment_Assignment ? this.previewdata.ID_Attachment_Assignment : null;
        },
        editquery() {
            return this.previewdata && this.previewdata.editquery ? this.previewdata.editquery : null;
        },
    },
    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === "setpreviewdata") {

                if (state.previewdata === null) {
                    this.previewActive = false;
                }
                else {
                    this.previewdata = state.previewdata;
                    this.preview();
                }

            }
        });
    },
    watch: {
        clientX: {
            handler() {
                this.movepreview();
            },
        },
        clientY: {
            handler() {
                this.movepreview();
            },
        },
    },
    methods: {

        mouseleave: function () {
            this.previewActive = false;
        },
        previewimageclick() {
            this.openfile(this.lastItemid);
        },

        preview: function () {


            if (sessionStorage["prevhtml" + this.itemid]) {
                this.previewtype = "html";
                this.htmlprevcontent = sessionStorage["prevhtml" + this.itemid];
                this.previewActive = true;
            } else if (sessionStorage["prev" + this.itemid]) {
                this.previewtype = "img";
                this.previewsrc = sessionStorage["prev" + this.itemid];
                this.movepreview();
                this.previewActive = true;
            } else {
                this.previewActive = false;
                this.previewsrc = "";
                this.openfile(this.itemid, true);
            }

        },

        openfile(itemid, asPreview = false, MaxPreviewDimension = 300, degree = 0) {
            var winName = "FilePresentation";
            var winURL = "api/getAttachedFileContentGet";

            var params =
                this.editquery.id_query +
                "." +
                this.editquery.id_link +
                "." +
                this.editquery.id +
                "." +
                this.editquery.identifier +
                "." +
                degree +
                "." +
                (itemid.ID_Attachment_Assignment
                    ? itemid.ID_Attachment_Assignment
                    : itemid) +
                (asPreview ? ".1." + MaxPreviewDimension : "");

            if (asPreview) {
                if (!this.previewloading) {
                    this.previewloading = true;
                    this.previewActive = false;
                    document.body.style.cursor = "wait";
                    this.previewsrc = "";

                    this.$http
                        .get(winURL + "?att=" + params)
                        .then((response) => {
                            if (response.data.error) {
                                this.myerror = response.data.error;
                                this.loading = false;
                            } else if (response.data !== "") {
                                this.previewtype =
                                    response.headers["content-type"] === "text/html"
                                        ? "html"
                                        : "img";

                                if (this.previewtype === "html") {
                                    this.htmlprevcontent = response.data;
                                    sessionStorage["prevhtml" + itemid] = response.data;
                                    this.previewActive = true;
                                } else if (MaxPreviewDimension === 750) {
                                    this.delpreviewsrc =
                                        "data:" +
                                        response.headers["content-type"] +
                                        ";base64," +
                                        response.data;
                                } else {
                                    this.previewsrc =
                                        "data:" +
                                        response.headers["content-type"] +
                                        ";base64," +
                                        response.data;
                                    sessionStorage["prev" + itemid] = this.previewsrc;
                                }
                            } else {
                                this.previewsrc = "";
                                this.delpreviewsrc = "";
                            }

                        })
                        .catch(() => {
                            this.previewtype = "img";
                            this.delpreviewsrc = "";
                            this.previewsrc = this.owl;
                        }).finally(() => {
                            this.previewloading = false;
                            document.body.style.cursor = "default";
                        });
                }
            } else {
                window.open(winURL + "?att=" + params, winName);
            }
        },



        loadfinished() {
            this.$nextTick(() => {
                if (this.$refs.previewimage.naturalHeight > 0) {
                    this.movepreview();
                    this.previewActive = true;
                }
            });
        },

        movepreview() {
            var trg = document.elementFromPoint(this.clientX, this.clientY).closest('[prevId]');
            if (trg) {


                if (this.previewtype === "img") {
                    if (this.$refs.previewimage.naturalHeight > 0) {
                        this.$refs.previewimage.style.top =
                            this.clientY - this.$refs.previewimage.naturalHeight - 15 + "px";
                        this.$refs.previewimage.style.left =
                            this.clientX -
                            Math.round(this.$refs.previewimage.naturalWidth / 2) +
                            "px";
                    } else {
                        this.$refs.previewimage.style.top = this.clientY - 360 + "px";
                        this.$refs.previewimage.style.left = this.clientX - 330 + "px";
                    }
                }
            } else if (this.previewActive) {
                if (this.previewtype === "img") {
                    this.previewActive = false;
                }

            }
        },
    },
};
</script>