<template>
  <v-dialog
    v-model="confirmDialog.show"
    content-class="v-dialog-mks"
    persistent
    no-click-animation
  >
    <v-card>
      <v-card-title>
        <span class="mksheadline">{{ confirmDialog.header }}</span>
        <v-spacer></v-spacer>
        <v-icon class="pnt" @click="confirmDialog.show = false"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text>{{ confirmDialog.text }}</v-card-text>
      <v-card-actions>
        <v-btn
          :class="'mr-4' + (selectedButton === 0 ? ' selected' : '')"
          color="error"
          @click="$emit('close', confirmDialog.action)"
          ><span> {{ confirmDialog.buttonconfirmtext }}</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :class="'mr-4' + (selectedButton === 1 ? ' selected' : '')"
          color="info"
          @click="$emit('close', 'closeconfirmdialog')"
          >{{ confirmDialog.buttoncanceltext }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
DIV >>> .selected {
  text-decoration: underline dotted;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "mksdialogconfirm",

  data: () => ({
    selectedButton: 0,
  }),

  props: {
    confirmDialog: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    // visible: {
    //   type: Boolean,
    //   required: true,
    //   default: false,
    // },
  },
  methods: {},
  computed: {
    ...mapState(["lastKeyUp"]),

    show: {
      get() {
        return this.confirmDialog.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close", "");
        }
      },
    },
  },

  watch: {
    lastKeyUp: {
      handler() {
        if (this.show) {
          if (!this.lastKeyUp.altKey) {
            switch (this.lastKeyUp.keyCode) {
              case 13:
                this.$emit(
                  "close",
                  this.selectedButton === 0
                    ? this.confirmDialog.action
                    : "closeconfirmdialog"
                );
                break;
              case 37:
                this.selectedButton = 0;
                break;
              case 39:
                this.selectedButton = 1;
                break;
            }
          }
        }
      },
    },
  },
};
</script>