<template>
  <div>
    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="white">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <template v-for="mi in menuitems">
          <v-divider
            v-if="mi.title === 'Settings'"
            :key="'divider_' + mi.id"
          ></v-divider>

          <v-list-item :key="mi.id" @click="handleMenuItem(mi)">
            <v-list-item-icon>
              <v-icon>{{ mi.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ mi.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
    <mkssettings :visible="showsettings" @close="closeSettings"></mkssettings>
    <mksselectdb :visible="showselectdb" @close="closeSelectdb"></mksselectdb>
    <mksaccessrights
      :visible="showrights"
      @close="closeRights"
    ></mksaccessrights>

    <mksactivity :visible="showactivity" @close="closeactivity"></mksactivity>
  </div>
</template>

<script>
import mkssettings from "@/components/mkssettings.vue";
import mksactivity from "@/components/mksactivity.vue";
import mksaccessrights from "@/components/mksaccessrights.vue";
import mksselectdb from "@/components/mksselectdb.vue";

export default {
  name: "mksmenu",

  components: {
    mkssettings,

    mksactivity,
    mksaccessrights,
    mksselectdb,
  },

  data: () => ({
    menuitems: [],
    showsettings: false,
    showactivity: false,
    showrights: false,
    showselectdb: false,
  }),

  created() {
    this.$http
      .get("api/MenuEntries")
      .then((result) => {
        this.menuitems = result.data.data;
        this.menuitems.push({
          title: "Settings",
          icon: "settings",
          id: -1,
          url: "",
        });
        // this.menuitems.push({
        //   title: "Help",
        //   icon: "help",
        //   id: -2,
        //   url: "",
        // });
      })
      .catch((e) => {
        var message =
          e.response && e.response.data
            ? e.response.data
            : e.message
            ? e.message
            : e;
        this.$showMessage({ content: message, color: "error" });
      });
  },

  methods: {
    handleMenuItem(mi) {
      if (mi.title === "Send Refresh Version") {
        if (confirm("Are you sure?")) {
          this.sendRefreshVersion();
        }
      } else if (mi.title === "Access Rights") {
        this.showrights = true;
      } else if (
        process.env.NODE_ENV === "development" &&
        mi.title === "Tools"
      ) {
        if (this.$route.path != "/Tools") {
          this.$router.push("/Tools");
        }
      } else if (mi.title === "Change Dev DB") {
        this.showselectdb = true;
      } else if (mi.title === "Latest Activity") {
        this.showactivity = true;
      } else if (mi.url && mi.url !== "") {
        window.open(mi.url);
      } else {
        switch (mi.id) {
          case -1:
            this.showsettings = true;
            break;
          case -2:
            this.showhelp = true;
            break;
        }
      }
    },

    closeSettings() {
      this.showsettings = false;
    },
    closeSelectdb() {
      this.showselectdb = false;
    },

    closeactivity() {
      this.showactivity = false;
    },
    closeRights() {
      this.showrights = false;
    },

    sendRefreshVersion() {
      this.$http
        .post("api/sendRefreshVersion")
        // .then(() => {
        //   this.$showMessage({
        //     content: "Active Clients have been informed",
        //     color: "success",
        //   });
        // })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        });
    },
  },
};
</script>
