<template>
  <v-dialog
    v-model="show"
    content-class="v-dialog-mks"
    persistent
    no-click-animation
  >
    <v-card>
      <v-card-title>
        <span class="mksheadline">{{ title }}</span> <v-spacer></v-spacer>
        <v-icon class="pnt" @click="show = false"
          >mdi-close</v-icon
        > </v-card-title
      ><v-card-text>
        <v-autocomplete
          :search-input.sync="searchForProp"
          update:search-input
          autofocus
          auto-select-first
          v-model="fastjumpmodel"
          :items="getFieldsForJumpList"
          item-text="a"
          item-value="n"
          no-data-text="property not found"
          @change="show = false"
          @keyup.enter.stop="show = false"
          return-object
        ></v-autocomplete>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
const propComparator = (searchForProp) => (a, b) => {
  var nameA = a.a.toLowerCase(); // Groß-/Kleinschreibung ignorieren
  var nameB = b.a.toLowerCase(); // Groß-/Kleinschreibung ignorieren

  if (searchForProp !== "") {
    return nameA.indexOf(searchForProp) < nameB.indexOf(searchForProp) ? -1 : 1;
  }

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // Namen müssen gleich sein
  return 0;
};

export default {
  name: "mksdetails",

  //   components: {
  //     mksautocomplete,
  //     mkslocation,
  //     mkssubquery,
  //     mksdate,
  //     mkstextinput,
  //     mksphoto,
  //     mksnumberinput,
  //     mksdoubleinput,
  //     mksbooleaninput,
  //     mksdependencies,
  //     mkshistoryglob,
  //     mksfiles,
  //     mksdialogconfirm,
  //     draggable,
  //   },

  data: () => ({
    autocompleteitems: [],
    fastjumpmodel: null,
    searchForProp: "",
  }),

  props: {
    properties: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
     title: {
      type: String,
      required: false,
      default: 'Jump to:',
    },
  },

  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close", this.fastjumpmodel);
          this.$nextTick(() => {
            this.fastjumpmodel = null;
            this.searchForProp = "";
          });
        }
      },
    },

    getFieldsForJumpList: {
      get() {
        var jfields = Object.values(this.autocompleteitems).filter((obj) => {
          return obj;
        });

        jfields.sort(
          propComparator(
            this.searchForProp ? this.searchForProp.toLowerCase() : ""
          )
        );
        return jfields;
      },
    },
  },
  watch: {
    visible: {
      handler() {
        if (!this.visible) {
          this.autocompleteitems = [];
        } else {
          this.autocompleteitems = Object.assign({}, this.properties);
        }
      },
    },
  },
};
</script>