<template>
  <div>
    <div v-if="field.d || field.print">
      <v-text-field
        :filled="!field.print"
        v-model="field.v"
        readonly
        :label="$mksLabel(field, alternatelayout)"
        :hint="$mksLabel(field, !alternatelayout)"
        :persistent-hint="!alternatelayout"
        :outlined="!alternatelayout"
        :dense="!alternatelayout"
        :prepend-icon="!alternatelayout ? '' : 'event'"
        :prepend-inner-icon="alternatelayout ? '' : 'event'"
        ><v-tooltip top slot="append" v-if="field.why">
          <template v-slot:activator="{ on }">
            <v-icon
              v-if="!field.print"
              class="outlined"
              v-on="on"
              style="opacity: 0.1"
            >
              lock
            </v-icon>
          </template>
          <span>{{ field.why }}</span>
        </v-tooltip></v-text-field
      >
    </div>
    <div v-else>
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="dateFormatted"
            ref="txtfld"
            :label="$mksLabel(field, alternatelayout)"
            :hint="$mksLabel(field, !alternatelayout)"
            :placeholder="alternatelayout ? '' : 'DD.MM.YYYY'"
            persistent-hint
            :outlined="!alternatelayout"
            :background-color="field.bc"
            :dense="!alternatelayout"
            :prepend-icon="!alternatelayout ? '' : 'event'"
            :prepend-inner-icon="alternatelayout ? '' : 'event'"
            @blur="date = parseDate(dateFormatted)"
            v-on="on"
            :rules="requiredRule"
            @dblclick.native="setToday"
            @keyup="this.$emit('userinput', field)"
            clearable
            :messages="message"
            hide-details="auto"
            ><template slot="label" v-if="alternatelayout">
              <span v-html="$mksLabel(field, alternatelayout)"></span>
            </template>
            <template v-slot:message="{ message }" v-if="!alternatelayout">
              <span v-html="message"></span> </template
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          @input="menu1 = false"
          locale="de-de"
          first-day-of-week="1"
        ></v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "mksdate",

  data: () => ({
    date: null,
    dateFormatted: null,
    menu1: false,
    origval: null,
  }),

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  created() {
    this.initDate();
  },

  computed: {
    message: {
      get() {
        return this.$mksLabel(
          this.field,
          !this.alternatelayout,
          this.fielderror
        );
      },
    },
    requiredRule: {
      get() {
        if (this.field.r) {
          return [
            !!this.date ||
              (!this.alternatelayout ? this.field.a : "") + " is required",
          ];
        } else {
          return [];
        }
      },
    },
    returnValue: {
      get() {
        return {
          n: this.field.n,
          v: this.dateFormatted,
        };
      },
    },
    changed: {
      get() {
        return this.origval != this.date;
      },
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    dateFormatted() {
      if (this.origval != this.date) {
        this.$emit("userinput", this.field);
      }
    },
    field: {
      handler() {
        this.initDate();
      },
      deep: true,
    },
  },

  methods: {
    setFocus() {
      this.$nextTick(() => {
        this.$refs.txtfld.$refs.input.focus();
      });
    },
    initialize() {
      this.origval = this.date;
    },
    setUnsaved() {
      this.origval = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split(" ")[0].split("-");
      return (
        `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}` +
        (date.split(" ").length > 1 ? " " + date.split(" ")[1] : "")
      );
    },
    parseDate(date) {
      if (!date) return null;

      try {
        const [day, month, year] = date.split(" ")[0].split(".");
        return (
          `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}` +
          (date.split(" ").length > 1 ? " " + date.split(" ")[1] : "")
        );
      } catch {
        return "";
      }
    },
    initDate() {
      if (this.field.v) {
        var td = new Date(this.parseDate(this.field.v));
        this.date =
          td.getFullYear() +
          "-" +
          String(td.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(td.getDate()).padStart(2, "0") +
          ((this.field.v.indexOf(" ") > 0 && td.getHours() !== 0) ||
          td.getMinutes() !== 0
            ? " " +
              String(td.getHours()).padStart(2, "0") +
              ":" +
              String(td.getMinutes()).padStart(2, "0")
            : "");
        this.origval = this.date;
      }
    },
    setToday() {
      var td = new Date();
      this.date =
        td.getFullYear() +
        "-" +
        String(td.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(td.getDate()).padStart(2, "0");
    },
  },
};
</script>
