<template>
  <div>
    <v-btn v-if="imgagesInfo.length > 0" class="button" @click="unselectall()">unselect all</v-btn>
    <div class="imgLoadError" ref="error"></div>
    <div
      v-for="(img, index) in imgagesInfo"
      v-bind:key="'i_' + index"
      style="float: left"
    >
      <div
        class="image"
        @click="toggleImgSelection(img)"
        :style="showSelector ? 'cursor: pointer;' : ''"
      >
        <img
          :ref="'prevImg' + index"
          :alt="index"
          :src2="img.url"
          style="min-width: 55px"
        />
        <span v-if="false" v-html="img.filename" style="min-width: 55px"></span>
        <v-simple-checkbox
          v-if="showSelector"
          dark
          v-model="img.checked"
          :ripple="false"
        ></v-simple-checkbox>
      </div>
    </div>
  </div>
</template>

<style scoped>
.image {
  position: relative;
  width: 100%; /* for IE 6 */
  margin-right: 5px;
}

.image > img {
  height: 220px;
  border-radius: 4px;
}

.image > div {
  position: absolute;
  top: 0px;
  right: 10px;
}

.image > span {
  position: absolute;
  max-width: 90%;
  bottom: 15px;
  left: 10px;
  padding: 0px 5px 0px 5px;

  color: #fff;

  mix-blend-mode: difference;
  /* background-color: #aaa;
  box-shadow: 0px 0px 19px 11px #aaa; */
}

div.imgLoadError {
  color: red;
  font-weight: bold;
}

@media print {
  div.imgLoadError {
    display: none;
  }

  .button {
    display: none;
  }
}
</style>


<script>
export default {
  name: "mksphotosprint",

  props: {
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
    className: {
      type: String,
      required: true,
      default: "",
    },
    showSelector: {
      type: Boolean,
      required: true,
      default: false,
    },
    imgagesPropInfo: {
      type: Array,
      required: true,
    },
    nextStep: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data: () => ({
    imgagesInfo: [],
    imgCounter: 0,
    imgErrorCounter: 0,
  }),

  watch: {
    nextStep: {
      handler() {
        if (this.nextStep) {
          this.$emit(
            "saveImgSelection",
            this.imgagesInfo.filter((i) => i.checked)
          );
        }
      },
    },

    imgagesInfo: {
      handler() {},
    },
  },

  mounted() {
    this.imgagesInfo = this.imgagesPropInfo;
  },

  updated() {
    if (this.imgCounter === 0) {
      this.loadNextImage();
    }
  },

  methods: {
    toggleImgSelection(img) {
      img.checked = !img.checked;
    },

    unselectall() {
      this.imgagesInfo.forEach((i) => {
        i.checked = false;
      });
    },

    loadNextImage() {
      if (
        this.$refs["prevImg" + this.imgCounter] &&
        this.$refs["prevImg" + this.imgCounter][0].hasAttribute("src2")
      ) {
        var img = this.$refs["prevImg" + this.imgCounter][0];
        img.addEventListener("load", this.loadNextImage, false);
        var tempthis = this;
        img.addEventListener(
          "error",
          function () {
            tempthis.imgErrorCounter++;
            img.style.display = "none";
            tempthis.loadNextImage();
          },
          false
        );
        this.imgCounter++;
        img.setAttribute("src", img.getAttribute("src2"));
      } else {
        if (this.imgErrorCounter > 0) {
          this.$refs["error"].innerHTML =
            this.imgErrorCounter +
            " image" +
            (this.imgErrorCounter > 1 ? "s" : "") +
            " could not be loaded.";
        }

        this.$emit("imagesLoaded");
      }
    },
  },
};
</script>