var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"scrollable":"","retain-focus":true},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"position":"relative"}},[_c('span',{staticClass:"mksheadline"},[_vm._v("Settings")]),_c('v-spacer'),_c('v-icon',{staticClass:"pnt",on:{"click":function($event){_vm.show = false}}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('v-list',[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("filter_b_and_w")])],1),_c('v-list-item-content',[_c('v-switch',{attrs:{"dense":"","label":`Dark Mode: ${_vm.DarkMode ? 'On' : 'Off'}`,"hide-details":""},on:{"change":function($event){return _vm.changeSetting('DarkMode')}},model:{value:(_vm.DarkMode),callback:function ($$v) {_vm.DarkMode=$$v},expression:"DarkMode"}})],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("list")])],1),_c('v-list-item-content',[_c('v-switch',{attrs:{"dense":"","label":`Always hide List Selector: ${
                _vm.AlwaysHideListSelector ? 'On' : 'Off'
              }`,"hide-details":""},on:{"change":function($event){return _vm.changeSetting('AlwaysHideListSelector')}},model:{value:(_vm.AlwaysHideListSelector),callback:function ($$v) {_vm.AlwaysHideListSelector=$$v},expression:"AlwaysHideListSelector"}})],1)],1),_c('v-list-item',[_c('v-list-item-icon',[(_vm.SaveAndClose)?[_c('v-btn',{staticClass:"mr-2",attrs:{"dark":_vm.$vuetify.theme.dark,"color":"success"}},[(_vm.IconButtons)?_c('v-icon',{attrs:{"left":""}},[_vm._v("save")]):_c('span',[_vm._v("Save")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"success"}},[(_vm.IconButtons)?_c('span',[_c('v-icon',[_vm._v("save")]),_vm._v("+ "),_c('v-icon',[_vm._v("close")])],1):_c('span',[_vm._v("Save + Close")])])]:[_c('v-btn',{staticClass:"mr-2",attrs:{"dark":_vm.$vuetify.theme.dark,"color":"success"}},[(_vm.IconButtons)?_c('v-icon',{attrs:{"left":""}},[_vm._v("save")]):_c('span',[_vm._v("Save")])],1)]],2),_c('v-list-item-content',[_c('v-switch',{attrs:{"dense":"","label":`Show 'Save and Close'-Button: ${
                _vm.SaveAndClose ? 'On' : 'Off'
              }`,"hide-details":""},on:{"change":function($event){return _vm.changeSetting('SaveAndClose')}},model:{value:(_vm.SaveAndClose),callback:function ($$v) {_vm.SaveAndClose=$$v},expression:"SaveAndClose"}})],1)],1),_c('v-list-item',[_c('v-list-item-icon',[(_vm.ShowHistoryCount)?_c('v-badge',{attrs:{"color":"accent","content":"12","overlap":"","dark":_vm.$vuetify.theme.dark}},[_c('v-icon',[_vm._v("history")])],1):_c('v-icon',[_vm._v("history")])],1),_c('v-list-item-content',[_c('v-switch',{attrs:{"dense":"","label":`Show Item History Count: ${
                _vm.ShowHistoryCount ? 'On' : 'Off'
              }`,"hide-details":""},on:{"change":function($event){return _vm.changeSetting('ShowHistoryCount')}},model:{value:(_vm.ShowHistoryCount),callback:function ($$v) {_vm.ShowHistoryCount=$$v},expression:"ShowHistoryCount"}})],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("playlist_add_check")])],1),_c('v-list-item-content',[_c('v-switch',{attrs:{"dense":"","label":`Show Lists in Favourites and default Folder: ${
                _vm.ShowListsinFavouritesAndDefaultFolder ? 'On' : 'Off'
              }`,"hide-details":""},on:{"change":function($event){return _vm.changeSetting('ShowListsinFavouritesAndDefaultFolder')}},model:{value:(_vm.ShowListsinFavouritesAndDefaultFolder),callback:function ($$v) {_vm.ShowListsinFavouritesAndDefaultFolder=$$v},expression:"ShowListsinFavouritesAndDefaultFolder"}})],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("fingerprint")])],1),_c('v-list-item-content',[_c('v-switch',{attrs:{"dense":"","label":`Show Identifier Column: ${
                _vm.ShowIdentifierColumn ? 'On' : 'Off'
              }`,"hide-details":""},on:{"change":function($event){return _vm.changeSetting('ShowIdentifierColumn')}},model:{value:(_vm.ShowIdentifierColumn),callback:function ($$v) {_vm.ShowIdentifierColumn=$$v},expression:"ShowIdentifierColumn"}})],1)],1),_c('v-list-item',[_c('v-list-item-icon',[(!_vm.IconButtons)?_c('v-btn',[_vm._v("Explore")]):_vm._e(),(_vm.IconButtons)?_c('v-btn',[_c('v-icon',[_vm._v("explore")])],1):_vm._e()],1),_c('v-list-item-content',[_c('v-switch',{attrs:{"dense":"","label":`Use Icon Buttons: ${_vm.IconButtons ? 'On' : 'Off'}`,"hide-details":""},on:{"change":function($event){return _vm.changeSetting('IconButtons')}},model:{value:(_vm.IconButtons),callback:function ($$v) {_vm.IconButtons=$$v},expression:"IconButtons"}})],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("rounded_corner")])],1),_c('v-list-item-content',[_c('v-switch',{attrs:{"dense":"","label":`Alternative Details Layout: ${
                _vm.AlternativeDetailsLayout ? 'On' : 'Off'
              }`,"hide-details":""},on:{"change":function($event){return _vm.changeSetting('AlternativeDetailsLayout')}},model:{value:(_vm.AlternativeDetailsLayout),callback:function ($$v) {_vm.AlternativeDetailsLayout=$$v},expression:"AlternativeDetailsLayout"}})],1)],1),_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v("Example")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"col-sm-4 col-md-4 col-lg-2 col-12"},[_c('mkstextinput',{attrs:{"field":_vm.example('Text Field'),"alternatelayout":_vm.AlternativeDetailsLayout}})],1),_c('v-col',{staticClass:"col-sm-4 col-md-4 col-lg-2 col-12"},[_c('mksdate',{attrs:{"field":_vm.example('Date Field'),"alternatelayout":_vm.AlternativeDetailsLayout}})],1),_c('v-col',{staticClass:"col-sm-4 col-md-4 col-lg-2 col-12"},[_c('mksbooleaninput',{attrs:{"field":_vm.example('Boolean Field'),"alternatelayout":_vm.AlternativeDetailsLayout}})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }