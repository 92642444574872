<template>
  <v-text-field
    :background-color="$getPropertyBackgroundColor(field)"
    v-model.number="field.v"
    :readonly="field.d"
    :filled="field.d && !field.print"
    ref="txtfld"
    type="number"
    :rules="[...requiredRule, ...regexRule]"
    :persistent-hint="!alternatelayout"
    :outlined="!alternatelayout"
    :dense="!alternatelayout"
    :clearable="!field.d && !field.print"
    :messages="message"
    ><v-tooltip top slot="append" v-if="field.why && !field.print">
      <template v-slot:activator="{ on }">
        <v-icon class="outlined" v-on="on" style="opacity: 0.1">lock</v-icon>
      </template>
      <span>{{ field.why }}</span>
    </v-tooltip>
    <template slot="label" v-if="alternatelayout">
      <span v-html="$mksLabel(field, alternatelayout)"></span>
    </template>
    <template v-slot:message="{ message }" v-if="!alternatelayout">
      <span v-html="message"></span>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "mksnumberinput",

  data: () => ({
    origval: null,
  }),

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    message: {
      get() {
        return this.$mksLabel(this.field, !this.alternatelayout);
      },
    },
    requiredRule: {
      get() {
        if (this.field.r) {
          return [
            (v) =>
              !!v ||
              (!this.alternatelayout ? this.field.a : "") + " is required",
          ];
        } else {
          return [];
        }
      },
    },
    regexRule: {
      get() {
        if (this.field.regex && this.field.v && this.field.v.length > 0) {
          return [
            (v) =>
              new RegExp(this.field.regex).test(v) ||
              (!this.alternatelayout ? this.field.a + " " : "") +
                (this.field.Comment
                  ? " - " + this.field.Comment + "!"
                  : " - incorrect format!"),
          ];
        } else {
          return [];
        }
      },
    },
    returnValue: {
      get() {
        return {
          n: this.field.n,
          v: this.field.v,
        };
      },
    },
    changed: {
      get() {
        return this.origval != this.field.v;
      },
    },
    fieldvalue: {
      get() {
        return this.field.v;
      },
    },
  },
  methods: {
    initialize() {
      this.origval = this.field.v;
    },
    setUnsaved() {
      this.origval = null;
    },
    setFocus() {
      this.$nextTick(() => {
        this.$refs.txtfld.$refs.input.focus();
      });
    },
  },
  mounted() {
    this.origval = this.field.v;
  },
  watch: {
    fieldvalue() {
      this.$emit("userinput", this.field);
    },
  },
};
</script>
