<template>
  <div>
    <v-sheet height="64">
      <v-toolbar flat>
        <v-tooltip top open-delay="700" close-delay="500">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              text
              small
              color="grey darken-2"
              @click="addClicked()"
            >
              <v-icon
                :class="noReservations && !bundle.hasChanges ? 'pulse' : ''"
                >add</v-icon
              ></v-btn
            >
          </template>
          <span>add reservations</span>
        </v-tooltip>

        <v-tooltip right open-delay="700" close-delay="500">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab
              text
              small
              color="grey darken-2"
              @click="
                calendarvalue = '';
                $refs.calendar.scrollToTime('08:00');
              "
              ><v-icon>today</v-icon>
            </v-btn>
          </template>
          <span>jump to today</span>
        </v-tooltip>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="$refs.calendar.prev()"
        >
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="$refs.calendar.next()"
        >
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          v-model="type"
          :items="types"
          dense
          outlined
          hide-details
          class="ma-2"
          label="type"
          style="width: 2em"
        ></v-select>
      </v-toolbar>
    </v-sheet>

    <v-sheet ref="movingTextouter" height="420" style="position: relative">
      <div id="movingText" ref="movingText">{{ tmpTime }}</div>

      <v-calendar
        ref="calendar"
        v-model="calendarvalue"
        :events="calendarevents"
        :weekdays="cal_weekday"
        :type="type"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        :interval-format="intervalFormat"
        @click:event="eventClicked"
        @contextmenu:event="eventClicked"
        @mousemove:time="mousemovetime"
        @mousemove:event="mousemoveevent"
        @mouseleave:event="mouseleaveevent"
      >
        <template v-slot:event="{ event }">
          <div
            :title="event.name !== null ? event.name.replace('<br>', ' ') : ''"
            :class="
              'v-event-draggable' +
              (event.other > 0 ? ' other' : '') +
              (event.outside > 0 ? ' outside' : '')
            "
            :style="bundle.details.interchangeable ? '' : 'white-space: normal'"
          >
            <!-- <strong>{{ event.name }}</strong><br> -->
            {{
              event.name == "setup" || event.name == "teardown"
                ? event.name
                : formatEventTime(event.start) +
                  " - " +
                  formatEventTime(event.end)
            }}
            <br />
            <strong
              v-html="
                event.name && event.name.length > 23
                  ? event.name.substring(0, 20) + '...'
                  : event.name
              "
            ></strong
            ><br />
          </div>
          <v-icon
            :class="event.outside > 0 ? ' outside' : ''"
            dense
            color="white"
            v-if="
              event.series &&
              !(event.name == 'setup' || event.name == 'teardown')
            "
            style="position: absolute; right: 0px; bottom: 0px"
            v-text="event.ide > 0 ? 'sync_disabled' : 'sync'"
          ></v-icon>
        </template>
      </v-calendar>
    </v-sheet>
    <v-sheet height="64">
      <div style="margin-top: 1em" v-show="bundle.warning === ''">
        <v-row>
          <v-col cols="4" class="smallmenu">
            <v-switch
              class="smallmenu"
              v-model="calendarSettings.showBookable"
              :color="calendarSettings.showBookable ? '#abe57f' : '#d8e67e'"
              dense
              :hide-details="true"
            >
              <template slot="label">
                <v-avatar
                  :color="calendarSettings.showBookable ? '#abe57f' : '#d8e67e'"
                  size="10"
                  tile
                ></v-avatar>
                <span style="margin-left: 0.5em">{{
                  calendarSettings.showBookable ? "bookable" : "aggregated"
                }}</span>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="8" class="smallmenu">
            <v-switch
              class="smallmenu"
              v-model="calendarSettings.showInvalid"
              color="#e6acb8"
              dense
              :hide-details="true"
            >
              <template slot="label">
                <v-avatar color="#e6acb8" size="10" tile></v-avatar>
                <span style="margin-left: 0.5em">show invalid</span>
              </template>
            </v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4" class="smallmenu">
            <v-switch
              class="smallmenu"
              v-model="calendarSettings.showReservationEventsForThisBundle"
              color="#e59e7f"
              dense
              :hide-details="true"
            >
              <template slot="label">
                <v-avatar color="#e59e7f" size="10" tile></v-avatar>
                <span style="margin-left: 0.5em">bundle reservations</span>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="8" class="smallmenu">
            <v-switch
              class="smallmenu"
              v-model="calendarSettings.showReservationEventsForOtherBundles"
              color="#c19682"
              dense
              :hide-details="true"
            >
              <template slot="label">
                <v-avatar
                  color="#e59e7f"
                  class="other"
                  size="10"
                  tile
                ></v-avatar>
                <span style="margin-left: 0.5em"
                  >bookings of shared resources in other bundles</span
                >
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </div>
      <div v-show="bundle.warning !== ''" class="warning text-center">
        {{ bundle.warning }}
      </div>
    </v-sheet>
    <v-menu
      v-model="selectedOpen"
      :activator="selectedElement"
      offset-y
      :open-on-click="false"
    >
      <v-list dense nav>
        <v-list-item
          v-show="selectedSlot.other === 0 && offerJustThis"
          link
          @click="showException"
        >
          <v-list-item-icon><v-icon>event</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Open just this one</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-show="selectedSlot.other === 0"
          link
          @click="showReservationSlotRule"
        >
          <v-list-item-icon><v-icon>date_range</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Open the entire series</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-show="selectedSlot.other !== 0"
          link
          @click="$emit('selectOtherBundle', selectedSlot.other)"
        >
          <v-list-item-icon><v-icon>category</v-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Jump to corresponding bundle</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <mksresourcereservation
      :bundle="bundle"
      :editquery="reservationQuery"
      v-if="showResourceReservation"
      :alternatelayout="AlternativeDetailsLayout"
      @close="closeResourceReservation"
    ></mksresourcereservation>
    <mksresourcereservationexception
      :reservationslot="selectedSlot"
      :title="bundle.details.bundle_title"
      v-if="showResourceReservationException"
      :alternatelayout="AlternativeDetailsLayout"
      @close="closeResourceReservationException"
      :editquery="editquery"
    ></mksresourcereservationexception>
  </div>
</template>

<style scoped>
.custom-icon-color {
  opacity: 0.5;
}

div.smallmenu {
  margin: 0px;
}

#movingText {
  position: absolute;
  font-size: 18px;
  color: #000;
  /* Change the color as desired */
  white-space: nowrap;
  z-index: 5000;
  background-color: white;
  opacity: 0.5;
}

div.other {
  background-color: #ffffff56;
  opacity: 0.8;
  background: repeating-linear-gradient(
    -45deg,
    #00000010,
    #00000010 1px,
    #00000042 1px,
    #00000042 5px
  );
}

.outside {
  opacity: 0.4;
}

div >>> .v-messages {
  min-height: 0px;
}
</style>

<style>
.v-event-timed {
  border-radius: 0 !important;
}
</style>

<script>
import mksresourcereservation from "@/components/mksresourcereservation.vue";
import mksresourcereservationexception from "@/components/mksresourcereservationexception.vue";
// import mksresourcebundle from "@/components/mksresourcebundle.vue";

import { mapState } from "vuex";

export default {
  name: "mksresourcecalendar",

  components: {
    mksresourcereservation,
    mksresourcereservationexception,
    // mksresourcebundle,
  },

  data: () => ({
    lastbookingId: 0,
    selectedElement: null,
    offerJustThis: true,
    selectedOpen: false,
    selectedSlot: {},
    showResourceReservation: false,
    showResourceReservationException: false,
    AlternativeDetailsLayout: false,
    bundleOrig: null,
    type: "week",
    types: ["month", "week"], //'day', '4day'],
    mode: "stack",
    modes: ["stack", "column"],
    cal_weekday: [1, 2, 3, 4, 5, 6, 0],
    cal_weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      // { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    calendarvalue: "",
    tmpTime: "",
    tmpbookingId: null,
    // eventHovered: { bookingId: 0 },
    minuteSteps: 15,
    id_booked_slot: 0,
  }),

  props: {
    bundle: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    calendarSettings: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.scrollCalendarToDefault();
  },

  methods: {
    scrollCalendarToDefault() {
      this.$nextTick(() => {
        if (this.$refs.calendar) {
          this.$refs.calendar.scrollToTime("08:00");
        }
      });
    },
    getDayOfYear(date) {
      var start = new Date(date.getFullYear(), 0, 0);
      var diff = date - start;
      var oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
      var dayOfYear = Math.floor(diff / oneDay);

      return dayOfYear;
    },
    addLeadingZero(number) {
      return number < 10 ? "0" + number : number;
    },
    mousemoveevent(event) {
      this.tmpbookingId = event.event.bookingId;
    },
    mouseleaveevent() {
      this.tmpbookingId = null;
      this.tmpTime = "";
    },
    mousemovetime(tms) {
      const mouse = this.toTime(tms);
      var tmp = new Date(this.roundTime(mouse, false));

      if (this.tmpbookingId === 0) {
        this.tmpTime =
          this.addLeadingZero(tmp.getHours()) +
          ":" +
          this.addLeadingZero(tmp.getMinutes());
      } else {
        this.tmpTime = "";
      }

      const xPos = tms.nativeEvent.clientX;
      const yPos = tms.nativeEvent.clientY;

      // this.$refs.movingText.style.transform = `translate(${xPos}px, ${yPos}px)`;
      this.$refs.movingText.style.top = `${
        yPos - this.$refs.movingTextouter.$el.offsetTop - 80
      }px`;
      this.$refs.movingText.style.left = `${
        xPos - this.$refs.movingTextouter.$el.offsetLeft - 10
      }px`;
    },

    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    roundTime(time, down = true) {
      const roundDownTime = this.minuteSteps * 60 * 1000;
      time -= (this.minuteSteps / 2) * 60 * 1000;
      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    addClicked() {
      if (this.bundle.hasChanges) {
        this.$showMessage({
          content: "Save bundle changes before making reservations.",
          color: "warning",
        });
        return;
      }
      if (!this.hasChildren) {
        this.$showMessage({
          content:
            "Add child elements to this bundle before making reservations.",
          color: "warning",
        });
        return;
      }
      this.selectedSlot = { bookingId: 0 };
      this.showResourceReservation = true;
    },
    eventClicked({ nativeEvent, event }) {
      if (nativeEvent.target.style.backgroundColor === "rgb(133, 179, 98)") {
        return;
      }

      if (this.bundle.hasChanges) {
        this.$showMessage({
          content: "Save bundle changes before making reservations.",
          color: "warning",
        });
        return;
      }
      if (!this.hasChildren) {
        this.$showMessage({
          content:
            "Add child elements to this bundle before making reservations.",
          color: "warning",
        });
        return;
      }

      if (event.other) {
        const open = () => {
          this.selectedElement = nativeEvent.target;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          );
        };

        if (this.selectedOpen) {
          this.selectedOpen = false;
          requestAnimationFrame(() => requestAnimationFrame(() => open()));
        } else {
          open();
        }

        nativeEvent.stopPropagation();

        this.selectedSlot = event;
        this.lastbookingId = this.selectedSlot.bookingId;
        return;
      }

      if (!this.calendarSettings.showReservationEventsForThisBundle) {
        this.calendarSettings.showReservationEventsForThisBundle = true;
        return;
      }
      if (event.bookingId > 0) {
        this.selectedSlot = event;
        this.lastbookingId = this.selectedSlot.bookingId;
        this.id_booked_slot = this.selectedSlot.bookingId;

        if (
          this.selectedSlot.series === true &&
          this.selectedSlot.name !== "setup" &&
          this.selectedSlot.name !== "teardown"
        ) {
          const open = () => {
            this.selectedElement = nativeEvent.target;
            const currentDate = new Date();
            const clickedDate = new Date(event.start);

            this.offerJustThis = clickedDate > currentDate;
            requestAnimationFrame(() =>
              requestAnimationFrame(() => (this.selectedOpen = true))
            );
          };

          if (this.selectedOpen) {
            this.selectedOpen = false;
            requestAnimationFrame(() => requestAnimationFrame(() => open()));
          } else {
            open();
          }

          nativeEvent.stopPropagation();
        } else {
          this.showResourceReservation = true;
        }
      } else {
        var s = new Date(event.start);
        var e = new Date(event.end);
        if (this.getDayOfYear(e) > this.getDayOfYear(s)) {
          if (e.getHours() === 0 && e.getMinutes() === 0) {
            e.setMinutes(e.getMinutes() - 1);
          }
        }

        var newstartdate =
          s.getFullYear() +
          "-" +
          this.addLeadingZero(s.getMonth() + 1) +
          "-" +
          this.addLeadingZero(s.getDate());
        var newstarttime = this.tmpTime + ":00";

        this.bundle.TimeSlotsBookedRules[
          this.bundle.TimeSlotsBookedRules.length - 1
        ].start_date = newstartdate + "T00:00:00";
        this.bundle.TimeSlotsBookedRules[
          this.bundle.TimeSlotsBookedRules.length - 1
        ].end_date = newstartdate + "T00:00:00";

        var e2 = new Date(newstartdate + "T" + newstarttime);
        e2 = new Date(
          e2.getTime() +
            this.bundle.details.smallest_possible_booking_period_in_minutes *
              60000
        );
        var newendtime =
          this.addLeadingZero(e2.getHours()) +
          ":" +
          this.addLeadingZero(e2.getMinutes());
        this.bundle.TimeSlotsBookedRules[
          this.bundle.TimeSlotsBookedRules.length - 1
        ].from = newstarttime;
        this.bundle.TimeSlotsBookedRules[
          this.bundle.TimeSlotsBookedRules.length - 1
        ].to = newendtime;

        this.id_booked_slot = 0;
        this.showResourceReservation = true;
      }
    },
    showReservationSlotRule() {
      if (this.selectedSlot) {
        this.showResourceReservation = true;
      }
    },
    showException() {
      if (this.selectedSlot) {
        this.showResourceReservationException = true;
      }
    },

    formatEventTime(date) {
      return new Date(date).toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    },

    intervalFormat(interval) {
      return interval.time;
    },
    getEventColor(event) {
      return event.color;
    },

    closeResourceReservation: function () {
      this.$emit("closeReservation");
      // this.getDetails(false, true);
      this.showResourceReservation = false;
    },

    closeResourceReservationException: function () {
      this.showResourceReservationException = false;
      this.$emit("closeReservation");
    },

    refreshSettings() {
      this.AlternativeDetailsLayout = this.$getPref({
        TabName: "Options",
        PrefID: "AlternativeDetailsLayout",
      });
      if (this.AlternativeDetailsLayout == null) {
        this.AlternativeDetailsLayout = false;
      }
      this.IconButtons = this.$getPref({
        TabName: "Options",
        PrefID: "IconButtons",
      });
      if (this.IconButtons == null) {
        this.IconButtons = false;
      }
      this.SaveAndClose = this.$getPref({
        TabName: "Options",
        PrefID: "SaveAndClose",
      });
      if (this.SaveAndClose == null) {
        this.SaveAndClose = false;
      }

      var temp = this.$getPref({
        TabName: "Options",
        PrefID: "ShowIdentifierColumn",
      });
      if (temp !== null && temp !== this.ShowIdentifierColumn) {
        this.ShowIdentifierColumn = temp;
      }
      if (this.ShowIdentifierColumn == null) {
        this.ShowIdentifierColumn = false;
      }
    },
  },

  watch: {
    type: {
      handler() {
        this.scrollCalendarToDefault();
      },
    },
    prefs: {
      handler() {
        this.refreshSettings();
      },
      deep: true,
    },

    bundle: {
      handler() {
        if (
          this.bundle &&
          this.bundle.TimeSlotsBookedAsEventList &&
          this.lastbookingId > 0
        ) {
          this.selectedSlot = this.bundle.TimeSlotsBookedAsEventList.find(
            (e) => e.bookingId === this.lastbookingId
          );
          if (!this.selectedSlot) {
            this.selectedSlot = this.bundle.TimeSlotsBookedAsEventList[0];
          }
          if (!this.selectedSlot) {
            this.selectedSlot = {};
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["currentUser", "currenthubsubscriptions", "lastKeyUp"]),

    prefs() {
      return this.currentUser.prefs;
    },

    reservationQuery() {
      return {
        id_query: this.editquery.id_query,
        id_link: this.editquery.id_link,
        id: this.editquery.id,
        identifier: this.editquery.identifier,
        tablename: this.editquery.tablename,
        hasPrevious: false,
        hasNext: false,
        id_booked_slot: this.id_booked_slot,
      };
    },

    noReservations() {
      return (
        this.bundle.leaves &&
        this.bundle.leaves.length > 0 &&
        this.bundle.leaves[0].children &&
        this.bundle.leaves[0].children.length > 0 &&
        this.bundle.TimeSlotsBookedRules &&
        this.bundle.TimeSlotsBookedRules.length < 2
      );
    },

    hasChildren() {
      return (
        this.bundle.leaves &&
        this.bundle.leaves.length > 0 &&
        this.bundle.leaves[0].children &&
        this.bundle.leaves[0].children.length > 0
      );
    },

    calendarevents() {
      if (!this.bundle.TimeSlotsBookedAsEventList) {
        return [];
      }

      var tmpevents = [];
      if (this.type == "week") {
        if (this.calendarSettings.showBookable) {
          tmpevents = this.bundle.TimeSlotsOfferedAsEventList;
        } else {
          tmpevents = this.bundle.TimeSlotsBasedOnResourcesAsEventList;
        }
      }

      var showDeleted = false;
      var TimeSlotsBookedAsEventList = showDeleted
        ? this.bundle.TimeSlotsBookedAsEventList
        : this.bundle.TimeSlotsBookedAsEventList.filter((e) => !e.deleted);

      if (!this.calendarSettings.showInvalid) {
        TimeSlotsBookedAsEventList = TimeSlotsBookedAsEventList.filter(
          (e) => !e.outside && !e.overlap
        );
      }

      if (
        tmpevents &&
        this.calendarSettings.showReservationEventsForThisBundle &&
        this.calendarSettings.showReservationEventsForOtherBundles
      ) {
        return tmpevents.concat(TimeSlotsBookedAsEventList);
      }
      if (
        tmpevents &&
        this.calendarSettings.showReservationEventsForThisBundle &&
        !this.calendarSettings.showReservationEventsForOtherBundles
      ) {
        return tmpevents.concat(
          TimeSlotsBookedAsEventList.filter((e) => e.other === 0)
        );
      }
      if (
        tmpevents &&
        !this.calendarSettings.showReservationEventsForThisBundle &&
        this.calendarSettings.showReservationEventsForOtherBundles
      ) {
        return tmpevents.concat(
          TimeSlotsBookedAsEventList.filter((e) => e.other !== 0)
        );
      } else if (tmpevents) {
        return tmpevents;
      } else {
        return [];
      }
    },

    show: {
      get() {
        return true;
      },
      set(value) {
        if (!value) {
          if (!this.hasChanges) {
            this.bundle.leaves = [];
            this.$emit("close");
          } else {
            //this.$showMessage({ content: "unsaved changes detected", color: "warning" });
            this.confirmDialogShow = true;
          }
        }
      },
    },

    currentCalendarSettings() {
      return {
        showBookable: this.calendarSettings.showBookable,
        showInvalid: this.calendarSettings.showInvalid,
        showReservationEventsForThisBundle:
          this.calendarSettings.showReservationEventsForThisBundle,
        showReservationEventsForOtherBundles:
          this.calendarSettings.showReservationEventsForOtherBundles,
      };
    },
  },
};
</script>
