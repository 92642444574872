<template>
  <v-text-field ref="field" :background-color="$getPropertyBackgroundColor(field)" :prefix="prefix" v-model="model"
    :readonly="field.d || field.print" :filled="field.d && !field.print" @focus="onFocus" @keyup="onKeyUp"
    :error-messages="errorMessages" v-bind="$attrs" @change="onChange" @blur="onBlur" :rules="requiredRule"
    :clearable="!field.d && !field.print" :persistent-hint="!alternatelayout" :outlined="!alternatelayout"
    :dense="!alternatelayout" :messages="message"><v-tooltip top slot="append" v-if="field.why && !field.print">
      <template v-slot:activator="{ on }">
        <v-icon class="outlined" v-on="on" style="opacity: 0.1">lock</v-icon>
      </template>
      <span>{{ field.why }}</span>
    </v-tooltip>
    <template slot="label" v-if="alternatelayout">
      <span v-html="$mksLabel(field, alternatelayout)"></span>
    </template>
    <template v-slot:message="{ message }" v-if="!alternatelayout">
      <span v-html="message"></span>
    </template></v-text-field>
</template>

<script>
function tryParseFloat(str, defaultValue) {
  var retValue = defaultValue;

  if (str !== null) {
    if (str.length > 0) {
      if (!isNaN(str)) {
        retValue = parseFloat(str);
      }
    }
  }
  return retValue;
}

export default {
  name: "mksdoubleinput",

  data: () => ({
    ignoreinput: false,
    numberValue: null,
    model: null,
    isMasked: true,
    origval: null,

    thousandsSeparatorRegex: null,
    decimalSeparatorRegex: null,
    decimalKeepRegex: null,
  }),

  mounted() {
    this.ignoreinput = true;
    this.origval = this.field.v;
    this.numberValue = this.field.v;
    this.model = this.field.v;
    this.origval = this.field.v;

    this.thousandsSeparatorRegex = new RegExp(
      `\\${this.thousandsSeparator}`,
      "g"
    );
    this.decimalSeparatorRegex = new RegExp(`\\${this.decimalSeparator}`, "g");
    this.decimalKeepRegex = new RegExp(/[^0-9.,]+/, "g");
  },

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    "error-messages": null,
    allowNegative: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: "",
    },
    thousandsSeparator: {
      type: String,
      default: ".",
    },
    decimalSeparator: {
      type: String,
      default: ",",
    },
    languageCode: {
      type: String,
      default: "de-DE",
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    message: {
      get() {
        return this.$mksLabel(this.field, !this.alternatelayout);
      },
    },
    requiredRule: {
      get() {
        if (this.field.r) {
          return [
            (v) =>
              !!v ||
              (!this.alternatelayout ? this.field.a : "") + " is required",
          ];
        } else {
          return [];
        }
      },
    },
    returnValue: {
      get() {
        return {
          n: this.field.n,
          v: this.model,
        };
      },
    },
    changed: {
      get() {
        return this.origval != this.model;
      },
    },
  },

  methods: {
    setFocus() {
      let tempthis = this;
      this.$nextTick(() => {
        tempthis.$refs.field.$refs.input.focus();
      });
    },
    initialize() {
      this.origval = this.model;
    },
    setUnsaved() {
      this.origval = null;
    },
    onFocus() {
      this.isMasked = false;
      this.updateModel();
    },

    onBlur() {
      if (this.$listeners.blur) this.$listeners.blur();

      this.isMasked = true;
      this.format();
    },

    onKeyUp() {
      this.updateNumberValue();
    },

    onChange() {
      if (this.$listeners.change) this.$listeners.change();
    },

    updateNumberValue() {
      if (!this.model) {
        this.numberValue = null;
        return;
      }
      let v = this.model.replace(this.decimalKeepRegex, "");
      let parsed;

      v = v.replace(this.thousandsSeparatorRegex, "");
      if (this.decimalSeparator !== ".")
        v = v.replace(this.decimalSeparatorRegex, this.thousandsSeparator);

      const result = tryParseFloat(v);

      if (!result) parsed = 0;
      else parsed = result;

      if (!this.allowNegative && result < 0) parsed = 0;

      // this.numberValue = Math.round(parsed * 100) / 100;
      this.numberValue = parsed;
    },

    updateModel() {
      if (this.numberValue === null) return;

      let v = this.numberValue.toLocaleString(this.languageCode);
      v = v.replace(this.thousandsSeparatorRegex, "");

      this.model = v;
    },

    format() {
      if (!this.model) {
        this.numberValue = null;
        return;
      }
      if (this.numberValue === null) return;

      let v = this.numberValue;

      v = v.toLocaleString(this.languageCode);

      if (
        v.length !== 1 &&
        v.slice(v.indexOf(this.decimalSeparator) + 1).length === 1
      )
        v += "0";

      this.model = v;
    },
  },

  watch: {
    numberValue(v) {
      this.$emit("input", v);
      if (this.ignoreinput) {
        this.ignoreinput = false;
        return;
      }
      this.$emit("userinput", this.field);
    },

    value(v) {
      this.numberValue = v;

      if (!this.$refs.field.isFocused) {
        this.format();
      }
    },
    field: {
      handler() {
        this.model = this.field.v;
        this.updateNumberValue();
      },
      deep: true,
    },
  },

  created() {
    this.format();
  },
};
</script>

