<template>
  <div>
    <template v-for="(table, index) in subquerytables">
      <v-data-table
        :dark="$vuetify.theme.dark"
        :key="index"
        v-if="subquerytabletitles[index] !== 'FinanzdatenOpenUrl'"
        :style="
          bgcolor +
          ';' +
          (!field.print ? 'overflow: auto; max-height: 375px;' : '')
        "
        :items="table"
        :headers="headers[index]"
        hide-default-footer
        disable-pagination
        :hide-default-header="hideDefaultHeader[index]"
        :class="!alternatelayout ? 'mksoutlined' : 'elevation-3'"
        :outlined="!alternatelayout"
        fixed-header
        @mousedown.native="mousedown"
        @mousemove.native="mousemove"
        :custom-sort="
          (items, sortBy, sortDesc) =>
            customSort(items, sortBy, sortDesc, headers)
        "
      >
        <template v-slot:top>
          <v-toolbar
            flat
            :class="!alternatelayout ? 'mksborderradius' : ''"
            :style="bgcolor"
            @dblclick.native="!field.print && openSubqueryFromDblClick(index)"
          >
            <v-toolbar-title>{{
              subquerytabletitles[index] + " (" + table.length + ")"
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!field.print && table.hasdirectLink && writeAllowed"
              icon
              class="mx-2"
              fab
              small
              @click="openDetailsFromSubquery()"
            >
              <v-icon>add</v-icon>
            </v-btn>

            <v-tooltip
              top
              v-if="
                !field.print &&
                writeAllowed &&
                subquerytabletitles[index] !== 'Finanzdaten Insgesamt'
              "
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mx-2"
                  fab
                  small
                  v-on="on"
                  @click="openSubqueryFromDblClick(index)"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
              </template>
              <span>Edit {{ subquerytabletitles[index] }}</span>
            </v-tooltip>
          </v-toolbar>
        </template>

        <template v-slot:header.markable="{ header }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>rule</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item link @click="mark_select_all">
                select all
              </v-list-item>
              <v-list-item link @click="mark_select_none"
                >select none</v-list-item
              >
              <v-list-item link @click="mark_invert_selection"
                >invert selection</v-list-item
              >
              <v-divider></v-divider>


              <v-tooltip right v-for="(action, index) in actions" :key="'act_' + index">
      <template v-slot:activator="{ on, attrs }">
        

        <v-list-item
                link
                 v-bind="attrs"
        v-on="on"
                @click="fireAction(action, table)"
              >
                <v-list-item-avatar>
                  <v-icon
                    :color="$vuetify.theme.dark ? 'white' : 'black'"
                    dark
                    v-text="action.ActionIcon"
                  ></v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ action.ActionName }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  

    
                </v-list-item-icon>
                </v-list-item
              >

      </template>
      <span v-html="action.ActionDescriptionHtml"></span>
    </v-tooltip>

              
            </v-list>
          </v-menu>
        </template>

        <template v-slot:body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            :disabled="!table.hasPosition"
          >
            <tr
              v-for="(item, index) in props.items"
              :key="'r1_' + index"
              :style="
                !field.print
                  ? 'background-color: ' + getColor(item['_bgcolor'])
                  : ''
              "
            >
              <!-- <td
                v-if="table.hasdirectLink"
                @click="openDetailsFromSubquery(item)"
              >
                <v-icon small>north_east</v-icon>
              </td> -->

              <td v-if="props.headers.hasPosition">
                <v-icon small> mdi-arrow-up-down </v-icon>
              </td>

              <template v-for="(header, index) of props.headers">
                <td v-if="header.value === 'markable'" :key="index">
                  <v-checkbox
                    v-if="item.markable"
                    dense
                    v-model="item.mark"
                  ></v-checkbox>
                </td>
                <td
                  :key="index"
                  v-else-if="
                    item[header.value] &&
                    item[header.value] !== null &&
                    item[header.value]
                      .toString()
                      .toLowerCase()
                      .startsWith('https://')
                  "
                >
                  <span
                    :inner-html.prop="
                      '<a target=\'_blank\' href=\'' +
                      item[header.value] +
                      '\'>' +
                      item[header.value] +
                      '</a>'
                    "
                  ></span>
                </td>
                <td v-else-if="item[header.value] === true" :key="'1_' + index">
                  &#10004;
                </td>
                <td
                  v-else-if="item[header.value] === false"
                  :key="'2_' + index"
                >
                  &nbsp;
                </td>
                <td
                  :key="'4_' + index"
                  v-else-if="header.value === 'Attachments'"
                >
                  <div class="maxheight">
                    <div
                      v-for="(att, index) in item[header.value]"
                      :key="'att_' + index"
                      class="dvptr"
                      @click="openFile(att)"
                      :prevId="att.ID_Attachment_Assignment"
                    >
                      <v-icon>file_present</v-icon>
                      {{ att.Filename }} ({{
                        $niceBytes(att.length.replaceAll(".", ""))
                      }})
                    </div>
                  </div>
                </td>
                <td
                  @click="!field.print && openDetailsFromSubquery(item)"
                  v-else-if="header.value !== 'posupdwn'"
                  :key="'5_' + index"
                  :class="
                    'maxheight ' +
                    (header.rightalign ? 'tdright' : '') +
                    ' ' +
                    (!field.print && table.hasdirectLink && writeAllowed
                      ? 'pntr'
                      : '')
                  "
                >
                  <div class="maxheight" v-html="item[header.value]"></div>
                </td>
              </template>
            </tr>
          </draggable>

          <tr
            v-if="
              props.items.length > 0 &&
              !field.print &&
              table.hasdirectLink &&
              writeAllowed
            "
          >
            <td
              class="onhover"
              @click="openDetailsFromSubquery()"
              :colspan="props.headers.length"
              style="text-align: center"
            >
              <v-btn icon class="mx-2" fab small>
                <v-icon>add</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-dialog
        :key="'sd_' + index"
        v-model="showTable"
        content-class="v-dialog-mks"
        persistent
        no-click-animation
      >
        <mkstable
          v-bind:id_query="idJumpQuery"
          v-bind:idfilter="editquery"
          @closeTable="closeTable"
          :showCloseSymbol="true"
        ></mkstable>
      </v-dialog>
    </template>
    <mksdetails
      :editquery="editlnk"
      :visible="showDetails"
      :idfilter="filterlnk"
      :calledFromDetails="false"
      @close="closeDetails"
    ></mksdetails>
  </div>
</template>

<style>
.v-dialog-mks {
  width: unset;
}

.mksborderradius {
  border-radius: 4px !important;
}
</style>

<style scoped>
td.onhover:hover {
  background-color: #eee;
  cursor: pointer;
}

tr {
  vertical-align: top;
}

td {
  padding-top: 0.5em !important;
}

div.maxheight {
  max-height: 250px !important;
  overflow-y: auto;
  max-width: 50vw;
}

.v-sheet.v-toolbar {
  border-radius: 4px;
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}

TD.pntr {
  cursor: pointer;
}

div.dvptr {
  cursor: pointer;
}

div.dvptr:hover {
  -webkit-text-stroke: 1px black;
}
</style>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  name: "mkssubquery",

  data: () => ({
    loading: false,
    headers: [],
    subquerytables: [],
    subquerytablesColumns: [],
    subquerytabletitles: [],
    hideDefaultHeader: [],
    idJumpQuery: 0,
    subIdentifier: "",
    jumpQuery: "",
    writeAllowed: false,
    visible: false,
    grphsh: "",
    directLink_id_query: 0,
    directLink_id_link: 0,
    directLink_identifier: "",
    showDetails: false,
    editlnk: {},
    filterlnk: {},
    msdwn: 0,
    msmv: 0,
    actions: [],
  }),

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
    bgcolor: {
      type: String,
      required: true,
      default: "",
    },
  },

  computed: {
    showTable: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.getSubqueryData();
        }
        this.visible = value;
      },
    },
    elementCount: {
      get() {
        return this.subquerytables && this.subquerytables.length > 0
          ? this.subquerytables[0].length
          : 0;
      },
    },
  },
  methods: {
    mark_select_all() {
      this.subquerytables[0].forEach((item) => {
        item.mark = true;
      });
    },
    mark_select_none() {
      this.subquerytables[0].forEach((item) => {
        item.mark = false;
      });
    },
    mark_invert_selection() {
      this.subquerytables[0].forEach((item) => {
        item.mark = !item.mark;
      });
    },
    fireAction(action, table) {

      var IDs = table.filter((item) => item.mark).map((item) => Number(item[this.directLink_identifier]));

      if (IDs.length === 0) {
        this.$showMessage({ content: "No items selected", color: "info" });
        return;
      }

      var actionQuery = {
        tablename: this.directLink_identifier.substr(3).replaceAll("_", " "),
        id_query: this.directLink_id_query,
        id_link: this.directLink_id_link,
        id: 0,
        identifier: this.directLink_identifier,
      };
      
      this.$http
        .post("api/queryaction", {
          query: actionQuery,
          action: action.ActionName,
          ids: IDs,
        })
        .then(() => {
             this.getSubqueryData();
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // toggleSelectAll(val) {
    //   this.$refs.table.toggleSelectAll(val);
    // },
    customSort(items, sortBy, sortDesc, headers) {
      // Function to parse German date time format to a Date object
      const parseGermanDate = (dateString) => {
        const [day, month, yearAndTime] = dateString.split(".");
        const [year, time] = yearAndTime.split(" ");
        return new Date(`${year}-${month}-${day}T${time}`);
      };

      // Function to compare dates
      const compareDates = (a, b) => {
        const dateA = parseGermanDate(a);
        const dateB = parseGermanDate(b);
        return dateA - dateB;
      };

      // Function to compare numbers
      const compareNumbers = (a, b) => {
        if (a === null) a = 0;
        if (b === null) b = 0;
        if (a === "" && b === "") return 0;
        if (a === "") return 1;
        if (b === "") return -1;
        return parseFloat(a) - parseFloat(b);
      };

      // Function to compare strings
      const compareStrings = (a, b) => {
        if (a === null) a = "";
        if (b === null) b = "";
        if (a === "" && b === "") return 0;
        if (a === "") return 1;
        if (b === "") return -1;
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      };

      return items.sort((a, b) => {
        for (let i = 0; i < sortBy.length; i++) {
          const sortKey = sortBy[i];
          const sortOrder = sortDesc[i] ? -1 : 1;

          const header = headers[0].find((h) => h.value === sortKey);

          let result = 0;

          if (header) {
            if (header.type === "DateTime") {
              result = compareDates(a[sortKey], b[sortKey]);
            } else if (
              header.type === "Int32" ||
              header.type === "Double" ||
              header.type === "Decimal" ||
              header.type === "Int64" ||
              header.type === "UInt64" ||
              header.type === "UInt32" ||
              header.type === "Single"
            ) {
              result = compareNumbers(a[sortKey], b[sortKey]);
            } else {
              result = compareStrings(a[sortKey], b[sortKey]);
            }
          }

          if (result !== 0) {
            return result * sortOrder;
          }
        }
        return 0;
      });
    },
    openFile(att) {
      window.open(
        "api/getAttachedFileContentGet?att=" + att.link,
        "FilePresentation"
      );
    },
    previewFile(att) {
      window.open(
        "api/getAttachedFileContentGet?att=" + att.link,
        "FilePresentation"
      );
    },
    getColor(color) {
      if (color) {
        return this.$getColor(this.$vuetify.theme.dark, color);
      } else {
        return "auto";
      }
    },

    mousemove(evt) {
      var trg = document.elementFromPoint(evt.clientX, evt.clientY);
      if (trg && trg.hasAttribute("prevId")) {
        var params = {
          editquery: this.editquery,
          ID_Attachment_Assignment: parseInt(trg.getAttribute("prevId")),
        };
        this.$showPreview(params);
      }

      if (this.msdwn === 1) {
        this.msmv = 1;
      }
    },

    mousedown() {
      this.msdwn = 1;
      this.msmv = 0;
    },

    setFocus() {
      this.$nextTick(() => {
        this.$el.scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    closeTable() {
      this.showTable = false;
      this.$el.focus();
    },
    initialize() {
      this.getSubqueryData();
    },

    openSubqueryFromDblClick(index) {
      if (this.subquerytabletitles.indexOf("FinanzdatenOpenUrl") > -1) {
        var url =
          this.subquerytables[
            this.subquerytabletitles.indexOf("FinanzdatenOpenUrl")
          ][index].OpenUrl;
        if (url !== null && url !== "") {
          window.open(
            "https://homedb-old.laboratories.telekom.de/Daten/" + url
          );
        }
      } else if (this.writeAllowed) {
        this.showTable = true;
      }
    },

    rightalign(stype) {
      return stype === "Double" || stype === "Int32";
    },

    getSubqueryData() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$changesHub.leaveDetailsGroup(this.grpId);

      var tempthis = this;

      this.$http
        .post("api/subquery", {
          query: this.editquery,
          field: this.field,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            this.myerror = response.data.error;
            this.loading = false;
          } else {
            this.idJumpQuery = response.data.id_jump_query;
            this.jumpQuery = response.data.jump_query;
            this.writeAllowed = response.data.write_allowed;
            this.subIdentifier = response.data.subIdentifier;
            this.directLink_id_query = response.data.directLink_id_query;
            this.directLink_id_link = response.data.directLink_id_link;
            this.directLink_identifier = response.data.directLink_identifier;
            this.grphsh = response.data.grphsh;

            this.actions = response.data.actions.data;

            this.headers = new Array();
            this.hideDefaultHeader = new Array();
            this.subquerytables = new Array();

            this.$changesHub.joinDetailsGroup(this.grphsh);

            for (
              var icnt = 0;
              icnt < response.data.results.tables.length;
              icnt++
            ) {
              var propertyName = Object.keys(
                response.data.results.tables[icnt]
              )[0];

              if (
                response.data.results.tables[icnt][propertyName].data.length ===
                  1 &&
                response.data.results.tables[icnt][propertyName].data[0][
                  "Function_Name"
                ] === "AttachmentInfos"
              ) {
                var keys1 = response.data.results.tables[
                  icnt - 1
                ].Default.columns
                  .flatMap((item) => Object.keys(item))
                  .filter((item) => item.indexOf("ID_") > -1);
                var keys2 = response.data.results.tables[
                  icnt + 1
                ].Table4.columns
                  .flatMap((item) => Object.keys(item))
                  .filter((item) => item.indexOf("ID_") > -1);
                var AttIdFieldname = keys1.filter(
                  (element) =>
                    keys2.includes(element) && element.indexOf("ID_" > -1)
                );
                if (AttIdFieldname.length > 0) {
                  AttIdFieldname = AttIdFieldname[0];
                }

                // var atts = [];
                for (
                  var subitemcnt = 0;
                  subitemcnt < tempthis.subquerytables[icnt - 1].length;
                  subitemcnt++
                ) {
                  tempthis.subquerytables[icnt - 1][subitemcnt].Attachments =
                    response.data.results.tables[icnt + 1].Table4.data.filter(
                      (item) =>
                        item[AttIdFieldname] ===
                        tempthis.subquerytables[icnt - 1][subitemcnt][
                          AttIdFieldname
                        ]
                    );
                }
                tempthis.headers[icnt - 1].push({
                  text: "Files",
                  value: "Attachments",
                });
                icnt++;
              } else {
                this.subquerytables.push(
                  response.data.results.tables[icnt][propertyName].data
                );
                this.subquerytablesColumns.push(
                  response.data.results.tables[icnt][propertyName].columns
                );
                this.subquerytabletitles.push(
                  propertyName === "Default" ? this.field.a : propertyName
                );
                var headercolums =
                  response.data.results.tables[icnt][propertyName].columns;

                this.hideDefaultHeader.push(headercolums.length === 1);
                var tableheaders = new Array();
                this.subquerytables[icnt].hasPosition = false;

                var headertitle;

                for (var cnt = 0; cnt < headercolums.length; cnt++) {
                  headertitle = Object.getOwnPropertyNames(
                    headercolums[cnt]
                  )[0].toLowerCase();
                  if (
                    !headertitle.startsWith("id_") &&
                    headertitle !== "_bgcolor" &&
                    headertitle !== "mark"
                  ) {
                    var tmpObject = {
                      text: Object.getOwnPropertyNames(
                        headercolums[cnt]
                      )[0].replace(/_/g, " "),
                      value: Object.getOwnPropertyNames(headercolums[cnt])[0],
                      rightalign: this.rightalign(
                        headercolums[cnt][
                          Object.getOwnPropertyNames(headercolums[cnt])[0]
                        ]
                      ),
                      sortable: !this.field.print && headertitle !== "markable",
                      type: headercolums[cnt][
                        Object.getOwnPropertyNames(headercolums[cnt])[0]
                      ],
                      markable: headertitle === "markable",
                    };
                    tableheaders.push(tmpObject);
                    
                  }
                }
                if (this.directLink_id_query !== 0) {
                  
                  this.subquerytables[icnt].hasdirectLink = true;
                }

                this.headers.push(tableheaders);
              }
            }
            
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    Changes(params) {
      if (params["params"] && params["params"].grpId === this.grphsh) {
        switch (params["params"].what) {
          case "list item changed":
            this.getSubqueryData();
            break;
          case "list item added":
            this.getSubqueryData();
            break;
        }
      }
    },

    closeDetails(detailsSaved) {
      this.$emit("closeSubDetails", detailsSaved);
      this.showDetails = false;
    },

    openDetailsFromSubquery(item) {
      if (this.directLink_id_query === 0) {
        return;
      }

      if (this.msdwn === 1 && this.msmv === 1) {
        this.msdwn = 0;
        this.msmv = 0;
        return;
      }

      this.msdwn = 0;
      this.msmv = 0;

      var idcolumnname =
        this.subquerytablesColumns[0]
          .map((f) => Object.keys(f)[0])
          .indexOf(this.editquery.identifier + "_filter_for") > -1
          ? item
            ? this.editquery.identifier + "_filter_for"
            : this.editquery.identifier
          : this.editquery.identifier;

      var asLowercase = idcolumnname.toLowerCase();

      var filterid = item
        ? item[
            Object.keys(item).find((key) => key.toLowerCase() === asLowercase)
          ]
        : this.editquery.id;
      this.filterlnk = {
        tablename: this.directLink_identifier.substr(3).replaceAll("_", " "),
        id_query: this.editquery.id_query,
        id_link: this.editquery.id_link,
        id: parseInt(filterid),
        identifier: this.editquery.identifier,
      };

      var id = item ? parseInt(item[this.directLink_identifier]) : 0;

      if (isNaN(id)) {
        this.$showMessage({
          content: "This item cannot be opened from here.",
          color: "info",
          timeout: 2000,
        });
      } else {
        this.editlnk = {
          tablename: this.directLink_identifier.substr(3).replaceAll("_", " "),
          id_query: this.directLink_id_query,
          id_link: this.directLink_id_link,
          id: id,
          identifier: this.directLink_identifier,
        };
        this.showDetails = true;
      }
    },
  },
  created() {
    this.$changesHub.$on("Changes", this.Changes);
  },
  beforeDestroy() {
    this.$changesHub.$off("Changes", this.Changes);
  },
};
</script>
