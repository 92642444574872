<template>
  <div>
    <v-dialog
      v-model="show"
      scrollable
      v-bind:retain-focus="true"
      persistent
      no-click-animation
      style="position: relative"
    >
      <div id="movingText" ref="movingText">{{ tmpTime }}</div>
      <v-card :style="dlgStyle">
        <v-card-title :class="headlineclass" style="position: relative">
          <span class="mksheadline" :dialogid="$el ? $el.__vue__._uid : ''">
            Manage Resource Bundles
          </span>
          <v-spacer></v-spacer
          ><v-btn
            style="margin-right: 1.5em"
            :disabled="!bundle.hasChanges"
            :loading="saving"
            @click="saveBundle"
            ><v-icon>save</v-icon></v-btn
          >
          <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-text ref="outer">
          <v-container ref="inner">
            <template>
              <div ref="jumpdummytop"></div>
            </template>
            <template v-if="showLoadingCircle">
              <v-row align="center" justify="center">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-row>
            </template>
            <template v-else>
              <v-row>
                <v-col cols="5">
                  <v-row>
                    <v-col
                      ><v-text-field
                        outlined
                        dense
                        ref="bundleTitle"
                        v-model="bundle.details.bundle_title"
                        label="Bundle title"
                        flat
                        hide-details
                        clearable
                        @keyup="checkBundleChange"
                        :rules="[(v) => !!v || 'is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="3"
                      style="padding-top: 0px; padding-bottom: 0px"
                    >
                      <v-tooltip top open-delay="700" close-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-switch outlined dense v-model="interchangeable">
                            <template v-slot:label
                              ><v-icon v-on="on">swap_horizontal_circle</v-icon>
                            </template>
                          </v-switch>
                        </template>
                        <span>Interchangeable Content</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row>
                    <v-col cols="3">
                      <v-tooltip top open-delay="700" close-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-select
                            v-on="on"
                            v-model="
                              bundle.details
                                .smallest_possible_booking_period_in_minutes
                            "
                            :items="bookingperiods"
                            item-text="t"
                            item-value="v"
                            dense
                            outlined
                            hide-details
                            label="Smallest period"
                            @change="
                              getAvailability();
                              checkBundleChange();
                            "
                          ></v-select>
                        </template>
                        <span
                          >smallest possible booking period in hours and
                          minutes</span
                        >
                      </v-tooltip>
                    </v-col>
                    <v-col cols="3">
                      <v-tooltip top open-delay="700" close-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-select
                            :readonly="interchangeable"
                            v-on="on"
                            v-model="bundle.details.setup_time_in_minutes"
                            :items="setupperiods"
                            item-text="t"
                            item-value="v"
                            dense
                            outlined
                            hide-details
                            label="Setup"
                            @change="
                              getAvailability();
                              checkBundleChange();
                            "
                          ></v-select>
                        </template>
                        <span>Setup time in hours and minutes</span>
                      </v-tooltip>
                    </v-col>

                    <v-col cols="3">
                      <v-tooltip top open-delay="700" close-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-select
                            :readonly="interchangeable"
                            v-on="on"
                            v-model="bundle.details.teardown_time_in_minutes"
                            :items="setupperiods"
                            item-text="t"
                            item-value="v"
                            dense
                            outlined
                            hide-details
                            label="Teardown"
                            @change="
                              getAvailability();
                              checkBundleChange();
                            "
                          ></v-select>
                        </template>
                        <span>Teardown time in hours and minutes</span>
                      </v-tooltip>
                    </v-col>
                    <v-col
                      cols="3"
                      :style="
                        'padding: 7px;' +
                        (bundle.details
                          .smallest_possible_booking_period_in_minutes -
                          bundle.details.setup_time_in_minutes -
                          bundle.details.teardown_time_in_minutes <
                        0
                          ? 'color:red;'
                          : '')
                      "
                    >
                      =
                      {{
                        bundle.details
                          .smallest_possible_booking_period_in_minutes -
                        bundle.details.setup_time_in_minutes -
                        bundle.details.teardown_time_in_minutes
                      }}
                      min <br /><span style="font-size: smaller"
                        >effective usage</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-if="!isnewbundle">
                <v-col cols="12" style="margin-top: 0px">
                  <hr />
                </v-col>
              </v-row>

              <v-row v-if="!isnewbundle">
                <v-col cols="5">
                  <v-toolbar flat>
                    <v-row>
                      <v-col cols="1">
                        <v-tooltip top open-delay="700" close-delay="500">
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              text
                              small
                              color="grey darken-2"
                              @click="btnGetgetSuggestions()"
                            >
                              <v-icon :class="hasChildren ? '' : 'pulse'"
                                >add</v-icon
                              ></v-btn
                            >
                          </template>
                          <span>add components</span>
                        </v-tooltip>
                      </v-col>

                      <v-col class="text-right">
                        <v-text-field
                          outlined
                          dense
                          v-model="search"
                          label="Filter for components"
                          flat
                          hide-details
                          clearable
                          append-icon="search"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar>
                  <v-row>
                    <v-col cols="12">
                      <div
                        style="
                          overflow-x: auto;
                          overflow-y: auto;
                          height: 500px;
                        "
                      >
                        <v-treeview
                          ref="itemtree"
                          v-model="selected"
                          :open.sync="open"
                          :items="bundle.leaves"
                          :search="search"
                          :filter="filter"
                          :open-all="expanded"
                          hoverable
                          item-key="id_rd"
                          dense
                        >
                          <template v-slot:label="{ item }">
                            <div
                              @contextmenu.prevent="showToolsmenu($event, item)"
                              @dblclick="handleDoubleClick(item)"
                              class="ox"
                            >
                              <v-icon
                                v-if="item.level % 2 === 1 || item.level === 0"
                                v-text="item.icon"
                              >
                              </v-icon
                              >&nbsp;
                              <span
                                :style="
                                  item.deleteLeaf
                                    ? 'background-color: #f0a13a;'
                                    : item.newLeaf
                                    ? 'background-color: #9be695;'
                                    : ''
                                "
                                v-html="
                                  $highlight(
                                    item.name,
                                    search,
                                    'indigo lighten-3'
                                  ) +
                                  (item.icon !== null &&
                                  item.children &&
                                  item.level % 2 === 1 &&
                                  item.level > 0
                                    ? ' (' + item.children.length + ')'
                                    : '')
                                "
                              >
                              </span>
                            </div>
                          </template>
                          <template v-slot:append="{ item }">
                            <v-tooltip right open-delay="700" close-delay="500">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  v-show="item.hasAS"
                                  @click="showAvailabilitySlots(item)"
                                  >date_range</v-icon
                                >
                              </template>
                              <span>change availability slots</span>
                            </v-tooltip>

                            <v-tooltip right open-delay="700" close-delay="500">
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on"
                                  class="custom-icon-color"
                                  v-show="
                                    !item.hasAS &&
                                    item.children &&
                                    item.level % 2 === 1 &&
                                    item.children.filter(
                                      (c) => c.hasAS === true
                                    ).length
                                  "
                                  >date_range</v-icon
                                >
                              </template>
                              <span
                                >contains resource(s) with deviating time
                                slots</span
                              >
                            </v-tooltip>
                          </template>
                        </v-treeview>
                      </div>
                    </v-col></v-row
                  >
                </v-col>
                <v-col cols="7">
                  <mksresourcecalendar
                    ref="calendar"
                    :bundle="bundle"
                    :editquery="editquery"
                    :calendarSettings="calendarSettings"
                    @closeReservation="getDetails()"
                    @selectOtherBundle="selectOtherBundle"
                    style="height: 400px"
                  >
                  </mksresourcecalendar>
                </v-col>
              </v-row>
              <v-menu
                v-model="showMenu"
                :position-x="x"
                :position-y="y"
                absolute
                offset-y
              >
                <v-list style="cursor: pointer">
                  <v-list-item
                    v-for="menuItem in toolMenu"
                    :key="menuItem.text"
                    :style="menuItem.style"
                    @click.stop="tool(menuItem.id)"
                    :disabled="menuItem.disabled"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="menuItem.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ menuItem.text }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showDeleteBundleConfirm"
      scrollable
      v-bind:retain-focus="true"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title :class="headlineclass">
          <span class="mksheadline" :dialogid="$el ? $el.__vue__._uid : ''">
            Delete Bundle "{{ bundle.details.bundle_title }}"?
          </span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="showDeleteBundleConfirm = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              ><v-col
                >All reservations associated with this bundle will be removed
                from the database. This also applies to dates in the
                past.</v-col
              ></v-row
            >
            <v-row>
              <v-col>
                <v-btn color="warning" :loading="saving" @click="deleteBundle"
                  ><v-icon>delete</v-icon>Delete</v-btn
                > </v-col
              ><v-col cols="4" class="text-right">
                <v-btn color="primary" @click="showDeleteBundleConfirm = false"
                  ><v-icon>cancel</v-icon>Cancel</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showAddResource"
      scrollable
      v-bind:retain-focus="true"
      persistent
      no-click-animation
    >
      <v-card :style="dlgStyle">
        <v-card-title :class="headlineclass" style="position: relative">
          <span class="mksheadline" :dialogid="$el ? $el.__vue__._uid : ''">
            {{ addRessourceHeadline }}
          </span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="showAddResource = false"
            >mdi-close</v-icon
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                ><v-text-field
                  ref="searchForAdd"
                  v-model="searchForAdd"
                  :label="searchlabel"
                  flat
                  hide-details
                  clearable
                  prepend-icon="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div style="overflow: auto; height: 550px" ref="listContainer">
                  <v-list style="cursor: pointer">
                    <v-list-item-group v-model="newChildItem">
                      <v-list-item
                        v-for="newLeaf in newLeaves"
                        :key="newLeaf.ID"
                        @click.stop="addChild(newLeaf)"
                        ref="listItemRefs"
                      >
                        <v-list-item-icon>
                          <v-icon v-text="newLeaf.Icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="
                              $highlight(
                                newLeaf.Resource,
                                searchForAdd,
                                'indigo lighten-3'
                              )
                            "
                          >
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-html="
                              $highlight(
                                newLeaf.ResourceSubtitle,
                                searchForAdd,
                                'indigo lighten-3'
                              )
                            "
                          >
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDialogShow"
      content-class="v-dialog-mks"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title>
          <span class="mksheadline" v-text="hintOnClose"></span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="confirmDialogShow = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>Close anyway?</v-card-text>
        <v-card-actions>
          <v-btn
            class="mr-4 selected"
            color="error"
            @click="
              bundle.leaves = [];
              $emit('close');
            "
            ><span> Close item and discard changes</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mr-4" color="info" @click="confirmDialogShow = false"
            >return to item</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectOtherDialogShow"
      content-class="v-dialog-mks"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title>
          <span class="mksheadline" v-text="hintOnClose"></span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="selectOtherDialogShow = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>Jump to the causing bundle anyway?</v-card-text>
        <v-card-actions>
          <v-btn
            class="mr-4 selected"
            color="error"
            @click="selectOtherBundleDo"
            ><span> Jump to the causing bundle and discard changes</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-4"
            color="info"
            @click="selectOtherDialogShow = false"
            >return to item</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <mksresourcetimeslots
      :editquery="getAvailabilityQuery()"
      v-if="showResourceAvailability"
      :alternatelayout="AlternativeDetailsLayout"
      @close="closeResourceAvailability"
    ></mksresourcetimeslots>

    <mksresourcebundle
      v-if="showSubbundle"
      :editquery="editquerysubbundle"
      :visible="true"
      @close="
        showSubbundle = false;
        getDetails();
      "
    >
    </mksresourcebundle>
  </div>
</template>

<style scoped>
.custom-icon-color {
  opacity: 0.5;
}

#movingText {
  position: absolute;
  font-size: 18px;
  color: #000;
  white-space: nowrap;
  z-index: 5000;
}

div.ox {
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
</style>

<style>
.trix-content {
  max-width: 85vw;
  height: 197px;
}
.pulse {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 70%;
  background: #7fd679;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(79, 204, 44, 0.4);
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(79, 204, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(79, 204, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(79, 204, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(79, 204, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(79, 204, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(79, 204, 44, 0);
    box-shadow: 0 0 0 10px rgba(79, 204, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(79, 204, 44, 0);
    box-shadow: 0 0 0 0 rgba(79, 204, 44, 0);
  }
}
</style>

<script>
import mksresourcetimeslots from "@/components/mksresourcetimeslots.vue";

import { mapState } from "vuex";
export default {
  name: "mksresourcebundle",

  components: {
    mksresourcetimeslots,
  },

  data: () => ({
    urlPattern: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
    urlError: "",
    saving: false,
    hintOnClose: "You have unsaved changes!",
    showReservationEvents: true,
    newstartdate: null,
    newstarttime: null,
    selectedElement: null,
    selectedOpen: false,
    selectedSlot: {},
    currentquery: null,
    showSubbundle: false,
    showResourceAvailability: false,
    showResourceReservation: false,
    headlineclass: "",
    bgcolor: "",
    loading: false,
    showLoadingCircle: false,
    firstcall: false,
    bundle: {
      details: {
        id_resource_bundle: null,
        bundle_title: null,
        description: null,
        id_resource_root: null,
      },
      leaves: [],
    },
    selected: [],
    initiallyOpen: [],
    open: [],

    confirmModeOn: false,
    showMenu: false,
    x: 0,
    y: 0,
    menuOpen: [],
    search: null,
    searchForAdd: null,
    caseSensitive: false,
    expanded: false,
    leavesForDeletion: [],
    newLeaves: [],
    parentObj: null,
    showAddResource: false,
    addRessourceHeadline: "",
    searchlabel: "Search for components",
    AlternativeDetailsLayout: false,
    IconButtons: false,
    SaveAndClose: false,
    currentItem: { id: 0 },
    global_unique_id_rd: 0,
    grphsh: "",
    detailshashOrig: "",
    editquerysubbundle: {},
    newChildItem: null,
    companies: [],
    testtypes: [],
    bundleOrig: null,
    detailsOrig: null,
    showDeleteBundleConfirm: false,
    confirmDialogShow: false,
    selectOtherDialogShow: false,
    otherBundleId: 0,
    type: "week",
    types: ["month", "week"], //'day', '4day'],
    mode: "stack",
    modes: ["stack", "column"],
    cal_weekday: [1, 2, 3, 4, 5, 6, 0],
    cal_weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      // { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    calendarvalue: "",
    // events: [],
    imagefile: null,
    bundleimagesource: null,
    bundleimageerror: false,
    imagechanged: false,
    dragover: false,
    deleteImage: false,
    tmpTime: "",
    minuteSteps: 15,
    calendarSettings: {
      showBookable: true,
      showInvalid: false,
      showReservationEventsForThisBundle: true,
      showReservationEventsForOtherBundles: false,
    },
    calendarSettingsOrig: null,
    editorContent: null,
    interchangeable: false,
  }),

  props: {
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    // visible: {
    //     type: Boolean,
    //     required: true,
    //     default: false,
    // },
  },

  mounted() {
    document.getElementsByTagName("trix-toolbar")[0].style.display = undefined;
    var linkBtn = document.querySelector(
      'trix-toolbar [data-trix-action="link"]'
    );
    if (linkBtn) {
      linkBtn.style.display = "none";
    }
    var codeBtn = document.querySelector(
      "trix-toolbar .trix-button--icon-code"
    );
    if (codeBtn) {
      codeBtn.style.display = "none";
    }
    var fileBtn = document.querySelector(
      "trix-toolbar .trix-button-group--file-tools"
    );
    if (fileBtn) {
      fileBtn.style.display = "none";
    }
    this.currentquery = this.editquery;
    this.getDetails(true);

    var temp = this.$getPref({
      TabName: "Options",
      PrefID: "CalendarSettings",
    });

    if (temp != null) {
      this.calendarSettings = JSON.parse(temp);
      this.calendarSettingsOrig = temp;
    } else {
      this.$setPref({
        TabName: "Options",
        PrefID: "CalendarSettings",
        Value: JSON.stringify(this.calendarSettings),
        Save: true,
      });
      this.calendarSettingsOrig = JSON.stringify(this.calendarSettings);
    }
  },

  methods: {
    validateUrl(value) {
      const ruleResult = !value || this.urlPattern.test(value) || "Invalid URL";
      this.urlError = typeof ruleResult === "string" ? ruleResult : "";
    },
    getDayOfYear(date) {
      var start = new Date(date.getFullYear(), 0, 0);
      var diff = date - start;
      var oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
      var dayOfYear = Math.floor(diff / oneDay);

      return dayOfYear;
    },
    addLeadingZero(number) {
      return number < 10 ? "0" + number : number;
    },
    selectOtherBundle(other) {
      this.otherBundleId = other;
      if (!this.bundle.hasChanges) {
        this.editquery.id = other;
        this.getDetails();
      } else {
        this.selectOtherDialogShow = true;
      }
    },
    selectOtherBundleDo() {
      this.selectOtherDialogShow = false;

      this.editquery.id = this.otherBundleId;
      this.getDetails();
    },

    handleDoubleClick(item) {
      if (item.level % 2 == 0 && item.level > 0) {
        this.currentItem = item;
        this.tool(3);
      } else {
        if (!this.open.includes(item.id_rd)) {
          this.open.push(item.id_rd);
        } else {
          var found = this.open.indexOf(item.id_rd);
          if (found > -1) {
            this.open.splice(found, 1);
          }
        }
      }
    },

    scrollToItem(index) {
      if (this.$refs.listItemRefs && this.$refs.listItemRefs[index]) {
        const listItemElement = this.$refs.listItemRefs[index].$el;
        const containerElement = this.$refs.listContainer;

        if (listItemElement && containerElement) {
          const listItemRect = listItemElement.getBoundingClientRect();
          const containerRect = containerElement.getBoundingClientRect();

          if (
            listItemRect.top < containerRect.top ||
            listItemRect.bottom > containerRect.bottom
          ) {
            listItemElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }
      }
    },

    handleImageChange(event) {
      this.imagechanged = true;
      this.checkBundleChange();

      this.imagefile = null;

      if (event && event.target.id === "") {
        this.imagefile = event.dataTransfer.files[0];
      } else {
        this.imagefile = this.$refs.uploadImage.files[0];
      }

      if (this.imagefile) {
        const reader = new FileReader();
        var tempthis = this;
        reader.onload = function (e) {
          tempthis.bundleimagesource = e.target.result;
          tempthis.bundleimageerror = false;
        };
        reader.onerror = function () {
          tempthis.bundleimagesource = null;
        };
        reader.readAsDataURL(this.imagefile);
      }
    },

    getAvailabilityQuery() {
      var id = 0;

      if (this.currentItem.id_r) {
        // value from suggestions
        id = this.currentItem.id_r;
      } else {
        id = parseInt(this.currentItem.id);
      }

      return {
        id_query: this.editquery.id_query,
        id_link: this.editquery.id_link,
        id: id,
        identifier: this.editquery.identifier,
        tablename: this.editquery.tablename,
        hasPrevious: false,
        hasNext: false,
      };
    },

    getReservationQuery() {
      return {
        id_query: this.editquery.id_query,
        id_link: this.editquery.id_link,
        id: this.editquery.id,
        identifier: this.editquery.identifier,
        tablename: this.editquery.tablename,
        hasPrevious: false,
        hasNext: false,
        id_booked_slot: this.selectedSlot.bookingId,
        newstartdate: this.newstartdate,
        newstarttime: this.newstarttime,
      };
    },

    closeResourceAvailability: function (hasAS) {
      this.getAvailability();
      this.currentItem.hasAS = hasAS;
      this.showResourceAvailability = false;
    },

    deleteBundle() {
      this.deleteChild(this.bundle.leaves[0]);
      this.bundle.leaves[0].newLeaf = false;
      this.bundle.leaves[0].deleteLeaf = true;
      this.bundle.details.delete = true;

      this.saveBundle(true);
      // this.show = false;
    },
    areObjectsEqual(obj1, obj2) {
      // Get the keys of both objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      // Check if both objects have the same number of keys
      if (keys1.length !== keys2.length) {
        return false;
      }

      // Check if all keys and their corresponding values are the same
      for (let key of keys1) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      }

      return true;
    },

    checkBundleChange() {
      if (
        this.deleteImage ||
        this.bundleOrig.bundle_title != this.bundle.details.bundle_title ||
        this.bundleOrig.description != this.bundle.details.description ||
        this.bundleOrig.owner != this.bundle.details.owner ||
        !this.areObjectsEqual(
          this.bundleOrig.testtypes,
          this.bundle.details.testtypes
        ) ||
        this.bundleOrig.interchangeable !=
          this.bundle.details.interchangeable ||
        this.bundleOrig.setup_time_in_minutes !=
          this.bundle.details.setup_time_in_minutes ||
        this.bundleOrig.smallest_possible_booking_period_in_minutes !=
          this.bundle.details.smallest_possible_booking_period_in_minutes ||
        this.bundleOrig.teardown_time_in_minutes !=
          this.bundle.details.teardown_time_in_minutes ||
        this.bundleOrig.url_for_further_information !=
          this.bundle.details.url_for_further_information ||
        this.imagechanged
      ) {
        this.bundle.hasChanges = true;

        this.bundle.leaves[0].name = this.bundle.details.bundle_title;
        if (this.bundle.leaves[0].id_rd == "new") {
          this.bundle.leaves[0].id_rd = "-1";
        }
      }
    },
    showToolsmenu(e, item) {
      // if (item.level % 2 === 0) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.currentItem = item;
      this.$nextTick(() => {
        this.showMenu = true;
      });
      // }
    },
    showAvailabilitySlots(item) {
      this.currentItem = item;
      this.tool(3);
    },
    deleteChild(item, id_rd) {
      var childIndex = item.children.findIndex((c) => c.id_rd === id_rd);
      if (childIndex != -1) {
        // item.children.splice(child, 1);
        item.children[childIndex].newLeaf = false;
        item.children[childIndex].deleteLeaf = true;

        this.bundle.hasChanges = true;
        this.showMenu = false;
      } else {
        item.children.forEach((c) => {
          this.deleteChild(c, id_rd);
        });
      }
    },
    markItemForDeletion(item) {
      item.newLeaf = false;
      item.deleteLeaf = true;
      if (!(item.level > 0 && item.type === "Bundles")) {
        item.children.forEach((c) => {
          this.markItemForDeletion(c);
        });
      }
      this.getAvailability();
    },
    addChild(newChild) {
      const results = this.findPropertyInObject(
        this.bundle.leaves,
        "id_rd",
        this.currentItem.id_rd
      );
      if (results.length === 0) {
        return;
      }

      var parent = results[0];

      if (parent && newChild) {
        // has parent type child?
        var newGroupType = this.getGroupType(newChild.type);
        var typeChild = parent.children.find((c) => c.type === newGroupType);
        if (!typeChild) {
          typeChild = {
            name: newGroupType,
            id: --this.global_unique_id_rd + "",
            icon: newChild.Icon.replace("person", "people"),
            type: newGroupType,
            id_rd: parent.id + "_" + newGroupType,
            children: [],
            level: parent.level + 1,
          };
          parent.children.push(typeChild);
        }
        var alreadyChild = typeChild.children.find(
          (a) => a.name === newChild.Resource
        );
        if (alreadyChild) {
          this.$showMessage({
            content: "This item is already part of this branch.",
            color: "error",
            timeout: 3000,
          });
        } else {
          typeChild.children.push({
            name: newChild.Resource,
            id: --this.global_unique_id_rd + "",
            id_db: newChild.ID_DB,
            id_parent: parent.id,
            icon: null,
            id_rd: --this.global_unique_id_rd + "",
            children: [],
            level: parent.level + 2,
            newLeaf: true,
            type: newChild.type,
            hasAS: newChild.hasAS,
            id_r: newChild.ID_Resource,
          });

          typeChild.children.sort((a, b) => {
            const nameA = a.name.toUpperCase(); // Um Groß- und Kleinschreibung zu ignorieren
            const nameB = b.name.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });

          if (!this.open.includes(parent.id_rd)) {
            this.open.push(parent.id_rd);
          }
          if (!this.open.includes(typeChild.id_rd)) {
            this.open.push(typeChild.id_rd);
          }

          this.showAddResource = false;
          this.bundle.hasChanges = true;
          this.$nextTick(() => {
            var treeElem = document
                .querySelector("div.v-treeview-node")
                .querySelectorAll("span"),
              myDiv = [...treeElem].filter(
                (e) => e.innerText == newChild.Resource
              );
            if (myDiv.length === 1) {
              myDiv[0].scrollIntoView({ behavior: "smooth", block: "start" });
            }
          });
        }
      }

      this.getAvailability();
    },

    tool(toolselection) {
      switch (toolselection) {
        case 1: // add
          this.showMenu = false;
          this.addRessourceHeadline = "Add a Component";
          this.showAddResource = true;
          this.$nextTick(() => {
            setTimeout(() => this.$refs.searchForAdd.$refs.input.focus(), 100);
          });

          break;
        case 2: // remove child - Step 1
          if (this.confirmModeOn) {
            this.confirmModeOn = false;
            this.leavesForDeletion.push(this.currentItem.id_rd);
            // this.deleteChild(this.bundle.leaves[0], this.currentItem.id_rd);
            this.markItemForDeletion(this.currentItem);
            this.bundle.hasChanges = true;
            this.showMenu = false;
          } else {
            this.confirmModeOn = true;
          }
          break;
        case 3: // set availability
          this.showResourceAvailability = true;
          this.showMenu = false;
          break;
        case 4: // open branch
          this.openBranch(this.currentItem);
          this.showMenu = false;
          break;
        case 5: // close branch
          this.closeBranch(this.currentItem);
          this.showMenu = false;
          break;
        case 6: // remove whole bundle
          this.showDeleteBundleConfirm = true;
          this.showMenu = false;
          break;
        case 7: // edit sub bundle
          this.editquerysubbundle = {
            id_query: this.editquery.id_query,
            id_link: this.editquery.id_link,
            id: parseInt(this.currentItem.id_p),
            identifier: "ID_Resource_Bundle",
            tablename: "Resources Bundles",
            hasPrevious: false,
            hasNext: false,
          };
          this.showSubbundle = true;
          this.showMenu = false;
          break;
      }
    },
    openBranch(item) {
      this.open.push(item.id_rd);
      item.children.forEach((child) => this.openBranch(child));
    },
    closeBranch(item) {
      var found = this.open.indexOf(item.id_rd);
      if (found > -1) {
        this.open.splice(found, 1);
      }
      item.children.forEach((child) => this.closeBranch(child));
    },
    getDetails(showloading = false, reservationsonly = false) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (showloading) {
        this.showLoadingCircle = true;
      }
      var tempthis = this;

      this.$http
        .post("api/ResourceBundleGet", {
          query: this.currentquery,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else if (response.data.bundle) {
            if (reservationsonly) {
              this.bundle.TimeSlotsBookedAsEventList =
                response.data.bundle.TimeSlotsBookedAsEventList;
            } else {
              this.bundle.hasChanges = false;
              this.bundle = response.data.bundle;

              if (
                this.bundle.details
                  .smallest_possible_booking_period_in_minutes === null ||
                this.bundle.details
                  .smallest_possible_booking_period_in_minutes === 0
              ) {
                this.bundle.details.smallest_possible_booking_period_in_minutes = 60;
              }
              this.editorContent = this.bundle.details.description;

              this.bundleOrig = JSON.parse(JSON.stringify(this.bundle.details));

              if (this.open.length === 0) {
                this.open = [];
                if (!this.isnewbundle) {
                  this.open.push(tempthis.bundle.leaves[0].id_rd);
                }
              }

              this.global_unique_id_rd = response.data.bundle.globunique_id;
              this.grphsh = response.data.bundle.grphsh;
              if (this.detailshashOrig != this.detailshash) {
                if (this.detailshashOrig !== "") {
                  this.$changesHub.leaveDetailsGroup(this.detailshashOrig);
                }
                this.detailshashOrig = this.detailshash;
              }
              this.$changesHub.joinDetailsGroup(this.detailshash);
            }

            if (this.isnewbundle) {
              this.$nextTick(() => {
                setTimeout(
                  () => this.$refs.bundleTitle.$refs.input.focus(),
                  100
                );
              });
            }
            this.bundleimageerror = false;
            this.bundleimagesource = this.getImgSrc(this.bundle.details);
          } else {
            this.show = false;
            this.$showMessage({
              content: "bundle not found",
              color: "warning",
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
          this.show = false;
        })
        .finally(() => {
          this.showLoadingCircle = false;
          this.getSelectionLists();
        });
    },
    getAvailability() {
      this.$http
        .post("api/ResourceBundleGetSimFreeSlots", {
          query: this.editquery,
          bundle: this.bundle,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else if (response.data.bundle) {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");

            const dateString = `${year}-${month}-${day}`;

            this.bundle.TimeSlotsOfferedAsEventList =
              this.bundle.TimeSlotsOfferedAsEventList.filter(
                (obj) => obj.start.split("T")[0] < dateString
              ).concat(response.data.bundle.TimeSlotsOfferedAsEventList);
            this.bundle.TimeSlotsBasedOnResourcesAsEventList =
              this.bundle.TimeSlotsBasedOnResourcesAsEventList.filter(
                (obj) => obj.start.split("T")[0] < dateString
              ).concat(
                response.data.bundle.TimeSlotsBasedOnResourcesAsEventList
              );
            this.bundle.TimeSlotsBookedAsEventList =
              this.bundle.TimeSlotsBookedAsEventList.filter(
                (obj) => obj.start.split("T")[0] < dateString
              ).concat(response.data.bundle.TimeSlotsBookedAsEventList);
          } else {
            this.show = false;
            this.$showMessage({
              content: "bundle not found",
              color: "warning",
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
          this.show = false;
        });
    },
    getImgSrc(item) {
      if (item.hasImage) {
        this.bundleimageerror = false;
        var d = new Date();
        return (
          "/api/imgFRB?Id=" + item.id_resource_bundle + "&v=" + d.getTime()
        );
      } else {
        this.bundleimageerror = true;
        return null;
      }
    },
    btnGetgetSuggestions() {
      this.currentItem = this.bundle.leaves[0];
      this.showMenu = false;
      this.addRessourceHeadline = "Add a Component";
      this.showAddResource = true;
      this.$nextTick(() => {
        setTimeout(() => this.$refs.searchForAdd.$refs.input.focus(), 100);
      });
    },
    getSuggestions(showloading = false) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (showloading) {
        this.showLoadingCircle = true;
      }

      this.$http
        .post("api/ResourceGetSuggestions", {
          query: this.editquery,
          search: this.searchForAdd,
          id_resource: this.currentItem.id,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.newLeaves = response.data.results.data;
          }
          this.loading = false;
          this.newChildItem = 0;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
          this.show = false;
        });
    },
    getSelectionLists() {
      this.$http
        .post("api/ResourceGetSelectionLists", {
          query: this.editquery,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.companies = response.data.companies;
            this.testtypes = response.data.testtypes;
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
          this.show = false;
        });
    },
    saveBundle(closeAfterSave = false) {
      if (
        this.bundle.details.bundle_title === null ||
        this.bundle.details.bundle_title === ""
      ) {
        this.$showMessage({
          content: "Missing bundle title",
          color: "error",
          timeout: 2500,
        });

        return;
      }

      if (
        this.bundle.details.owner === null ||
        this.bundle.details.owner === ""
      ) {
        this.$showMessage({
          content: "Missing bundle owner",
          color: "error",
          timeout: 2500,
        });

        return;
      }

      var tempthis = this;

      let formData = new FormData();
      formData.append(
        "bundle",
        JSON.stringify({
          query: this.editquery,
          bundle: this.bundle,
          delete: this.leavesForDeletion,
          deleteImage: this.deleteImage,
        })
      );

      if (this.imagechanged) {
        formData.append(this.imagefile.name, this.imagefile);
      }

      this.saving = true;

      this.$http
        .post("api/ResourceBundleSave", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else if (response.data.bundle) {
            this.bundle = response.data.bundle;
            this.currentquery.id = this.bundle.details.id_resource_bundle;
            this.bundleOrig = JSON.parse(JSON.stringify(this.bundle.details));

            tempthis.open.push(...response.data.bundle.open);
            this.bundle.hasChanges = false;
            this.global_unique_id_rd = response.data.bundle.globunique_id;
            this.$changesHub.detailsChanged(this.detailshash);
          }

          this.$showMessage({
            content: "Changes saved.",
            color: "success",
            timeout: 1500,
          });
          this.loading = false;
          if (closeAfterSave === true) {
            this.show = false;
          }
          if (this.deleteImage) {
            this.deleteImage = false;
            this.bundleimageerror = false;
            this.bundleimagesource = this.getImgSrc(this.bundle.details);
          }
          this.getDetails();
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
        })
        .finally(() => {
          tempthis.saving = false;
          this.showDeleteBundleConfirm = false;
        });
    },
    findPropertyInObject(obj, targetProp, targetValue) {
      const results = [];

      // Hilfsfunktion für die rekursive Durchsuchung
      function searchInObject(currentObj) {
        for (const key in currentObj) {
          if (typeof currentObj[key] === "object") {
            // Wenn der Wert eine Objektreferenz ist, rekursiv in das Objekt eintauchen
            searchInObject(currentObj[key]);
          } else if (key === targetProp && currentObj[key] === targetValue) {
            // Wenn die Eigenschaft und der Wert übereinstimmen, das Objekt zu den Ergebnissen hinzufügen
            results.push(currentObj);
          }
        }
      }

      searchInObject(obj);
      return results;
    },
    refreshSettings() {
      this.AlternativeDetailsLayout = this.$getPref({
        TabName: "Options",
        PrefID: "AlternativeDetailsLayout",
      });
      if (this.AlternativeDetailsLayout == null) {
        this.AlternativeDetailsLayout = false;
      }
      this.IconButtons = this.$getPref({
        TabName: "Options",
        PrefID: "IconButtons",
      });
      if (this.IconButtons == null) {
        this.IconButtons = false;
      }
      this.SaveAndClose = this.$getPref({
        TabName: "Options",
        PrefID: "SaveAndClose",
      });
      if (this.SaveAndClose == null) {
        this.SaveAndClose = false;
      }

      var temp = this.$getPref({
        TabName: "Options",
        PrefID: "ShowIdentifierColumn",
      });
      if (temp !== null && temp !== this.ShowIdentifierColumn) {
        this.ShowIdentifierColumn = temp;
      }
      if (this.ShowIdentifierColumn == null) {
        this.ShowIdentifierColumn = false;
      }
    },

    getGroupType(type) {
      return (type + (type.startsWith("Asset") ? "" : "s")).replace(
        "Persons",
        "People"
      );
    },
  },

  watch: {
    'bundle.details.setup_time_in_minutes': {
      handler() {
        if (this.showLoadingCircle === false && this.bundle.allBoundBundles && this.bundle.allBoundBundles.length) {
          this.$showMessage({
            content:
              "This bundle is bound to other bundles by shared resource(s).<br>The same value will be set for:<ul><li>" + this.bundle.allBoundBundles.join('<li>') +"</ul>",
            color: "warning",
            timeout: 5000,
          });
        }
        this.checkBundleChange();
      },
    },
    'bundle.details.teardown_time_in_minutes': {
      handler() {
        if (this.showLoadingCircle === false && this.bundle.allBoundBundles && this.bundle.allBoundBundles.length) {
          this.$showMessage({
            content:
              "This bundle is bound to other bundles by shared resource(s).<br>The same value will be set for:<ul><li>" + this.bundle.allBoundBundles.join('<li>') +"</ul>",
            color: "warning",
            timeout: 5000,
          });
        }
        this.checkBundleChange();
      },
    },
    interchangeable: {
      handler() {
        if (this.interchangeable) {
          this.detailsOrig = JSON.parse(JSON.stringify(this.bundle.details));
          this.bundle.details.teardown_time_in_minutes = 0;
          this.bundle.details.teardown_time_in_minutes = 0;
        } else {
          this.bundle.details.setup_time_in_minutes =
            this.detailsOrig.setup_time_in_minutes;
          this.bundle.details.teardown_time_in_minutes =
            this.detailsOrig.teardown_time_in_minutes;
        }
        this.bundle.details.interchangeable = this.interchangeable;
        this.checkBundleChange();
        this.getAvailability();
      },
    },
    deleteImage: {
      handler() {
        this.checkBundleChange();
      },
    },
    searchForAdd: {
      handler() {
        if (this.searchForAdd.length === 0) {
          this.searchlabel = "Search for components";
          this.newLeaves = [];
        } else if (this.searchForAdd.length < 2) {
          this.searchlabel = "keep typing!";
          this.newLeaves = [];
        } else {
          this.searchlabel = "";
          this.getSuggestions();
        }
      },
    },
    showMenu: {
      handler() {
        this.confirmModeOn = false;
      },
    },
    search: {
      handler() {
        if (this.search !== "" && this.initiallyOpen.length === 0) {
          this.initiallyOpen = this.open;
        } else if (this.search === "" && this.initiallyOpen.length > 0) {
          this.open = this.initiallyOpen;
          this.initiallyOpen = [];
          return;
        }

        this.open =
          this.search !== "" ? Object.keys(this.$refs.itemtree.nodes) : [];
      },
    },

    prefs: {
      handler() {
        this.refreshSettings();
      },
      deep: true,
    },

    newChildItem(newValue) {
      if (newValue !== null) {
        this.scrollToItem(newValue);
      }
    },
    lastKeyUp: {
      handler() {
        if (this.show) {
          if (this.lastKeyUp.altKey) {
            //check if dialog is topmost
            var dia2 = this.$getHighest(".v-dialog--active");
            dia2 = dia2 && dia2.querySelector("[dialogid]");
            if (
              dia2 &&
              parseInt(dia2.getAttribute("dialogid")) === this.$el.__vue__._uid
            ) {
              switch (this.lastKeyUp.keyCode) {
                case 83: // Alt + S = Save
                  this.saveBundle();
                  break;
                case 171: // Alt + Plus Sign
                  this.btnGetgetSuggestions();
                  break;
              }
            }
          } else if (this.showAddResource) {
            const pagesize = 10;
            switch (this.lastKeyUp.keyCode) {
              case 36: // pos1
                this.newChildItem = 0;
                break;
              case 38: // up
                if (this.newChildItem > 0) {
                  this.newChildItem--;
                }
                break;
              case 40: // down
                if (this.newChildItem < this.newLeaves.length - 1) {
                  this.newChildItem++;
                }
                break;
              case 35: // end
                this.newChildItem = this.newLeaves.length - 1;
                break;
              case 34: // page down
                if (this.newChildItem + pagesize <= this.newLeaves.length - 1) {
                  this.newChildItem += pagesize;
                } else {
                  this.newChildItem = this.newLeaves.length - 1;
                }
                break;
              case 33: // page up
                if (this.newChildItem - pagesize >= 0) {
                  this.newChildItem -= pagesize;
                } else {
                  this.newChildItem = 0;
                }
                break;
              case 13:
                this.addChild(this.newLeaves[this.newChildItem]);
                break;
            }
          }
        }
      },
    },

    editorContent: {
      handler(newContent) {
        this.bundle.details.description = newContent;
        this.checkBundleChange();
      },
    },
  },
  computed: {
    ...mapState(["currentUser", "currenthubsubscriptions", "lastKeyUp"]),
    urlRule() {
      return (value) => !value || this.urlPattern.test(value) || "Invalid URL";
    },

    prefs() {
      return this.currentUser.prefs;
    },
    bookingperiods() {
      var b = [];
      var hours = 0;
      var remainingMinutes = 0;
      for (let minutes = 15; minutes < 1440; minutes += 15) {
        hours = Math.floor(minutes / 60);
        remainingMinutes = minutes % 60;
        b.push({
          v: minutes,
          t:
            (hours.toString() < 10 ? "0" : "") +
            hours +
            ":" +
            (remainingMinutes.toString() < 10 ? "0" : "") +
            remainingMinutes,
        });
      }
      return b;
    },
    setupperiods() {
      var b = [];
      var hours = 0;
      var remainingMinutes = 0;
      for (let minutes = 0; minutes < 1440; minutes += 15) {
        hours = Math.floor(minutes / 60);
        remainingMinutes = minutes % 60;
        b.push({
          v: minutes,
          t:
            (hours.toString() < 10 ? "0" : "") +
            hours +
            ":" +
            (remainingMinutes.toString() < 10 ? "0" : "") +
            remainingMinutes,
        });
      }
      return b;
    },
    detailshash: {
      get() {
        return this.grphsh + "_" + this.editquery.id;
      },
    },
    show: {
      get() {
        return true;
      },
      set(value) {
        if (!value) {
          var cs = JSON.stringify(this.$refs.calendar.currentCalendarSettings);
          if (this.calendarSettingsOrig !== cs) {
            this.$setPref({
              TabName: "Options",
              PrefID: "CalendarSettings",
              Value: cs,
              Save: true,
            });
          }

          if (!this.bundle.hasChanges) {
            this.bundle.leaves = [];
            this.$emit("close");
          } else {
            this.confirmDialogShow = true;
          }
        }

        this.$changesHub.leaveDetailsGroup(this.detailshash);
        this.grphsh = "";
      },
    },
    isnewbundle: {
      get() {
        return (
          this.bundle.details.id_resource_bundle === null ||
          this.bundle.details.id_resource_bundle === 0
        );
      },
    },

    dlgStyle: {
      get() {
        return this.bgcolor !== "" ? "background-color:" + this.bgcolor : "";
      },
    },

    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    hasChildren() {
      return (
        this.bundle.leaves &&
        this.bundle.leaves.length > 0 &&
        this.bundle.leaves[0].children &&
        this.bundle.leaves[0].children.length > 0
      );
    },
    toolMenu() {
      if (!this.currentItem) {
        return [];
      }

      var menuItems = [];

      if (this.currentItem.level === 0) {
        menuItems.push({
          id: 1,
          text: "add a Component",
          icon: "add",
          disabled: false,
          style: null,
        });
      }

      if (
        this.currentItem.level % 2 === 0 &&
        (this.currentItem.id > 0 || this.currentItem.hasAS)
      ) {
        menuItems.push({
          id: 3,
          text: "set availability slots",
          icon: "event_available",
          disabled: false,
          style: null,
        });
      }

      if (
        this.currentItem.level > 1 &&
        this.currentItem.id_p > 0 &&
        this.editquery.id !== this.currentItem.id_p
      ) {
        menuItems.push({
          id: 7,
          text: "edit sub bundle",
          icon: "edit",
          disabled: false,
          style: null,
        });
      }

      if (
        this.currentItem.level > 0 &&
        (!this.currentItem.read_only || this.currentItem.type === "Bundles")
      ) {
        if (this.confirmModeOn) {
          menuItems.push({
            id: 2,
            text: "Click again to confirm",
            icon: "check",
            disabled: false,
            style: "background-color:red;",
          });
        } else {
          menuItems.push({
            id: 2,
            text: "remove",
            icon: "delete",
            disabled: false,
            style: null,
          });
        }
      }

      if (this.currentItem.level === 0 && this.currentItem.type === "Bundles") {
        menuItems.push({
          id: 6,
          text: "delete bundle",
          icon: "delete",
          disabled: false,
          style: null,
        });
      }

      if (this.currentItem.children && this.currentItem.children.length > 0) {
        if (this.open.indexOf(this.currentItem.id_rd) > -1) {
          menuItems.push({
            id: 5,
            text: "close all branches",
            icon: "expand_less",
            disabled: false,
            style: null,
          });
        } else {
          menuItems.push({
            id: 4,
            text: "open all branches",
            icon: "expand_more",
            disabled: false,
            style: null,
          });
        }
      }

      return menuItems;
    },
  },
};
</script>
