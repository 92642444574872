<template>
  <div>
    <v-text-field v-if="field.d || field.print" :background-color="$getPropertyBackgroundColor(field)"
      :filled="!field.print" v-model="trIcon" readonly :label="alternatelayout ? field.a + (field.r ? ' *' : '') : null"
      :hint="!alternatelayout ? field.a : null" :persistent-hint="!alternatelayout" :outlined="!alternatelayout"
      :dense="!alternatelayout"><v-tooltip top slot="append" v-if="field.why">
        <template v-slot:activator="{ on }">
          <v-icon v-if="!field.print" class="outlined" v-on="on" style="opacity: 0.1;">
            lock
          </v-icon>
        </template>
        <span>{{ field.why }}</span>
      </v-tooltip></v-text-field>

    <div v-else :class="!alternatelayout ? 'mksoutlined' : ''" :style="'background-color:' + $getPropertyBackgroundColor(field) + ';'">
      <v-checkbox :background-color="$getPropertyBackgroundColor(field)" :ref="field.n" v-model="field.v"
        :readonly="field.d" :label="field.a" :persistent-hint="!alternatelayout" :outlined="!alternatelayout"
        :dense="!alternatelayout"></v-checkbox>
    </div>
  </div>
</template>

<style scoped>
.mksoutlined {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 40px;
  overflow-y: hidden;
}

.mksoutlined>div {
  margin-top: 2px;
  vertical-align: top;
}
</style>

<script>
export default {
  name: "mksbooleaninput",

  data: () => ({
    origval: null,
  }),

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    requiredRule: {
      get() {
        if (this.field.r) {
          return [(v) => !!v || " is required"];
        } else {
          return [];
        }
      },
    },
    returnValue: {
      get() {
        return {
          n: this.field.n,
          v: this.field.v,
        };
      },
    },
    changed: {
      get() {
        return this.origval != this.field.v;
      },
    },
    fieldvalue: {
      get() {
        return this.field.v;
      },
    },
    trIcon: {
      get() {
        return this.field.v ? "Yes" : "No";
      },
    },
  },
  methods: {
    initialize() {
      this.origval = this.field.v;
    },
    setUnsaved() {
      this.origval = null;
    },
    // setFocus() {
    //   this.$nextTick(() => {
    //     this.$refs[this.field.n].$refs["input"].focus();
    //   });
    // },
  },
  mounted() {
    this.origval = this.field.v;
  },
  watch: {
    fieldvalue() {
      this.$emit("userinput", this.field);
    },
  },
};
</script>
