<template>
  <v-dialog v-model="show" scrollable v-bind:retain-focus="true">
    <v-card>
      <v-card-title style="position: relative">
        <span class="mksheadline">Development - Change DB </span>
        <v-spacer></v-spacer>
        <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item-group v-model="selectedDbItemGrp" color="primary">
            <v-list-item
              v-for="(db, index) in dbNames"
              :key="'db' + index"
              @click.stop="devDBset(db)"
            >
              <v-list-item-icon>
                <v-icon>filter_b_and_w</v-icon>
              </v-list-item-icon>
              <v-list-item-content>{{ db }}</v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  margin-left: 10px;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "mksselectdb",

  data: () => ({
    selectedDbItemGrp: null,
    dbNames: {},
  }),

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  computed: {
    ...mapState(["currentUser"]),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close", "");
        }
      },
    },
  },
  created() {},
  methods: {
    devDBsGet() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      let tempthis = this;

      this.$http
        .get("api/devDBsGet")
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            tempthis.dbNames = response.data;
          }
          this.isLoading = false;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.isLoading = false;

          this.show = false;
        });
    },
    devDBset(db) {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      //let tempthis = this;

      this.$http
        .post("api/devDBChange", { dbname: db })
        .then((response) => {
          // if (typeof response.data === "string") {
          //   throw "malformatted JSON Data";
          // }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.$store.dispatch(
              "setAppheading",
              response.data +
                (this.currentUser ? " - " + this.currentUser.initials : "")
            );

            this.$showMessage({ content: "database changed", color: "success" });
            window.location.href = window.location;
            // this.$emit('close');
          }
          this.isLoading = false;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.isLoading = false;

          this.show = false;
        });
    },
  },
  watch: {
    visible: {
      handler() {
        if (this.visible) {
          this.devDBsGet();
        }
      },
    },
  },
};
</script>