<template>
  <div ref="wiringcontainer">
    <v-card elevation="2">
      <v-card-title>Wiring - connection documentation </v-card-title>
      <v-card-subtitle
        ><v-icon>devices</v-icon> = interface port / <v-icon>outlet</v-icon> =
        outlet (port)</v-card-subtitle
      >
      <v-card-text>
        <v-sheet>
          <v-row>
            <v-col class="col-2">
              <v-text-field
                rows="1"
                ref="search"
                label="search"
                outlined
                dense
                single-line
                hide-details="auto"
                v-model="search"
                @change="getchains"
                clearable
              >
              </v-text-field> </v-col
            ><v-spacer></v-spacer
            ><v-col class="col-3">
              <v-select
                v-model="selectedsetups"
                :items="AllSetups"
                no-data-text="No Setups found in current Chains"
                item-text="s"
                item-value="i"
                label="Setups"
                multiple
                hint="Restrict the list to selected Setup(s)"
                outlined
                dense
                @change="getchains"
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>

            <v-col class="col-5" style="text-align: right">
              <v-tooltip bottom open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mb-2 mx-2"
                    :dark="$vuetify.theme.dark"
                    v-on="on"
                    @click="toggleIncludeCables"
                  >
                    <v-icon>{{
                      includeCables ? "cable" : "highlight_off"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  "include static patches: " + (includeCables ? "on" : "off")
                }}</span>
              </v-tooltip>
              <v-tooltip bottom open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mb-2 mx-2"
                    :dark="$vuetify.theme.dark"
                    v-on="on"
                    @click="toggleAutoExpand"
                  >
                    <v-icon>{{
                      autoExpandChainsOn ? "auto_fix_normal" : "auto_fix_off"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  "auto expand chains: " + (autoExpandChainsOn ? "on" : "off")
                }}</span>
              </v-tooltip>
              <v-tooltip bottom open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mb-2 mx-2"
                    :dark="$vuetify.theme.dark"
                    v-on="on"
                    @click="expandVisible"
                    :disabled="autoExpandChainsOn"
                  >
                    <v-icon>open_in_full</v-icon>
                  </v-btn>
                </template>
                <span>expand visible</span>
              </v-tooltip>
              <v-tooltip bottom open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mb-2 mx-2"
                    :dark="$vuetify.theme.dark"
                    v-on="on"
                    @click="collapseAll"
                    :disabled="autoExpandChainsOn"
                  >
                    <v-icon>close_fullscreen</v-icon>
                  </v-btn>
                </template>
                <span>collapse all</span>
              </v-tooltip>
              <v-tooltip bottom open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mb-2 mx-2"
                    :dark="$vuetify.theme.dark"
                    v-on="on"
                    @click="changewiringSortMode"
                  >
                    <v-icon>sort</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  "sort by: " + (wiringSortMode === 1 ? "Node" : "Location")
                }}</span>
              </v-tooltip>

              <v-tooltip bottom open-delay="700">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mb-2 mx-2"
                    :dark="$vuetify.theme.dark"
                    v-on="on"
                    @click="handleMenuItem(null, 'new')"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <span>Start new Chain (Alt + n)</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-sheet>
        <v-virtual-scroll
          :items="items"
          bench="1"
          :height="tableheight"
          item-height="140"
          v-mutate="{
            options: {
              childList: true,
              attributes: false,
              subtree: true,
            },
            handler: this.onMutate,
          }"
        >
          <template v-slot:default="{ item, index }">
            <table class="chain" :cid="'ch_' + item.ID_Chain">
              <tbody>
                <tr
                  :style="'background: ' + rowColor(item.ID_Chain, index) + ';'"
                >
                  <td>
                    <div class="setupwrap">
                      <div
                        class="setup"
                        v-for="(setup, sindex) in item.Setups"
                        :key="'sk_' + sindex"
                      >
                        {{ setup.s }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      :style="
                        'width: ' +
                        ($refs.wiringcontainer.clientWidth - 70 - 300 - 40) +
                        'px;overflow-x: auto; overflow-y: hidden'
                      "
                    >
                      <v-row
                        :key="'ch' + index"
                        :ref="'ch' + item.ID_Chain"
                        style="height: 140px"
                        @click="currentID_Chain = item.ID_Chain"
                      >
                        <template v-for="(node, nindex) in item.Nodes">
                          <div
                            :key="'expChNode' + nindex"
                            v-if="
                              item.isexpanded ||
                              nindex === 0 ||
                              nindex === item.Nodes.length - 1
                            "
                          >
                            <div
                              :class="
                                'pa-2 v-card node ' +
                                ($vuetify.theme.dark ? 'wdark' : 'wlight')
                              "
                            >
                              <div class="dvfl">
                                <v-icon
                                  v-if="node.fxd"
                                  class="pnt flx1"
                                  @click="openDetails(node)"
                                  >{{ node.o ? "outlet" : "devices" }}</v-icon
                                >
                                <div
                                  v-if="wiringSortMode === 1"
                                  :class="'flx2 ' + rightalign(index, nindex)"
                                  v-html="$highlight(node.l, search, highl)"
                                ></div>
                                <div
                                  v-else
                                  :class="
                                    'flx2 font-weight-bold ' +
                                    rightalign(index, nindex)
                                  "
                                  v-html="
                                    $highlight(node.n, search, highl) +
                                    (node.mac
                                      ? '<br/>' +
                                        $highlight(node.mac, search, highl)
                                      : '')
                                  "
                                ></div>
                                <v-icon
                                  v-if="!node.fxd"
                                  class="pnt flx1"
                                  @click="openDetails(node)"
                                  >{{ node.o ? "outlet" : "devices" }}</v-icon
                                >
                              </div>
                              <div
                                v-if="wiringSortMode === 1"
                                :class="
                                  'dvfl2 font-weight-bold ' +
                                  rightalign(index, nindex)
                                "
                                v-html="
                                  $highlight(node.n, search, highl) +
                                  (node.mac
                                    ? '<br/>' +
                                      $highlight(node.mac, search, highl)
                                    : '')
                                "
                              ></div>
                              <div
                                v-else
                                :class="'dvfl2 ' + rightalign(index, nindex)"
                                v-html="$highlight(node.l, search, highl)"
                              ></div>
                            </div>
                          </div>
                          <div :key="'expChNodecon' + nindex">
                            <div
                              :class="
                                node.fxd
                                  ? 'v-card node ' +
                                    (item.isexpanded
                                      ? 'fxdgrd' +
                                        ($vuetify.theme.dark ? 'dark' : '')
                                      : '')
                                  : ''
                              "
                              style="padding-top: 12px"
                            >
                              <div>
                                <template v-if="nindex < item.Nodes.length - 1">
                                  <div
                                    v-if="!item.isexpanded && nindex === 0"
                                    class="pnt center"
                                    @click="expand(item.ID_Chain)"
                                  >
                                    <template
                                      v-if="!item.isexpanded && item.ncount > 2"
                                    >
                                      <img
                                        style="width: auto"
                                        :src="getConExp()"
                                      /><br />
                                      <span class="mkschip" x-small>{{
                                        "+" + (item.ncount - 2)
                                      }}</span>
                                    </template>
                                  </div>

                                  <v-menu
                                    v-else-if="item.isexpanded"
                                    offset-y
                                    right
                                    top
                                    open-on-hover
                                    open-delay="500"
                                    close-delay="500"
                                    :close-on-content-click="
                                      toolsMenuMode === 1
                                    "
                                    v-model="node.menuOpened"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <img
                                        v-bind="attrs"
                                        v-on="on"
                                        class="pnt"
                                        style="width: auto"
                                        :src="getCon()"
                                        @click="
                                          handleMenuItem(node, 'collapse')
                                        "
                                      /><br />
                                      <div
                                        v-bind="attrs"
                                        v-on="on"
                                        class="cable"
                                      >
                                        {{
                                          node.ct === "" &&
                                          nindex == item.Nodes.length - 2
                                            ? item.Nodes[item.Nodes.length - 1]
                                                .ct
                                            : node.ct
                                        }}
                                      </div>
                                    </template>

                                    <v-list v-if="toolsMenuMode === 2" dense>
                                      <template v-for="mi in CableTypes">
                                        <v-list-item
                                          :key="mi.i"
                                          @click="
                                            handleMenuItem(mi, 'set_cable_type')
                                          "
                                          :disabled="
                                            !(
                                              mi.fnc !== 'collapse' ||
                                              item.ncount > 2
                                            )
                                          "
                                        >
                                          <v-list-item-title>{{
                                            mi.ct
                                          }}</v-list-item-title>
                                        </v-list-item>
                                      </template>
                                    </v-list>

                                    <v-list
                                      v-else-if="
                                        toolsMenuMode === 1 && !node.fxd
                                      "
                                      dense
                                    >
                                      <template v-for="mi in menucutitems">
                                        <v-list-item
                                          :key="mi.id"
                                          @click="handleMenuItem(node, mi.fnc)"
                                          :disabled="
                                            !(
                                              mi.fnc !== 'collapse' ||
                                              item.ncount > 2
                                            )
                                          "
                                        >
                                          <v-list-item-icon>
                                            <v-icon>{{ mi.icon }}</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-title>{{
                                            mi.title
                                          }}</v-list-item-title>
                                        </v-list-item>
                                      </template>
                                    </v-list>

                                    <v-list
                                      v-else-if="toolsMenuMode === 3"
                                      dense
                                    >
                                      <v-list-item
                                        @click="
                                          handleMenuItem(node, 'cutcancel')
                                        "
                                      >
                                        <v-list-item-icon>
                                          <v-icon>thumb_down_off_alt</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title
                                          >No - keep Link</v-list-item-title
                                        >
                                      </v-list-item>

                                      <v-list-item
                                        @click="handleMenuItem(node, 'cut')"
                                      >
                                        <v-list-item-icon>
                                          <v-icon>thumb_up_off_alt</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title
                                          >Yes - cut Link<span
                                            style="color: red"
                                            >{{
                                              item.Remarks_Count > 0 &&
                                              item.ncount == 2
                                                ? " (You will lose all chain remarks!!)"
                                                : ""
                                            }}</span
                                          ></v-list-item-title
                                        >
                                      </v-list-item>
                                    </v-list>
                                    <v-list
                                      v-else-if="toolsMenuMode === 4"
                                      dense
                                    >
                                      <v-list-item
                                        @click="
                                          handleMenuItem(node, 'cutcancel')
                                        "
                                      >
                                        <v-list-item-icon>
                                          <v-icon>close</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title
                                          >Keep Chain</v-list-item-title
                                        >
                                      </v-list-item>

                                      <v-list-item
                                        @click="handleMenuItem(node, 'deletewholechain')"
                                      >
                                        <v-list-item-icon>
                                          <v-icon>delete</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title
                                          >Delete Chain<span
                                            style="color: red"
                                            >{{
                                              item.Remarks_Count > 0 
                                                ? " (You will also lose all chain remarks!!)"
                                                : ""
                                            }}</span
                                          ></v-list-item-title
                                        >
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </template>
                              </div>
                            </div>
                          </div>
                        </template>

                        <div>
                          <div style="padding-top: 12px">
                            <template v-if="item.Nodes.length == 1">
                              <v-tooltip bottom open-delay="700">
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    class="pnt"
                                    @click="
                                      handleMenuItem(
                                        item.Nodes[item.Nodes.length - 1],
                                        'append'
                                      )
                                    "
                                    >add</v-icon
                                  >
                                </template>
                                <span>Append Node (Alt + a)</span>
                              </v-tooltip>
                            </template>
                            <template v-else>
                              <v-menu
                                offset-y
                                right
                                top
                                open-on-hover
                                open-delay="500"
                                close-delay="500"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="
                                      handleMenuItem(
                                        item.Nodes[item.Nodes.length - 1],
                                        'append'
                                      )
                                    "
                                    >add</v-icon
                                  >
                                </template>

                                <v-list dense>
                                  <template
                                    v-for="(mi, mindex) in menuadditems"
                                  >
                                    <v-list-item
                                      v-if="mindex > 0 || item.Nodes.length > 1"
                                      :key="mi.id"
                                      @click="
                                        handleMenuItem(
                                          item.Nodes[item.Nodes.length - 1],
                                          mi.fnc
                                        )
                                      "
                                    >
                                      <v-list-item-icon>
                                        <v-icon>{{ mi.icon }}</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-title>{{
                                        mi.title
                                      }}</v-list-item-title>
                                    </v-list-item>
                                  </template>
                                </v-list>
                              </v-menu>
                            </template>
                          </div>
                        </div>
                      </v-row>
                    </div>
                  </td>
                  <td @click="editRemarks(item.ID_Chain)">
                    <div style="width: 250px">
                      <div class="Notes" v-if="item.Nodes.length > 1">
                        <p v-if="item.Latest_Remarks.length === 0">
                          <v-icon> note_add </v-icon>
                        </p>

                        <p
                          v-else
                          v-for="(remark, index) in item.Latest_Remarks"
                          :key="'rem' + index"
                        >
                          {{ remark.r }} <br />
                          <span>{{ remark.u }} {{ remark.c }}</span>
                        </p>
                        <p v-if="item.Remarks_Count > 2">...</p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </v-virtual-scroll>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showAddNode"
      scrollable
      v-bind:retain-focus="true"
      content-class="v-dialog-mks"
      width="650"
    >
      <v-card>
        <v-card-title style="position: relative">
          <span class="mksheadline">{{
            addNodeMode === "append" ? "Append Node" : "Select Starting Node"
          }}</span
          ><v-spacer></v-spacer>
          <v-icon class="pnt" @click="showAddNode = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle v-if="addNodeMode === 'append'">
          <v-chip
            :class="
              'ma-2 itemdontmatchauto' + ($vuetify.theme.dark ? '_dark' : '')
            "
            >red Nodes are already part of a chain (either start or end
            node)</v-chip
          ></v-card-subtitle
        >
        <v-card-text>
          <mksautocomplete
            :ref="config.n"
            :editquery="config.query"
            :field="config"
            :alternatelayout="AlternativeDetailsLayout"
          ></mksautocomplete>
        </v-card-text>
        <v-card-actions
          ><v-btn
            :class="'mr-4' + (selectedButton === 0 ? ' selected' : '')"
            color="error"
            @click="
              showAddNode = false;
              config.v = null;
            "
            >cancel</v-btn
          ><v-spacer></v-spacer
          ><v-btn
            :class="'mr-4' + (selectedButton === 1 ? ' selected' : '')"
            color="info"
            @click="appendNode"
            >{{ addNodeMode === "append" ? "append" : "start chain" }}</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showTable"
      content-class="v-dialog-mks"
      persistent
      no-click-animation
    >
      <mkstable
        v-bind:id_query="id_remarks_query"
        v-bind:idfilter="remarks_query_filter"
        @closeTable="closeTable"
        :showCloseSymbol="true"
      ></mkstable>
    </v-dialog>

    <mksdetails
      :editquery="editlnk"
      :visible="showDetails"
      :idfilter="{}"
      :calledFromDetails="false"
      @close="closeDetails"
    ></mksdetails>
  </div>
</template>

<style scoped>
div.node {
  min-height: 84px;
  /* background-color: white; */
}

.wdark {
  background-color: black;
}
.wlight {
  background-color: white;
}

div.rlgntext {
  text-align: right;
}

div.fxdgrd {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.6) 40%,
    rgba(255, 255, 255, 0.6) 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

div.fxdgrddark {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.6) 40%,
    rgba(0, 0, 0, 0.6) 60%,
    rgba(0, 0, 0, 1) 100%
  );
}

.flx1 {
  flex: 1;
}

.flx2 {
  flex: 0 0 90%;
}

table.chain {
  border-collapse: collapse;
}

table.chain > tbody > tr {
  vertical-align: top;
}

/* table.chain td:first-child {
  width: 4em;
}

table.chain td:last-child {
  width: 10em;
} */

div.Notes {
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  margin: 5px;
}
div.Notes p {
  margin-bottom: 2px;
}

div.Notes span {
  margin-left: 1em;
}

div.Notes span {
  font-size: x-small;
}

span.mkschip {
  padding: 4px;
  margin: auto;
}

div.cable {
  position: relative;
  text-align: center;
  font-size: x-small;
  transform: rotate(-90deg);
}
.center {
  text-align: center;
}

.pnt {
  cursor: pointer;
}

div.setup {
  border-radius: 4px;
  /* border: 1px solid black; */
  padding: 4px;
  margin-bottom: 2px;
  background: #a8a8a8;
  color: white;
}
div.setupwrap {
  margin: 5px;
  width: 120px;
  height: 140px;
  overflow-x: hidden;
  overflow-y: auto;
}
div >>> .dvfl {
  display: flex;
  align-items: flex-start;
  width: 250px;
  font-size: smaller;
}

div >>> .dvfl2 {
  width: 250px;
  font-size: smaller;
}

div >>> .highl {
  background: #9fa8da;
  border-color: #9fa8da;
}

div >>> .col {
  padding: 6px 0px 6px 0px;
}

div >>> .row {
  padding: 6px 6px 6px 12px;
  margin: 0px;
  flex-wrap: nowrap;
  overflow-x: auto;
}

div >>> .v-virtual-scroll {
  overflow-x: hidden;
}

DIV >>> .selected {
  text-decoration: underline dotted;
}
</style>

<script>
import { mapState } from "vuex";

import mksautocomplete from "@/components/mksautocomplete.vue";

export default {
  name: "mkswiring",

  components: {
    mksautocomplete,
  },

  data: () => ({
    autoExpandChainsOn: false,
    includeCables: false,
    showDetails: false,
    editlnk: {},
    wiringSortMode: 1,
    AlternativeDetailsLayout: false,
    items: [],
    currentID_Chain: null,
    addNodeMode: "",
    selectedButton: 1,

    colwdthstl: "col-xs-12 col-sm-5 col-md-4 col-lg-3 col-xl-2",
    search: null,
    highl: "highl",

    tableheight: "500px",
    baseheight: null,
    container: null,

    selectedsetups: [],
    AllSetups: [],
    CableTypes: [],
    menucutitems: [
      { title: "Delete Chain", icon: "delete_outline", fnc: "deletewholechainask" },
      { title: "Disconnect Link", icon: "content_cut", fnc: "cutask" },
      { title: "Set Cable Type", icon: "cable", fnc: "show_set_cable_type" },
      // { title: "collapse chain", icon: "close_fullscreen", fnc: "collapse" },
    ],
    menucutitemsask: [
      { title: "No - keep Link", icon: "thumb_down_off_alt", fnc: "cutcancel" },
      { title: "Yes - cut Link", icon: "thumb_up_off_alt", fnc: "cut" },

      // { title: "collapse chain", icon: "close_fullscreen", fnc: "collapse" },
    ],
    menuadditems: [
      { title: "Flip Chain", icon: "flip_camera_android", fnc: "flip" },
      { title: "Append Node", icon: "add", fnc: "append" },
    ],

    showAddNode: false,
    toolsMenuMode: 1,
    setCableTypeItem: null,
    config: {
      n: "appendnode",
      query: {
        wiring: "",
        id: 0,
        id_link: 0,
      },
      a: "start typing",
      s: "",
      cff: "xyz",
      v: "",
      forceload: true,
      separator: "[",
    },

    id_remarks_query: 0,
    ID_Chain_current: 0,
    remarks_query_filter: {},
    showTable: false,
  }),

  computed: {
    ...mapState(["currentUser", "lastKeyUp"]),

    prefs() {
      return this.currentUser.prefs;
    },

    length() {
      return this.items.length;
    },
  },

  mounted() {
    this.getchains();

    this.container = document.getElementsByClassName(
      "container fill-height container--fluid"
    )[0];

    this.$nextTick(function () {
      // zeitverzögerte Ausführung um richtige Höhe für container zu erhalten
      this.refreshBaseHeight();
      window.addEventListener("resize", this.refreshBaseHeight);
    });
  },

  watch: {
    items: {
      handler() {
        this.toolsMenuMode = 1;
      },
      deep: true,
    },
    lastKeyUp: {
      handler() {
        if (this.showAddNode) {
          if (!this.lastKeyUp.altKey) {
            switch (this.lastKeyUp.keyCode) {
              case 13:
                if (this.selectedButton === 0) {
                  this.showAddNode = false;
                  this.config.v = null;
                } else {
                  this.appendNode();
                }

                break;
              case 37:
                this.selectedButton = 0;
                break;
              case 39:
                this.selectedButton = 1;
                break;
            }
          }
        } else if (this.lastKeyUp.altKey) {
          switch (this.lastKeyUp.keyCode) {
            case 78:
              this.handleMenuItem(null, "new");
              break;
            case 65:
              if (this.currentID_Chain === null && this.items.length > 0) {
                this.currentID_Chain = this.items[0].ID_Chain;
              }

              if (this.currentID_Chain !== null) {
                var chain = this.items.find(
                  (r) => r.ID_Chain === this.currentID_Chain
                );
                if (chain) {
                  this.handleMenuItem(
                    chain.Nodes[chain.Nodes.length - 1],
                    "append"
                  );
                }
              }
              break;
          }
        } else if (
          this.lastKeyUp.keyCode === 38 ||
          this.lastKeyUp.keyCode === 40
        ) {
          if (this.currentID_Chain === null && this.items.length > 0) {
            this.currentID_Chain = this.items[0].ID_Chain;
            return;
          }

          if (this.currentID_Chain !== null) {
            var chainIdx = this.items.findIndex(
              (r) => r.ID_Chain === this.currentID_Chain
            );
            if (
              this.lastKeyUp.keyCode === 38 &&
              chainIdx > -1 &&
              chainIdx > 0
            ) {
              this.currentID_Chain = this.items[chainIdx - 1].ID_Chain;
            } else if (
              this.lastKeyUp.keyCode === 40 &&
              chainIdx > -1 &&
              chainIdx < this.items.length - 1
            ) {
              this.currentID_Chain = this.items[chainIdx + 1].ID_Chain;
            }
          }
        }
      },
    },
    autoExpandChainsOn: {
      handler() {
        if (this.autoExpandChainsOn) {
          this.expandVisible();
        }
      },
    },
  },

  created() {
    this.wiringSortMode = this.$getPref({
      TabName: "Options",
      PrefID: "wiringSortMode",
    });
    if (this.wiringSortMode == null) {
      this.wiringSortMode = 1;
    } else {
      this.wiringSortMode = parseInt(this.wiringSortMode);
    }
    this.autoExpandChainsOn = this.$getPref({
      TabName: "Options",
      PrefID: "autoExpandChainsOn",
    });
    if (this.autoExpandChainsOn == null) {
      this.autoExpandChainsOn = false;
    }
    this.includeCables = this.$getPref({
      TabName: "Options",
      PrefID: "includeCables",
    });
    if (this.includeCables == null) {
      this.includeCables = false;
    }
  },
  methods: {
    rightalign(index, nindex) {
      return this.items[index].isexpanded &&
        nindex > 0 &&
        this.items[index].Nodes[nindex - 1].fxd
        ? "rlgntext"
        : "";
    },

    toggleAutoExpand() {
      this.autoExpandChainsOn = !this.autoExpandChainsOn;

      this.$setPref({
        TabName: "Options",
        PrefID: "autoExpandChainsOn",
        Value: this.autoExpandChainsOn,
        Save: true,
      });
    },
    toggleIncludeCables() {
      this.includeCables = !this.includeCables;

      this.getchains();

      this.$setPref({
        TabName: "Options",
        PrefID: "includeCables",
        Value: this.includeCables,
        Save: true,
      });
    },
    isChainFullyLoaded(item) {
      return item.ncount === item.Nodes.length;
    },
    closeTable() {
      this.refreshChain(this.ID_Chain_current);
      this.showTable = false;
    },
    editRemarks(ID_Chain) {
      var tquery = this.currentUser.allowedQueries.find(
        (x) => x.title === "Wiring Remarks"
      );
      if (tquery) {
        this.id_remarks_query = tquery.id;
        this.ID_Chain_current = ID_Chain;

        this.remarks_query_filter = {
          id_query: this.id_remarks_query,
          id: ID_Chain,
          id_link: 0,
          identifier: "ID_Chain",
        };

        this.showTable = true;
      }
    },
    openDetails(node) {
      if (node.o) {
        this.editlnk = {
          tablename: "Outlets",
          id_query: 63,
          id_link: 68,
          id: node.idoe,
          identifier: "id_outlet_edit",
        };
      } else {
        this.editlnk = {
          tablename: "Network Interfaces",
          id_query: 30,
          id_link: 27,
          id: node.idn,
          identifier: "id_network_interface",
        };
      }

      this.showDetails = true;
    },
    closeDetails() {
      if (this.currentID_Chain !== null) {
        this.refreshChain(this.currentID_Chain);
      }
      this.showDetails = false;
    },
    async changeSetting(PrefID) {
      await this.$setPref({
        TabName: "Options",
        PrefID: PrefID,
        Value: this[PrefID],
        Save: true,
      });
      // .then(() => {
      //   this.$root.$emit("changedSetting", PrefID);
      // });
    },

    collapseAll() {
      var expanded = this.items.filter((x) => x.isexpanded && x.ncount > 2);
      expanded.forEach((x) => {
        x.isexpanded = false;
      });
    },
    onMutate() {
      if (this.autoExpandChainsOn) {
        this.expandVisible();
      }
    },
    expandVisible() {
      var visibleChains = document.querySelectorAll("table[cid]");
      visibleChains.forEach((x) => {
        this.expand(parseInt(x.getAttribute("cid").split("_")[1]));
      });
    },

    changewiringSortMode() {
      if (this.wiringSortMode === 1) {
        this.wiringSortMode = 2;
      } else {
        this.wiringSortMode = 1;
      }
      this.sortItems();
      this.changeSetting("wiringSortMode");
    },

    sortItems() {
      if (this.wiringSortMode === 1) {
        this.items.sort((a, b) => {
          if (a.Nodes[0].l == b.Nodes[0].l) {
            if (a.Nodes[0].n.toLowerCase() < b.Nodes[0].n.toLowerCase()) {
              return -1;
            }
            if (a.Nodes[0].n.toLowerCase() > b.Nodes[0].n.toLowerCase()) {
              return 1;
            }
            return 0;
          } else {
            if (a.Nodes[0].l.toLowerCase() < b.Nodes[0].l.toLowerCase()) {
              return -1;
            }
            if (a.Nodes[0].l.toLowerCase() > b.Nodes[0].l.toLowerCase()) {
              return 1;
            }
            return 0;
          }
        });
      } else {
        this.items.sort((a, b) => {
          if (a.Nodes[0].n == b.Nodes[0].n) {
            if (a.Nodes[0].l.toLowerCase() < b.Nodes[0].l.toLowerCase()) {
              return -1;
            }
            if (a.Nodes[0].l.toLowerCase() > b.Nodes[0].l.toLowerCase()) {
              return 1;
            }
            return 0;
          } else {
            if (a.Nodes[0].n.toLowerCase() < b.Nodes[0].n.toLowerCase()) {
              return -1;
            }
            if (a.Nodes[0].n.toLowerCase() > b.Nodes[0].n.toLowerCase()) {
              return 1;
            }
            return 0;
          }
        });
      }
    },
    hightlightRow(ID_Chain) {
      this.sortItems();

      this.currentID_Chain = ID_Chain;
      this.$nextTick(function () {
        if (ID_Chain !== null && this.$refs["ch" + ID_Chain]) {
          this.$refs["ch" + ID_Chain].scrollIntoView();
        }
      });
    },
    handleMenuItem(item, fnc) {
      this.config.node = item;
      var tempthis = this;

      switch (fnc) {
        case "show_set_cable_type":
          this.setCableTypeItem = item;
          this.toolsMenuMode = 2;
          break;
        case "new":
        case "append":
          this.addNodeMode = fnc;

          this.showAddNode = true;
          this.config.v = null;
          this.config.query.wiring = 1;
          var excludedId = item
            ? item.o
              ? parseInt(item.ido)
              : -parseInt(item.idn)
            : 0;
          this.config.query.id_link = excludedId;
          this.config.search = "";

          if (fnc === "append") {
            this.config.query.id = 1;
            this.config.cff = "xyz";
          } else {
            this.config.query.id = 2;
            this.config.cff = null;
          }

          this.$nextTick(() => {
            this.$refs[this.config.n].initialize();
            var inptfld = this.$refs[this.config.n];
            window.setTimeout(function () {
              inptfld.setFocus();
            }, 500);
          });
          break;
        case "flip":
        case "set_cable_type":
          var params = {
            ID_Chain: parseInt(item.cid),
            ID_Wiring: parseInt(item.idw),
            fnc: fnc,
          };
          if (fnc === "set_cable_type") {
            this.toolsMenuMode = 1;
            params = {
              ID_Chain: parseInt(this.setCableTypeItem.cid),
              ID_Wiring: parseInt(this.setCableTypeItem.idw),
              ID_Cable_Type: parseInt(item.i),
              fnc: fnc,
            };
          }
          this.$http
            .post("api/executeChainFunction", params)
            .then((response) => {
              if (typeof response.data === "string") {
                throw "malformatted JSON Data";
              }

              if (response.data.error) {
                throw response.data.error;
              } else {
                var chain = tempthis.items.find(
                  (r) => r.ID_Chain === params.ID_Chain
                );
                if (chain) {
                  chain.Nodes = response.data.chains[0].Nodes;
                  chain.expandable = false;

                  this.hightlightRow(chain.ID_Chain);
                }
              }
            })
            .catch((err) => {
              this.$showMessage({ content: err, color: "error" });
            });
          break;
        case "cutask":
          this.toolsMenuMode = 3;
          break;
        case "deletewholechainask":
          this.toolsMenuMode = 4;
          break;
        case "cutcancel":
          this.toolsMenuMode = 1;
          break;
        case "cut":
        case "deletewholechain":
          this.toolsMenuMode = 1;
          this.$http
            .post("api/executeChainFunction", {
              ID_Wiring: item.idw,
              ID_Chain: item.cid,
              fnc: fnc,
            })
            .then((response) => {
              if (typeof response.data === "string") {
                throw "malformatted JSON Data";
              }

              if (fnc === "deletewholechain")
              {
                this.getchains();
                return;
              }

              if (response.data.error) {
                throw response.data.error;
              } else {
                var chainIndex = -1;
                response.data.chains.forEach((chainData) => {
                  chainIndex = tempthis.items.findIndex(
                    (r) => r.ID_Chain === Math.abs(chainData.ID_Chain)
                  );
                  if (chainIndex > -1) {
                    tempthis.items[chainIndex].ID_Chain = chainData.ID_Chain;
                    tempthis.items[chainIndex].Nodes = chainData.Nodes;
                    tempthis.items[chainIndex].ncount = chainData.ncount;
                    tempthis.items[chainIndex].isexpanded =
                      chainData.ncount === chainData.Nodes.length;
                    tempthis.items[chainIndex].Remarks_Count =
                      chainData.Remarks_Count;
                    tempthis.items[chainIndex].Latest_Remarks =
                      chainData.Latest_Remarks;
                    tempthis.items[chainIndex].Setups = chainData.Setups;
                    this.addAdditionalSetups(chainData.Setups);

                    this.hightlightRow(chainData.ID_Chain);

                    var currentResponseChainIndex =
                      response.data.chains.findIndex(
                        (rd) => rd.ID_Chain === chainData.ID_Chain
                      );
                    response.data.chains.splice(currentResponseChainIndex, 1);
                  }
                });
                response.data.chains.forEach((chainData) => {
                  chainData.isexpanded =
                    chainData.ncount === chainData.Nodes.length;
                  tempthis.items.splice(chainIndex + 1, 0, chainData);
                });
              }
            })
            .catch((err) => {
              this.$showMessage({ content: err, color: "error" });
            });
          break;
        case "collapse":
          var chain = this.items.find((r) => r.ID_Chain === item.cid);
          if (chain && chain.ncount > 2) {
            chain.isexpanded = false;
          }

          break;
      }
    },

    addAdditionalSetups(SetupsFromChain) {
      var missingSetups = SetupsFromChain.filter(
        (setup1) => !this.AllSetups.find((setup2) => setup2.i === setup1.i)
      );
      this.AllSetups = this.AllSetups.concat(missingSetups);
      this.AllSetups.sort((a, b) => a.s.localeCompare(b.s));
    },

    appendNode() {
      var tempthis = this;
      var newNodeID = 0;
      if (this.$refs[this.config.n]) {
        var checkElem = this.$refs[this.config.n];
        if (
          checkElem &&
          checkElem.field &&
          checkElem.returnObject &&
          checkElem.returnObject.sid
        ) {
          newNodeID = parseInt(checkElem.returnObject.sid);
          var newNodeData = null;
          if (this.config.node === null) {
            newNodeData = {
              ID_Chain: 0,
              id: 0,
              isOutlet: false,
            };
          } else {
            newNodeData = {
              ID_Chain: this.config.node.cid,
              id: this.config.node.o
                ? this.config.node.ido
                : this.config.node.idn,
              isOutlet: this.config.node.o,
            };
          }

          this.$http
            .post("api/executeChainFunction", {
              fnc: "append",
              ID_Chain: newNodeData.ID_Chain,
              ID_A: parseInt(newNodeData.id),
              A_is_Outlet: newNodeData.isOutlet,
              ID_B: Math.abs(newNodeID),
              B_is_Outlet: checkElem.returnObject.IsOutlet === 1,
            })
            .then((response) => {
              if (response.data !== "") {
                if (typeof response.data === "string") {
                  throw "malformatted JSON Data";
                }

                if (response.data.error) {
                  throw response.data.error;
                } else {
                  var chainIndex = -1;
                  var scrollto = "";
                  response.data.chains.forEach((chainData) => {
                    chainIndex = tempthis.items.findIndex(
                      (r) => r.ID_Chain === Math.abs(chainData.ID_Chain)
                    );
                    if (chainIndex > -1) {
                      if (chainData.Nodes.length === 0) {
                        tempthis.items.splice(chainIndex, 1);
                      } else {
                        tempthis.items[chainIndex].Nodes = chainData.Nodes;
                        tempthis.items[chainIndex].ncount =
                          chainData.Nodes.length;
                        tempthis.items[chainIndex].expandable = false;
                        // tempthis.items[chainIndex].isexpanded = true;
                        if (scrollto === "") {
                          scrollto = tempthis.items[chainIndex].ID_Chain;
                        }
                      }
                    } else if (chainData.Nodes.length > 0) {
                      chainData.isexpanded = true;
                      tempthis.items.push(chainData);
                      if (scrollto === "") {
                        scrollto = chainData.ID_Chain;
                      }
                    }

                    var nodeindex;
                    chainData.Nodes.forEach((Node) => {
                      for (let ccnt = 0; ccnt < tempthis.items.length; ccnt++) {
                        if (tempthis.items[ccnt].ID_Chain < 0) {
                          nodeindex = tempthis.items[ccnt].Nodes.findIndex(
                            (n) =>
                              (Node.idn !== null && n.idn === Node.idn) ||
                              (Node.ido !== null && n.ido === Node.ido)
                          );
                          if (nodeindex > -1) {
                            tempthis.items.splice(ccnt, 1);
                            ccnt--;
                          }
                        }
                      }
                    });
                  });

                  this.showAddNode = false;

                  this.hightlightRow(scrollto);
                }
              }
            })
            .catch((err) => {
              this.$showMessage({ content: err, color: "error" });
            });
        }
      }
    },

    rowColor(ID_Chain, index) {
      if (this.currentID_Chain === ID_Chain) {
        if (this.$vuetify.theme.dark) {
          return "#996300";
        } else {
          return "#FFA500";
        }
      } else {
        if (this.$vuetify.theme.dark) {
          return index % 2 !== 0 ? "#222" : "#333";
        } else {
          return index % 2 !== 0 ? "#f0f0f0" : "#e0e0e0";
        }
      }
    },

    refreshBaseHeight() {
      if (this.container.querySelector("div.col")) {
        this.container.querySelector("div.col").style.display = "none";
        this.baseheight = this.container.offsetHeight;
        this.container.querySelector("div.col").style.display = "block";
      } else {
        this.baseheight = this.container.offsetHeight;
      }
      this.updateTableHeight();
    },

    updateTableHeight() {
      if (this.baseheight) {
        this.tableheight = this.baseheight - 205;
      }
    },

    getchains() {
      var tempthis = this;
      this.currentID_Chain = null;
      this.$http
        .post("api/getChains", {
          sortMode: this.wiringSortMode,
          search: this.search,
          setups: this.selectedsetups,
          includecables: this.includeCables,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            tempthis.items = response.data.chains;
            tempthis.AllSetups = response.data.AllSetups;
            tempthis.CableTypes = response.data.CableTypes;
            tempthis.CableTypes.push({
              i: 0,
              ct: "No information available",
            });

            // tempthis.items.push({
            //   ID_Chain: "-1",
            //   expandable: false,
            //   Nodes: [],
            // });
            // dummy Values
            // tempthis.items = Array.from(
            //   { length: 30 * tempthis.items.length },
            //   (_, i) => tempthis.items[i % tempthis.items.length]
            // );

            if (this.search !== null) {
              tempthis.items
                .filter((c) => c.expandable)
                .forEach((chain) => {
                  this.expand(chain.ID_Chain);
                });
            }

            var threfresh = function () {
              tempthis.updateTableHeight();
            };
            window.setTimeout(threfresh, 600);
          }
        });
    },
    expand(ID_Chain) {
      var chain = this.items.find((r) => r.ID_Chain === ID_Chain);
      if (chain) {
        chain.isexpanded = true;
        if (!this.isChainFullyLoaded(chain)) {
          this.refreshChain(ID_Chain);
        }
      }
    },
    refreshChain(ID_Chain) {
      // var tempthis = this;
      this.$http
        .post("api/getSingleChain", {
          ID_Chain: parseInt(ID_Chain),
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            var chain = this.items.find((r) => r.ID_Chain === ID_Chain);
            if (chain) {
              chain.Nodes = response.data.chains[0].Nodes;
              chain.ncount = response.data.chains[0].Nodes.length;
              chain.Latest_Remarks = response.data.chains[0].Latest_Remarks;
              chain.Setups = response.data.chains[0].Setups;
              this.addAdditionalSetups(chain.Setups);
              chain.Remarks_Count = response.data.chains[0].Remarks_Count;
            }
          }
        });
    },

    getCon() {
      return require("@/assets/con_sim.png");
    },
    getConExp() {
      return require("@/assets/con_exp.png");
    },

    getAsset(imgsrc) {
      return require("@/assets/" + imgsrc);
    },

    clearUrl() {
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
    },
  },
};
</script>