import { render, staticRenderFns } from "./App.vue?vue&type=template&id=47a5c2cc&scoped=true"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=47a5c2cc&prod&scoped=true&lang=css"
import style1 from "./App.vue?vue&type=style&index=1&id=47a5c2cc&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a5c2cc",
  null
  
)

export default component.exports