<template>
  <div>
    <v-menu offset-y close-on-click v-model="showMenu">
      <template v-slot:activator="{}">
        <v-textarea
          :background-color="$getPropertyBackgroundColor(field)"
          @keydown="counterEnOn(!field.d)"
          @focus="errormessage = null"
          @blur="
            counterEnOn(false);
            transformContent();
          "
          :class="field.n.indexOf('Email') > -1 ? 'text-style-lowercase' : ''"
          :filled="field.d && !field.print"
          hide-details="auto"
          v-model="field.v"
          :clearable="!field.d && !field.print"
          :readonly="field.d || field.print"
          ref="txtfld"
          auto-grow
          rows="1"
          :outlined="!alternatelayout"
          :dense="!alternatelayout"
          :rules="[...requiredRule, ...regexRule]"
          :counter="counterEn ? MaxLength(field) : false"
          :error-messages="errormessage"
          :append-outer-icon="
            isMac
              ? 'content_copy'
              : id_link !== 410 && field.a === 'Patch Mode'
              ? 'settings'
              : ''
          "
          @click:append-outer="specialhandling"
          @keyup="checkcond()"
          @paste="checkcond()"
          :messages="message"
          @update:error="fielderror = $event"
          ><template slot="label" v-if="alternatelayout">
            <span v-html="$mksLabel(field, alternatelayout)"></span>
          </template>
          <template v-slot:message="{ message }" v-if="!alternatelayout">
            <span v-html="message"></span>
          </template>
          <template v-if="!field.print" slot="append">
            <v-tooltip top v-if="field.why">
              <template v-slot:activator="{ on }">
                <v-icon class="outlined" v-on="on" style="opacity: 0.1">
                  lock
                </v-icon>
              </template>
              <span>{{ field.why }}</span>
            </v-tooltip>
            <v-tooltip
              top
              v-if="
                field.v &&
                field.v === field.v.toString() &&
                $isMailAddress(field.v.toString())
              "
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="outlined"
                  style="opacity: 0.5; cursor: pointer"
                  @click="createMail"
                >
                  mail_outline
                </v-icon>
              </template>
              <span>create e-mail</span>
            </v-tooltip>
          </template>
        </v-textarea>
      </template>

      <v-list v-model="formsofmac">
        <template v-for="(formofmac, index) in formsofmac">
          <v-list-item
            v-if="formofmac !== 'separator'"
            :key="index"
            @click="copyToClipboard(formofmac)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="formofmac"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-else :key="index"></v-divider>
        </template>
      </v-list>
    </v-menu>
    <mksdetails
      :editquery="editlnk"
      :visible="PatchDetailsVisible"
      :idfilter="{}"
      :calledFromDetails="true"
      @close="closePatchDetails"
    ></mksdetails>
  </div>
</template>

<style scoped>
.text-style-lowercase >>> .v-text-field__slot textarea {
  text-transform: lowercase;
}

.text-style-grey >>> .v-input__slot fieldset {
  background-color: darkgray;
}
</style>

<script>
import _ from "lodash";

export default {
  name: "mkstextinput",

  data: () => ({
    origval: null,
    counterEn: false,
    errormessage: null,
    showMenu: false,
    formsofmac: {},
    PatchDetailsVisible: false,
    editlnk: {},
    fielderror: false
  }),

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },

    id_link: {
      type: Number,
      required: false,
      default: 0,
    },
    id: {
      type: Number,
      required: false,
      default: 0,
    },
    offerPatchChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    detailsLink: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    message: {
      get() {
        return this.$mksLabel(this.field, !this.alternatelayout, this.fielderror);
      },
    },
    isMac: {
      get() {
        return this.field.n.endsWith("MAC");
      },
    },
    requiredRule: {
      get() {
        if (this.field.r) {
          return [
            (v) =>
              !!v ||
              (!this.alternatelayout ? this.field.a + " " : "") + "is required",
          ];
        } else {
          return [];
        }
      },
    },
    regexRule: {
      get() {
        if (this.field.regex && this.field.v && this.field.v.length > 0) {
          return [
            (v) =>
              new RegExp(this.field.regex).test(v) ||
              (!this.alternatelayout ? this.field.a + " " : "") +
                (this.field.Comment
                  ? " - " + this.field.Comment + "!"
                  : " - incorrect format!"),
          ];
        } else {
          return [];
        }
      },
    },
    returnValue: {
      get() {
        let returnValue = this.field.v;

        return {
          n: this.field.n,
          v: returnValue,
        };
      },
    },
    changed: {
      get() {
        return this.origval != this.field.v;
      },
    },
    fieldvalue: {
      get() {
        return this.field.v;
      },
    },
  },
  methods: {
    initialize() {
      this.origval = this.field.v;
    },
    setUnsaved() {
      this.origval = null;
    },
    MakeCard(field) {
      return (
        (field && field.n && field.n.substr(0, 9) === "Bemerkung") ||
        (field && field.l > 500)
      );
    },
    MaxLength(field) {
      return field && field.l && field.l > 10000 ? 10000 : field.l;
    },
    counterEnOn(onoroff) {
      this.counterEn = onoroff;
    },
    closePatchDetails(newPatchMode) {
      this.field.v = newPatchMode.pm;
      this.PatchDetailsVisible = false;
      this.$emit("newPatchState", newPatchMode.ps);
    },
    specialhandling() {
      if (this.isMac && this.field.v !== null && this.field.v != "") {
        var MACForms = new Array();
        var newMACs = this.getMacs();
        for (var mccnt = 0; mccnt < newMACs.length; mccnt++) {
          let MAC = newMACs[mccnt];
          MAC = MAC.trim()
            .replaceAll(".", "")
            .replaceAll(":", "")
            .replaceAll("-", "")
            .replaceAll(" ", "");

          MACForms.push(MAC);
          MACForms.push(
            MAC.substr(0, 4) + "." + MAC.substr(4, 4) + "." + MAC.substr(8, 4)
          );
          MACForms.push(
            MAC.substr(0, 2) +
              ":" +
              MAC.substr(2, 2) +
              ":" +
              MAC.substr(4, 2) +
              ":" +
              MAC.substr(6, 2) +
              ":" +
              MAC.substr(8, 2) +
              ":" +
              MAC.substr(10, 2)
          );
          MACForms.push(
            MAC.substr(0, 2) +
              "-" +
              MAC.substr(2, 2) +
              "-" +
              MAC.substr(4, 2) +
              "-" +
              MAC.substr(6, 2) +
              "-" +
              MAC.substr(8, 2) +
              "-" +
              MAC.substr(10, 2)
          );
          if (newMACs.length > 1 && mccnt < newMACs.length - 1) {
            MACForms.push("separator");
          }
        }
        this.formsofmac = MACForms;
        this.showMenu = true;
      } else if (this.field.a === "Patch Mode") {
        this.editlnk = {
          tablename: "Patch Level",
          id_query: 365,
          id_link: 410,
          id: this.id,
          identifier: "id_hw",
        };

        this.PatchDetailsVisible = true;
      }
    },
    getMacs() {
      if (this.field.v) {
        let MACs = this.field.v
          .trim()
          .replaceAll(".", "")
          .replaceAll(":", "")
          .replaceAll("-", "")
          .replaceAll(" ", "")
          .split("+");
        var newMACs = new Array();
        MACs.forEach((MAC) => {
          if (MAC.length > 0 && MAC.length == 12) {
            MAC = MAC.toLowerCase();
            if (process.env.VUE_APP_TITLE === "HomeDB2.0") {
              MAC =
                MAC.substr(0, 4) +
                "." +
                MAC.substr(4, 4) +
                "." +
                MAC.substr(8, 4);
            } else {
              MAC =
                MAC.substr(0, 2) +
                ":" +
                MAC.substr(2, 2) +
                ":" +
                MAC.substr(4, 2) +
                ":" +
                MAC.substr(6, 2) +
                ":" +
                MAC.substr(8, 2) +
                ":" +
                MAC.substr(10, 2);
            }
            newMACs.push(MAC);
          } else {
            newMACs.push(MAC);
            this.errormessage = "wrong format for " + this.field.a + "";
          }
        });
        if (newMACs.length > 0) {
          this.field.v = newMACs.join(" + ");
        }
        return newMACs;
      } else {
        return null;
      }
    },

    createMail() {
      window.open(
        "mailto:" +
          this.field.v +
          "?body=" +
          encodeURIComponent("Link to Item:\r\n" + this.detailsLink) //eslint-disable-line
      );
    },
    transformContent() {
      this.errormessage = null;
      if (this.field.v) {
        if (this.field.n.indexOf("Email") > -1) {
          this.field.v = this.field.v.toLowerCase();
        } else if (this.isMac) {
          this.getMacs();
        }
      }
    },

    copyToClipboard(mac) {
      navigator.clipboard.writeText(mac);
    },

    throttledMethod: _.debounce(function () {
      this.$emit("checkcond", { fncname: "emplname" });
    }, 300),

    setFocus() {
      this.$nextTick(() => {
        this.$refs.txtfld.$refs.input.focus();
      });
    },

    checkcond() {
      if (
        this.field.a === "Nachname" ||
        this.field.a === "Vornamen" ||
        this.field.a === "Surname" ||
        this.field.a === "First Names"
      )
        this.throttledMethod();
    },
  },
  mounted() {
    this.origval = this.field.v;
  },
  watch: {
    fieldvalue() {
      this.$emit("userinput", this.field);
    },
  },
};
</script>
