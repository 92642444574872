<template>
    <div ref="wiringcontainer">
        <v-card elevation="2">
            <v-card-title>Resources</v-card-title>
            <v-card-subtitle>manage bundles</v-card-subtitle>
            <v-card-text>
              
            </v-card-text>
        </v-card>
        <mkstable
                v-bind:id_query="123"
              ></mkstable>
    </div>
</template>

<script>
import { mapState } from "vuex";

// import mksautocomplete from "@/components/mksautocomplete.vue";

import mkstable from "@/components/mkstable.vue";

export default {
  name: "mkswiring",

  components: {
    // mksautocomplete,
    mkstable
  },

  data: () => ({
  }),

  
  computed: {
    ...mapState(["currentUser", "lastKeyUp"]),

    prefs() {
      return this.currentUser.prefs;
    },

    length() {
      return this.items.length;
    },
  },
}
</script>