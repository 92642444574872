var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-combobox',{ref:"txtfld",attrs:{"disabled":_vm.field.d || _vm.field.print,"filled":_vm.field.d || _vm.field.print,"no-filter":"","background-color":_vm.field.bc,"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"hide-no-data":"","item-text":"v","item-value":"sid","outlined":!_vm.alternatelayout,"dense":!_vm.alternatelayout,"prepend-inner-icon":_vm.field.s === 'id_location' ? 'place' : '',"clearable":"","auto-select-first":"","open-on-clear":"","return-object":"","validate-on-blur":"","rules":_vm.requiredRule,"messages":_vm.message},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keyup":function($event){return _vm.keyup($event)},"click:clear":function($event){return _vm.getOptions('', 0)},"focus":_vm.onFocus},scopedSlots:_vm._u([(!_vm.alternatelayout)?{key:"message",fn:function({ message }){return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}:null,{key:"item",fn:function({ item, on }){return [_c('v-list-item',_vm._g({class:'v-list-item v-list-item--link theme--light ' +
          (_vm.field.cff
            ? (item.match === 1 ? 'itemmatchauto' : 'itemdontmatchauto') +
              (_vm.$vuetify.theme.dark ? '_dark' : '')
            : '')},on),[_c('v-list-item-content',{staticClass:"v-list-item__content"},[_c('v-list-item-title',{staticClass:"v-list-item__title",domProps:{"innerHTML":_vm._s(_vm.$highlight(item.v, _vm.search))}}),(_vm.field.separator)?_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.$highlight(item.subtitle, _vm.search))}}):_vm._e()],1)],1)]}}],null,true),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[(_vm.alternatelayout)?_c('template',{slot:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$mksLabel(_vm.field, _vm.alternatelayout))}})]):_vm._e(),(this.field.id_query && this.model && this.model.sid !== 0)?_c('template',{slot:"append-outer"},[_c('v-icon',{attrs:{"link":""},on:{"click":_vm.openDetails}},[_vm._v("north_east")])],1):_vm._e(),(_vm.field.why)?_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(!_vm.field.print)?_c('v-icon',_vm._g({staticClass:"outlined",staticStyle:{"opacity":"0.1"}},on),[_vm._v(" lock ")]):_vm._e()]}}],null,false,2987248861)},[_c('span',[_vm._v(_vm._s(_vm.field.why))])]):_vm._e(),(
        typeof this.field.exturl !== 'undefined' && this.field.exturl !== ''
      )?_c('v-icon',{attrs:{"slot":"prepend","title":this.field.exturlicontitle,"color":"blue"},on:{"click":_vm.openExtUrl},slot:"prepend"},[_vm._v(_vm._s(this.field.exturlicon))]):_vm._e()],2),_c('mksdetails',{attrs:{"editquery":_vm.editlnk,"visible":_vm.showDetails,"idfilter":{},"calledFromDetails":false},on:{"close":_vm.closeDetails}}),_c('mksdialogconfirm',{attrs:{"confirmDialog":_vm.confirmDialog},on:{"close":_vm.confirmResult}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }