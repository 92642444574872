<template>
  <v-form ref="form" v-model="valid" aria-autocomplete>
    <v-card v-if="showRemarksSelector">
      <v-card-title ref="remarksSelector">
        Would you like to include all remarks? </v-card-title><v-card-text><v-row><v-col><v-btn
              @click="printremarks(false)">don't print remarks</v-btn></v-col><v-col><v-btn
              @click="printremarks(true)">include remarks</v-btn></v-col></v-row>
      </v-card-text>
    </v-card>

    <v-card v-if="showImagesSelector">
      <v-card-title ref="photostitle">
        Please unselect any images that you do not want printed.<v-spacer></v-spacer>
        <v-btn @click="showpreview()">open print dialog</v-btn> </v-card-title><v-card-text
        :style="'height:' + photoselectorheight + 'px; overflow-y: auto;'">
        <mksphotosprint ref="mksphotosprint" :editquery="editquery" :className="detailsclass"
          :alternatelayout="AlternativeDetailsLayout" :showSelector="true" :imgagesPropInfo="imgagesPropInfo"
          v-on:filesChanged="refreshphotofunc" @saveImgSelection="saveImgSelection" :nextStep="nextStep"></mksphotosprint>
      </v-card-text>
    </v-card>

    <v-card v-if="showPrintPreview" :style="'box-shadow: none !Important;'">
      <v-card-title :class="headlineclass" style="position: relative">
        <span class="mksheadline" :dialogid="$el ? $el.__vue__._uid : ''">{{
          headline
        }}</span><v-spacer></v-spacer><v-btn class="printbutton" @click="print()">print</v-btn>
        <v-spacer></v-spacer><span v-html="new Date().toLocaleDateString('de')"></span>
      </v-card-title>

      <v-card-text ref="outer" :class="detailsclass">
        <v-container ref="inner">
          <template>
            <div ref="jumpdummytop"></div>
          </template>
          <template v-if="showLoadingCircle && !resetting">
            <v-row align="center" justify="center">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-row>
          </template>
          <template v-else>
            <table class="tablelayout" v-if="showTableLayout">
              <tr>
                <td v-for="colnr in tablelayoutcolumnitemproperties.length" :key="'sur' + colnr">
                  <table>
                    <tr v-for="(item, iindex) in tablelayoutcolumnitemproperties[
                      colnr - 1
                    ]" :key="'tr' + iindex">
                      <td>{{ item.a }}</td>
                      <td>{{ item.v }}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <v-row dense v-else>
              <template v-for="(field, index) in itemproperties">
                <template v-if="field.t === 'COMBO' && checkIncl(field)">
                  <v-col :class="vColClass(field)" v-bind:key="'COMBO' + index" :fn="field.s">
                    <mksautocomplete :ref="field.n" :editquery="editquery" :field="field"
                      :alternatelayout="AlternativeDetailsLayout" @setIncludedProperties="setIncludedProperties">
                    </mksautocomplete>
                  </v-col>
                </template>
                <template v-else-if="field.t === 'PHOTO'">
                  <v-col :class="vColClass(field)" v-bind:key="index" :fn="field.n">
                    <mksphoto :key="refreshphoto" ref="Photo" :editquery="editquery" :field="field"
                      :alternatelayout="AlternativeDetailsLayout" v-on:photoChanged="refreshfilesfunc"></mksphoto>
                  </v-col>
                </template>
                <template v-else-if="
                  field.t === 'REMARKS' &&
                  editquery.id !== 0 &&
                  printremarksanswer
                ">
                  <v-col class="col-12" :fn="field.n" v-bind:key="'remarks_' + index">
                    <mksremarks ref="mksremarks" :editquery="editquery" :className="detailsclass"
                      :alternatelayout="AlternativeDetailsLayout" :bgcolor="dlgStyle" :print="field.print"
                      :itemrights="itemrights" :IconButtons="IconButtons"></mksremarks>
                  </v-col>
                </template>
                <template v-else-if="
                  field.t === 'ATTACHEDFILES' &&
                  filesvisible &&
                  editquery.id !== 0
                ">
                  <v-col class="col-12" :fn="field.n" v-bind:key="'files_' + index">
                    <mksphotosprint ref="mksphotosprint" :editquery="editquery" :className="detailsclass"
                      :alternatelayout="AlternativeDetailsLayout" v-on:filesChanged="refreshphotofunc"
                      :imgagesPropInfo="imgagesPropInfo" :showSelector="false" :nextStep="true"
                      @imagesLoaded="initializeElements"></mksphotosprint>
                  </v-col>
                </template>
                <template v-else-if="field.t === 'LOCATION_development' && checkIncl(field)">
                  <v-col :class="vColClass(field)" v-bind:key="index" :fn="field.s">
                    <mkslocation :ref="field.n" :editquery="editquery" :field="field"
                      :alternatelayout="AlternativeDetailsLayout"></mkslocation>
                  </v-col>
                </template>
                <template v-else-if="field.n === '_bgcolor'"></template>
                <template v-else-if="field.t === 'datetime' && checkIncl(field)">
                  <v-col :class="vColClass(field)" v-bind:key="index" :fn="field.n">
                    <mksdate :ref="field.n" :field="field" :alternatelayout="AlternativeDetailsLayout"></mksdate>
                  </v-col>
                </template>
                <template v-else-if="field.t === 'boolean' && checkIncl(field)">
                  <v-col :class="vColClass(field)" v-bind:key="index" :fn="field.n">
                    <mksbooleaninput :ref="field.n" :field="field" :alternatelayout="AlternativeDetailsLayout">
                    </mksbooleaninput>
                  </v-col>
                </template>
                <template v-else-if="field.t === 'SUBTABLE' && checkIncl(field)">
                  <v-col :key="index" cols="12" :fn="field.n" v-if="editquery.id > 0">
                    <mkssubquery v-bind:key="index" :ref="field.n" :editquery="editquery" :field="field"
                      :alternatelayout="AlternativeDetailsLayout" :bgcolor="dlgStyle"></mkssubquery>
                  </v-col>
                </template>
                <template v-else-if="field.t.startsWith('int') && checkIncl(field)">
                  <v-col :class="vColClass(field)" v-bind:key="index" :fn="field.n">
                    <mksnumberinput :ref="field.n" :field="field" :alternatelayout="AlternativeDetailsLayout">
                    </mksnumberinput>
                  </v-col>
                </template>
                <template v-else-if="field.t === 'double' && checkIncl(field)">
                  <v-col :class="vColClass(field)" v-bind:key="index" :fn="field.n">
                    <mksdoubleinput :ref="field.n" :field="field" :alternatelayout="AlternativeDetailsLayout">
                    </mksdoubleinput>
                  </v-col>
                </template>
                <template v-else-if="field.t === 'string' && checkIncl(field)">
                  <v-col :class="vColClass(field)" v-bind:key="index" :fn="field.n">
                    <mkstextinput :ref="field.n" :field="field" :alternatelayout="AlternativeDetailsLayout"
                      :id="editquery.id" :id_link="editquery.id_link"></mkstextinput>
                  </v-col>
                </template>
              </template>
              <v-col v-if="false" class="col-sm-4 col-md-4 col-lg-2 col-12">
                <mkstextinput v-if="ShowIdentifierColumn && editquery.id > 0" :field="{
                  a: editquery.identifier,
                  n: editquery.identifier,
                  v: this.editquery.id,
                  d: true,
                }" :alternatelayout="AlternativeDetailsLayout"></mkstextinput>
              </v-col>
            </v-row>
          </template>
          <template>
            <div ref="jumpdummybottom"></div>
          </template>
        </v-container>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<style scoped>
@media print {
  html {
    zoom: 65%;
  }

  table {
    page-break-inside: avoid;
  }

  .printbutton {
    display: none;
  }
}


form>>>.v-card {
  box-shadow: none !important;
}

.btnend {
  top: 50px;
}

.v-input--switch {
  margin-top: 4px !important;
  padding: 0px 0px 0px 0px !important;
}

.hightlightscrolltarget {
  background-color: paleturquoise;
}

table.tablelayout>tr {
  vertical-align: top;
}
</style>

<script>
import mksautocomplete from "@/components/mksautocomplete.vue";
import mkslocation from "@/components/mkslocation.vue";
import mkssubquery from "@/components/mkssubquery.vue";
import mksdate from "@/components/mksdate.vue";
import mkstextinput from "@/components/mkstextinput.vue";
import mksphoto from "@/components/mksphoto.vue";
import mksremarks from "@/components/mksremarks.vue";
import mksnumberinput from "@/components/mksnumberinput.vue";
import mksdoubleinput from "@/components/mksdoubleinput.vue";
import mksphotosprint from "@/components/mksphotosprint.vue";
import mksbooleaninput from "@/components/mksbooleaninput.vue";

import { mapState } from "vuex";

const propComparator = (searchForProp) => (a, b) => {
  var nameA = a.a.toLowerCase(); // Groß-/Kleinschreibung ignorieren
  var nameB = b.a.toLowerCase(); // Groß-/Kleinschreibung ignorieren

  if (searchForProp !== "") {
    return nameA.indexOf(searchForProp) < nameB.indexOf(searchForProp) ? -1 : 1;
  }

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // Namen müssen gleich sein
  return 0;
};

export default {
  name: "mksdetailsprint",

  components: {
    mksautocomplete,
    mkslocation,
    mkssubquery,
    mksdate,
    mkstextinput,
    mksphoto,
    mksremarks,
    mksnumberinput,
    mksdoubleinput,
    mksbooleaninput,
    mksphotosprint,
  },

  data: () => ({
    printremarksanswer: null,
    nextStep: false,
    photoselectorheight: 500,
    showImagesSelector: false,
    showRemarksSelector: false,
    showPrintPreview: false,
    imgagesPropInfo: [],
    imageFileNameExtentions: [
      "apng",
      "avif",
      "gif",
      "jpg",
      "jpeg",
      "jfif",
      "pjpeg",
      "pjp",
      "png",
      "svg",
      "webp",
    ],
    imgBaseURL: "api/getAttachedFileContentGet",
    printingDialog: false,
    showTableLayout: false,
    TableLayoutColumnNumber: 3,
    propCounter: 0,
    fieldorder: null,
    reorderFieldsActive: false,
    resetOrderFieldsActive: false,
    hasPrevious: false,
    hasNext: false,
    searchForProp: "",
    valid: true,
    loading: false,
    showLoadingCircle: false,
    resetting: false,
    itemproperties: [],
    tablelayoutcolumnitemproperties: [],
    itemrights: {},
    grphsh: "",
    detailshashOrig: "",
    requiredRule: [(v) => !!v || "missing"],
    Photo: null,
    bgcolor: "",
    scrollbtnvisible: false,
    hasHistory: false,
    exportmodel: true,
    exportitems: [],
    selectedExport: null,
    AlternativeDetailsLayout: false,
    IconButtons: false,
    SaveAndClose: false,
    ShowIdentifierColumn: false,
    refreshphoto: 0,
    refreshfile: 0,
    blockedBy: "",
    headline: "",
    headlineclass: "",
    detailsclass: "",
    resetElements: [],
    filesvisible: false,
    firstcall: true,
    fastjumpmodel: null,
    tobecheckedorigid: null,
    scrldwn: true,
    elementCount: 100,
    saveandcloseclicked: false,
    surnameFieldName: "",
    firstnamesFieldName: "",
    changedElementsForModulCheck: [],
    detailsSaved: false,
    included_props: []
  }),

  props: {
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    idfilter: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    calledFromDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  watch: {
    prefs: {
      handler() {
        this.refreshSettings();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState(["currentUser", "currenthubsubscriptions", "lastKeyUp"]),

    prefs() {
      return this.currentUser.prefs;
    },

    isfiltered: {
      get() {
        return !(
          Object.keys(this.idfilter).length === 0 &&
          this.idfilter.constructor === Object
        );
      },
    },
    dlgStyle: {
      get() {
        return this.bgcolor !== "" ? "background-color:" + this.bgcolor : "";
      },
    },

    getFieldsForJumpList: {
      get() {
        var jfields = Object.values(this.itemproperties).filter((obj) => {
          return obj;
        });

        jfields.sort(
          propComparator(
            this.searchForProp ? this.searchForProp.toLowerCase() : ""
          )
        );
        return jfields;
      },
    },
  },

  methods: {
    printremarks(answer) {
      this.printremarksanswer = answer;
      this.showRemarksSelector = false;
      this.getDetails(this.firstcall);
    },

    saveImgSelection(imgsInfos) {
      this.imgagesPropInfo = imgsInfos;
      this.showImagesSelector = false;
      this.showPrintPreview = true;
      this.getDetails(true);
    },
    showpreview() {
      this.nextStep = true;
    },
    print() {
      window.print();
    },

    initializeElements() {

      this.$nextTick(() => {
        this.$nextTick(() => {
          var checkElem = null;
          for (var ref in this.$refs) {
            if (this.$refs[ref]) {
              checkElem = this.$refs[ref][0];
              if (checkElem) {
                if (this.editquery.id < 0) {
                  if (checkElem.setUnsaved) {
                    checkElem.setUnsaved();
                  }
                }
                if (checkElem.initialize) {
                  checkElem.initialize();
                }
              }
            }
          }
        });

        this.$nextTick(() => {
          window.setTimeout(function () {
            window.print();
          }, 300);
        });
      });
    },

    setIncludedProperties(includedProps) {
      if (includedProps == null) {
        return;
      }
      this.included_props = includedProps.replace(/\r?\n|\r/g, "").split(/,|\[|\]/).filter(element => element.trim() !== '');
      this.included_props.push(...this.included_props.map(i => '_' + i));
    },
    checkIncl(field) {
      if (this.included_props.length < 1) { return true; }
      else if (this.included_props.indexOf(field.n) > -1) {
        return true;
      }
      else if (this.included_props.indexOf(field.n) > -1) {
        return true;
      }
      return false;
    },
    onOuterScroll() {
      this.scrldwn =
        this.$refs.outer.scrollTop < this.$refs.inner.scrollHeight / 3;
    },

    vColClass(field) {
      var flength =
        field.t === "boolean"
          ? field.a.length - 5
          : (field.avrlen >
            (field.v && field.v !== null && field.v.length ? field.v.length : 20)
            ? field.avrlen
            : field.v && field.v !== null && field.v.length
              ? field.v.length
              : 20) +
          (field.t === "COMBO" ? (field.id_link !== null ? 12 : 8) : 4);
      var colwdthstl = "col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2";

      if (flength > 120) {
        colwdthstl = "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8";
      } else if (flength > 80) {
        colwdthstl = "col-12 col-sm-12 col-md-8 col-lg-9 col-xl-6";
      } else if (flength > 40) {
        colwdthstl = "col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4";
      }

      colwdthstl = "col-4";

      if (flength > 110) {
        colwdthstl = "col-12";
      } else if (flength > 30) {
        colwdthstl = "col-8";
      } else if (flength > 20) {
        colwdthstl = "col-4";
      } else if (flength > 20) {
        colwdthstl = "col-4";
      }

      return colwdthstl;
    },

    field(index) {
      return this.itemproperties && this.itemproperties[index]
        ? this.itemproperties[index]
        : { t: "" };
    },

    refreshphotofunc() {
      this.refreshphoto++;
    },

    refreshfilesfunc() {
      this.refreshfile++;
    },

    refreshSettings() {
      this.AlternativeDetailsLayout = this.$getPref({
        TabName: "Options",
        PrefID: "AlternativeDetailsLayout",
      });
      if (this.AlternativeDetailsLayout == null) {
        this.AlternativeDetailsLayout = false;
      }
      this.IconButtons = this.$getPref({
        TabName: "Options",
        PrefID: "IconButtons",
      });
      if (this.IconButtons == null) {
        this.IconButtons = false;
      }
      this.SaveAndClose = this.$getPref({
        TabName: "Options",
        PrefID: "SaveAndClose",
      });
      if (this.SaveAndClose == null) {
        this.SaveAndClose = false;
      }

      var temp = this.$getPref({
        TabName: "Options",
        PrefID: "ShowIdentifierColumn",
      });
      if (temp !== null && temp !== this.ShowIdentifierColumn) {
        this.ShowIdentifierColumn = temp;
      }
      if (this.ShowIdentifierColumn == null) {
        this.ShowIdentifierColumn = false;
      }
    },

    getAttachments() {
      this.$http
        .post("api/getAttachedFiles", {
          query: this.editquery,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            if (response.data === "NO ATTACHMENT TABLE") {
              this.getDetails(this.firstcall);
              return;
            } else {
              throw "malformatted JSON Data";
            }
          }

          if (response.data.error) {
            this.tabletitle = "Error loading Data";
            this.myerror = response.data.error;
            this.items = null;
            this.loading = false;
          } else {
            this.dbitems = response.data.recordsets[0].data;
            this.itemrights = response.data.rights;
            this.tabletitle = "Files";

            if (this.dbitems.length == 0) {
              this.items = null;
            }

            this.totalitems = parseInt(response.data.recordsets[1]);

            this.identifier = response.data.recordsets[5];

            this.loading = false;

            var params =
              this.editquery.id_query +
              "." +
              this.editquery.id_link +
              "." +
              this.editquery.id +
              "." +
              this.editquery.identifier +
              ".";
            this.dbitems.forEach((element) => {
              if (
                element.Filename &&
                this.imageFileNameExtentions.indexOf(
                  element.Filename.split(".").pop().toLowerCase()
                ) > -1
              ) {
                this.imgagesPropInfo.push({
                  url:
                    this.imgBaseURL +
                    "?att=" +
                    params +
                    "0." +
                    element.ID_Attachment_Assignment + ".2.600",
                  filename: element.Filename,
                  checked: true,
                  ID_Attachment_Assignment: element.ID_Attachment_Assignment,
                });
              }
            });

            if (this.imgagesPropInfo.length > 0) {
              this.showImagesSelector = true;
            } else {
              this.showPrintPreview = true;
              this.getDetails(this.firstcall);
            }
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
                ? e.message
                : e;
          this.$showMessage({ content: message, color: "error" });
          this.errors.push(e);
        });
    },

    getDetails(showloading = false) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.resetElements.length > 0) {
        this.resetElements.forEach((x) => {
          x.style.backgroundColor = "";
        });
      }

      if (showloading) {
        this.showLoadingCircle = true;
      }
      let tempthis = this;

      this.$http
        .post("api/detail", {
          query: this.editquery,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            tempthis.itemproperties = response.data.item;
            if (response.data.headline) {
              document.title = (
                process.env.VUE_APP_TITLE +
                " - " +
                response.data.headline
              ).replace(/[_.]/g, " ");
              tempthis.headline =
                process.env.VUE_APP_TITLE + " - " + response.data.headline;
            } else {
              document.title = (
                process.env.VUE_APP_TITLE + " - Details"
              ).replace(/[_.]/g, " ");
              tempthis.headline = process.env.VUE_APP_TITLE + " - Details";
            }
            if (tempthis.itemproperties[0] === null) {
              tempthis.show = false;
              return;
            }
            // show Attached files at document end
            var attachedfilesposition = tempthis.itemproperties.findIndex(
              (e) => e.t === "ATTACHEDFILES"
            );
            if (attachedfilesposition > -1) {
              tempthis.itemproperties = tempthis.itemproperties.concat(
                tempthis.itemproperties.splice(attachedfilesposition, 1)
              );
            }

            tempthis.itemproperties = tempthis.itemproperties.map(function (
              obj
            ) {
              obj.print = true;
              return obj;
            });

            // Offer include Remarks
            var remarksPosition = tempthis.itemproperties.findIndex(
              (e) => e.t === "REMARKS"
            );
            if (remarksPosition > -1 && this.printremarksanswer === null) {
              this.showRemarksSelector = true;
              this.showPrintPreview = false;
              this.loading = false;
              return;
            } else {
              this.showPrintPreview = true;
            }

            var isdark = false;
            tempthis.itemproperties.forEach((p) => {
              if (typeof p.bc !== "undefined" && p.bc !== "") {
                p.bc = this.$getColor(isdark, p.bc);
              }
            });
            tempthis.fieldorder = Object.keys(tempthis.itemproperties);
            tempthis.itemrights = response.data.rights;
            tempthis.filesvisible = response.data.hasAttachments;
            tempthis.editquery.id = parseInt(response.data.id);
            tempthis.editquery.identifier = response.data.identifier;
            tempthis.editquery.headline = response.data.headline;

            tempthis.hasPrevious = this.editquery.hasPrevious;
            tempthis.hasNext = this.editquery.hasNext;

            tempthis.grphsh = response.data.grphsh;
            if (tempthis.detailshashOrig != tempthis.detailshash) {
              tempthis.detailshashOrig = tempthis.detailshash;
            }

            tempthis.blockedBy = response.data.blockedBy;
            tempthis.hasHistory = response.data.hasHistory;

            if (tempthis.isfiltered) {
              let fixedValueFor = tempthis.itemproperties.find(
                (o) => o.s === tempthis.idfilter.identifier.toLowerCase()
              );
              if (fixedValueFor) {
                fixedValueFor.d = true;
                fixedValueFor.v = "";
                fixedValueFor.forceload = true;
                fixedValueFor.sid = tempthis.idfilter.id;

                tempthis.itemproperties.unshift(
                  tempthis.itemproperties.splice(
                    tempthis.itemproperties.findIndex(
                      (item) => item.n === fixedValueFor.n
                    ),
                    1
                  )[0]
                );
              }
            }
          }
          this.loading = false;
          this.showLoadingCircle = false;
          this.resetting = false;

          if (
            tempthis.itemproperties.find((p) => p.t === "ATTACHEDFILES") == null
          ) {
            this.$nextTick(() => {
              this.$nextTick(() => {
                var preloadcombos = tempthis.itemproperties.filter((p) => p.forcepreload === true);
                preloadcombos.forEach(function (combo) {
                  if (tempthis.$refs[combo.n] && tempthis.$refs[combo.n][0]) {
                    tempthis.$refs[combo.n][0].getOptions('', 0);
                  }
                });

                this.$nextTick(() => {
                  window.setTimeout(function () {
                    tempthis.initializeElements();
                  }, 300);
                });
              });
            });
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
                ? e.message
                : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
          this.resetting = false;
          this.show = false;
        });
    },
  },
  created() {
    this.refreshSettings();

    this.photoselectorheight = window.innerHeight - 65 - 68;

    this.surnameFieldName =
      process.env.VUE_APP_TITLE === "HomeDB2.0" ||
        this.currentUser.webname.indexOf("HomeDB") > -1 ||
        this.currentUser.webname.indexOf("TLabs_Data") > -1
        ? "Nachname"
        : "Surname";

    this.firstnamesFieldName =
      process.env.VUE_APP_TITLE === "HomeDB2.0" ||
        this.currentUser.webname.indexOf("HomeDB") > -1 ||
        this.currentUser.webname.indexOf("TLabs_Data") > -1
        ? "Vornamen"
        : "First_Names";

    if (this.editquery.print) {
      // this.getDetails(this.firstcall);
      this.getAttachments();
    }
  },
};
</script>