<template>
  <div>
    <v-text-field
      :filled="!field.print"
      v-model="field.v"
      readonly
      :label="$mksLabel(field, alternatelayout)"
      :hint="$mksLabel(field, !alternatelayout)"
      :persistent-hint="!alternatelayout"
      :outlined="!alternatelayout"
      :dense="!alternatelayout"
      prepend-inner-icon="place"
      append-outer-icon="edit"
      @click:append-outer="showeditlocation"
    ></v-text-field>
    <v-dialog v-model="show" scrollable v-bind:retain-focus="true">
      <v-card>
        <v-card-title style="position: relative">
          <v-icon>place</v-icon><span class="mksheadline">Set Location</span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text> </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.mksoutlined {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 40px;
  overflow-y: hidden;
}

.mksoutlined > div {
  margin-top: 2px;
  vertical-align: top;
}
</style>

<script>
export default {
  name: "mkslocation",

  data: () => ({
    origval: null,
    show: false,
  }),

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    requiredRule: {
      get() {
        if (this.field.r) {
          return [(v) => !!v || " is required"];
        } else {
          return [];
        }
      },
    },
    returnValue: {
      get() {
        return {
          n: this.field.n,
          v: this.field.v,
        };
      },
    },
    changed: {
      get() {
        return this.origval != this.field.v;
      },
    },
    fieldvalue: {
      get() {
        return this.field.v;
      },
    },
  },
  methods: {
    initialize() {
      this.origval = this.field.v;
    },
    setUnsaved() {
      this.origval = null;
    },
    setFocus() {
      this.$nextTick(() => {
        this.$refs[this.field.n].$refs["input"].focus();
      });
    },
    showeditlocation() {
      this.show = true;
    },
  },
  mounted() {
    this.origval = this.field.v;
  },
  watch: {
    fieldvalue() {
      this.$emit("userinput", this.field);
    },
  },
};
</script>
