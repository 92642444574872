<template>
  <div>
    <v-dialog
      v-model="show"
      scrollable
      v-bind:retain-focus="true"
      persistent
      no-click-animation
    >
      <v-card :style="dlgStyle">
        <v-card-title :class="headlineclass" style="position: relative">
          <span class="mksheadline" :dialogid="$el ? $el.__vue__._uid : ''">
            Reservations for {{ bundle.details.bundle_title }}
          </span>
          <v-spacer></v-spacer>
          <v-btn
            v-show="currentSlot.id_resource_bundle_reservation_slot === 0"
            style="margin-right: 1.5em"
            @click="resetNewSlot"
            ><v-icon>settings_backup_restore</v-icon></v-btn
          >

          <v-btn
            :disabled="saveDisabled"
            style="margin-right: 1.5em"
            @click="saveSlots"
            :loading="saving"
            ><v-icon>save</v-icon></v-btn
          >

          <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="align-start">
              <v-col cols="3">
                <v-card class="mx-auto" max-width="400" tile>
                  <v-card-actions>
                    <v-text-field
                      v-model="searchReservation"
                      append-icon="mdi-magnify"
                      label="Search"
                      outlined
                      dense
                      hide-details
                    ></v-text-field
                    ><v-spacer></v-spacer
                    ><v-switch
                      style="margin-left: 1em"
                      v-model="showPast"
                      primary
                      dense
                      class="reverse-switch"
                      label="incl. past"
                    ></v-switch>
                  </v-card-actions>
                  <v-card-text>
                    <v-list dense>
                      <v-list-item-group
                        style="overflow-y: auto; height: 600px"
                        mandatory
                        v-model="selectedSlotIndex"
                        color="primary"
                      >
                        <v-list-item
                          v-for="(slot, x) in listToShow"
                          :ref="
                            'slot' + slot.id_resource_bundle_reservation_slot
                          "
                          :style="slot.delete ? 'background-color:#9e6c6c' : ''"
                          :key="x"
                        >
                          <v-tooltip
                            top
                            open-delay="500"
                            v-if="
                              slot.id_resource_bundle_reservation_slot > 0 &&
                              (slot.outside.length > 0 ||
                                slot.overlap.length > 0)
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-icon
                                v-on="on"
                                @click.stop="
                                  selectedSlotIndex = x;
                                  showNotMet = true;
                                "
                              >
                                <v-icon v-text="'warning'"></v-icon
                              ></v-list-item-icon>
                            </template>
                            <span
                              >Not all reservation requests could be met.<br />
                              Click for details</span
                            >
                          </v-tooltip>
                          <v-list-item-icon v-else>
                            <v-icon
                              v-text="
                                slot.slot_errors.length > 0 ? 'error' : 'event'
                              "
                            ></v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            @dblclick="
                              copyToClipboard(
                                slot.id_resource_bundle_reservation_slot
                              )
                            "
                          >
                            {{
                              slot.id_resource_bundle_reservation_slot === 0
                                ? "new reservation"
                                : x + 1 + " " + slot.name
                            }}</v-list-item-title
                          >
                          <v-list-item-icon>
                            <v-icon
                              v-if="
                                slot.id_resource_bundle_reservation_slot > 0
                              "
                              @click.stop="
                                selectedSlotIndex = x;
                                slot.delete = !slot.delete;
                              "
                              :class="
                                slot.delete ? '' : 'material-icons-outlined'
                              "
                              >delete</v-icon
                            >
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="9">
                <v-card
                  class="mx-auto"
                  tile
                  v-show="selectedSlotIndex > -1"
                  :style="
                    'background-color:' + (currentSlot.delete ? '#fee' : '')
                  "
                >
                  <v-card-title v-show="currentSlot.delete"
                    >marked for deletion</v-card-title
                  >
                  <v-card-text>
                    <v-container>
                      <v-row class="align-start">
                        <v-col cols="4">
                          <v-combobox
                            label="reservation holder"
                            @change="personChanged()"
                            dense
                            outlined
                            v-model="persmodel"
                            no-filter
                            :items="people"
                            :loading="isLoadingPers"
                            :search-input.sync="searchPers"
                            item-text="v"
                            item-value="id"
                            clearable
                            @keyup="keyupPers($event)"
                            @click:clear="
                              searchPers = '';
                              getOptionsPers();
                            "
                            ref="txtfldPers"
                            auto-select-first
                            :rules="[(v) => !!v || 'is required']"
                          >
                            <template v-slot:no-data>
                              <p class="ml-3 mt-3">unknown person</p>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col cols="4">
                          <v-combobox
                            label="related project (optional)"
                            @change="projectChanged()"
                            dense
                            outlined
                            v-model="projmodel"
                            no-filter
                            :items="projects"
                            :loading="isLoadingProj"
                            :search-input.sync="searchProj"
                            item-text="v"
                            item-value="id"
                            clearable
                            @keyup="keyupProj($event)"
                            @click:clear="
                              searchProj = '';
                              currentSlot.id_project = null;
                              currentSlot.project = null;
                              getOptionsProj(true);
                            "
                            ref="txtfldProj"
                            auto-select-first
                          >
                            <template v-slot:no-data>
                              <p class="ml-3 mt-3">unknown person</p>
                            </template>
                          </v-combobox>
                        </v-col>
                        <v-col
                          v-if="currentSlot.hasE"
                          cols="4"
                          class="d-flex justify-end"
                        >
                          <v-btn @click="getExceptions()">
                            deviating series events
                            <v-icon right dark>sync_disabled</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="
                          !bundle.details.interchangeable &&
                          (bundle.details.setup_time_in_minutes > 0 ||
                            bundle.details.teardown_time_in_minutes > 0)
                        "
                      >
                        <v-col cols="12">
                          <v-banner
                            color="warning"
                            icon="priority_high"
                            outlined
                            rounded
                          >
                            Be aware that this bundle needs
                            <span
                              v-if="bundle.details.setup_time_in_minutes > 0"
                            >
                              <strong
                                >{{
                                  bundle.details.setup_time_in_minutes
                                }}
                                minutes setup</strong
                              >
                            </span>
                            <span
                              v-if="
                                bundle.details.setup_time_in_minutes > 0 &&
                                bundle.details.teardown_time_in_minutes > 0
                              "
                            >
                              and
                            </span>
                            <span
                              v-if="bundle.details.teardown_time_in_minutes > 0"
                            >
                              <strong
                                >{{
                                  bundle.details.teardown_time_in_minutes
                                }}
                                minutes teardown</strong
                              >
                            </span>

                            time.<br />This shortens the effective usage
                            duration!<br />If possible, you may schedule the
                            start of the reservation earlier to compensate for
                            this.
                          </v-banner>
                        </v-col>
                      </v-row>
                      <v-row class="align-start">
                        <v-col cols="2">
                          <v-radio-group
                            v-model="currentSlot.recurrence_pattern"
                            @change="calcNoOfChanges()"
                            label="Recurrence pattern"
                          >
                            <v-radio label="None" value="none"></v-radio>
                            <v-radio label="Daily" value="daily"></v-radio>
                            <v-radio label="Weekly" value="weekly"></v-radio>
                            <v-radio label="Monthly" value="monthly"></v-radio>
                            <v-radio label="Yearly" value="yearly"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col
                          cols="8"
                          v-show="currentSlot.recurrence_pattern === 'none'"
                          style="
                            border-left: 2px solid #eee;
                            min-height: 350px;
                          "
                        >
                          <v-row class="align-center">
                            <v-col cols="2">Start time </v-col>
                            <v-col cols="4">
                              <mksdatesimple
                                ref="start_date1"
                                :date="currentSlot.start_date"
                                :fieldname="'start_date'"
                                @updatedate="updatedate"
                                :elemError="elemErrors['start_date1']"
                              >
                              </mksdatesimple>
                            </v-col>
                            <v-col cols="3">
                              <mksslottimeonly
                                :disabled="currentSlot.all_day"
                                ref="from"
                                label=""
                                :elemError="elemErrors['from']"
                                :required="true"
                                :timeonlyval="currentSlot.from"
                                @changed="prepDurationFrom"
                              >
                              </mksslottimeonly>
                            </v-col>
                            <v-col cols="3">
                              <v-checkbox
                                @change="alldaytoggle()"
                                dense
                                v-model="currentSlot.all_day"
                                label="All day"
                                hide-details
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <v-row class="align-center">
                            <v-col cols="2">End time </v-col>
                            <v-col cols="4">
                              <mksdatesimple
                                ref="end_date1"
                                :date="currentSlot.end_date"
                                :fieldname="'end_date'"
                                @updatedate="updatedate"
                                :elemError="elemErrors['end_date1']"
                              >
                              </mksdatesimple>
                            </v-col>
                            <v-col cols="3">
                              <mksslottimeonly
                                :disabled="currentSlot.all_day"
                                ref="to"
                                label=""
                                :elemError="elemErrors['to']"
                                :required="false"
                                :timeonlyval="currentSlot.to"
                                @changed="prepDurationTo"
                              >
                              </mksslottimeonly>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col
                          v-show="currentSlot.recurrence_pattern !== 'none'"
                          cols="2"
                          style="
                            border-left: 2px solid #eee;
                            min-height: 350px;
                          "
                        >
                          <mksslottimeonly
                            :disabled="currentSlot.all_day"
                            label="start"
                            :elemError="elemErrors['from']"
                            :required="true"
                            :timeonlyval="currentSlot.from"
                            @changed="prepDurationFrom"
                          >
                          </mksslottimeonly>
                          <mksslottimeonly
                            :disabled="currentSlot.all_day"
                            label="end"
                            :elemError="elemErrors['to']"
                            :required="false"
                            :timeonlyval="currentSlot.to"
                            @changed="prepDurationTo"
                          >
                          </mksslottimeonly>

                          <v-checkbox
                            @change="alldaytoggle()"
                            dense
                            v-model="currentSlot.all_day"
                            label="All day"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          v-show="currentSlot.recurrence_pattern !== 'none'"
                          style="
                            border-left: 2px solid #eee;
                            min-height: 350px;
                          "
                        >
                          <div
                            v-show="currentSlot.recurrence_pattern === 'daily'"
                          >
                            <v-row class="align-center">
                              <v-col>Every </v-col>
                              <v-col>
                                <v-text-field
                                  class="shrink"
                                  v-model="currentSlot.every_x_days"
                                  type="Number"
                                  @input="calcNoOfChanges()"
                                ></v-text-field
                              ></v-col>
                              <v-col
                                >day{{
                                  currentSlot.every_x_days > 1 ? "s" : ""
                                }}</v-col
                              >
                            </v-row>
                          </div>
                          <div
                            v-show="currentSlot.recurrence_pattern === 'weekly'"
                          >
                            <v-row class="align-center">
                              <v-col>Recur every </v-col>
                              <v-col>
                                <v-text-field
                                  class="shrink"
                                  v-model="currentSlot.every_x_weeks"
                                  type="Number"
                                  @input="calcNoOfChanges()"
                                ></v-text-field
                              ></v-col>
                              <v-col
                                >week{{
                                  currentSlot.every_x_weeks > 1 ? "s" : ""
                                }}
                                on:</v-col
                              >
                            </v-row>
                            <v-row class="align-center">
                              <v-col cols="3">
                                <v-checkbox
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.mondays"
                                  label="mondays"
                                  hide-details
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.tuesdays"
                                  label="tuesdays"
                                  hide-details
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.wednesdays"
                                  label="wednesdays"
                                  hide-details
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.thursdays"
                                  label="thursdays"
                                  hide-details
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.fridays"
                                  label="fridays"
                                  hide-details
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.saturdays"
                                  label="saturdays"
                                  hide-details
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.sundays"
                                  label="sundays"
                                  hide-details
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </div>
                          <div
                            v-show="
                              currentSlot.recurrence_pattern === 'monthly'
                            "
                          >
                            <v-radio-group
                              @change="calcNoOfChanges()"
                              v-model="currentSlot.monthly_pattern_type"
                              label=""
                            >
                              <v-row class="align-center"
                                ><v-col>
                                  <v-radio :value="0" label="Day"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    class="shrink"
                                    v-model="currentSlot.monthly_day"
                                    type="Number"
                                    @input="calcNoOfChanges()"
                                    :disabled="
                                      currentSlot.monthly_pattern_type != 0
                                    "
                                  ></v-text-field
                                ></v-col>
                                <v-col>of every</v-col>
                                <v-col>
                                  <v-text-field
                                    class="shrink"
                                    v-model="currentSlot.monthly_every_x_month"
                                    type="Number"
                                    @input="calcNoOfChanges()"
                                    :disabled="
                                      currentSlot.monthly_pattern_type != 0
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  >month{{
                                    currentSlot.monthly_every_x_month > 1
                                      ? "s"
                                      : ""
                                  }}</v-col
                                >
                              </v-row>
                              <v-row class="align-center"
                                ><v-col
                                  ><v-radio :value="1" label="The"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="monthly_weekday_type"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.monthly_weekday_type"
                                    :disabled="
                                      currentSlot.monthly_pattern_type != 1
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="weekdays"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.monthly_weekday"
                                    :disabled="
                                      currentSlot.monthly_pattern_type != 1
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col>of every</v-col>
                                <v-col>
                                  <v-text-field
                                    @input="calcNoOfChanges()"
                                    class="shrink"
                                    v-model="currentSlot.monthly_every_x_month"
                                    type="Number"
                                    :disabled="
                                      currentSlot.monthly_pattern_type != 1
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  >month{{
                                    currentSlot.monthly_every_x_month > 1
                                      ? "s"
                                      : ""
                                  }}</v-col
                                >
                              </v-row>
                            </v-radio-group>
                          </div>
                          <div
                            v-show="currentSlot.recurrence_pattern === 'yearly'"
                          >
                            <v-row class="align-center">
                              <v-col cols="2">Recur every </v-col>
                              <v-col cols="2">
                                <v-text-field
                                  @input="calcNoOfChanges()"
                                  class="shrink"
                                  v-model="currentSlot.yearly_every_x_years"
                                  type="Number"
                                ></v-text-field
                              ></v-col>
                              <v-col
                                >year{{
                                  currentSlot.yearly_every_x_years > 1
                                    ? "s"
                                    : ""
                                }}</v-col
                              >
                            </v-row>
                            <v-radio-group
                              @change="calcNoOfChanges()"
                              v-model="currentSlot.yearly_pattern_type"
                              label=""
                            >
                              <v-row class="align-center">
                                <v-col cols="2">
                                  <v-radio :value="0" label="On:"></v-radio>
                                </v-col>
                                <v-col cols="2">
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="daysPerMonth"
                                    item-text="v"
                                    item-value="v"
                                    v-model="currentSlot.yearly_day"
                                    :disabled="
                                      currentSlot.yearly_pattern_type != 0
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="months"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.yearly_on_month"
                                    :disabled="
                                      currentSlot.yearly_pattern_type != 0
                                    "
                                  ></v-select>
                                </v-col>
                              </v-row>

                              <v-row class="align-center">
                                <v-col cols="2"
                                  ><v-radio
                                    :value="1"
                                    label="On the:"
                                  ></v-radio>
                                </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="monthly_weekday_type"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.yearly_weekday_type"
                                    :disabled="
                                      currentSlot.yearly_pattern_type != 1
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="weekdays"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.yearly_weekday"
                                    :disabled="
                                      currentSlot.yearly_pattern_type != 1
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col cols="1">of </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="months"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.yearly_on_month"
                                    :disabled="
                                      currentSlot.yearly_pattern_type != 1
                                    "
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </div>
                        </v-col>
                      </v-row>
                      <div v-show="currentSlot.recurrence_pattern !== 'none'">
                        <v-row class="align-start">
                          <v-col cols="12" style="border-top: 2px solid #eee"
                            >Range of recurrence</v-col
                          >
                        </v-row>
                        <v-radio-group
                          @change="calcNoOfChanges()"
                          v-model="currentSlot.range_type"
                        >
                          <v-row class="align-center">
                            <v-col cols="1">Start: </v-col>
                            <v-col cols="3">
                              <mksdatesimple
                                ref="start_date2"
                                :date="currentSlot.start_date"
                                :fieldname="'start_date'"
                                @updatedate="updatedate"
                                :elemError="elemErrors['start_date2']"
                              >
                              </mksdatesimple>
                            </v-col>
                            <v-col cols="2">
                              <v-radio :value="0" label="End by:"></v-radio>
                            </v-col>
                            <v-col cols="3">
                              <mksdatesimple
                                ref="end_date2"
                                :date="currentSlot.end_date"
                                :fieldname="'end_date'"
                                @updatedate="updatedate"
                                :elemError="elemErrors['end_date2']"
                                :disabled="currentSlot.range_type != 0"
                              >
                              </mksdatesimple>
                            </v-col>
                          </v-row>
                          <v-row class="align-center">
                            <v-col cols="4"> </v-col>
                            <v-col cols="2">
                              <v-radio :value="1" label="End after:"></v-radio
                            ></v-col>
                            <v-col cols="2">
                              <v-text-field
                                @input="calcNoOfChanges"
                                v-model="currentSlot.end_after_occurrences"
                                type="Number"
                                ref="end_after_occurrences"
                                clearable
                                :disabled="currentSlot.range_type != 1"
                                :style="
                                  elemErrors['end_after_occurrences']
                                    ? 'background-color:#fee;'
                                    : ''
                                "
                                :hint="elemErrors['end_after_occurrences']"
                                persistent-hint
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3"> occurences </v-col>
                          </v-row>
                          <v-row class="align-center">
                            <v-col cols="4"> </v-col>
                            <v-col cols="2"
                              ><v-radio
                                :value="2"
                                label="No end date"
                              ></v-radio>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </div>
                      <v-row
                        v-show="
                          currentSlot.slot_errors &&
                          currentSlot.slot_errors.length > 0
                        "
                      >
                        <v-col cols="12" class="warning">
                          <ul>
                            <li
                              v-for="(e, index) in currentSlot.slot_errors"
                              v-bind:key="'error_li_' + index"
                              v-html="e.split('|')[0]"
                            ></li>
                          </ul>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmDialogShow"
      content-class="v-dialog-mks"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title>
          <span class="mksheadline">You have unsaved changes!</span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="confirmDialogShow = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>Close anyway?</v-card-text>
        <v-card-actions>
          <v-btn class="mr-4 selected" color="error" @click="$emit('close')"
            ><span> Close Item and discard Changes</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mr-4" color="info" @click="confirmDialogShow = false"
            >return to item</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showNotMet"
      content-class="notmet"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title>
          <span class="mksheadline">reservation requests not met</span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="showNotMet = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row>
                <v-col>Outside</v-col>
              </v-row>
              <v-row>
                <v-col style="height: 600px; overflow-y: auto">
                  <v-list>
                    <v-list-item v-for="o in currentSlot.outside" :key="o">
                      <v-list-item-content>
                        {{ o }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col>Overlap</v-col>
              </v-row>
              <v-row>
                <v-col style="height: 600px; overflow-y: auto">
                  <v-list>
                    <v-list-item v-for="o in currentSlot.overlap" :key="o">
                      <v-list-item-content>
                        {{ o }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mr-4" color="info" @click="showNotMet = false"
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showExceptions"
      content-class="v-dialog-mks"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title>
          <span class="mksheadline">This Series Exceptions</span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="showExceptions = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="slotExceptionsTableHeaders"
            :items="slotExceptions"
            :items-per-page="10"
            :options.sync="slotExceptionsTableOptions"
            class="elevation-1"
          >
            <template v-slot:item="{ item, index }">
              <tr @click="toggleRowDeleted(item)" :key="index">
                <td>{{ formatDate(item.start_orig, true) }}</td>
                <td>{{ formatDate(item.start, true) }}</td>
                <td>{{ formatDate(item.end, true) }}</td>
                <td><v-icon v-if="item.canceled">cancel</v-icon></td>
                <td>
                  <v-checkbox
                    dense
                    v-model="item.reset"
                    @click.stop
                    hide-details
                  ></v-checkbox>
                </td>
              </tr>
            </template>

            <template v-slot:header.reset="{ props }">
              <v-checkbox
                dense
                v-model="selectAllReset"
                @change="toggleSelectAll"
                :indeterminate="indeterminate"
                :value="allSelected"
                hide-details
                label="reset"
              ></v-checkbox>
            </template>

            <template v-slot:item.canceled="{ item }">
              <v-icon v-if="item.canceled">cancel</v-icon>
            </template>

            <!-- Slot for the "deleted" column cells with checkboxes @change="onCheckboxChange(item)" -->
            <template v-slot:item.reset="{ item }">
              <v-checkbox dense v-model="item.reset" hide-details></v-checkbox>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mr-4"
            color="warning"
            @click="resetSelected()"
            :disabled="toBeReset.length === 0"
            :loading="saving"
            >reset selected</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn class="mr-4" color="info" @click="showExceptions = false"
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.notmet {
  width: 80% !important;
}
</style>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import mksslottimeonly from "@/components/mksslottimeonly.vue";
import mksdatesimple from "@/components/mksdatesimple.vue";

export default {
  name: "mksresourcereservation",

  components: {
    mksslottimeonly,
    mksdatesimple,
  },

  data: () => ({
    showPast: false,
    saving: false,
    items: [],

    isLoadingPers: false,
    persmodel: { v: "", id: null },
    people: [{ v: "", id: null }],
    searchPers: "",

    isLoadingProj: false,
    projmodel: { v: "", id: null },
    projects: [{ v: "", id: null }],
    searchProj: "",

    searchReservation: "",

    confirmDialogShow: false,
    headlineclass: "",
    slotType: "available",
    loading: false,
    //bundle: { "TimeSlotsBookedRules": [], "details": { "id_resource_bundle": null, "bundle_title": null, "description": null, "id_resource_root": null }, "leaves": [] },
    // resource: { "TimeSlotsBookedRules": [], details: { bundle_title: '' } },
    selectedSlotIndex: 0,
    oldSelectedSlotIndex: 0,
    currentSlot: {
      from: "00:00",
      to: "00:00",
      changed: false,
    },
    monthly_weekday_type: [
      { v: 0, n: "first" },
      { v: 1, n: "second" },
      { v: 2, n: "third" },
      { v: 3, n: "fourth" },
      { v: 4, n: "last" },
    ],
    weekdays: [
      { v: 0, n: "monday" },
      { v: 1, n: "tuesday" },
      { v: 2, n: "wednesday" },
      { v: 3, n: "thursday" },
      { v: 4, n: "friday" },
      { v: 5, n: "saturday" },
      { v: 6, n: "sunday" },
    ],
    months: [
      { v: 1, n: "January", m: 31 },
      { v: 2, n: "February", m: 29 },
      { v: 3, n: "March", m: 31 },
      { v: 4, n: "April", m: 30 },
      { v: 5, n: "May", m: 31 },
      { v: 6, n: "June", m: 30 },
      { v: 7, n: "July", m: 31 },
      { v: 8, n: "August", m: 31 },
      { v: 9, n: "September", m: 30 },
      { v: 10, n: "October", m: 31 },
      { v: 11, n: "November", m: 30 },
      { v: 12, n: "December", m: 31 },
    ],
    menu1: false,
    menu2: false,
    endSmallerStarterror: false,
    // hasChanges: false,
    noOfChanges: 0,
    alldaytmpvalues: [],
    resetElemError: [],
    elemErrors: [],
    newSlotTemplate: {},
    TimeSlotsBookedRulesCompare: [],

    type: "week",
    types: ["month", "week"], //'day', '4day'],
    mode: "stack",
    modes: ["stack", "column"],
    cal_weekday: [1, 2, 3, 4, 5, 6, 0],
    cal_weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      // { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    calendarvalue: "",
    events: [],
    showNotMet: false,
    slotExceptionsTableHeaders: [
      { text: "original start", value: "start_orig" },
      { text: "new start", value: "start" },
      { text: "new end", value: "end" },
      { text: "canceled", value: "canceled" },
      { text: "reset", value: "reset", sortable: false },
    ],
    slotExceptions: [],
    showExceptions: false,
    slotExceptionsTableOptions: {
      sortBy: ["start_orig"],
      sortDesc: [false], // false for ascending, true for descending
    },
    selectAllReset: false,
  }),

  props: {
    bundle: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    this.newSlotTemplate = {
      ...this.bundle.TimeSlotsBookedRules[
        this.bundle.TimeSlotsBookedRules.length - 1
      ],
    };

    this.resetSelectedSlotIndex();
    this.calcNoOfChanges();
    this.copyOrigRulesForComapre();
  },

  methods: {
    resetSelectedSlotIndex() {
      this.selectedSlotIndex = this.listToShow.findIndex(
        (bs) =>
          bs.id_resource_bundle_reservation_slot ===
          this.editquery.id_booked_slot
      );
      this.setCurrentSlot();
    },

    copyOrigRulesForComapre() {
      this.TimeSlotsBookedRulesCompare = JSON.parse(
        JSON.stringify(this.bundle.TimeSlotsBookedRules)
      );
    },
    formatDate(originalString, short = false) {
      if (originalString === null) {
        return "";
      }
      const date = new Date(originalString);

      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");

      const weekday = date.toLocaleDateString("en-US", { weekday: "long" });

      if (short) {
        return `${day}.${month}.${year} ${hours}:${minutes}`;
      } else {
        return `${day}.${month}.${year} ${hours}:${minutes} (${weekday})`;
      }
    },

    keyupPers(event) {
      if (
        !event.altKey &&
        (event.keyCode > 44 || event.keyCode === 32 || event.keyCode === 8)
      ) {
        this.throttledMethodPers();
        this.$refs.txtfldPers.isMenuActive = true;
      }
    },
    keyupProj(event) {
      if (
        !event.altKey &&
        (event.keyCode > 44 || event.keyCode === 32 || event.keyCode === 8)
      ) {
        this.throttledMethodProj();
        this.$refs.txtfldProj.isMenuActive = true;
      }
    },
    copyToClipboard(id) {
      navigator.clipboard.writeText(id).then(
        () => {
          this.$showMessage({ content: id, color: "info", timeout: 1500 });
        },
        () => {
          this.$showMessage({
            content: "failed to copy",
            color: "error",
            timeout: 1500,
          });
        }
      );
    },

    throttledMethodPers: _.debounce(function () {
      this.getOptionsPers();
    }, 300),

    throttledMethodProj: _.debounce(function () {
      this.getOptionsProj();
    }, 300),

    getOptionsPers() {
      if (this.isLoadingPers) return;

      var tempthis = this;
      this.isLoadingPers = true;

      this.$http
        .post("api/combopers", {
          search: this.searchPers,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            tempthis.people = response.data.results.data;
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        })
        .finally(() => {
          tempthis.isLoadingPers = false;
          // this.detailsMode = "default";
        });
    },
    getOptionsProj(recalcChanges = false) {
      if (this.isLoadingProj) return;

      var tempthis = this;
      this.isLoadingProj = true;

      this.$http
        .post("api/comboproj", {
          search: this.searchProj,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            tempthis.projects = response.data.results.data;
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        })
        .finally(() => {
          tempthis.isLoadingProj = false;
          // this.detailsMode = "default";
          if (recalcChanges) {
            this.calcNoOfChanges();
          }
        });
    },
    getExceptions() {
      if (this.isLoading) return;

      this.isLoading = true;

      this.$http
        .post("api/slotexceptionsget", {
          query: this.editquery,
          id: this.currentSlot.id_resource_bundle_reservation_slot,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.slotExceptions = response.data.results;
            this.selectAllReset = false;
            this.toggleSelectAll();
            this.showExceptions = true;
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetNewSlot() {
      this.bundle.TimeSlotsBookedRules[
        this.bundle.TimeSlotsBookedRules.length - 1
      ] = this.newSlotTemplate;
      this.calcNoOfChanges();
      this.setCurrentSlot();
    },
    alldaytoggle() {
      if (this.currentSlot.all_day) {
        this.alldaytmpvalues[this.selectedSlotIndex] = {
          from: this.currentSlot.from,
          to: this.currentSlot.to,
        };
        this.currentSlot.from = "00:00";
        this.currentSlot.to = "00:00";
      } else {
        if (this.alldaytmpvalues[this.selectedSlotIndex]) {
          this.currentSlot.from =
            this.alldaytmpvalues[this.selectedSlotIndex].from;
          this.currentSlot.to = this.alldaytmpvalues[this.selectedSlotIndex].to;
        }
      }
      this.calcNoOfChanges();
    },
    deleteReservationSlot() {
      this.currentSlot.delete = !this.currentSlot.delete;
      this.calcNoOfChanges();
    },
    updatedate(newdateinfo) {
      if (!this.loading) {
        this.currentSlot[newdateinfo.fieldname] = newdateinfo.date;
        if (newdateinfo.fieldname === "start_date") {
          var sdate = new Date(this.currentSlot["start_date"]);
          var edate = new Date(this.currentSlot["end_date"]);

          if (
            (this.currentSlot.recurrence_pattern === "none" ||
              this.currentSlot.range_type === 0) &&
            sdate > edate
          ) {
            this.currentSlot["end_date"] = this.currentSlot["start_date"];
          }
        }
        this.calcNoOfChanges();
      }
    },
    prepDurationFrom(value) {
      var diff = this.timeDifferenceInMinutes(this.currentSlot.from, value);
      this.currentSlot.from = value;
      this.currentSlot.to = this.addMinutesToTime(this.currentSlot.to, diff);
    },
    prepDurationTo(value) {
      if (this.timeDifferenceInMinutes(this.currentSlot.from, value) < 0) {
        this.currentSlot.to = this.currentSlot.from;
      } else {
        this.currentSlot.to = value;
      }
      this.calcNoOfChanges();
    },
    timeDifferenceInMinutes(timeStr1, timeStr2) {
      // Parse time strings to Date objects
      const time1 = new Date("1970-01-01T" + timeStr1 + "Z");
      const time2 = new Date("1970-01-01T" + timeStr2 + "Z");

      // Calculate time difference in milliseconds
      const timeDifference = time2 - time1;

      // Convert time difference to minutes
      const minutesDifference = timeDifference / (1000 * 60);

      return minutesDifference;
    },
    addMinutesToTime(timeStr, minutesToAdd) {
      // Parse time string to Date object
      const baseTime = new Date("1970-01-01T" + timeStr + "Z");

      // Add minutes to the base time
      const newTime = new Date(baseTime.getTime() + minutesToAdd * 60 * 1000);

      // Format the new time as a time-only string
      const formattedTime = newTime.toISOString().substr(11, 8);

      return formattedTime;
    },

    setCurrentSlot() {
      if (this.currentSlot) {
        if (this.oldSelectedSlotIndex > this.listToShow.length - 1) {
          this.oldSelectedSlotIndex = this.listToShow.length - 1;
        }

        if (this.selectedSlotIndex > this.listToShow.length - 1) {
          this.selectedSlotIndex = this.listToShow.length - 1;
        }

        this.currentSlot = this.listToShow[this.selectedSlotIndex];
        if (!this.currentSlot) {
          this.currentSlot = { from: "00:00", to: "00:00", slot_errors: [] };
          this.copyOrigRulesForComapre();
        } else {
          this.persmodel = {
            id: this.currentSlot.id_person,
            v: this.currentSlot.name,
          };
          this.projmodel = {
            id: this.currentSlot.id_project,
            v: this.currentSlot.project,
          };
        }

        // error handling

        this.elemErrors = [];

        for (var ec = 0; ec < this.currentSlot.slot_errors.length; ec++) {
          var parts = this.currentSlot.slot_errors[ec].split("|");
          var message = parts[0];
          if (parts.length > 1) {
            parts = parts[1].split(",");
            for (var p = 0; p < parts.length; p++) {
              var elems = Object.keys(this.$refs).filter(
                (k) => k.substr(0, parts[p].length) === parts[p]
              );
              for (var i = 0; i < elems.length; i++) {
                this.resetElemError.push(elems[i]);
                this.elemErrors[elems[i]] = message;
              }
            }
          }
        }
        this.getOptionsPers();
        this.getOptionsProj();
        if (
          this.$refs["slot" + this.selectedSlotIndex] &&
          this.$refs["slot" + this.selectedSlotIndex].$el &&
          this.$refs["slot" + this.selectedSlotIndex].$el.scrollIntoView
        ) {
          this.$refs["slot" + this.selectedSlotIndex].$el.scrollIntoView();
        }

        this.events = this.bundle.TimeSlotsOfferedAsEventList.concat(
          this.bundle.TimeSlotsBookedAsEventList
        );

        this.$nextTick(() => {
          if (this.$refs.calendar) {
            this.$refs.calendar.scrollToTime("08:00");
          }
        });
        if (
          this.currentSlot.id_resource_bundle_reservation_slot === 0 &&
          this.currentSlot.id_person === 0
        ) {
          this.$nextTick(() => {
            this.$refs.txtfldPers.focus();
            this.$refs.txtfldPers.isMenuActive = false;
          });
        }
      }
    },

    saveSlots() {
      this.setCurrentSlot();
      this.calcNoOfChanges();

      if (this.currentSlot.id_person === 0) {
        this.$showMessage({
          content: "Please fill in the requesting person.",
          color: "warning",
        });
        this.$refs.txtfldPers.focus();
        this.$refs.txtfldPers.isMenuActive = true;
        return;
      }

      if (this.noOfChanges === 0) {
        this.$showMessage({
          content: "There are no changes to save.",
          color: "warning",
        });
        return;
      }
      var tempthis = this;

      // prep date format
      this.saving = true;

      var rulesChanged = this.bundle.TimeSlotsBookedRules.filter(
        (r) => r.changed
      );

      rulesChanged = JSON.parse(JSON.stringify(rulesChanged)); // FORCE CHANGED VALUE PERSISTENCE

      this.$http
        .post("api/ResourceReservationSlotsSave", {
          query: this.editquery,
          rulesChanged: rulesChanged,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else if (response.data.bundle) {
            this.bundle.TimeSlotsBookedRules =
              response.data.bundle.TimeSlotsBookedRules;
            this.bundle.hasSlotErrors = response.data.bundle.hasSlotErrors;

            if (this.bundle.hasSlotErrors == true) {
              this.$showMessage({
                content: "One or more reservations contain errors.",
                color: "warning",
              });
              this.setCurrentSlot();
            } else {
              this.newSlotTemplate = {
                ...this.bundle.TimeSlotsBookedRules[
                  this.bundle.TimeSlotsBookedRules.length - 1
                ],
              };
              this.copyOrigRulesForComapre();
              this.hasChanges = false;
              this.setCurrentSlot();

              this.$showMessage({
                content: "Changes saved.",
                color: "success",
                timeout: 1500,
              });
              this.$nextTick(() => {
                this.$nextTick(() => {
                  this.copyOrigRulesForComapre();
                  this.calcNoOfChanges();
                });
              });
            }
          } else {
            this.show = false;
            this.$showMessage({
              content: "bundle not found",
              color: "warning",
            });
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;

          var parts = message.split("|");
          if (parts.length > 1) {
            var elems = Object.keys(tempthis.$refs).filter(
              (k) => k.substr(0, parts[1].length) === parts[1]
            );
            // var elem = tempthis.$refs[parts[1]];
            for (var i = 0; i < elems.length; i++) {
              var elem = tempthis.$refs[elems[i]];
              if (
                elem &&
                elem.$refs &&
                elem.$refs.txtfld &&
                elem.$refs.txtfld.$refs.input
              ) {
                elem.$refs.txtfld.$refs.input.focus();
              }
            }
          }
          this.$showMessage({ content: parts[0], color: "error" });
        })
        .finally(() => {
          this.loading = false;
          this.saving = false;
        });
    },
    personChanged() {
      if (this.persmodel) {
        this.currentSlot.id_person = this.persmodel.id;
        this.currentSlot.name = this.persmodel.v;
      }
      this.calcNoOfChanges();
    },
    projectChanged() {
      if (this.projmodel) {
        this.currentSlot.id_project = this.projmodel.id;
        this.currentSlot.project = this.projmodel.v;
      }
      this.calcNoOfChanges();
    },
    calcNoOfChanges() {
      // for (var c = 0; c < this.bundle.TimeSlotsBookedRules.length; c++) {
      // var comp = this.TimeSlotsBookedRulesCompare.find(s => s.id_resource_bundle_reservation_slot === this.bundle.TimeSlotsBookedRules[c].id_resource_bundle_reservation_slot);
      var comp = this.TimeSlotsBookedRulesCompare.find(
        (s) =>
          s.id_resource_bundle_reservation_slot ===
          this.currentSlot.id_resource_bundle_reservation_slot
      );
      if (comp) {
        var diff = false;
        var elems = Object.keys(comp).filter(
          (k) =>
            k !== "changed" &&
            k !== "slot_errors" &&
            k !== "exceptions" &&
            k !== "outside" &&
            k !== "overlap"
        );
        // var elem = tempthis.$refs[parts[1]];
        for (var i = 0; i < elems.length; i++) {
          if (elems[i] === "from" || elems[i] === "to") {
            if (comp[elems[i]].length < this.currentSlot[elems[i]].length) {
              comp[elems[i]] += ":00";
            }
            if (comp[elems[i]].length > this.currentSlot[elems[i]].length) {
              comp[elems[i]] = comp[elems[i]].substr(0, 5);
            }
          }
          if (comp[elems[i]] !== this.currentSlot[elems[i]]) {
            diff = true;
            break;
          }
        }
        comp.changed = diff;
        this.currentSlot.changed = diff;
      }

      this.noOfChanges =
        this.TimeSlotsBookedRulesCompare.filter((s) => s.changed).length +
        this.bundle.TimeSlotsBookedRules.filter((s) => s.delete).length;
      // }
    },
    // onCheckboxChange(item) {
    //   // Handle individual checkbox change if needed
    // },
    toggleRowDeleted(item) {
      item.reset = !item.reset;
    },
    // Method to toggle select all checkboxes
    toggleSelectAll() {
      const value = this.selectAllReset;
      this.slotExceptions.forEach((slot) => {
        slot.reset = value;
      });
    },

    resetSelected() {
      if (this.saving) return;

      this.saving = true;

      this.$http
        .post("api/slotexceptionsreset", {
          query: this.editquery,
          id: this.currentSlot.id_resource_bundle_reservation_slot,
          toBeReset: this.toBeReset,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.slotExceptions = response.data.results;
            this.currentSlot.hasE =
              response.data.results &&
              Array.isArray(response.data.results) &&
              this.slotExceptions.length > 0;

            this.TimeSlotsBookedRulesCompare[this.selectedSlotIndex] =
              JSON.parse(JSON.stringify(this.currentSlot));

            this.showExceptions = false;
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },

  computed: {
    ...mapState(["currentUser", "currenthubsubscriptions"]),

    saveDisabled() {
      return (
        this.noOfChanges === 0 &&
        this.currentSlot.id_resource_bundle_reservation_slot !== 0
      );
    },

    // Computed property to check if all items are selected
    allSelected() {
      return this.items.every((item) => item.reset);
    },

    listToShow() {
      if (this.searchReservation === "") {
        return this.bundle.TimeSlotsBookedRules.filter((s) =>
          !this.showPast ? s.past === false : true
        );
      } else {
        return this.bundle.TimeSlotsBookedRules.filter(
          (slot) =>
            slot.name &&
            slot.name
              .toLowerCase()
              .includes(this.searchReservation.toLowerCase())
        ).filter((s) => (!this.showPast ? s.past === false : true));
      }
    },

    toBeReset() {
      if (
        this.slotExceptions &&
        Array.isArray(this.slotExceptions) &&
        this.slotExceptions.length > 0
      ) {
        return this.slotExceptions
          .filter((slot) => slot.reset)
          .map((slot) => slot.id_resource_bundle_reservation_slot_exception);
      } else {
        return [];
      }
    },
    // Computed property to check if some items are selected
    indeterminate() {
      return (
        !this.allSelected && this.slotExceptions.some((slot) => slot.reset)
      );
    },

    daysPerMonth() {
      var days = [];
      var yearly_on_month = this.months.find(
        (m) => m.v === this.currentSlot.yearly_on_month
      );
      if (yearly_on_month) {
        var maxdays = yearly_on_month.m;

        for (var i = 1; i <= maxdays; i++) {
          days.push({ v: i });
        }
        return days;
      } else {
        return null;
      }
    },
    dlgStyle: {
      get() {
        return this.bgcolor !== "" ? "background-color:" + this.bgcolor : "";
      },
    },

    show: {
      get() {
        return true;
      },
      set(value) {
        if (!value) {
          var changes = this.bundle.TimeSlotsBookedRules.filter(
            (s) => s.changed || s.delete
          );
          if (changes.length === 0) {
            this.$emit("close");
          } else {
            //this.$showMessage({ content: "unsaved changes detected", color: "warning" });
            this.confirmDialogShow = true;
          }
        }
      },
    },
  },
  watch: {
    selectedSlotIndex: {
      handler() {
        this.setCurrentSlot();
        this.oldSelectedSlotIndex = this.selectedSlotIndex;
      },
    },

    currentSlot: {
      handler() {
        if (this.currentSlot) {
          this.endSmallerStarterror = false;

          if (
            this.currentSlot.start_date &&
            this.currentSlot.end_date &&
            this.currentSlot.start_date.substr(0, 10) !== "0001-01-01" &&
            this.currentSlot.end_date.substr(0, 10) !== "0001-01-01"
          ) {
            const [sYear, sMonth, sDay] = this.currentSlot.start_date
              .split(" ")[0]
              .split("-");
            const [eYear, eMonth, eDay] = this.currentSlot.end_date
              .split(" ")[0]
              .split("-");

            var cd1 = new Date(sYear, sMonth, sDay);
            var cd2 = new Date(eYear, eMonth, eDay);
            this.endSmallerStarterror = cd2 < cd1;
          }

          // this.calendarvalue = this.currentSlot.start_date;

          this.calcNoOfChanges();
        }
      },
      deep: true,
    },

    showPast: {
      handler() {
        this.resetSelectedSlotIndex();
      },
    },
  },
};
</script>
