import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    currentUser: null,
    appheading: null,
    snackbarContent: '',
    snackbarColor: '',
    currentDialog: null,
    openDialogs: [],
    selectedItems: [],
    markedItems: [],
    lastKeyUp: null,
    urlParams: null,
    previewdata: {}
  },

  getters: {
    getCurrentDialog: state => {
      return state.currentDialog;
    },

    getUrlParams: state => {
      return state.urlParams;
    },

    getSelectedItems: (state) => (id_query) => {
      return state.selectedItems.filter(item => item.id_query === id_query && item.isSelected).map(itemY => { return itemY.id; });
    },

    getMarkedItems: (state) => (id_query) => {
      const queryObject = state.markedItems.find(item => item.id_query === id_query);
      if (!queryObject) {
        return [];
      }
      else {
        return queryObject.ids.filter(item => item.marked).map(markedItem => markedItem.id);
      }
    },

  },
  mutations: {
    setUser: (state, newValue) => {
      state.currentUser = newValue
    },

    setUrlParams: (state, newValue) => {
      state.urlParams = newValue
    },

    setCurrentDialog: (state, newValue) => {
      state.currentDialog = newValue
    },

    snackbarShowMessage(state, payload) {
      state.snackbarContent = payload.content
      state.snackbarColor = payload.color
      state.timeout = payload.timeout
    },

    setpreviewdata(state, payload) {
      state.previewdata = payload
    },

    setLastKeyUp: (state, newValue) => {
      state.lastKeyUp = newValue
    },

    setSelectedItems: (state, newValue) => {
      if (!newValue.isSelected) {
        state.selectedItems = state.selectedItems.filter(item => !(item.id_query === newValue.id_query && item.id === newValue.id))
      }
      else {
        state.selectedItems.push(newValue)
      }
    },

    setMarkedItems: (state, newValue) => {

      let queryObject = state.markedItems.find(item => item.id_query === newValue.id_query);
      if (!queryObject) {
        queryObject = [];
        state.markedItems.push({ id_query: newValue.id_query, ids: [] });
      }

      newValue.ids.forEach(newItem => {
        // Find existing item by id
        const existingItem = queryObject.ids.find(item => item.id === newItem.id);
        if (existingItem) {
          existingItem.marked = newItem.marked;
        } else {
          queryObject.ids.push(newItem);
        }
      });
    },

    clearMarkedItems: (state, id_query) => {

      let queryObject = state.markedItems.find(item => item.id_query === id_query);
      if (!queryObject) {
        queryObject = [];
        state.markedItems.push({ id_query: id_query, ids: [] });
      }
      else {
        queryObject.ids.forEach(id => {
          id.marked = false;
        });
      }
    },

    setAppheading: (state, newValue) => {
      state.appheading = newValue
    },

  },
  actions: {
    setUser: ({ commit }, newValue) => {
      commit('setUser', newValue)
    },

    setUrlParams: ({ commit }, newValue) => {
      commit('setUrlParams', newValue)
    },

    setCurrentDialog: ({ commit }, newValue) => {
      commit('setCurrentDialog', newValue)
    },

    setAppheading: ({ commit }, newValue) => {
      commit('setAppheading', newValue)
    },

    setLastKeyUp: ({ commit }, newValue) => {
      commit('setLastKeyUp', newValue)
    },
  }

})