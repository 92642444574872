<template>
  <div>
    <v-combobox
      :disabled="field.d || field.print"
      :filled="field.d || field.print"
      v-model="model"
      no-filter
      :background-color="field.bc"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      hide-no-data
      item-text="v"
      item-value="sid"
      :outlined="!alternatelayout"
      :dense="!alternatelayout"
      :prepend-inner-icon="field.s === 'id_location' ? 'place' : ''"
      clearable
      auto-select-first
      open-on-clear
      return-object
      validate-on-blur
      ref="txtfld"
      :rules="requiredRule"
      @keyup="keyup($event)"
      @click:clear="getOptions('', 0)"
      @focus="onFocus"
      :messages="message"
    >
      <template
        slot="label"
        v-if="alternatelayout"
      >
        <span v-html="$mksLabel(field, alternatelayout)"></span>
      </template>
      <template
        v-slot:message="{ message }"
        v-if="!alternatelayout"
      >
        <span v-html="message"></span>
      </template>
      <template
        v-if="this.field.id_query && this.model && this.model.sid !== 0"
        slot="append-outer"
      >
        <v-icon
          link
          @click="openDetails"
          >north_east</v-icon
        >
      </template>

      <v-tooltip
        top
        slot="append"
        v-if="field.why"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-if="!field.print"
            class="outlined"
            v-on="on"
            style="opacity: 0.1"
          >
            lock
          </v-icon>
        </template>
        <span>{{ field.why }}</span>
      </v-tooltip>
      <v-icon
        :title="this.field.exturlicontitle"
        v-if="
          typeof this.field.exturl !== 'undefined' && this.field.exturl !== ''
        "
        slot="prepend"
        color="blue"
        @click="openExtUrl"
        >{{ this.field.exturlicon }}</v-icon
      >
      <template #item="{ item, on }">
        <v-list-item
          v-on="on"
          :class="
            'v-list-item v-list-item--link theme--light ' +
            (field.cff
              ? (item.match === 1 ? 'itemmatchauto' : 'itemdontmatchauto') +
                ($vuetify.theme.dark ? '_dark' : '')
              : '')
          "
        >
          <v-list-item-content class="v-list-item__content">
            <v-list-item-title
              class="v-list-item__title"
              v-html="$highlight(item.v, search)"
            >
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="field.separator"
              v-html="$highlight(item.subtitle, search)"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
    <mksdetails
      :editquery="editlnk"
      :visible="showDetails"
      :idfilter="{}"
      :calledFromDetails="false"
      @close="closeDetails"
    ></mksdetails>
    <mksdialogconfirm
      :confirmDialog="confirmDialog"
      @close="confirmResult"
    ></mksdialogconfirm>
  </div>
</template>

<script>
import _ from "lodash";
import mksdialogconfirm from "@/components/mksdialogconfirm.vue";

export default {
  name: "mksautocomplete",

  components: {
    mksdialogconfirm,
  },

  data: () => ({
    ignoreinput: false,
    detailsMode: "default",
    items: [],
    isLoading: false,
    model: { v: "", sid: null },
    search: null,
    tmpsearch: null,
    origval: null,
    initRunning: false,
    showDetails: false,
    editlnk: {},
    confirmDialog: { show: false },
    confirmDialogTemplate: [
      {
        show: false,
        action: "",
        header: "",
        text: "",
        buttonconfirmtext: "",
        buttoncanceltext: "",
      },
      {
        show: true,
        action: "addtolist",
        header: "",
        text: "",
        buttonconfirmtext: "add",
        buttoncanceltext: "cancel",
      },
    ],
  }),

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  mounted() {
    this.ignoreinput = true;
  },

  computed: {
    requiredRule: {
      get() {
        if (this.field.r) {
          return [
            (this.model && this.model.sid && !!this.model.sid) ||
              (!this.alternatelayout ? this.field.a : "") + " is required",
          ];
        } else {
          return [];
        }
      },
    },
    returnValue: {
      get() {
        var fieldval = this.model && this.model.sid ? this.model.sid : null;
        var show = this.model && this.model.v ? this.model.v : "";
        return {
          n: this.field.s,
          v: fieldval,
          show: show,
        };
      },
    },
    returnObject: {
      get() {
        return this.model;
      },
    },
    changed: {
      get() {
        if (this.field.forceload) {
          return true;
        }
        var fieldval =
          this.model && this.model.sid !== null ? this.model.sid : null;
        return this.origval != fieldval;
      },
    },
    message: {
      get() {
        return this.$mksLabel(this.field, !this.alternatelayout);
      },
    },
  },
  methods: {
    setFocus() {
      this.$nextTick(() => {
        if (this.$refs.txtfld) {
          this.$refs.txtfld.focus();
        }
      });
    },
    keyup(event) {
      if (
        !event.altKey &&
        (event.keyCode > 44 || event.keyCode === 32 || event.keyCode === 8)
      ) {
        this.throttledMethod();
        this.$refs.txtfld.isMenuActive = true;
      }
    },
    onFocus() {
      if (
        (this.reloadAfterDepFieldChange ||
          (this.items.length > 0 && this.items[0].sid === 0)) &&
        this.detailsMode !== "addtolist"
      ) {
        this.ignoresearchange = true;
        this.getOptions(this.search, 0);
      }
    },
    initialize() {
      this.initRunning = true;

      this.items = [this.field];
      this.model = this.items[0];
      if (!this.model) {
        this.model = { v: "", sid: null };
      }
      this.origval = this.model.sid;

      if (this.field.forceload) {
        this.getOptions("", this.model.sid);
      }
    },
    setUnsaved() {
      this.origval = null;
    },

    throttledMethod: _.debounce(function () {
      this.getOptions(this.search);
    }, 300),

    getOptions(val, sid = 0) {
      if (this.isLoading) return;

      if (
        this.editquery.tablename === "Visitors" &&
        this.field.n === "Card_ID"
      ) {
        if (val === null) {
          val = "";
        }
        val =
          val +
          "|" +
          document.querySelector("[fn='Access from']").querySelector("input")
            ._value +
          "|" +
          document.querySelector("[fn='Access until']").querySelector("input")
            ._value;
      }

      this.isLoading = true;
      var sv = "";

      if (sid === 0 && val) {
        sv = val;
        // var sv = val.replace(/\s\s+/g, " ").replace(/[*,\s]/g, "%");
        // var pos1 = val.indexOf("*");
        // if (pos1 === -1) pos1 = val.indexOf("%");
        // if (pos1 === -1) pos1 = val.indexOf(" ");

        // if (0 < pos1 && pos1 < val.length) {
        //   sv = "%" + sv + "%";
        // }
      } else {
        val = "";
      }

      var tempthis = this;

      this.$http
        .post("api/combo", {
          editquery: this.editquery,
          field: this.field,
          search: sv,
          sid: sid,
          rowcountonly: false,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            if (this.field.separator) {
              var pos1 = -1;
              response.data.results.data.forEach((elem) => {
                pos1 = elem.v.indexOf(this.field.separator);
                if (pos1 > 0) {
                  elem.subtitle = elem.v.substr(
                    pos1 + 1,
                    elem.v.length - pos1 - 2
                  );
                  elem.v = elem.v.substr(0, pos1 - 1);
                }
              });
            }

            tempthis.items = response.data.results.data;
          }
          tempthis.loading = false;
          tempthis.reloadAfterDepFieldChange = true;

          if (sid !== 0 || tempthis.detailsMode === "addtolist") {
            tempthis.model = tempthis.items[0];
          } else if (tempthis.field.search) {
            tempthis.model = tempthis.items.find(
              (x) => x.v === this.field.search
            );
          }

          if (tempthis.field.forcepreload && tempthis.items.length > 0) {
            this.ignoreinput = true;
            if (tempthis.editquery.id == 0) {
              tempthis.model = tempthis.items[0];
            } else if (tempthis.model && tempthis.model.sid) {
              tempthis.model = tempthis.items.find(
                (x) => x.sid === tempthis.model.sid
              );
            }
          }

          if (tempthis.field.d) {
            tempthis.field.v = tempthis.model.v;
          }

          if (tempthis.field.d || tempthis.field.print) {
            var selItem = tempthis.field
              ? tempthis.items.find((i) => i.sid == tempthis.field.sid)
              : null;
            if (selItem && selItem.included_fields) {
              tempthis.$emit("setIncludedProperties", selItem.included_fields);
            }
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        })
        .finally(() => {
          tempthis.isLoading = false;
          // this.detailsMode = "default";
        });
    },

    closeDetails(detailsSaved) {
      if (this.detailsMode === "addtolist") {
        this.field.sid = detailsSaved.id;
        var tmpthis = this;
        window.setTimeout(function () {
          tmpthis.$emit("closeSubDetails", false);
          tmpthis.showDetails = false;
          tmpthis.getOptions("", detailsSaved.id);
        }, 500);
        //this.throttledMethod();//this.getOptions(this.search, 0);
        this.detailsMode = "default";
      } else {
        this.$emit("closeSubDetails", detailsSaved);
        this.showDetails = false;
      }
    },
    openExtUrl() {
      if (this.field.exturl !== "") {
        window.open(this.field.exturl);
      }
    },
    openDetails() {
      if (this.model && this.model.sid !== null && this.model.sid !== 0) {
        this.detailsMode = "default";
        this.editlnk = {
          tablename: this.field.identifier.substr(3).replaceAll("_", " "),
          id_query: parseInt(this.field.id_query),
          id_link: parseInt(this.field.id_link),
          id: parseInt(this.model.sid),
          identifier: this.field.identifier ? this.field.identifier : "",
        };

        this.showDetails = true;
      }
    },
    confirm(action) {
      this.confirmDialog = this.confirmDialogTemplate.find(
        (x) => x.action === action
      );

      this.confirmDialog.header = "New " + this.field.a;
      this.confirmDialog.text =
        'Add "' + this.model + '" as new ' + this.field.a + " ?";
      this.confirmDialog.buttonconfirmtext = "add new value";
      this.confirmDialog.show = true;
    },
    confirmResult(action) {
      switch (action) {
        case "closeconfirmdialog":
          this.confirmDialog = this.confirmDialogTemplate[0];
          this.model = { v: "", sid: null };
          break;
        case "cancel":
          this.show = false;
          break;
        case "addtolist":
          this.detailsMode = "addtolist";
          this.tmpsearch = this.search;
          var aua = "";
          if (this.field.aua != "false") {
            var patterns = this.field.aua
              .replaceAll(", ", ",")
              .replaceAll(" ,", ",")
              .split("|");
            var patternParts = [];
            var valueParts = [];
            var pCounter = 0;

            patterns.every((p) => {
              if (p.indexOf(",") > -1 && this.model.indexOf(",") > -1) {
                patternParts = p.split(",");
                valueParts = this.model
                  .replaceAll(", ", ",")
                  .replaceAll(" ,", ",")
                  .split(",");
                patternParts.forEach((pPart) => {
                  if (pCounter < valueParts.length) {
                    aua +=
                      pPart.trim() + "|" + valueParts[pCounter++].trim() + ";";
                  }
                });
                return false;
              } else if (p.indexOf(" ") > -1 && this.model.indexOf(" ") > -1) {
                patternParts = p.split(" ");
                valueParts = this.model
                  .replaceAll(", ", ",")
                  .replaceAll(" ,", ",")
                  .split(" ");
                patternParts.forEach((pPart) => {
                  if (pCounter < valueParts.length) {
                    aua +=
                      pPart.trim() + "|" + valueParts[pCounter++].trim() + ";";
                  }
                });
                return false;
              } else if (p.indexOf(" ") == -1 && p.indexOf(",") == -1) {
                aua += p.trim() + "|" + this.model.trim() + ";";
                return false;
              }

              return true;
            });
          }

          this.editlnk = {
            tablename: this.field.identifier.substr(3).replaceAll("_", " "),
            id_query: parseInt(this.field.id_query),
            id_link: parseInt(this.field.id_link),
            id: 0,
            identifier: this.field.identifier ? this.field.identifier : "",
            aua: aua,
          };
          if (!aua.startsWith("proc~")) {
            this.editlnk.newvalfieldname = this.field.s.substr(3);
            this.editlnk.newvalfieldvalue = this.model;
          }

          this.showDetails = true;
          break;
      }
      this.confirmDialog = this.confirmDialogTemplate[0];
    },
  },
  watch: {
    model: {
      handler: function (newVal, oldVal) {
        if (
          (newVal !== null && oldVal === null) ||
          (newVal === null && oldVal !== null) ||
          newVal.sid !== oldVal.sid
        ) {
          var selItem = this.model
            ? this.items.find((i) => i.sid == this.model.sid)
            : null;
          if (selItem && selItem.included_fields) {
            this.$emit("setIncludedProperties", selItem.included_fields);
          }

          if (this.ignoreinput) {
            this.ignoreinput = false;
            return;
          }

          if (!this.field.d) {
            this.$emit("userinput", this.field);

            if (typeof this.model === "string") {
              if (this.field.aua != "false") {
                this.confirm("addtolist");
              } else {
                this.model = { v: "", sid: null };
              }
            }
          }
        }
      },
      deep: true,
    },
    field: {
      handler() {
        if (this.field.cffValue > 0) {
          this.reloadAfterDepFieldChange = true;
        }

        if (this.field.d) {
          this.field.v = this.model.v;
        }
        if (this.field.search) {
          this.$refs.txtfld.$refs.input.value = this.field.search;
          this.getOptions(this.field.search);
          // this.model.v = this.field.search
          // this.field.v = this.model.v;
        }
      },
      deep: true,
    },
  },
};
</script>
