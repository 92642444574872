<template>
  <v-app id="homedb" @mousemove.native="mousemove">
    <v-navigation-drawer v-model="drawer" :permanent="drawerPermanent" :temporary="drawerTemporary" app
      :dark="$vuetify.theme.dark" v-if="!printlayout">
      <template v-slot:prepend>
        <v-toolbar :dark="$vuetify.theme.dark">
          <v-tooltip right open-delay="700" close-delay="1000">
            <template v-slot:activator="{ on }">
              <v-autocomplete v-on="on" @keydown.esc="closeDrawer" ref="listselector" v-model="querycombo"
                :items="getQueriesOnly" :loading="isLoading" :search-input.sync="search" :dark="$vuetify.theme.dark"
                hide-no-data hide-selected item-text="title" item-value="id" placeholder="List Name" prepend-icon="search"
                return-object full-width auto-select-first clearable @change="selectQuery()" dense>
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>{{ item.title }}</v-list-item-content>
                  <v-list-item-icon @mousemove="toogleFunctionSetActive()">
                    <v-icon>{{ getFavMenuitems(item)[0].icon }}</v-icon>
                  </v-list-item-icon>
                </template>
              </v-autocomplete>
            </template>
            <span>Search for lists<br />Shortcut: Alt + X</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <v-list dense nav>
        <v-list-group v-for="(item, index) in queries" :key="item.id" :value="index == 0"
          v-show="item.children.length > 0" v-model="querylistgroupmodel[index]">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <draggable :list="item.children" @start="dragging = true" @end="draggingend()">
            <v-list-item style="padding-left: 8px" v-for="subItem in item.children"
              :key="subItem.parentId + ' ' + subItem.id" link v-model="subItem.active">
              <v-list-item-content @click.stop="changeQuery(subItem.id)" style="margin-left: 1em">
                <v-list-item-title v-text="subItem.title"></v-list-item-title>
              </v-list-item-content>
              <template>
                <v-menu v-model="subItem.showMenu" offset-y style="max-width: 600px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-icon v-bind="attrs" v-on="on">
                      <v-icon>reorder</v-icon>
                    </v-list-item-icon>
                  </template>

                  <v-list style="cursor: pointer">
                    <v-list-item v-for="(menuitem, index) in getFavMenuitems(subItem)" :key="index"
                      @click.stop="toggleFav(subItem)">
                      <v-list-item-icon>
                        <v-icon>{{ menuitem.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{
                        menuitem.title
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-list-item>
          </draggable>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-tooltip top v-if="
                  currentUser.dataprivacyurl &&
                  currentUser.dataprivacyurl !== null &&
                  currentUser.dataprivacyurl !== ''
                ">
                  <template v-slot:activator="{ on, attrs }">
                    <v-img max-width="24" max-height="24" v-bind="attrs" v-on="on" @click="opendataprivacy"
                      src="@/assets/dataprivacy.png" alt="privacy information"></v-img>
                  </template>
                  <span>Open data privacy information</span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-tooltip top v-if="
                  currentUser.termsandconditionsurl &&
                  currentUser.termsandconditionsurl !== null &&
                  currentUser.termsandconditionsurl !== ''
                ">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" @click="opentermsandconditions">edit_note</v-icon>
                  </template>
                  <span>Open terms &amp; conditions information</span>
                </v-tooltip>
              </v-col>
              <v-col style="text-align: right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" @click="resetSorting">restart_alt</v-icon>
                  </template>
                  <span>Reset Sorting to Default</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" @click="resetSortingAlphabetical">sort_by_alpha</v-icon>
                  </template>
                  <span>Sort List alphabetical</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-navigation-drawer>

    <v-app-bar app :color="
      'primary' + (this.$vuetify.theme.dark ? ' darken-4' : ' darken-1')
    " :dark="true" v-if="!printlayout">
      <v-app-bar-nav-icon color="white" v-if="!drawer" @click.stop="drawer = !drawer" />
      <v-toolbar-title style="color: white">{{ heading }} </v-toolbar-title>
      <!-- + ' - Breakpoint: ' + $vuetify.breakpoint.name -->
      <v-spacer></v-spacer>

      <v-menu v-if="currentUser.showLogout" bottom left close-on-click>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="white">logout</v-icon>
          </v-btn>
        </template>

        <v-list nav>
          <v-list-item>
            <v-list-item @click="logout()">LOG OUT</v-list-item>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon @click="showhelp = true">
        <v-icon color="white">help_outline</v-icon>
      </v-btn>

      <mksmenu></mksmenu>
      <mkshelp :visible="showhelp" @close="closeHelp"></mkshelp>
      <v-dialog v-model="showlogout" content-class="v-dialog-mks" no-click-animation persistent>
        <v-card>
          <v-card-title>
            <div style="text-align: center; width: 100%">Logout in process</div>
          </v-card-title>
          <v-card-text><v-sheet>
              <div style="text-align: center; width: 100%">
                <img src="@/assets/Logout.png" alt="logout" style="opacity: 0.1" />
              </div>
            </v-sheet> </v-card-text></v-card></v-dialog>
    </v-app-bar>

    <v-main>
      <v-container v-if="!printlayout" class="fill-height" fluid>
        <v-col>
          <router-view :id_query="id_query"></router-view>
        </v-col>
      </v-container>
      <mksdetailsprint :editquery="printlink" :visible="printlayout" :idfilter="{}" :calledFromDetails="false">
      </mksdetailsprint>
    </v-main>
    <!-- <v-footer color="indigo" app>
      <span class="white--text">&copy; 2020</span>
    </v-footer> -->
    <Snackbar></Snackbar>
    <v-snackbar v-model="showRefresh" absolute bottom right timeout="60000000">
      <v-layout row>
        <v-layout column align-end>
          A new Web Interface Version is available.
          <p>Click <strong>Refresh</strong> to update.</p>
        </v-layout></v-layout>

      <v-layout row>
        <v-layout column align-end>
          <v-btn class="accent" @click="refreshApp">Refresh</v-btn>
        </v-layout></v-layout>
    </v-snackbar>
    <mkspreview :clientX="clientX" :clientY="clientY"></mkspreview>
  </v-app>
</template>

<style scoped>
.fill-height {
  align-items: flex-start;
}
</style>

<style>
.itemmatchauto {
  background-color: #c5e1a5 !important;
}

.itemdontmatchauto {
  background-color: #e1a9a5 !important;
}

.itemmatchauto_dark {
  background-color: #556147 !important;
}

.itemdontmatchauto_dark {
  background-color: #614947 !important;
}

.mksoutlined {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  min-height: 40px;
}

.mksoutlined:hover {
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.pnt {
  cursor: pointer;
}

.mksheadline {
  margin-right: 12px;
}

.mksheadlineelement {
  margin-top: 0px;
  overflow-y: hidden;
  max-height: 45px;
  padding-top: 5px;
}
</style>

<script>
import Snackbar from "@/components/Snackbar.vue";
import mksmenu from "@/components/mksmenu.vue";
import mkshelp from "@/components/mkshelp.vue";

import draggable from "vuedraggable";
import { mapState } from "vuex";
import mksdetailsprint from "./components/mksdetailsprint.vue";
import mkspreview from "./components/mkspreview.vue";

function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;
  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId !== 0) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export default {
  components: {
    Snackbar,
    draggable,
    mksmenu,
    mkshelp,
    mksdetailsprint,
    mkspreview
  },

  data: () => ({
    clientX: 0,
    clientY: 0,
    printlayout: false,
    printlink: {},
    querylistgroupmodel: [],
    querylistsubitemmodel: [],
    drawer: null,
    showRefresh: false,
    id_query: 0,
    queries: [],
    showhelp: false,
    showlogout: false,
    isLoading: false,
    querycombo: null,
    search: null,
    selectedItem: null,
    showMenu: false,
    toogleFunctionActive: false,
    dragging: false,
    AlwaysHideListSelector: false,
    ShowListsinFavouritesAndDefaultFolder: false,
  }),
  created() {
    this.switchDarkMode();
    this.switchAlwaysHideListSelector();
    this.switchShowListsinFavouritesAndDefaultFolder();
    this.queries = JSON.parse(JSON.stringify(this.getItems));

    // var firstQuery = this.queries.find((x) => x.children.length > 0);
    // if (firstQuery !== null) {
    this.querylistgroupmodel[0] = true;

    // }
    // if (this.queries.length === 2 && this.queries[0].children.length === 0) {
    //   this.queries[1].active = true; // opens the single visible Nav group
    // }

    this.$store.dispatch(
      "setAppheading",
      (this.currentUser && this.currentUser.appheading
        ? this.currentUser.appheading
        : process.env.VUE_APP_TITLE) +
      (this.currentUser ? " - " + this.currentUser.initials : "")
    );

    this.$root.$on("changedSetting", (PrefID) => {
      switch (PrefID) {
        case "ShowListsinFavouritesAndDefaultFolder":
          this.RefreshUser({ params: { what: "refresh user" } });
          break;
      }
    });

    if (this.currentUser.allowedQueries.length > 0) {
      const urlParams = this.$readUrlParams(); //new URLSearchParams(window.location.search);

      if (urlParams.q) {
        this.id_query = parseInt(urlParams.q);
      } else if (
        this.queries.length > 0 &&
        this.queries[0].children.length > 0
      ) {
        var tquery = this.currentUser.allowedQueries.find(
          (x) => x.id === this.queries[0].children[0].id
        );
        // if (tquery.title === "Wiring") {
        //   if (this.$route.path != "/wiring") {
        //     this.$router.push("/wiring");
        //   }

        //   return;
        // }
        this.id_query = parseInt(tquery.id);
      } else {
        this.id_query = parseInt(
          this.currentUser.allowedQueries.find((x) => x.parentId !== 0).id
        );
      }

      this.changeQuery(this.id_query);

      if (urlParams.p && urlParams.p === 1) {
        this.printlayout = true;

        this.printlink = {
          tablename: "",
          id_query: urlParams.q,
          id_link: urlParams.l,
          id: urlParams.v,
          identifier: "",
          print: true,
        };
      }
    }

    this.$changesHub.joinHubGroup("U" + this.currentUser.id_query_user);

    this.$changesHub.$on("Changes", this.RefreshUser);
  },
  mounted() {
    this._evtListener = function (e) {
      if (e.type === "keyup" && e.target.placeholder !== "List Name") {
        this.$store.dispatch("setLastKeyUp", e);
      }
    };

    document.addEventListener("keyup", this._evtListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener("keyup", this._evtListener);
    this.$changesHub.$off("Changes", this.RefreshUser);
  },

  computed: {
    ...mapState([
      "currentUser",
      "currenthubsubscriptions",
      "appheading",
      "lastKeyUp",
    ]),

    heading() {
      return this.appheading;

      // if (this.currentUser) {
      //   return process.env.VUE_APP_TITLE + " - " + this.currentUser.initials;
      // } else {
      //   return process.env.VUE_APP_TITLE;
      // }
    },

    prefs() {
      return this.currentUser.prefs;
    },

    lastkeyEvent() {
      return this.keyEvent;
    },

    getItems() {
      if (this.currentUser) {
        return list_to_tree(
          this.currentUser.allowedQueries.filter(
            (x) => x.category !== "Hidden_from_Nav"
          )
        );
      } else {
        return process.env.VUE_APP_TITLE;
      }
    },

    getQueriesOnly() {
      if (this.currentUser) {
        let allQ = this.currentUser.allowedQueries.filter(
          (item) => item.parentId !== 0
        );
        const result = [];
        const map = new Map();
        for (const item of allQ) {
          if (!map.has(item.id)) {
            map.set(item.id, true); // set any value to Map
            result.push({
              id: item.id,
              title: item.category + " / " + item.title,
              parentId: item.parentId,
            });
          }
        }

        return result;

        // var compare = function (a, b) {
        //   // Use toUpperCase() to ignore character casing
        //   const bandA = a.title.toUpperCase();
        //   const bandB = b.title.toUpperCase();

        //   let comparison = 0;
        //   if (bandA > bandB) {
        //     comparison = 1;
        //   } else if (bandA < bandB) {
        //     comparison = -1;
        //   }
        //   return comparison;
        // };

        // return result.sort(compare);
      } else {
        return process.env.VUE_APP_TITLE;
      }
    },

    drawerPermanent() {
      return !this.AlwaysHideListSelector && this.$vuetify.breakpoint.lgAndUp;
    },
    drawerTemporary() {
      return this.AlwaysHideListSelector || this.$vuetify.breakpoint.mdAndDown;
    },
  },

  methods: {
    logout() {
      this.showlogout = true;
      this.$nextTick(() => {
        sessionStorage.removeItem("reqconfig");
        window.location.href = "/Account/Logout";
        // this.$router.push("/Account/Logout");
      });
    },
    closeHelp() {
      this.showhelp = false;
    },
    switchDarkMode: function () {
      this.$vuetify.theme.dark = this.$getPref({
        TabName: "Options",
        PrefID: "DarkMode",
      });
    },

    switchAlwaysHideListSelector: function () {
      this.AlwaysHideListSelector = this.$getPref({
        TabName: "Options",
        PrefID: "AlwaysHideListSelector",
      });
    },

    switchShowListsinFavouritesAndDefaultFolder: function () {
      this.ShowListsinFavouritesAndDefaultFolder = this.$getPref({
        TabName: "Options",
        PrefID: "ShowListsinFavouritesAndDefaultFolder",
      });
    },

    changeQuery: function (id) {
      var queryitem = this.queries.reduce((prev, curr) => {
        //for each group: if we already found the category, we return that. otherwise we try to find it within this group
        return prev || curr.children.find((children) => children.id === id);
      }, undefined);

      if (queryitem) {
        for (var gcnt = 0; gcnt < this.queries.length; gcnt++) {
          for (
            var ccnt = 0;
            ccnt < this.queries[gcnt].children.length;
            ccnt++
          ) {
            if (this.queries[gcnt].children[ccnt].active) {
              this.queries[gcnt].children[ccnt].active = false;
            }
          }
        }

        queryitem.active = true;
      }

      if (this.$route.path !== "/") {
        this.$router.push("/");
      }

      this.drawer = false;

      let queryToBeOpened = this.currentUser.allowedQueries.find(
        (x) => x.id === parseInt(id)
      );

      if (!queryToBeOpened && this.currentUser.allowedQueries.length > 0) {
        queryToBeOpened = this.currentUser.allowedQueries[0];
      }

      if (queryToBeOpened && queryToBeOpened.tablehash) {
        if (queryToBeOpened.title === "Wiring") {
          if (this.$route.path != "/wiring") {
            this.$router.push("/wiring");
          }
        }

        // if (queryToBeOpened.title === "Resources") {
        //   if (this.$route.path != "/resources") {
        //     this.$router.push("/resources");
        //   }
        // }

        this.id_query = queryToBeOpened.id;

        this.$nextTick(() => {
          this.querycombo = null;
          this.search = null;
          document.activeElement.blur();
        });
      } else {
        this.$showMessage({
          content: "Could not change Query",
          color: "error",
          timeout: 4000,
        });
      }
    },

    mousemove: function (evt) {
      this.clientX = evt.clientX;
      this.clientY = evt.clientY;
    },

    async RefreshUser(params) {
      if (params && params["params"] && params["params"].grpId === "User") {
        switch (params["params"].what) {
          case "refresh user":
            var tempthis = this;
            await tempthis.$refreshUser().then(() => {
              tempthis.queries = JSON.parse(JSON.stringify(tempthis.getItems));
              tempthis.querycombo = null;
              tempthis.changeQuery(tempthis.id_query);
            });
            break;
          case "refresh version":
            this.showRefresh = true;
            break;
        }
      }
    },

    refreshApp() {
      window.location.reload();
    },

    selectQuery() {
      if (this.querycombo) {
        if (this.toogleFunctionActive) {
          this.toggleFav(this.querycombo);
        } else {
          this.changeQuery(this.querycombo.id);
        }
      }
      this.toogleFunctionActive = false;
    },

    toogleFunctionSetActive() {
      this.toogleFunctionActive = true;

      window.setTimeout(function () {
        this.toogleFunctionActive = false;
      }, 500);
    },

    getFavMenuitems(subItem) {
      if (subItem.parentId === -1000000) {
        return [{ title: "Remove from Favorites", id: 1, icon: "favorite" }];
      } else {
        return [{ title: "Add to Favorites", id: 0, icon: "favorite_outline" }];
      }
    },

    draggingend() {
      var currentQueries = [];
      this.queries.map(function (elem) {
        elem.children.map(function (child) {
          currentQueries.push(
            child.parentId === -1000000 ? 1000000 + child.id : child.id
          );
        });
      });

      this.storePref(
        "Options",
        "QueryOrder",
        currentQueries.join(","),
        true,
        false
      );
      this.dragging = false;
    },

    toggleFav(query) {
      var currentFavs = [];
      if (this.queries[0] && this.queries[0].id === -1000000) {
        currentFavs = this.queries[0].children.map(function (elem) {
          return 1000000 + elem.id;
        });
      }

      if (query.parentId !== -1000000) {
        currentFavs.push(1000000 + query.id);
      } else {
        currentFavs.splice(currentFavs.indexOf(1000000 + query.id), 1);
      }

      this.storePref("Options", "Favourites", currentFavs.join(","), true);
    },

    resetSorting() {
      this.storePrefAndRefresh("Options", "QueryOrder", "DEFAULT", true);
    },

    resetSortingAlphabetical() {
      this.storePrefAndRefresh("Options", "QueryOrder", "ALPHABET", true);
    },

    opendataprivacy() {
      window.open(this.currentUser.dataprivacyurl, "_blank");
    },
    opentermsandconditions() {
      window.open(this.currentUser.termsandconditionsurl, "_blank");
    },

    focusListFilter() {
      var listinput = this.$refs.listselector.$refs.input;

      this.$nextTick(() => {
        listinput.focus();
      });
    },

    closeDrawer() {
      this.drawer = false;

      this.$nextTick(() => {
        this.$el.querySelector("input[fi]").focus();
      });
    },

    async storePrefAndRefresh(tabName, prefID, value, save) {
      let tempthis = this;

      await this.$setPref({
        TabName: tabName,
        PrefID: prefID,
        Value: value,
        Save: save,
      })
        .then(() => {
          return this.$refreshUser();
        })
        .then(() => {
          tempthis.queries = JSON.parse(JSON.stringify(this.getItems));

          tempthis.querycombo = null;
        });
    },

    storePref(tabName, prefID, value, save) {
      //, refreshNav = true) {

      this.$setPref({
        TabName: tabName,
        PrefID: prefID,
        Value: value,
        Save: save,
      });
    },
  },
  watch: {
    lastKeyUp: {
      handler() {
        if (this.lastKeyUp.altKey) {
          switch (this.lastKeyUp.keyCode) {
            case 88: // alt + x --> open navigation drawer
              this.drawer = true;
              break;
          }
        } else {
          switch (this.lastKeyUp.keyCode) {
            case 27:
              // nested dialogs richtig schließen
              // Button ermitteln, der im zu oberst liegenden Dialog für das Schließen zuständig ist und diesen anklicken
              var closebtn = this.$getHighest(".v-dialog--active");
              if (closebtn != null) {
                closebtn = closebtn.getElementsByClassName("mdi-close");
                if (closebtn.length > 0) {
                  closebtn[0].click();
                }
              }
              this.drawer = false;

              break;
          }
        }
      },
    },
    drawer: {
      handler() {
        if (this.drawer) {
          window.setTimeout(this.focusListFilter, 400);
        }
      },
    },
    prefs: {
      handler() {
        this.switchDarkMode();
        this.switchAlwaysHideListSelector();
        this.switchShowListsinFavouritesAndDefaultFolder();
      },
      deep: true,
    },
  },
};
</script>