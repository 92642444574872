<template>
  <div>
    <v-dialog
      v-model="show"
      scrollable
      v-bind:retain-focus="true"
      persistent
      no-click-animation
    >
      <v-card :style="dlgStyle">
        <v-card-title :class="headlineclass" style="position: relative">
          <span class="mksheadline" :dialogid="$el ? $el.__vue__._uid : ''">
            Availability
          </span>
          <v-spacer></v-spacer>
          <v-btn
            v-show="currentSlot.id_resource_availability === 0"
            style="margin-right: 1.5em"
            @click="resetNewSlot"
            ><v-icon>settings_backup_restore</v-icon></v-btn
          >

          <!-- <v-tooltip top slot="append" open-delay="700" close-delay="0">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" :disabled="currentSlot.id_resource_availability === 0"
                                style="margin-right: 1.5em;" @click="deleteResource"><v-icon
                                    :class="currentSlot.delete ? 'material-icons-outlined' : ''">delete</v-icon>
                            </v-btn>
                        </template>
<span>mark or unmark<br>slot for deletion</span>
</v-tooltip> -->

          <v-btn
            :disabled="noOfChanges === 0"
            style="margin-right: 1.5em"
            @click="saveSlots"
            :loading="saving"
            ><v-icon>save</v-icon></v-btn
          >

          <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle>
          {{ resource.ResourceName }}
        </v-card-subtitle>

        <v-card-text>
          <v-container>
            <v-row class="align-start">
              <v-col cols="3">
                <v-card class="mx-auto" max-width="400" tile>
                  <v-card-text>
                    <v-list dense>
                      <v-subheader>Select slot</v-subheader>
                      <v-list-item-group
                        mandatory
                        v-model="selectedSlotIndex"
                        color="primary"
                      >
                        <v-list-item
                          v-for="(slot, x) in resource.AvailabilitySlots"
                          :key="x"
                          :style="slot.delete ? 'background-color:#fee' : ''"
                          ><v-list-item-icon
                            ><v-icon
                              v-text="
                                slot.slot_errors.length > 0
                                  ? 'error'
                                  : 'event_available'
                              "
                            ></v-icon
                          ></v-list-item-icon>
                          <v-list-item-title>
                            {{
                              slot.id_resource_availability === 0
                                ? "new slot"
                                : "Slot " + (x + 1)
                            }}
                          </v-list-item-title>

                          <v-list-item-icon
                            v-if="slot.id_resource_availability > 0"
                            @click.stop="
                              slot.delete = !slot.delete;
                              currentSlot = slot;
                            "
                          >
                            <v-icon
                              :class="
                                slot.delete ? '' : 'material-icons-outlined'
                              "
                              >delete</v-icon
                            >
                          </v-list-item-icon>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="9">
                <v-card
                  class="mx-auto"
                  tile
                  v-show="selectedSlotIndex > -1"
                  :style="
                    'background-color:' + (currentSlot.delete ? '#fee' : '')
                  "
                >
                  <v-card-title v-show="currentSlot.delete"
                    >marked for deletion</v-card-title
                  >
                  <v-card-text>
                    <v-container>
                      <v-row class="align-start">
                        <v-col cols="2">
                          <v-radio-group
                            @change="calcNoOfChanges()"
                            v-model="currentSlot.is_not_available"
                            label="Slot type"
                          >
                            <v-radio label="available" :value="false"></v-radio>
                            <v-radio
                              label="not available"
                              :value="true"
                            ></v-radio>
                          </v-radio-group>

                          <v-radio-group
                            v-model="currentSlot.recurrence_pattern"
                            @change="calcNoOfChanges()"
                            label="Recurrence pattern"
                          >
                            <v-radio label="None" value="none"></v-radio>
                            <v-radio label="Daily" value="daily"></v-radio>
                            <v-radio label="Weekly" value="weekly"></v-radio>
                            <v-radio label="Monthly" value="monthly"></v-radio>
                            <v-radio label="Yearly" value="yearly"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col
                          cols="8"
                          v-show="currentSlot.recurrence_pattern === 'none'"
                          style="border-left: 2px solid #eee; min-height: 350px"
                        >
                          <v-row class="align-center">
                            <v-col cols="2">Start time </v-col>
                            <v-col cols="4">
                              <mksdatesimple
                                ref="start_date1"
                                :date="currentSlot.start_date"
                                :fieldname="'start_date'"
                                @updatedate="updatedate"
                                :elemError="elemErrors['start_date1']"
                              >
                              </mksdatesimple>
                            </v-col>
                            <v-col cols="3">
                              <mksslottimeonly
                                :disabled="currentSlot.all_day"
                                ref="from"
                                label=""
                                :elemError="elemErrors['from']"
                                :required="true"
                                :timeonlyval="currentSlot.from"
                                @changed="prepDurationFrom"
                              >
                              </mksslottimeonly>
                            </v-col>
                            <v-col>
                              <v-checkbox
                                @change="alldaytoggle()"
                                dense
                                v-model="currentSlot.all_day"
                                label="All day"
                                hide-details
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <v-row class="align-center">
                            <v-col cols="2">End time </v-col>
                            <v-col cols="4">
                              <mksdatesimple
                                ref="end_date1"
                                :date="currentSlot.end_date"
                                :fieldname="'end_date'"
                                @updatedate="updatedate"
                                :elemError="elemErrors['end_date1']"
                              >
                              </mksdatesimple>
                            </v-col>
                            <v-col cols="3">
                              <mksslottimeonly
                                :disabled="currentSlot.all_day"
                                ref="to"
                                label=""
                                :elemError="elemErrors['to']"
                                :required="false"
                                :timeonlyval="currentSlot.to"
                                @changed="prepDurationTo"
                              >
                              </mksslottimeonly>
                            </v-col>
                          </v-row>
                        </v-col>

                        <v-col
                          v-show="currentSlot.recurrence_pattern !== 'none'"
                          cols="2"
                          style="border-left: 2px solid #eee; min-height: 350px"
                        >
                          <mksslottimeonly
                            :disabled="currentSlot.all_day"
                            label="start"
                            :elemError="elemErrors['from']"
                            :required="true"
                            :timeonlyval="currentSlot.from"
                            @changed="prepDurationFrom"
                          >
                          </mksslottimeonly>
                          <mksslottimeonly
                            :disabled="currentSlot.all_day"
                            label="end"
                            :elemError="elemErrors['to']"
                            :required="false"
                            :timeonlyval="currentSlot.to"
                            @changed="prepDurationTo"
                          >
                          </mksslottimeonly>

                          <v-checkbox
                            @change="alldaytoggle()"
                            dense
                            v-model="currentSlot.all_day"
                            label="All day"
                            hide-details
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          v-show="currentSlot.recurrence_pattern !== 'none'"
                          style="border-left: 2px solid #eee; min-height: 350px"
                        >
                          <div
                            v-show="currentSlot.recurrence_pattern === 'daily'"
                          >
                            <v-row class="align-center">
                              <v-col>Every </v-col>
                              <v-col>
                                <v-text-field
                                  ref="every_x_days"
                                  class="shrink"
                                  v-model="currentSlot.every_x_days"
                                  type="Number"
                                  @input="calcNoOfChanges()"
                                  :style="
                                    elemErrors['every_x_days']
                                      ? 'background-color:#fee;'
                                      : ''
                                  "
                                ></v-text-field
                              ></v-col>
                              <v-col
                                >day{{
                                  currentSlot.every_x_days > 1 ? "s" : ""
                                }}</v-col
                              >
                            </v-row>
                          </div>
                          <div
                            v-show="currentSlot.recurrence_pattern === 'weekly'"
                          >
                            <v-row class="align-center">
                              <v-col>Recur every </v-col>
                              <v-col>
                                <v-text-field
                                  ref="every_x_weeks"
                                  class="shrink"
                                  v-model="currentSlot.every_x_weeks"
                                  type="Number"
                                  @input="calcNoOfChanges()"
                                  :style="
                                    elemErrors['every_x_weeks']
                                      ? 'background-color:#fee;'
                                      : ''
                                  "
                                ></v-text-field
                              ></v-col>
                              <v-col
                                >week{{
                                  currentSlot.every_x_weeks > 1 ? "s" : ""
                                }}
                                on:</v-col
                              >
                            </v-row>
                            <v-row class="align-center">
                              <v-col cols="3">
                                <v-checkbox
                                  ref="mondays"
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.mondays"
                                  label="mondays"
                                  hide-details
                                  :style="
                                    elemErrors['mondays']
                                      ? 'background-color:#fee;'
                                      : ''
                                  "
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  ref="tuesdays"
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.tuesdays"
                                  label="tuesdays"
                                  hide-details
                                  :style="
                                    elemErrors['mondays']
                                      ? 'background-color:#fee;'
                                      : ''
                                  "
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  ref="wednesdays"
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.wednesdays"
                                  label="wednesdays"
                                  hide-details
                                  :style="
                                    elemErrors['mondays']
                                      ? 'background-color:#fee;'
                                      : ''
                                  "
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  ref="thursdays"
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.thursdays"
                                  label="thursdays"
                                  hide-details
                                  :style="
                                    elemErrors['mondays']
                                      ? 'background-color:#fee;'
                                      : ''
                                  "
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  ref="fridays"
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.fridays"
                                  label="fridays"
                                  hide-details
                                  :style="
                                    elemErrors['mondays']
                                      ? 'background-color:#fee;'
                                      : ''
                                  "
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  ref="saturdays"
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.saturdays"
                                  label="saturdays"
                                  hide-details
                                  :style="
                                    elemErrors['mondays']
                                      ? 'background-color:#fee;'
                                      : ''
                                  "
                                ></v-checkbox></v-col
                              ><v-col cols="3">
                                <v-checkbox
                                  ref="sundays"
                                  @change="calcNoOfChanges()"
                                  dense
                                  v-model="currentSlot.sundays"
                                  label="sundays"
                                  hide-details
                                  :style="
                                    elemErrors['mondays']
                                      ? 'background-color:#fee;'
                                      : ''
                                  "
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </div>
                          <div
                            v-show="
                              currentSlot.recurrence_pattern === 'monthly'
                            "
                          >
                            <v-radio-group
                              @change="calcNoOfChanges()"
                              v-model="currentSlot.monthly_pattern_type"
                              label=""
                            >
                              <v-row class="align-center"
                                ><v-col>
                                  <v-radio :value="0" label="Day"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    class="shrink"
                                    v-model="currentSlot.monthly_day"
                                    type="Number"
                                    @input="calcNoOfChanges()"
                                    :disabled="
                                      currentSlot.monthly_pattern_type != 0
                                    "
                                  ></v-text-field
                                ></v-col>
                                <v-col>of every</v-col>
                                <v-col>
                                  <v-text-field
                                    class="shrink"
                                    v-model="currentSlot.monthly_every_x_month"
                                    type="Number"
                                    @input="calcNoOfChanges()"
                                    :disabled="
                                      currentSlot.monthly_pattern_type != 0
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  >month{{
                                    currentSlot.monthly_every_x_month > 1
                                      ? "s"
                                      : ""
                                  }}</v-col
                                >
                              </v-row>
                              <v-row class="align-center"
                                ><v-col
                                  ><v-radio :value="1" label="The"></v-radio>
                                </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="monthly_weekday_type"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.monthly_weekday_type"
                                    :disabled="
                                      currentSlot.monthly_pattern_type != 1
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="weekdays"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.monthly_weekday"
                                    :disabled="
                                      currentSlot.monthly_pattern_type != 1
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col>of every</v-col>
                                <v-col>
                                  <v-text-field
                                    @input="calcNoOfChanges()"
                                    class="shrink"
                                    v-model="currentSlot.monthly_every_x_month"
                                    type="Number"
                                    :disabled="
                                      currentSlot.monthly_pattern_type != 1
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  >month{{
                                    currentSlot.monthly_every_x_month > 1
                                      ? "s"
                                      : ""
                                  }}</v-col
                                >
                              </v-row>
                            </v-radio-group>
                          </div>
                          <div
                            v-show="currentSlot.recurrence_pattern === 'yearly'"
                          >
                            <v-row class="align-center">
                              <v-col cols="2">Recur every </v-col>
                              <v-col cols="2">
                                <v-text-field
                                  ref="yearly_every_x_years"
                                  @input="calcNoOfChanges()"
                                  class="shrink"
                                  v-model="currentSlot.yearly_every_x_years"
                                  type="Number"
                                  :style="
                                    elemErrors['yearly_every_x_years']
                                      ? 'background-color:#fee;'
                                      : ''
                                  "
                                ></v-text-field
                              ></v-col>
                              <v-col
                                >year{{
                                  currentSlot.yearly_every_x_years > 1
                                    ? "s"
                                    : ""
                                }}</v-col
                              >
                            </v-row>
                            <v-radio-group
                              @change="calcNoOfChanges()"
                              v-model="currentSlot.yearly_pattern_type"
                              label=""
                            >
                              <v-row class="align-center">
                                <v-col cols="2">
                                  <v-radio :value="0" label="On:"></v-radio>
                                </v-col>
                                <v-col cols="2">
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="daysPerMonth"
                                    item-text="v"
                                    item-value="v"
                                    v-model="currentSlot.yearly_day"
                                    :disabled="
                                      currentSlot.yearly_pattern_type != 0
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="months"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.yearly_on_month"
                                    :disabled="
                                      currentSlot.yearly_pattern_type != 0
                                    "
                                  ></v-select>
                                </v-col>
                              </v-row>

                              <v-row class="align-center">
                                <v-col cols="2"
                                  ><v-radio
                                    :value="1"
                                    label="On the:"
                                  ></v-radio>
                                </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="monthly_weekday_type"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.yearly_weekday_type"
                                    :disabled="
                                      currentSlot.yearly_pattern_type != 1
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="weekdays"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.yearly_weekday"
                                    :disabled="
                                      currentSlot.yearly_pattern_type != 1
                                    "
                                  ></v-select>
                                </v-col>
                                <v-col cols="1">of </v-col>
                                <v-col>
                                  <v-select
                                    @change="calcNoOfChanges()"
                                    :items="months"
                                    item-text="n"
                                    item-value="v"
                                    v-model="currentSlot.yearly_on_month"
                                    :disabled="
                                      currentSlot.yearly_pattern_type != 1
                                    "
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </div>
                        </v-col>
                      </v-row>
                      <div v-show="currentSlot.recurrence_pattern !== 'none'">
                        <v-row class="align-start" style="margin-bottom: -24px">
                          <v-col cols="12" style="border-top: 2px solid #eee"
                            >Range of recurrence</v-col
                          >
                        </v-row>
                        <v-radio-group
                          @change="calcNoOfChanges()"
                          v-model="currentSlot.range_type"
                        >
                          <v-row class="align-center myrow">
                            <v-col cols="1">Start: </v-col>
                            <v-col cols="3">
                              <mksdatesimple
                                ref="start_date2"
                                :date="currentSlot.start_date"
                                :fieldname="'start_date'"
                                @updatedate="updatedate"
                                :elemError="elemErrors['start_date2']"
                              >
                              </mksdatesimple>
                            </v-col>
                            <v-col cols="2">
                              <v-radio :value="0" label="End by:"></v-radio>
                            </v-col>
                            <v-col cols="3">
                              <mksdatesimple
                                ref="end_date2"
                                :date="currentSlot.end_date"
                                :fieldname="'end_date'"
                                @updatedate="updatedate"
                                :elemError="elemErrors['end_date2']"
                                :disabled="currentSlot.range_type != 0"
                              >
                              </mksdatesimple>
                            </v-col>
                          </v-row>
                          <v-row class="align-center myrow">
                            <v-col cols="4"> </v-col>
                            <v-col cols="2">
                              <v-radio :value="1" label="End after:"></v-radio
                            ></v-col>
                            <v-col cols="2">
                              <v-text-field
                                @input="calcNoOfChanges"
                                v-model="currentSlot.end_after_occurrences"
                                type="Number"
                                ref="end_after_occurrences"
                                clearable
                                :disabled="currentSlot.range_type != 1"
                                :style="
                                  elemErrors['end_after_occurrences']
                                    ? 'background-color:#fee;'
                                    : ''
                                "
                                :hint="elemErrors['end_after_occurrences']"
                                persistent-hint
                              ></v-text-field>
                            </v-col>
                            <v-col cols="3"> occurences </v-col>
                          </v-row>
                          <v-row class="align-center myrow">
                            <v-col cols="4"> </v-col>
                            <v-col cols="2"
                              ><v-radio
                                :value="2"
                                label="No end date"
                              ></v-radio>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </div>
                      <v-row>
                        <v-col cols="10">
                          <div
                            v-show="resource.Warning !== ''"
                            class="warning text-center"
                          >
                            {{ resource.Warning }}
                          </div>
                          <v-sheet height="64">
                            <v-toolbar flat>
                              <v-tooltip
                                right
                                open-delay="700"
                                close-delay="500"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    fab
                                    text
                                    small
                                    color="grey darken-2"
                                    @click="
                                      calendarvalue = '';
                                      $refs.calendar.scrollToTime('08:00');
                                    "
                                    ><v-icon>today</v-icon>
                                  </v-btn>
                                </template>
                                <span>jump to today</span>
                              </v-tooltip>
                              <v-btn
                                fab
                                text
                                small
                                color="grey darken-2"
                                @click="$refs.calendar.prev()"
                              >
                                <v-icon small> mdi-chevron-left </v-icon>
                              </v-btn>
                              <v-btn
                                fab
                                text
                                small
                                color="grey darken-2"
                                @click="$refs.calendar.next()"
                              >
                                <v-icon small> mdi-chevron-right </v-icon>
                              </v-btn>
                              <v-toolbar-title v-if="$refs.calendar">
                                {{ $refs.calendar.title }}
                              </v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-select
                                v-model="type"
                                :items="types"
                                dense
                                outlined
                                hide-details
                                class="ma-2"
                                label="type"
                                style="width: 2em"
                              ></v-select>
                            </v-toolbar>
                          </v-sheet>

                          <v-sheet height="400">
                            <v-calendar
                              ref="calendar"
                              v-model="calendarvalue"
                              :weekdays="cal_weekday"
                              :type="type"
                              :events="getEvents"
                              :event-overlap-mode="mode"
                              :event-overlap-threshold="30"
                              :event-color="getEventColor"
                              :interval-format="intervalFormat"
                              @change="setStartEnd"
                              @click:event="eventClicked"
                              @contextmenu:event="eventClicked"
                            >
                              <template v-slot:event="{ event }">
                                <div
                                  :title="
                                    event.name !== null
                                      ? event.name.replace('<br>', ' ')
                                      : ''
                                  "
                                  :class="
                                    'v-event-draggable' +
                                    (event.other > 0 ? ' other' : '') +
                                    (event.outside > 0 ? ' outside' : '')
                                  "
                                >
                                  <!-- <strong>{{ event.name }}</strong><br> -->
                                  {{ formatEventTime(event.start) }} -
                                  {{ formatEventTime(event.end) }}
                                  <br />
                                  <strong v-html="event.name"></strong><br />
                                </div>
                                <v-icon
                                  :class="event.outside > 0 ? ' outside' : ''"
                                  dense
                                  color="white"
                                  v-if="event.series"
                                  style="
                                    position: absolute;
                                    right: 0px;
                                    bottom: 0px;
                                  "
                                  v-text="
                                    event.ide > 0 ? 'sync_disabled' : 'sync'
                                  "
                                ></v-icon> </template
                            ></v-calendar>
                          </v-sheet>
                          <v-sheet>
                            <v-row>
                              <v-col cols="12" class="smallmenu">
                                <v-switch
                                  class="smallmenu"
                                  v-model="showReservation"
                                  color="#e59e7f"
                                  dense
                                  :hide-details="true"
                                >
                                  <template slot="label">
                                    <v-avatar
                                      color="#e59e7f"
                                      size="10"
                                      tile
                                    ></v-avatar>
                                    <span style="margin-left: 0.5em"
                                      >resource reservations</span
                                    >
                                  </template>
                                </v-switch>
                              </v-col>
                            </v-row>
                          </v-sheet>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <mksresourcebundle
      v-if="showSubbundle"
      :editquery="editquerysubbundle"
      :visible="true"
      @close="
        showSubbundle = false;
        getDetails();
      "
    >
    </mksresourcebundle>

    <v-dialog
      v-model="confirmDialogShow"
      content-class="v-dialog-mks"
      persistent
      no-click-animation
    >
      <v-card>
        <v-card-title>
          <span class="mksheadline">You have unsaved changes!</span>
          <v-spacer></v-spacer>
          <v-icon class="pnt" @click="confirmDialogShow = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>Close anyway?</v-card-text>
        <v-card-actions>
          <v-btn class="mr-4 selected" color="error" @click="$emit('close')"
            ><span> Close Item and discard Changes</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mr-4" color="info" @click="confirmDialogShow = false"
            >return to item</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.myrow {
  padding: 0px;
  margin: 0px;
}

.myrow div {
  padding: 3px;
  margin: 0px;
}

.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
</style>

<script>
import { mapState } from "vuex";
import mksslottimeonly from "@/components/mksslottimeonly.vue";
import mksdatesimple from "@/components/mksdatesimple.vue";

export default {
  name: "mksresourcetimeslots",

  components: {
    mksslottimeonly,
    mksdatesimple,
    mksresourcebundle: () => import("@/components/mksresourcebundle.vue"),
  },

  data: () => ({
    saving: false,
    confirmDialogShow: false,
    headlineclass: "",
    slotType: "available",
    loading: false,
    resource: { AvailabilitySlots: [] },
    selectedSlotIndex: 0,
    oldSelectedSlotIndex: 0,
    currentSlot: {
      from: "00:00",
      to: "00:00",
      changed: false,
    },
    monthly_weekday_type: [
      { v: 0, n: "first" },
      { v: 1, n: "second" },
      { v: 2, n: "third" },
      { v: 3, n: "fourth" },
      { v: 4, n: "last" },
    ],
    weekdays: [
      { v: 0, n: "monday" },
      { v: 1, n: "tuesday" },
      { v: 2, n: "wednesday" },
      { v: 3, n: "thursday" },
      { v: 4, n: "friday" },
      { v: 5, n: "saturday" },
      { v: 6, n: "sunday" },
    ],
    months: [
      { v: 1, n: "January", m: 31 },
      { v: 2, n: "February", m: 29 },
      { v: 3, n: "March", m: 31 },
      { v: 4, n: "April", m: 30 },
      { v: 5, n: "May", m: 31 },
      { v: 6, n: "June", m: 30 },
      { v: 7, n: "July", m: 31 },
      { v: 8, n: "August", m: 31 },
      { v: 9, n: "September", m: 30 },
      { v: 10, n: "October", m: 31 },
      { v: 11, n: "November", m: 30 },
      { v: 12, n: "December", m: 31 },
    ],
    menu1: false,
    menu2: false,
    endSmallerStarterror: false,
    // hasChanges: false,
    noOfChanges: 0,
    alldaytmpvalues: [],
    resetElemError: [],
    elemErrors: [],
    newSlotTemplate: {},
    AvailabilitySlotsCompare: [],

    type: "week",
    types: ["month", "week"], //'day', '4day'],
    mode: "column",
    modes: ["stack", "column"],
    cal_weekday: [1, 2, 3, 4, 5, 6, 0],
    cal_weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      // { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
    ],
    calendarvalue: "",
    viewStart: null,
    viewEnd: null,
    showReservation: true,
    showSubbundle: false,
    editquerysubbundle: {},
  }),

  props: {
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    // const lastDayOfWeekString = lastDayOfWeek.toISOString().split('T')[0];

    this.getDetails();
  },

  methods: {
    toggleDeleteState() {
      this.currentSlot.delete = !this.currentSlot.delete;
    },
    formatEventTime(date) {
      return new Date(date).toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    },
    intervalFormat(interval) {
      return interval.time;
    },
    getEventColor(event) {
      return event.color;
    },
    resetNewSlot() {
      this.resource.AvailabilitySlots[
        this.resource.AvailabilitySlots.length - 1
      ] = this.newSlotTemplate;
      this.calcNoOfChanges();
      this.setCurrentSlot();
    },
    alldaytoggle() {
      if (this.currentSlot.all_day) {
        this.alldaytmpvalues[this.selectedSlotIndex] = {
          from: this.currentSlot.from,
          to: this.currentSlot.to,
        };
        this.currentSlot.from = "00:00";
        this.currentSlot.to = "00:00";
      } else {
        if (this.alldaytmpvalues[this.selectedSlotIndex]) {
          this.currentSlot.from =
            this.alldaytmpvalues[this.selectedSlotIndex].from;
          this.currentSlot.to = this.alldaytmpvalues[this.selectedSlotIndex].to;
        }
      }
      this.calcNoOfChanges();
    },
    deleteResource() {
      this.currentSlot.delete = !this.currentSlot.delete;
      this.calcNoOfChanges(); //this.currentSlot.changed = true;
      // this.hasChanges = true;
    },
    updatedate(newdateinfo) {
      if (!this.loading) {
        this.currentSlot[newdateinfo.fieldname] = newdateinfo.date;
        if (newdateinfo.fieldname === "start_date") {
          var sdate = new Date(this.currentSlot["start_date"]);
          var edate = new Date(this.currentSlot["end_date"]);

          if (
            (this.currentSlot.recurrence_pattern === "none" ||
              this.currentSlot.range_type === 0) &&
            sdate > edate
          ) {
            this.currentSlot["end_date"] = this.currentSlot["start_date"];
          }
        }
        this.calcNoOfChanges(); //this.currentSlot.changed = true;
      }
    },
    prepDurationFrom(value) {
      var diff = this.timeDifferenceInMinutes(this.currentSlot.from, value);
      this.currentSlot.from = value;
      this.currentSlot.to = this.addMinutesToTime(this.currentSlot.to, diff);
    },
    prepDurationTo(value) {
      if (this.timeDifferenceInMinutes(this.currentSlot.from, value) < 0) {
        this.currentSlot.to = this.currentSlot.from;
      } else {
        this.currentSlot.to = value;
      }
      this.calcNoOfChanges();
    },
    timeDifferenceInMinutes(timeStr1, timeStr2) {
      // Parse time strings to Date objects
      const time1 = new Date("1970-01-01T" + timeStr1 + "Z");
      const time2 = new Date("1970-01-01T" + timeStr2 + "Z");

      // Calculate time difference in milliseconds
      const timeDifference = time2 - time1;

      // Convert time difference to minutes
      const minutesDifference = timeDifference / (1000 * 60);

      return minutesDifference;
    },
    addMinutesToTime(timeStr, minutesToAdd) {
      // Parse time string to Date object
      const baseTime = new Date("1970-01-01T" + timeStr + "Z");

      // Add minutes to the base time
      const newTime = new Date(baseTime.getTime() + minutesToAdd * 60 * 1000);

      // Format the new time as a time-only string
      const formattedTime = newTime.toISOString().substr(11, 8);

      return formattedTime;
    },
    setViewStartEnd() {
      const firstDayOfWeek = new Date();
      firstDayOfWeek.setHours(0, 0, 0, 0);
      firstDayOfWeek.setDate(
        firstDayOfWeek.getDate() - ((firstDayOfWeek.getDay() + 6) % 7)
      );
      this.viewStart = firstDayOfWeek.toISOString().split("T")[0] + "T00:00:00";

      const lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
      this.viewEnd = lastDayOfWeek.toISOString().split("T")[0] + "T00:00:00";
    },
    setStartEnd(event) {
      this.viewStart =
        this.$refs.calendar.getStartOfWeek(event.start).date + "T00:00:00";
      var nextDay = new Date(this.$refs.calendar.getEndOfWeek(event.end).date);
      nextDay.setDate(nextDay.getDate() + 2);

      this.viewEnd = nextDay.toISOString().split("T")[0] + "T00:00:00";
    },
    getDetails(showloading = false) {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (showloading) {
        this.showLoadingCircle = true;
      }

      this.$http
        .post("api/ResourceResourceSlotsGet", {
          query: this.editquery,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else if (response.data.resource) {
            this.resource = response.data.resource;
            this.newSlotTemplate = {
              ...this.resource.AvailabilitySlots[
                this.resource.AvailabilitySlots.length - 1
              ],
            };
            // this.hasChanges = false;
            this.setCurrentSlot();
            var sdate = new Date(this.currentSlot["start_date"]);
            var edate = new Date(this.currentSlot["end_date"]);

            if (
              (this.currentSlot.recurrence_pattern === "none" ||
                this.currentSlot.range_type === 0) &&
              sdate > edate
            ) {
              this.currentSlot["end_date"] = this.currentSlot["start_date"];
            }
            this.AvailabilitySlotsCompare = JSON.parse(
              JSON.stringify(this.resource.AvailabilitySlots)
            );
          } else {
            this.show = false;
            this.$showMessage({
              content: "resource not found",
              color: "warning",
            });
          }
          this.loading = false;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
          this.show = false;
        });
    },
    handleChange(newVal) {
      // Handle changes here!
      // eslint-disable-next-line no-console
      console.log(newVal);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split(" ")[0].split("-");
      return (
        `${day.padStart(2, "0")}.${month.padStart(2, "0")}.${year}` +
        (date.split(" ").length > 1 ? " " + date.split(" ")[1] : "")
      );
    },

    dateToReturnObject(date) {
      if (date) {
        const [year, month, day] = date.split(" ")[0].split("-");
        return {
          Year: year,
          Month: month,
          Day: day,
        };
      } else {
        return {
          Year: 0,
          Month: 0,
          Day: 0,
        };
      }
    },

    setCurrentSlot() {
      if (
        this.oldSelectedSlotIndex >
        this.resource.AvailabilitySlots.length - 1
      ) {
        this.oldSelectedSlotIndex = this.resource.AvailabilitySlots.length - 1;
      }

      if (this.selectedSlotIndex > this.resource.AvailabilitySlots.length - 1) {
        this.selectedSlotIndex = this.resource.AvailabilitySlots.length - 1;
      }

      this.currentSlot =
        this.resource.AvailabilitySlots[this.selectedSlotIndex];

      // error handling

      this.elemErrors = [];

      for (var ec = 0; ec < this.currentSlot.slot_errors.length; ec++) {
        var parts = this.currentSlot.slot_errors[ec].split("|");
        var message = parts[0];
        if (parts.length > 1) {
          parts = parts[1].split(",");
          for (var p = 0; p < parts.length; p++) {
            var elems = Object.keys(this.$refs).filter(
              (k) => k.substr(0, parts[p].length) === parts[p]
            );
            for (var i = 0; i < elems.length; i++) {
              this.resetElemError.push(elems[i]);
              this.elemErrors[elems[i]] = message;
            }
          }
        }
        this.$showMessage({
          content: message,
          color: "warning",
        });
      }

      this.$refs.calendar.scrollToTime("08:00");
    },

    saveSlots() {
      this.setCurrentSlot();
      this.calcNoOfChanges();

      if (this.noOfChanges === 0) {
        this.$showMessage({
          content: "There are no changes to save.",
          color: "warning",
        });
        return;
      }
      var tempthis = this;

      // prep date format

      this.saving = true;

      this.$http
        .post("api/ResourceResourceSlotsSave", {
          query: this.editquery,
          resource: this.resource,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else if (response.data.resource) {
            if (response.data.resource.hasSlotErrors == true) {
              this.resource.AvailabilitySlots =
                response.data.resource.AvailabilitySlots;
              this.$showMessage({
                content: "One or more availability rules contain errors.",
                color: "warning",
              });
              this.setCurrentSlot();
            } else {
              this.resource = response.data.resource;
              this.newSlotTemplate = {
                ...this.resource.AvailabilitySlots[
                  this.resource.AvailabilitySlots.length - 1
                ],
              };
              this.AvailabilitySlotsCompare = JSON.parse(
                JSON.stringify(this.resource.AvailabilitySlots)
              );

              this.setCurrentSlot();

              this.$showMessage({
                content: "Changes saved.",
                color: "success",
                timeout: 1500,
              });
              this.AvailabilitySlotsCompare = JSON.parse(
                JSON.stringify(this.resource.AvailabilitySlots)
              );
              this.calcNoOfChanges();
            }
          } else {
            this.show = false;
            this.$showMessage({
              content: "resource not found",
              color: "warning",
            });
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;

          if (message.status && message.status === 400 && message.title) {
            message = message.title;
          }
          var parts = message.split("|");
          if (parts.length > 1) {
            var elems = Object.keys(tempthis.$refs).filter(
              (k) => k.substr(0, parts[1].length) === parts[1]
            );
            // var elem = tempthis.$refs[parts[1]];
            for (var i = 0; i < elems.length; i++) {
              var elem = tempthis.$refs[elems[i]];
              if (
                elem &&
                elem.$refs &&
                elem.$refs.txtfld &&
                elem.$refs.txtfld.$refs.input
              ) {
                elem.$refs.txtfld.$refs.input.focus();
              }
            }
          }
          this.$showMessage({ content: parts[0], color: "error" });
        })
        .finally(() => {
          this.loading = false;
          this.saving = false;
        });
    },
    calcNoOfChanges() {
      // for (var c = 0; c < this.resource.AvailabilitySlots.length; c++) {
      // var comp = this.AvailabilitySlotsCompare.find(s => s.id_resource_availability === this.resource.AvailabilitySlots[c].id_resource_availability);
      var comp = this.AvailabilitySlotsCompare.find(
        (s) =>
          s.id_resource_availability ===
          this.currentSlot.id_resource_availability
      );
      if (comp) {
        var diff = false;
        var elems = Object.keys(comp).filter(
          (k) =>
            k !== "changed" &&
            k !== "slot_errors" &&
            k !== "exceptions" &&
            k !== "outside" &&
            k !== "overlap"
        );
        for (var i = 0; i < elems.length; i++) {
          if (elems[i] === "from" || elems[i] === "to") {
            if (comp[elems[i]].length < this.currentSlot[elems[i]].length) {
              comp[elems[i]] += ":00";
            }
            if (comp[elems[i]].length > this.currentSlot[elems[i]].length) {
              comp[elems[i]] = comp[elems[i]].substr(0, 5);
            }
          }
          if (comp[elems[i]] !== this.currentSlot[elems[i]]) {
            diff = true;
            break;
          }
        }
        comp.changed = diff;
        this.currentSlot.changed = diff;
      }

      this.noOfChanges =
        this.AvailabilitySlotsCompare.filter((s) => s.changed).length +
        this.resource.AvailabilitySlots.filter((s) => s.delete).length;
      // }
    },
    eventClicked(event) {
      this.editquerysubbundle = {
        id_query: this.editquery.id_query,
        id_link: this.editquery.id_link,
        id: parseInt(event.event.id_resource_bundle),
        identifier: "ID_Resource_Bundle",
        tablename: "Resources Bundles",
        hasPrevious: false,
        hasNext: false,
      };
      this.showSubbundle = true;
    },
  },

  computed: {
    ...mapState(["currentUser", "currenthubsubscriptions", "lastKeyUp"]),

    // hasChanges() {
    //     return this.noOfChanges > 0;
    // },

    daysPerMonth() {
      var days = [];
      var yearly_on_month = this.months.find(
        (m) => m.v === this.currentSlot.yearly_on_month
      );
      if (yearly_on_month) {
        var maxdays = yearly_on_month.m;

        for (var i = 1; i <= maxdays; i++) {
          days.push({ v: i });
        }
        return days;
      } else {
        return null;
      }
    },
    dlgStyle: {
      get() {
        return this.bgcolor !== "" ? "background-color:" + this.bgcolor : "";
      },
    },

    show: {
      get() {
        return true;
      },
      set(value) {
        if (!value) {
          var changes = this.resource.AvailabilitySlots.filter(
            (s) => s.changed || s.delete
          );
          if (changes.length === 0) {
            this.$emit("close", this.resource.AvailabilitySlots.length > 1);
          } else {
            //this.$showMessage({ content: "unsaved changes detected", color: "warning" });
            this.confirmDialogShow = true;
          }
        }
      },
    },

    getEvents() {
      if (
        this.resource.TimeSlotsOfferedAsEventList &&
        this.resource.TimeSlotsOfferedAsEventList.length > 0
      ) {
        if (this.showReservation) {
          return this.resource.TimeSlotsOfferedAsEventList.concat(
            this.resource.TimeSlotsBookedAsEventList
          );
        } else {
          return this.resource.TimeSlotsOfferedAsEventList;
        }
      }

      if (this.viewStart === null || this.viewEnd === null) {
        this.setViewStartEnd();
      }

      return [
        {
          name: null,
          start: this.viewStart,
          end: this.viewEnd,
          timed: true,
          color: "#abe57f",
          bookingId: 0,
          series: false,
        },
      ];
    },
  },
  watch: {
    selectedSlotIndex: {
      handler() {
        this.setCurrentSlot();
        this.oldSelectedSlotIndex = this.selectedSlotIndex;
      },
    },

    currentSlot: {
      handler() {
        this.endSmallerStarterror = false;

        if (
          this.currentSlot.start_date &&
          this.currentSlot.end_date &&
          this.currentSlot.start_date.substr(0, 10) !== "0001-01-01" &&
          this.currentSlot.end_date.substr(0, 10) !== "0001-01-01"
        ) {
          const [sYear, sMonth, sDay] = this.currentSlot.start_date
            .split(" ")[0]
            .split("-");
          const [eYear, eMonth, eDay] = this.currentSlot.end_date
            .split(" ")[0]
            .split("-");

          var cd1 = new Date(sYear, sMonth, sDay);
          var cd2 = new Date(eYear, eMonth, eDay);
          this.endSmallerStarterror = cd2 < cd1;
        }

        // this.calendarvalue = this.currentSlot.start_date;

        this.calcNoOfChanges();
      },
      deep: true,
    },
  },
};
</script>
