<template>
  <v-dialog v-model="show" scrollable v-bind:retain-focus="true">
    <v-card>
      <v-card-title style="position: relative">
        <span class="mksheadline">{{ headline }}</span>

        <v-spacer></v-spacer>
        <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
      </v-card-title>

      <v-card-text>
        <template v-for="(table, index) in dependencies">
          
          <div :key="'i' + index" v-html="Object.keys(table)[0]"></div>
          <template v-for="(btn, bindex) in table[Object.keys(table)[0]].data">
            <v-icon :key="'t' + index + 'l' + bindex" :disabled="btn.q === null" @click="openDetails(btn)">
              link
            </v-icon>
          </template>
          <div :key="'i2' + index" style="height: 1.5em;"></div>

        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" class="mr-2" @click="show = false">close</v-btn>
      </v-card-actions>

      <mksdetails :editquery="editlnk" :visible="showDetails" :idfilter="{}" :calledFromDetails="false"
        @close="closeDetails"></mksdetails>
    </v-card>
  </v-dialog>
</template>

<style scoped>
TD>>>SPAN.hishighl {
  font-weight: bold;
}

table.dataTable tbody td {
  vertical-align: top;
}
</style>

<script>
import { mapState } from "vuex";

//  <v-data-table
//             :key="'t' + index"
//             hide-default-footer
//             :headers="headers"
//             :items="table[Object.keys(table)[0]].data"
//           >
//           </v-data-table>

export default {
  name: "mksdependencies",
  data: () => ({
    loading: false,
    headline:
      "Could not delete Item - it is still being used by other database items",
    items: [],
    visibleProps: [],
    nodatatext: "Loading... Please wait",
    dependencies: null,
    headers: [
      { text: "TabName", value: "TabName" },
      { text: "id", value: "id" },
      { text: "q", value: "q" },
      { text: "l", value: "l" },
      { text: "TableName", value: "TableName" },
    ],
    showDetails: false,
    editlnk: {},
  }),

  props: {
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },

  },

  watch: {
    visible: {
      handler() {
        if (this.visible) {
          this.getDependencies();
        } else {
          this.loading = false;
          this.items = [];
        }
      },
    },
  },

  computed: {
    ...mapState(["currentUser"]),

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
          this.items = null;
        }
      },
    },
  },

  methods: {
    getDependencies() {
      this.$http
        .post("api/dependencies", {
          query: this.editquery,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.dependencies = response.data.tables;
            if (response.data.tables.length === 0) {
              this.show = false;
              this.$showMessage({
                content: "All Dependencies resolved. Item can be deleted.",
                color: "success",
              });
            }
            // this.items = response.data.data;
            // this.headers = [];
            // var tmpObject;
            // for (var cnt = 0; cnt < this.visibleProps.length; cnt++) {
            //   tmpObject = {
            //     text: Object.keys(this.visibleProps[cnt])[0].replace(/_/g, " "),
            //     value: Object.keys(this.visibleProps[cnt])[0],
            //     sortable: cnt === 0,
            //     groupable: cnt === 0,
            //   };

            //   this.headers.push(tmpObject);
            // }
          }
          // this.loading = false;
          // this.nodatatext = "No data available"

          // this.showDependencies = true;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
                ? e.message
                : e;
          this.$showMessage({ content: message, color: "error" });
          // this.loading = false;
          // this.resetting = false;
          // this.show = false;
          // this.nodatatext = "No data available"
        });
    },
    closeDetails() {
      this.showDetails = false;
      this.getDependencies();
    },
    openDetails(btn) {
      // btn
      // var url = '?q=' + btn['q'] + '&l=' + btn['l'] + '&v=' + btn['id'];
      // window.open(url);
      var jumpname = btn["f"];
      switch (jumpname) {
        case "User":
          jumpname = "Name";
          break;
        case "HW":
          jumpname = "Hardwarebezeichnung";
          break;
      }
      this.editlnk = {
        tablename: btn["TableName"],
        id_query: parseInt(btn["q"]),
        id_link: parseInt(btn["l"]),
        id: parseInt(btn["id"]),
        identifier: btn["k"],
        j: jumpname,
      };

      this.showDetails = true;
    },
  },
};
</script>