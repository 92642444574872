<template>
  <v-dialog v-model="show" scrollable v-bind:retain-focus="true" content-class="v-dialog-mks">
    <v-card>
      <v-card-title style="position: relative">
        <span class="mksheadline">{{ headline }}</span>

        <v-spacer></v-spacer>
        <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
      </v-card-title>

      <v-card-text>
        Drag and drop Columns between the following lists to adjust your current
        View:
        <v-container>
          <div class="row">
            <div class="col-6">
              <h3>Current Columns</h3>

              <v-list style="cursor: pointer" dense>
                <div style="height: 500px; overflow-y: auto">
                  <draggable
                    class="list-group"
                    :list="columns"
                    group="columnNames"
                    @change="savedisabled = false"
                  >
                    <v-list-item
                      v-for="(menuitem, index) in columns"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          menuitem.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </div>
              </v-list>
            </div>

            <div class="col-6">
              <h3>Available Columns</h3>
              <v-list style="cursor: pointer" dense>
                <div style="height: 500px; overflow-y: auto">
                  <draggable
                    class="list-group"
                    :list="columns2"
                    group="columnNames"
                    @change="sortColumns2"
                  >
                    <v-list-item
                      v-for="(menuitem, index) in columns2"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          menuitem.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </div>
              </v-list>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-checkbox
          v-model="resetColumns"
          label="Reset Columns, Column Order and Sort Order to default Values"
          outlined="true"
        ></v-checkbox><div style="width:2em;"></div>

        <v-spacer></v-spacer>
        <v-btn color="warning" class="mr-2" @click="cancelColumns"
          >cancel</v-btn
        >

        <v-btn
          :disabled="savedisabled"
          color="success"
          class="mr-2"
          @click="saveColumns"
          >save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "mkscolumns",
  autofocusFirstElement: true,

  components: {
    draggable,
  },

  data: () => ({
    loading: false,
    headline: "Column Settings",
    columns: [],
    columns2: [],
    savedisabled: true,
    resetColumns: false,
    reloadList: false,
  }),

  props: {
    id_query: {
      type: Number,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    visibleProps: {
      type: Array,
      required: true,
    },
  },

  watch: {
    visible: {
      handler() {
        if (this.visible) {
          this.getColumnInfo();
        } else {
          this.loading = false;
        }
      },
    },

    resetColumns: {
      handler() {
        this.savedisabled = false;
      },
    },
  },

  computed: {
    ...mapState(["currentUser"]),

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close", this.reloadList);
        }
      },
    },
  },

  methods: {
    getColumnInfo() {
      let tempthis = this;
      this.reloadList = false;

      this.$http
        .post("api/getcolumns", {
          id_query: this.id_query,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            tempthis.columns2 = response.data.tables[0].Table.data;
            tempthis.columns = [];
            var fIndex = 0;
            for (var cnt = 0; cnt < tempthis.visibleProps.length; cnt++) {
              tempthis.columns.push({ name: tempthis.visibleProps[cnt].value });
              fIndex = tempthis.columns2.findIndex(
                (item) => item.name.toLowerCase() === tempthis.visibleProps[cnt].value.toLowerCase()
              );
              if (fIndex > -1) {
                tempthis.columns2.splice(fIndex, 1);
              }
            }
          }
        });
    },

    compare(a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    },

    sortColumns2() {
      this.columns2.sort(this.compare);
      this.savedisabled = false;
    },

    saveColumns() {
      let tempthis = this;

      var returnedColumns = [];

      if (!this.resetColumns) {
        this.columns.forEach((column) => {
          returnedColumns.push(column.name);
        });
      }

     

      this.$http
        .post("api/setcolumns", {
          id_query: this.id_query,
          columnNames: returnedColumns,
          reset: this.resetColumns
        })
        .then((response) => {
          this.resetColumns = false;

          if (response.data.error) {
            throw response.data.error;
          } else if (response.data === "prefs saved") {
            tempthis.reloadList = true;
            tempthis.show = false;
          }
        });
    },

    cancelColumns() {
      this.show = false;
    },
  },
};
</script>