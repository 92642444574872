<template>
  <v-card elevation="2">
    <v-card-title
      ><span
        @click="
          tab = 0;
          enabledtab = 0;
          selectedTask = { title: 'allowed Tools' };
        "
      >
        Tools</span
      ><v-spacer></v-spacer>

      <v-icon @click="clearUrl">mdi-close</v-icon>
    </v-card-title>
    <v-card-subtitle> {{ selectedTask.title }}</v-card-subtitle>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab
          v-for="(item, index) in items"
          :key="item"
          :disabled="index > enabledtab"
        >
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-list dense>
                <v-list-item-group
                  v-model="selectedTaskItemGrp"
                  color="primary"
                >
                  <v-list-item
                    v-for="(toolslink, index) in toolsLinks"
                    :key="index"
                    @click.stop="handleToolsMenu(toolslink)"
                  >
                    <v-list-item-icon
                      ><v-icon>{{ toolslink.icon }}</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        toolslink.title
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-card-subtitle
              >Please provide all necessary information:</v-card-subtitle
            >
            <v-card-text>
              <template v-if="(isAnyTaskSelected = '1')">
                <template v-for="(field, index) in Combos" :ref="Combos">
                  <mksautocomplete
                    :key="'atcm_' + index"
                    :ref="field.n"
                    :editquery="{
                      tool: selectedTask.tooltype,
                      id: index + 1,
                    }"
                    :field="field"
                    :alternatelayout="AlternativeDetailsLayout"
                    v-on:userinput="userinput"
                  ></mksautocomplete>
                </template>
              </template>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-list dense>
                <v-list-item
                  v-for="(taskitem, index) in taskitems"
                  v-bind:key="'chitem_' + index"
                >
                  <v-list-item-action>
                    <v-checkbox
                      v-if="!taskitem.not_part_of_template"
                      :disabled="taskitem.already_installed"
                      dense
                      v-model="taskitem.selected"
                      
                      hide-details
                    >
                    </v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content
                    ><span
                      :class="
                        taskitem.not_part_of_template
                          ? 'npot'
                          : taskitem.already_installed
                          ? ''
                          : 'pnt'
                      "
                      @click="toggleItem(taskitem)"
                    >
                      {{ taskitem.SW_Liz_Bezeichnung }}

                      <v-tooltip top v-if="taskitem.not_part_of_template">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small dense
                            >warning</v-icon
                          >
                        </template>
                        <span
                          >The Software not Part of the selected Template, but
                          already installed on this Hardware (according to the
                          database)</span
                        >
                      </v-tooltip>
                    </span>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-card flat>
            <v-card-text> Result </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions
      ><v-btn color="info" v-if="enabledtab > 0" @click="tab--">back</v-btn
      ><v-spacer></v-spacer
      ><v-btn
        color="success"
        v-if="enabledtab > 0"
        :disabled="checkdisabled"
        @click="check"
        >check</v-btn
      ></v-card-actions
    >
  </v-card>
</template>

<style scoped>
span.npot {
  color: crimson;
}
</style>

<script>
import _ from "lodash";
import mksautocomplete from "@/components/mksautocomplete.vue";

export default {
  name: "mkstools",

  components: {
    mksautocomplete,
  },

  data: () => ({
    isAnyTaskSelected: false,
    selectedTaskItemGrp: null,
    toolsLinks: {},
    selectedTask: { title: "allowed Tools" },
    tab: 0,
    items: ["What", "Data", "Check", "Result"],
    enabledtab: 0,
    taskitem: null,
    taskitems: [],
    headers: [],
    taskitemlabel: "",
    search: null,
    Combos: [],
    AlternativeDetailsLayout: false,
    editquery: {},
    checkdisabled: true,
  }),

  mounted() {
    this.getToolLinks();
  },

  watch: {
    tab: {
      handler(val) {
        this.enabledtab = val;
      },
    },

    taskitem: {
      handler(val) {
        this.$showMessage({ content: val, color: "error" });
      },
    },

    // Combos: {
    //   handler(val) {
    //     this.$showMessage({ content: val, color: "error", timeout: 10000 });
    //   },
    //   deep: true,
    // },
  },

  methods: {
    clearUrl() {
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
    },
    toggleItem(taskitem) {
      if (!taskitem.already_installed) {
        var idx = this.taskitems.findIndex(
          (x) => x.ID_SW_Liz === taskitem.ID_SW_Liz
        );
        if (idx) {
          taskitem.selected = !taskitem.selected;
          this.$set(this.taskitems, idx, taskitem);
        }
      }
    },

    getToolLinks() {
      this.$http.get("api/getToolLinks").then((response) => {
        if (typeof response.data === "string") {
          throw "malformatted JSON Data";
        }

        if (response.data.error) {
          throw response.data.error;
        } else {
          this.toolsLinks = response.data;

          // for Testing
          this.$nextTick(() => {
            this.getCheckresults({
              tooltype: 1,
              toolparameters: [39, 1, 22067, 6],
            });
          });
        }
      });
    },

    handleToolsMenu(toolslink) {
      this.selectedTask = toolslink;
      this.isAnyTaskSelected = true;
      this.tab++;
      this.enabledtab++;

      this.getComboSets(this.selectedTask);
    },

    keyup(event) {
      if (
        !event.altKey &&
        (event.keyCode > 44 || event.keyCode === 32 || event.keyCode === 8)
      ) {
        this.throttledMethod();
      }
    },

    throttledMethod: _.debounce(function () {
      this.getOptions(this.search);
    }, 300),

    getComboSets(selectedTask) {
      if (this.isLoading) return;

      this.isLoading = true;

      this.$http
        .post("api/getToolComboSets", {
          tooltype: selectedTask.tooltype,
          filter: "",
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.Combos = response.data.Combos;
          }

          this.isLoading = false;

          if (this.tab === 1) {
            this.$nextTick(() => {
              var checkElem = null;
              for (var ref in this.$refs) {
                if (this.$refs[ref]) {
                  checkElem = this.$refs[ref][0];
                  if (checkElem) {
                    if (this.editquery.id < 0) {
                      if (checkElem.setUnsaved) {
                        checkElem.setUnsaved();
                      }
                    } else if (checkElem.initialize) {
                      checkElem.initialize();
                    }
                  }
                }
              }
            });

            var tempthis = this;
            window.setTimeout(function () {
              tempthis.$refs[tempthis.Combos[0].n][0].$refs.txtfld.$el
                .querySelector("input")
                .click();
            }, 500);
          }
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.isLoading = false;
        });
    },

    getCheckresults(testvalues) {
      if (this.isLoading) return;

      this.isLoading = true;

      var toolparameters = {
        tooltype: this.selectedTask.tooltype,
        toolparameters: this.Combos.map((a) => a.v),
      };
      if (testvalues) {
        toolparameters = testvalues;
        this.tab = 2;
      }

      this.$http
        .post("api/getToolCheckResults", toolparameters)
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.taskitemlabel = response.data.label;
            this.taskitems = response.data.data;
            for (var cnt = 0; cnt < this.taskitems.length; cnt++) {
              this.taskitems[cnt].selected =
                this.taskitems[cnt].preselected ||
                this.taskitems[cnt].already_installed;
            }

            var tmpObject;
            for (var cnth = 0; cnth < response.data.columns.length; cnth++) {
              tmpObject = {
                text: Object.keys(response.data.columns[cnth])[0],
                value: Object.keys(response.data.columns[cnth])[0],
              };

              this.headers.push(tmpObject);
            }
          }

          this.isLoading = false;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.isLoading = false;
        });
    },
    userinput(source) {
      var indexInCombos = this.Combos.map((e) => e.s).indexOf(source.s);
      this.Combos[indexInCombos].v = parseInt(
        this.$refs[source.s][0].returnValue.v
      );

      var dependingCombos = this.Combos.filter(
        (x) =>
          x.cff != null &&
          x.cff.toLocaleLowerCase() === source.s.toLocaleLowerCase()
      );

      dependingCombos.forEach(
        (dCombo) => (dCombo.cffValue = this.Combos[indexInCombos].v)
      );

      var setval = "";
      if (indexInCombos < this.Combos.length - 1) {
        switch (this.Combos[indexInCombos + 1].n) {
          case "id_matyp":
            setval =
              this.$refs[
                this.Combos[indexInCombos].n
              ][0].returnValue.show.split(/[(,)]+/)[2];

            this.Combos[indexInCombos + 1].search = setval;
            break;
        }
      }

      if (
        this.Combos.filter((x) => x.v).map((a) => a.v).length ===
        this.Combos.length
      ) {
        if (this.selectedTask.checkfunction !== null) {
          this.getCheckresults();
        }
      }

      var comboisempty = false;
      var tempthis = this;
      this.Combos.forEach((dCombo) => {
        if (
          tempthis.$refs[dCombo.n] &&
          tempthis.$refs[dCombo.n][0].returnValue.v === null
        ) {
          comboisempty = true;
        }
      });

      this.checkdisabled = comboisempty;
    },
    check() {
      this.tab++;
    },
  },
};
</script>