var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('span',{on:{"click":function($event){_vm.tab = 0;
        _vm.enabledtab = 0;
        _vm.selectedTask = { title: 'allowed Tools' };}}},[_vm._v(" Tools")]),_c('v-spacer'),_c('v-icon',{on:{"click":_vm.clearUrl}},[_vm._v("mdi-close")])],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.selectedTask.title))]),_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.items),function(item,index){return _c('v-tab',{key:item,attrs:{"disabled":index > _vm.enabledtab}},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedTaskItemGrp),callback:function ($$v) {_vm.selectedTaskItemGrp=$$v},expression:"selectedTaskItemGrp"}},_vm._l((_vm.toolsLinks),function(toolslink,index){return _c('v-list-item',{key:index,on:{"click":function($event){$event.stopPropagation();return _vm.handleToolsMenu(toolslink)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(toolslink.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(toolslink.title))])],1)],1)}),1)],1)],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-subtitle',[_vm._v("Please provide all necessary information:")]),_c('v-card-text',[((_vm.isAnyTaskSelected = '1'))?[_vm._l((_vm.Combos),function(field,index){return [_c('mksautocomplete',{key:'atcm_' + index,ref:field.n,refInFor:true,attrs:{"editquery":{
                    tool: _vm.selectedTask.tooltype,
                    id: index + 1,
                  },"field":field,"alternatelayout":_vm.AlternativeDetailsLayout},on:{"userinput":_vm.userinput}})]})]:_vm._e()],2)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.taskitems),function(taskitem,index){return _c('v-list-item',{key:'chitem_' + index},[_c('v-list-item-action',[(!taskitem.not_part_of_template)?_c('v-checkbox',{attrs:{"disabled":taskitem.already_installed,"dense":"","hide-details":""},model:{value:(taskitem.selected),callback:function ($$v) {_vm.$set(taskitem, "selected", $$v)},expression:"taskitem.selected"}}):_vm._e()],1),_c('v-list-item-content',[_c('span',{class:taskitem.not_part_of_template
                        ? 'npot'
                        : taskitem.already_installed
                        ? ''
                        : 'pnt',on:{"click":function($event){return _vm.toggleItem(taskitem)}}},[_vm._v(" "+_vm._s(taskitem.SW_Liz_Bezeichnung)+" "),(taskitem.not_part_of_template)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","dense":""}},'v-icon',attrs,false),on),[_vm._v("warning")])]}}],null,true)},[_c('span',[_vm._v("The Software not Part of the selected Template, but already installed on this Hardware (according to the database)")])]):_vm._e()],1)])],1)}),1)],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_vm._v(" Result ")])],1)],1)],1)],1),_c('v-card-actions',[(_vm.enabledtab > 0)?_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.tab--}}},[_vm._v("back")]):_vm._e(),_c('v-spacer'),(_vm.enabledtab > 0)?_c('v-btn',{attrs:{"color":"success","disabled":_vm.checkdisabled},on:{"click":_vm.check}},[_vm._v("check")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }