var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-menu',{attrs:{"offset-y":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function({}){return [_c('v-textarea',{ref:"txtfld",class:_vm.field.n.indexOf('Email') > -1 ? 'text-style-lowercase' : '',attrs:{"background-color":_vm.$getPropertyBackgroundColor(_vm.field),"filled":_vm.field.d && !_vm.field.print,"hide-details":"auto","clearable":!_vm.field.d && !_vm.field.print,"readonly":_vm.field.d || _vm.field.print,"auto-grow":"","rows":"1","outlined":!_vm.alternatelayout,"dense":!_vm.alternatelayout,"rules":[..._vm.requiredRule, ..._vm.regexRule],"counter":_vm.counterEn ? _vm.MaxLength(_vm.field) : false,"error-messages":_vm.errormessage,"append-outer-icon":_vm.isMac
            ? 'content_copy'
            : _vm.id_link !== 410 && _vm.field.a === 'Patch Mode'
            ? 'settings'
            : '',"messages":_vm.message},on:{"keydown":function($event){return _vm.counterEnOn(!_vm.field.d)},"focus":function($event){_vm.errormessage = null},"blur":function($event){_vm.counterEnOn(false);
          _vm.transformContent();},"click:append-outer":_vm.specialhandling,"keyup":function($event){return _vm.checkcond()},"paste":function($event){return _vm.checkcond()},"update:error":function($event){_vm.fielderror = $event}},scopedSlots:_vm._u([(!_vm.alternatelayout)?{key:"message",fn:function({ message }){return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}:null],null,true),model:{value:(_vm.field.v),callback:function ($$v) {_vm.$set(_vm.field, "v", $$v)},expression:"field.v"}},[(_vm.alternatelayout)?_c('template',{slot:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$mksLabel(_vm.field, _vm.alternatelayout))}})]):_vm._e(),(!_vm.field.print)?_c('template',{slot:"append"},[(_vm.field.why)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"outlined",staticStyle:{"opacity":"0.1"}},on),[_vm._v(" lock ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.field.why))])]):_vm._e(),(
              _vm.field.v &&
              _vm.field.v === _vm.field.v.toString() &&
              _vm.$isMailAddress(_vm.field.v.toString())
            )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"outlined",staticStyle:{"opacity":"0.5","cursor":"pointer"},on:{"click":_vm.createMail}},on),[_vm._v(" mail_outline ")])]}}],null,true)},[_c('span',[_vm._v("create e-mail")])]):_vm._e()],1):_vm._e()],2)]}}]),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-list',{model:{value:(_vm.formsofmac),callback:function ($$v) {_vm.formsofmac=$$v},expression:"formsofmac"}},[_vm._l((_vm.formsofmac),function(formofmac,index){return [(formofmac !== 'separator')?_c('v-list-item',{key:index,on:{"click":function($event){return _vm.copyToClipboard(formofmac)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(formofmac)}})],1)],1):_c('v-divider',{key:index})]})],2)],1),_c('mksdetails',{attrs:{"editquery":_vm.editlnk,"visible":_vm.PatchDetailsVisible,"idfilter":{},"calledFromDetails":true},on:{"close":_vm.closePatchDetails}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }