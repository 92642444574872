import { render, staticRenderFns } from "./mksdependencies.vue?vue&type=template&id=63c9c686&scoped=true"
import script from "./mksdependencies.vue?vue&type=script&lang=js"
export * from "./mksdependencies.vue?vue&type=script&lang=js"
import style0 from "./mksdependencies.vue?vue&type=style&index=0&id=63c9c686&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63c9c686",
  null
  
)

export default component.exports