<template>
  <div>
    <v-form ref="form" aria-autocomplete>
      <v-dialog
        v-model="show"
        scrollable
        v-bind:retain-focus="true"
        content-class="v-dialog-mks"
        persistent
      >
        <v-card>
          <v-card-title style="position: relative">
            <span class="mksheadline">Access Rights</span> <v-spacer></v-spacer
            ><v-icon v-if="listMode === 1" class="pnt" @click="canceldata"
              >close</v-icon
            >
          </v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col class="col-8">
                <v-select
                  v-model="listMode"
                  :items="listModes"
                  item-text="text"
                  item-value="value"
                  dense
                  solo
                >
                </v-select> </v-col
              ><v-col class="col-4" v-if="listMode !== 1">
                <v-switch
                  v-model="groupByCategory"
                  label="Group by Category"
                ></v-switch>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row v-if="listMode !== 1">
              <v-col v-if="!groupByCategory">
                <div class="mksheadlineelement">
                  <v-autocomplete
                    v-model="modelid"
                    :items="subjects"
                    hide-no-data
                    item-text="text"
                    item-value="id"
                    :label="operatingmode"
                    :outlined="true"
                    :dense="true"
                    clearable
                    auto-select-first
                    open-on-clear
                    validate-on-blur
                    ref="valuefld"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col>
                <div class="mksheadlineelement">
                  <v-autocomplete
                    v-model="modelcats"
                    :items="categories"
                    hide-no-data
                    item-text="QueryKat"
                    item-value="id"
                    label="Category"
                    :outlined="true"
                    :dense="true"
                    clearable
                    auto-select-first
                    open-on-clear
                    validate-on-blur
                    ref="catfld"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col v-if="groupByCategory">
                <div class="mksheadlineelement">
                  <v-autocomplete
                    v-model="modelquery"
                    :items="queries"
                    hide-no-data
                    item-text="Bezeichnung"
                    item-value="id"
                    label="Query"
                    :outlined="true"
                    :dense="true"
                    clearable
                    auto-select-first
                    open-on-clear
                    validate-on-blur
                    ref="valuefld"
                  ></v-autocomplete>
                </div>
              </v-col>
              <v-col>
                <div class="mksheadlineelement">
                  <v-text-field
                    v-model="modelsearch"
                    :label="!groupByCategory ? 'Query Title' : operatingmode"
                    outlined
                    clearable
                    dense
                    ref="searchfld"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-subtitle>

          <v-card-text>
            <v-container fluid>
              <mkstable
                v-if="listMode === 1"
                v-bind:id_query="ID_Users_and_Roles_Query"
              ></mkstable>
              <v-data-table
                v-else
                :items="rights"
                :headers="headers"
                :items-per-page="10000"
                fixed-header
                :height="tableheight"
                hide-default-footer
                :loading="isLoading"
              >
                <template
                  v-for="(header, index) in headers"
                  v-slot:[`header.${header.value}`]="{ header }"
                >
                  <template v-if="index === 0">
                    {{ header.text }}
                  </template>

                  <template v-else-if="index > 0 && index < 6">
                    {{ header.text }}
                    <v-checkbox
                      v-bind:key="'cb_0_' + index"
                      dense
                      hide-details
                      v-model="selAllCols[index]"
                      @change="selectallcols(header.value, index)"
                      :disabled="fixedCols[header.value]"
                    >
                    </v-checkbox>
                  </template>

                  <template v-else-if="index === 6">
                    <v-divider
                      v-bind:key="'cb_' + index"
                      class="mx-4"
                      vertical
                    ></v-divider>
                  </template>

                  <template v-else-if="index === 7">
                    {{ header.text }}
                    <v-checkbox
                      v-bind:key="'cb_' + index"
                      dense
                      hide-details
                      v-model="selAll"
                      @change="selectall()"
                    >
                    </v-checkbox>
                  </template>
                </template>

                <template v-slot:item="props">
                  <tr
                    class="text-start"
                    v-bind:key="
                      props.item[
                        !groupByCategory ? 'id_query' : operatingmodeid
                      ]
                    "
                  >
                    <td>{{ props.item[headers[0].value] }}</td>
                    <template v-for="(header, index) in headers">
                      <td v-if="index > 0 && index < 6" :key="'td_' + index">
                        <v-checkbox
                          :indeterminate="props.item[header.value] === -1"
                          :color="
                            props.item[header.value] === -1
                              ? '#767575'
                              : 'primary'
                          "
                          dense
                          hide-details
                          v-model="props.item[header.value]"
                          @change="setrow(props.item, header)"
                          :disabled="props.item[header.value + 'Fixed']"
                        ></v-checkbox>
                      </td>
                    </template>
                    <td>
                      <v-divider class="mx-4" vertical></v-divider>
                    </td>
                    <td>
                      <v-checkbox
                        :indeterminate="props.item['all'] === -1"
                        :color="
                          props.item['all'] === -1 ? '#767575' : 'primary'
                        "
                        dense
                        hide-details
                        v-model="props.item['all']"
                        @change="selectrowall(props.item)"
                        :disabled="props.item['allFixed']"
                      ></v-checkbox>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions v-if="listMode !== 1">
            <v-spacer></v-spacer>
            <v-btn color="primary" class="mr-4" @click="canceldata"
              >cancel</v-btn
            >
            <v-btn
              color="success"
              class="mr-4"
              @click="saveRights"
              :disabled="!rightsChanged"
              >save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<style scoped>
.fill-height {
  align-items: flex-start;
}

.btnend {
  top: 50px;
}

.v-input--selection-controls {
  margin-top: 0px !important;
}
</style>

<script>
import { mapState } from "vuex";

import _ from "lodash";

export default {
  name: "mksaccessrights",
  autofocusFirstElement: true,

  components: {},

  data: () => ({
    rightsChanged: false,
    isLoading: false,
    categories: [],
    allqueries: [],
    subjects: [],
    roles: [],
    persons: [],
    rights: [],
    fldNames: ["lesen", "schreiben", "loeschen", "exportieren", "drucken"],
    fixedCols: [],
    fixedRows: [],
    search: null,
    modelid: null,
    modelcats: null,
    modelsearch: null,
    modelquery: null,
    tableheight: "100px",
    baseheight: null,
    container: null,
    lastselectrowall: null,
    selAll: null,
    listMode: 1,
    listModes: [
      { text: "Users and Roles", value: 1 },
      { text: "Rights per Role", value: 2 },
      { text: "Additional Rights per User", value: 3 },
    ],
    operatingmode: "",
    operatingmodeid: "",
    groupByCategory: false,
    headers: [
      {
        text: "Query",
        align: "start",
        sortable: false,
        value: "Bezeichnung",
      },
      {
        text: "Read",
        align: "start",
        sortable: false,
        value: "lesen",
      },
      {
        text: "Edit",
        align: "start",
        sortable: false,
        value: "schreiben",
      },
      {
        text: "Delete",
        align: "start",
        sortable: false,
        value: "loeschen",
      },
      {
        text: "Export",
        align: "start",
        sortable: false,
        value: "exportieren",
      },
      {
        text: "Print",
        align: "start",
        sortable: false,
        value: "drucken",
      },
      {
        text: "divider",
        align: "start",
        sortable: false,
        value: "divider",
      },
      {
        text: "Select  all",
        align: "start",
        sortable: false,
        value: "selectrowall",
      },
    ],
    selAllCols: Array.apply(null, Array(10)).map(function () {}),
  }),

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    this.container = document.getElementsByClassName(
      "container fill-height container--fluid"
    )[0];
  },
  watch: {
    groupByCategory() {
      if (this.groupByCategory) {
        this.modelid = 0;
        this.headers[0].text = this.operatingmode;
        this.headers[0].value = this.operatingmode;
      } else {
        this.modelquery = 0;
        this.headers[0].text = "Query";
        this.headers[0].value = "Bezeichnung";
      }
      this.rights = [];
      this.modelsearch = "";
      for (var index = 1; index < 6; index++) {
        this.selAllCols[index] = false;
      }
      this.selAll = false;
    },
    listMode() {
      this.operatingmode = this.listMode === 3 ? "User" : "Role";
      this.operatingmodeid = this.listMode === 3 ? "id_user" : "id_role";
      this.subjects = this.listMode === 3 ? this.persons : this.roles;
      this.modelid = null;
      this.rights = [];
    },
    modelid() {
      this.getRights();
      this.$nextTick(() => {
        if (this.$refs.catfld) {
          this.$refs.catfld.$refs.input.focus();
          this.$refs.valuefld.$refs.input.blur();
        }
      });
    },
    modelcats() {
      if (this.allqueries.length > 0) {
        this.allqueries[0].id_querykat = this.modelcats;
        this.allqueries[1].id_querykat = this.modelcats;
        this.getRights();
      }
    },
    modelquery() {
      this.getRights();
    },
    modelsearch() {
      this.throttledMethod();
    },
    visible: {
      handler() {
        if (this.visible) {
          this.$setCurrentDialog(this.$options.name);
          this.getRightsPrep();
        } else {
          this.$setCurrentDialog(null);
          this.isLoading = false;
          this.subjects = [];
          this.roles = [];
          this.persons = [];
          this.rights = [];
          this.allqueries = [];
          this.modelcats = null;
          this.modelid = null;
        }
      },
    },

    // rights: {
    //   handler(val) {
    //     // do stuff
    //     alert(val);
    //   },
    //   deep: true,
    // },
  },

  computed: {
    ...mapState(["currentUser", "currentDialog"]),

    queries() {
      return this.allqueries.filter((x) => x.id_querykat === this.modelcats);
    },
    prefs() {
      return this.currentUser.prefs;
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.selAllCols = Array.apply(null, Array(10)).map(function () {});
          this.listMode = 1;
          this.modelsearch = "";
          this.$emit("close", "");
        }
      },
    },
    ID_Users_and_Roles_Query() {
      var uar = this.allqueries.find(
        (q) => q.Bezeichnung === "Users and Roles"
      );
      return uar ? uar.id : 0;
    },
  },

  methods: {
    throttledMethod: _.debounce(function () {
      this.getRights();
    }, 400),

    refreshBaseHeight() {
      if (this.container.querySelector("div.col")) {
        this.container.querySelector("div.col").style.display = "none";
        this.baseheight = this.container.offsetHeight;
        this.container.querySelector("div.col").style.display = "block";
      } else {
        this.baseheight = this.container.offsetHeight;
      }
      this.updateTableHeight();
    },

    getRightsPrep() {
      if (this.isLoading) {
        return;
      }
      this.SavedInfoText = "";
      this.isLoading = true;
      let tempthis = this;

      this.$http
        .get("api/getRightsPrep")
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            tempthis.categories = response.data.tables[0].querycategories.data;
            tempthis.allqueries = response.data.tables[1].allqueries.data;
            tempthis.allqueries.splice(0, 0, {
              id: -1,
              id_querykat: this.modelcats,
              Bezeichnung: "-- all queries --",
            });
            tempthis.allqueries.splice(1, 0, {
              divider: true,
              id_querykat: this.modelcats,
            });
            tempthis.roles = response.data.tables[2].roles.data;
            tempthis.persons = response.data.tables[3].users.data;
            tempthis.subjects = tempthis.roles;

            this.$nextTick(() => {
              if (this.$refs.valuefld) {
                this.$refs.valuefld.focus();
              }
            });
          }
          this.isLoading = false;

          this.$nextTick(function () {
            // zeitverzögerte Ausführung um richtige Höhe für container zu erhalten
            this.refreshBaseHeight();
            window.addEventListener("resize", this.refreshBaseHeight);
          });
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.isLoading = false;

          this.show = false;
        });
    },

    getRights() {
      if (
        this.isLoading ||
        (!this.groupByCategory && !this.modelid) ||
        (this.groupByCategory && !this.modelquery)
      ) {
        return;
      }

      this.selAllCols = Array.apply(null, Array(10)).map(function () {});
      let tmodelid = this.modelid ? this.modelid : 0;
      let tmodelcats = this.modelcats ? this.modelcats : 0;
      let tmodelquery = this.modelquery ? this.modelquery : 0;
      let tsearch = this.modelsearch ? this.modelsearch : "";

      if (tmodelcats === 0 && tsearch === "") {
        this.rights = [];
        return;
      }

      this.SavedInfoText = "";
      this.isLoading = true;
      let tempthis = this;

      this.$http
        .post("api/getRights", {
          id: tmodelid,
          id_querycat: tmodelcats,
          id_query: tmodelquery,
          listmode: this.listMode,
          search: tsearch,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            tempthis.rights = response.data.data;
            tempthis.rightsChanged = false;

            this.fldNames.forEach((i) => {
              var fixedValues = this.rights.filter(
                (r) => r[i + "Fixed"] === true
              );
              this.fixedCols[i] = fixedValues.length === this.rights.length;
            });

            // store inital fixed state for each right
            this.rights
              .filter(
                (x) =>
                  x.lesenFixed &&
                  x.schreibenFixed &&
                  x.loeschenFixed &&
                  x.exportierenFixed &&
                  x.druckenFixed
              )
              .forEach(function (query) {
                query.allFixed = true;
              });

            this.updateGlobalCheckboxes();
          }
          var threfresh = function () {
            tempthis.updateTableHeight();
          };
          window.setTimeout(threfresh, 600);

          this.isLoading = false;
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.isLoading = false;
        });
    },

    updateGlobalCheckboxes() {
      this.selAllCols[1] = Object.keys(this.rights).every(
        (k) => this.rights[k].lesen
      );
      this.selAllCols[2] = Object.keys(this.rights).every(
        (k) => this.rights[k].schreiben
      );
      this.selAllCols[3] = Object.keys(this.rights).every(
        (k) => this.rights[k].loeschen
      );
      this.selAllCols[4] = Object.keys(this.rights).every(
        (k) => this.rights[k].exportieren
      );
      this.selAllCols[5] = Object.keys(this.rights).every(
        (k) => this.rights[k].drucken
      );
      this.selAll =
        this.selAllCols[1] &&
        this.selAllCols[2] &&
        this.selAllCols[3] &&
        this.selAllCols[4] &&
        this.selAllCols[5];

      this.rights.forEach(function (query) {
        query.all =
          query.lesen &&
          query.schreiben &&
          query.loeschen &&
          query.exportieren &&
          query.drucken;
      });
    },

    updateTableHeight() {
      if (this.baseheight) {
        this.tableheight = this.baseheight - 90;
      }
    },

    canceldata() {
      this.SavedInfoText = "";
      this.listMode = 1;
      this.show = false;
    },

    selectrowall(query) {
      this.fldNames.forEach((i) => {
        if (!query[i + "Fixed"]) {
          query[i] = query.all;
          query.changed = true;
          this.rightsChanged = true;
        }
      });
    },

    setrow(item, header) {
      var query = null;
      if (!this.groupByCategory) {
        query = this.rights.find((x) => x.id_query == item.id_query);
      } else {
        query = this.rights.find((x) => x.id_user == item.id_user);
      }
      query.changed = true;
      item.changed = true;
      this.rightsChanged = true;

      if (header.text === "Read" && !query.lesen) {
        this.fldNames.forEach((i) => {
          if (!query[i + "Fixed"]) {
            query[i] = 0;
          }
        });
      } else if (header.text !== "Read" && query[header.value]) {
        query.lesen = 1;
      }

      if (
        !query.lesen &&
        !query.schreiben &&
        !query.loeschen &&
        !query.exportieren &&
        !query.drucken
      ) {
        query.all = 0;
      } else if (
        query.lesen &&
        query.schreiben &&
        query.loeschen &&
        query.exportieren &&
        query.drucken
      ) {
        query.all = 1;
      }

      this.updateGlobalCheckboxes();
    },

    selectall() {
      this.rights.forEach((query) => {
        if (typeof query.allFixed === "undefined" || !query.allFixed) {
          this.fldNames.forEach((i) => {
            if (!query[i + "Fixed"]) {
              query.changed = true;
              this.rightsChanged = true;
              query[i] = this.selAll;
            }
          });
          query.all = this.selAll;
        }
      });
      this.updateGlobalCheckboxes();
    },

    selectallcols(header, index) {
      this.rights.forEach((query) => {
        if (!query[header + "Fixed"]) {
          query[header] = this.selAllCols[index];
          query.changed = true;
          this.rightsChanged = true;
          if (index > 1 && this.selAllCols[index] && !query.lesenFixed) {
            query.lesen = 1;
          }
        }
      });
      this.updateGlobalCheckboxes();
    },

    saveRights() {
      var returnedRights = [];

      this.rights
        .filter((x) => x.changed)
        .forEach((query) => {
          returnedRights.push({
            id_user: query.id_user,
            id_role: query.ID_Role,
            id_querykat: query.id_querykat,
            id_query: query.id_query,
            lesen: query.lesen ? 1 : 0,
            schreiben: query.schreiben ? 1 : 0,
            loeschen: query.loeschen ? 1 : 0,
            exportieren: query.exportieren ? 1 : 0,
            drucken: query.drucken ? 1 : 0,
          });
        });

      this.$http
        .post("api/setRights", {
          returnedRights: returnedRights,
        })
        .then(() => {
          this.$showMessage({
            content: "Access Rights saved.",
            color: "success",
          });
          this.SavedInfoText = "Changes saved successfully";
          this.getRights();
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        });
    },
  },
};
</script> 