<template>
  <div>
    <VueTrix
      ref="field"
      v-model="model"
      placeholder="Enter your text here"
      :disabledEditor="field.d"
      autofocus
      @trix-attachment-add="handleAttachmentChanges"
      @trix-attachment-remove="handleAttachmentRemove"
    />
    <div class="v-text-field__details" style="margin: 0 12px; padding-top: 4px">
      <div class="v-messages theme--light" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message">
            <span data-v-cde73850="">{{ field.a }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.trix-content {
  max-width: 85vw;
  overflow: auto;
}

.trix-toolbar .trix-button {
  background: #ddd !important;
  /* force the background to be a light color since Trix icons do not support dark mode */
}

.trix-button-group {
  background-color: white !important;
}
</style>
<style scoped>
.attachment__progress {
  display: none !important;
}
</style>

<script>
import VueTrix from "vue-trix";

export default {
  name: "mkshtmleditor",

  components: {
    VueTrix,
  },

  data: () => ({
    model: null,
    ignoreinput: 2,
    attachments: [],
    origval: null,
    origvaltextonly: null,
  }),

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  mounted() {
    this.model = this.field.v;
    this.origval = this.field.v;
    document.getElementsByTagName("trix-toolbar")[0].style.display = this.field
      .d
      ? "none"
      : null;
    // document.getElementsByTagName('trix-editor')[0].style.backgroundColor = this.alternatelayout ? '#424242' : '#fff';
  },
  computed: {
    message: {
      get() {
        return this.$mksLabel(this.field, !this.alternatelayout);
      },
    },
    requiredRule: {
      get() {
        if (this.field.r) {
          return [
            (v) =>
              !!v ||
              (!this.alternatelayout ? this.field.a : "") + " is required",
          ];
        } else {
          return [];
        }
      },
    },
    returnValue: {
      get() {
        var tmpattachments = this.attachments.filter(
          (element) => element !== undefined
        );
        const container = document.createElement("div");
        container.style.display = "none";
        container.innerHTML = this.model;

        var imageUrls = [];
        var images = [...container.getElementsByTagName("img")];
        images.forEach((img) => {
          if (!this.isURL(img.src)) {
            try {
              const base64Data = img.src;
              const mimeType = base64Data.split(";")[0].split(":")[1];
              const base64 = base64Data.split(",")[1];
              const blob = this.base64ToBlob(base64, mimeType);
              const file = new File([blob], "filename.png", { type: mimeType });
              tmpattachments.push(file);
            } catch {} // eslint-disable-line
          }
        });

        return {
          n: this.field.n,
          v: this.model,
          files: tmpattachments,
          urls: imageUrls,
        };
      },
    },
    changed: {
      get() {
        return this.origval != this.model;
      },
    },
  },

  methods: {
    async downloadURLs(urls) {
      try {
        const responses = await Promise.all(
          urls.map((url) => fetch(url).then((response) => response.blob()))
        );

        const blobs = responses.map((response) => response);

        return blobs;
      } catch (error) {
        this.$showMessage({ content: error, color: "error" });
        return [];
      }
    },

    async fetchImageAndPushToFileArray(imageUrl) {
      return fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], "image.jpg");
          this.attachments.push(file);
        });
    },
    isURL(str) {
      try {
        var url = new URL(str); // eslint-disable-line
        return true;
      } catch (error) {
        return false;
      }
    },
    initialize() {
      this.origval = this.model;
    },
    setUnsaved() {
      this.origval = null;
    },
    handleAttachmentChanges(event) {
      this.attachments.push(event.attachment.file);
      this.$nextTick(() => {
        Array.from(document.getElementsByTagName("progress")).forEach(
          (element) => {
            element.style = "display: none;";
          }
        );
      });
    },
    handleAttachmentRemove(event) {
      var idx = this.attachments.findIndex(
        (f) =>
          f &&
          f.name == event.attachment.file.name &&
          f.size == event.attachment.file.size &&
          f.type == event.attachment.file.type
      );
      if (idx > -1) {
        this.attachments.splice(idx, 1);
      }
    },
    base64ToBlob(base64Data, contentType) {
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    },

    htmlToText(html) {
      const temp = document.createElement("div");
      temp.innerHTML = html;
      var returnText = temp.textContent || temp.innerText || "";
      returnText = returnText.replace(/(\r\n|\n|\r)/g, " ").trim();
      return returnText;
    },
  },

  watch: {
    model: {
      handler() {
        this.ignoreinput--;
        if (this.ignoreinput > 0) {
          if (this.origvaltextonly === null) {
            this.origvaltextonly = this.htmlToText(this.model);
          }
          return;
        }
        if (this.ignoreinput > -1) {
          if (this.origvaltextonly == this.htmlToText(this.model)) {
            return;
          }
        }
        this.$emit("userinput", this.field);
      },
    },
    field: {
      handler() {
        this.model = this.field.v;
        document.getElementsByTagName("trix-toolbar")[0].style.display = this
          .field.d
          ? "none"
          : null;
      },
      deep: true,
    },
  },
};
</script>
