var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.subquerytables),function(table,index){return [(_vm.subquerytabletitles[index] !== 'FinanzdatenOpenUrl')?_c('v-data-table',{key:index,class:!_vm.alternatelayout ? 'mksoutlined' : 'elevation-3',style:(_vm.bgcolor +
        ';' +
        (!_vm.field.print ? 'overflow: auto; max-height: 375px;' : '')),attrs:{"dark":_vm.$vuetify.theme.dark,"items":table,"headers":_vm.headers[index],"hide-default-footer":"","disable-pagination":"","hide-default-header":_vm.hideDefaultHeader[index],"outlined":!_vm.alternatelayout,"fixed-header":"","custom-sort":(items, sortBy, sortDesc) =>
          _vm.customSort(items, sortBy, sortDesc, _vm.headers)},nativeOn:{"mousedown":function($event){return _vm.mousedown.apply(null, arguments)},"mousemove":function($event){return _vm.mousemove.apply(null, arguments)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{class:!_vm.alternatelayout ? 'mksborderradius' : '',style:(_vm.bgcolor),attrs:{"flat":""},nativeOn:{"dblclick":function($event){!_vm.field.print && _vm.openSubqueryFromDblClick(index)}}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.subquerytabletitles[index] + " (" + table.length + ")"))]),_c('v-spacer'),(!_vm.field.print && table.hasdirectLink && _vm.writeAllowed)?_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.openDetailsFromSubquery()}}},[_c('v-icon',[_vm._v("add")])],1):_vm._e(),(
              !_vm.field.print &&
              _vm.writeAllowed &&
              _vm.subquerytabletitles[index] !== 'Finanzdaten Insgesamt'
            )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.openSubqueryFromDblClick(index)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit "+_vm._s(_vm.subquerytabletitles[index]))])]):_vm._e()],1)]},proxy:true},{key:"header.markable",fn:function({ header }){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("rule")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.mark_select_all}},[_vm._v(" select all ")]),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.mark_select_none}},[_vm._v("select none")]),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.mark_invert_selection}},[_vm._v("invert selection")]),_c('v-divider'),_vm._l((_vm.actions),function(action,index){return _c('v-tooltip',{key:'act_' + index,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":""},on:{"click":function($event){return _vm.fireAction(action, table)}}},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark ? 'white' : 'black',"dark":""},domProps:{"textContent":_vm._s(action.ActionIcon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(action.ActionName))])],1),_c('v-list-item-icon')],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(action.ActionDescriptionHtml)}})])})],2)],1)]}},{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":props.items,"tag":"tbody","disabled":!table.hasPosition}},_vm._l((props.items),function(item,index){return _c('tr',{key:'r1_' + index,style:(!_vm.field.print
                ? 'background-color: ' + _vm.getColor(item['_bgcolor'])
                : '')},[(props.headers.hasPosition)?_c('td',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-up-down ")])],1):_vm._e(),_vm._l((props.headers),function(header,index){return [(header.value === 'markable')?_c('td',{key:index},[(item.markable)?_c('v-checkbox',{attrs:{"dense":""},model:{value:(item.mark),callback:function ($$v) {_vm.$set(item, "mark", $$v)},expression:"item.mark"}}):_vm._e()],1):(
                  item[header.value] &&
                  item[header.value] !== null &&
                  item[header.value]
                    .toString()
                    .toLowerCase()
                    .startsWith('https://')
                )?_c('td',{key:index},[_c('span',{domProps:{"innerHTML":'<a target=\'_blank\' href=\'' +
                    item[header.value] +
                    '\'>' +
                    item[header.value] +
                    '</a>'}})]):(item[header.value] === true)?_c('td',{key:'1_' + index},[_vm._v(" ✔ ")]):(item[header.value] === false)?_c('td',{key:'2_' + index},[_vm._v("   ")]):(header.value === 'Attachments')?_c('td',{key:'4_' + index},[_c('div',{staticClass:"maxheight"},_vm._l((item[header.value]),function(att,index){return _c('div',{key:'att_' + index,staticClass:"dvptr",attrs:{"prevId":att.ID_Attachment_Assignment},on:{"click":function($event){return _vm.openFile(att)}}},[_c('v-icon',[_vm._v("file_present")]),_vm._v(" "+_vm._s(att.Filename)+" ("+_vm._s(_vm.$niceBytes(att.length.replaceAll(".", "")))+") ")],1)}),0)]):(header.value !== 'posupdwn')?_c('td',{key:'5_' + index,class:'maxheight ' +
                  (header.rightalign ? 'tdright' : '') +
                  ' ' +
                  (!_vm.field.print && table.hasdirectLink && _vm.writeAllowed
                    ? 'pntr'
                    : ''),on:{"click":function($event){!_vm.field.print && _vm.openDetailsFromSubquery(item)}}},[_c('div',{staticClass:"maxheight",domProps:{"innerHTML":_vm._s(item[header.value])}})]):_vm._e()]})],2)}),0),(
            props.items.length > 0 &&
            !_vm.field.print &&
            table.hasdirectLink &&
            _vm.writeAllowed
          )?_c('tr',[_c('td',{staticClass:"onhover",staticStyle:{"text-align":"center"},attrs:{"colspan":props.headers.length},on:{"click":function($event){return _vm.openDetailsFromSubquery()}}},[_c('v-btn',{staticClass:"mx-2",attrs:{"icon":"","fab":"","small":""}},[_c('v-icon',[_vm._v("add")])],1)],1)]):_vm._e()]}}],null,true)}):_vm._e(),_c('v-dialog',{key:'sd_' + index,attrs:{"content-class":"v-dialog-mks","persistent":"","no-click-animation":""},model:{value:(_vm.showTable),callback:function ($$v) {_vm.showTable=$$v},expression:"showTable"}},[_c('mkstable',{attrs:{"id_query":_vm.idJumpQuery,"idfilter":_vm.editquery,"showCloseSymbol":true},on:{"closeTable":_vm.closeTable}})],1)]}),_c('mksdetails',{attrs:{"editquery":_vm.editlnk,"visible":_vm.showDetails,"idfilter":_vm.filterlnk,"calledFromDetails":false},on:{"close":_vm.closeDetails}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }