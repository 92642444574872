<template>
  <v-dialog v-model="show" scrollable v-bind:retain-focus="true">
    <v-card>
      <v-card-title style="position: relative">
        <span class="mksheadline">Settings</span>
        <v-spacer></v-spacer>
        <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>filter_b_and_w</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-switch
                dense
                v-model="DarkMode"
                :label="`Dark Mode: ${DarkMode ? 'On' : 'Off'}`"
                @change="changeSetting('DarkMode')"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>list</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-switch
                dense
                v-model="AlwaysHideListSelector"
                :label="`Always hide List Selector: ${
                  AlwaysHideListSelector ? 'On' : 'Off'
                }`"
                @change="changeSetting('AlwaysHideListSelector')"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <template v-if="SaveAndClose">
                <v-btn :dark="$vuetify.theme.dark" color="success" class="mr-2">
                  <v-icon v-if="IconButtons" left>save</v-icon>
                  <span v-else>Save</span>
                </v-btn>

                <v-btn color="success" class="mr-2">
                  <span v-if="IconButtons"
                    ><v-icon>save</v-icon>+ <v-icon>close</v-icon></span
                  >
                  <span v-else>Save + Close</span>
                </v-btn>
              </template>
              <template v-else>
                <v-btn :dark="$vuetify.theme.dark" color="success" class="mr-2">
                  <v-icon v-if="IconButtons" left>save</v-icon>
                  <span v-else>Save</span>
                </v-btn>
              </template>
            </v-list-item-icon>
            <v-list-item-content>
              <v-switch
                dense
                v-model="SaveAndClose"
                :label="`Show 'Save and Close'-Button: ${
                  SaveAndClose ? 'On' : 'Off'
                }`"
                @change="changeSetting('SaveAndClose')"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-badge
                v-if="ShowHistoryCount"
                color="accent"
                content="12"
                overlap
                :dark="$vuetify.theme.dark"
              >
                <v-icon>history</v-icon>
              </v-badge>
              <v-icon v-else>history</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-switch
                dense
                v-model="ShowHistoryCount"
                :label="`Show Item History Count: ${
                  ShowHistoryCount ? 'On' : 'Off'
                }`"
                @change="changeSetting('ShowHistoryCount')"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>playlist_add_check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-switch
                dense
                v-model="ShowListsinFavouritesAndDefaultFolder"
                :label="`Show Lists in Favourites and default Folder: ${
                  ShowListsinFavouritesAndDefaultFolder ? 'On' : 'Off'
                }`"
                @change="changeSetting('ShowListsinFavouritesAndDefaultFolder')"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>fingerprint</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-switch
                dense
                v-model="ShowIdentifierColumn"
                :label="`Show Identifier Column: ${
                  ShowIdentifierColumn ? 'On' : 'Off'
                }`"
                @change="changeSetting('ShowIdentifierColumn')"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-btn v-if="!IconButtons">Explore</v-btn>
              <v-btn v-if="IconButtons"><v-icon>explore</v-icon></v-btn>
            </v-list-item-icon>
            <v-list-item-content>
              <v-switch
                dense
                v-model="IconButtons"
                :label="`Use Icon Buttons: ${IconButtons ? 'On' : 'Off'}`"
                @change="changeSetting('IconButtons')"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>rounded_corner</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-switch
                dense
                v-model="AlternativeDetailsLayout"
                :label="`Alternative Details Layout: ${
                  AlternativeDetailsLayout ? 'On' : 'Off'
                }`"
                @change="changeSetting('AlternativeDetailsLayout')"
                hide-details
              ></v-switch>
            </v-list-item-content>
          </v-list-item>
          <v-card elevation="5">
            <v-card-title>Example</v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="col-sm-4 col-md-4 col-lg-2 col-12">
                  <mkstextinput
                    :field="example('Text Field')"
                    :alternatelayout="AlternativeDetailsLayout"
                  ></mkstextinput>
                </v-col>
                <v-col class="col-sm-4 col-md-4 col-lg-2 col-12">
                  <mksdate
                    :field="example('Date Field')"
                    :alternatelayout="AlternativeDetailsLayout"
                  ></mksdate>
                </v-col>
                <v-col class="col-sm-4 col-md-4 col-lg-2 col-12">
                  <mksbooleaninput
                    :field="example('Boolean Field')"
                    :alternatelayout="AlternativeDetailsLayout"
                  ></mksbooleaninput>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  margin-left: 10px;
}
</style>

<script>
import mkstextinput from "@/components/mkstextinput.vue";
import mksdate from "@/components/mksdate.vue";
import mksbooleaninput from "@/components/mksbooleaninput.vue";

export default {
  name: "mkssettings",

  components: {
    mkstextinput,
    mksdate,
    mksbooleaninput,
  },

  data: () => ({
    DarkMode: false,
    AlternativeDetailsLayout: false,
    SaveAndClose: true,
    AlwaysHideListSelector: false,
    ShowHistoryCount: false,
    ShowIdentifierColumn: false,
    ShowListsinFavouritesAndDefaultFolder: false,
    IconButtons: false,
  }),

  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close", "");
        }
      },
    },
  },
  created() {
    this.DarkMode = this.$getPref({
      TabName: "Options",
      PrefID: "DarkMode",
    });
    this.AlternativeDetailsLayout = this.$getPref({
      TabName: "Options",
      PrefID: "AlternativeDetailsLayout",
    });
    this.AlternativeDetailsLayout = this.AlternativeDetailsLayout
      ? this.AlternativeDetailsLayout
      : false;
    this.ShowHistoryCount = this.$getPref({
      TabName: "Options",
      PrefID: "ShowHistoryCount",
    });
    this.ShowHistoryCount = this.ShowHistoryCount
      ? this.ShowHistoryCount
      : false;

    this.ShowIdentifierColumn = this.$getPref({
      TabName: "Options",
      PrefID: "ShowIdentifierColumn",
    });
    this.ShowIdentifierColumn = this.ShowIdentifierColumn
      ? this.ShowIdentifierColumn
      : false;

    this.ShowListsinFavouritesAndDefaultFolder = this.$getPref({
      TabName: "Options",
      PrefID: "ShowListsinFavouritesAndDefaultFolder",
    });
    this.ShowListsinFavouritesAndDefaultFolder = this
      .ShowListsinFavouritesAndDefaultFolder
      ? this.ShowListsinFavouritesAndDefaultFolder
      : false;

    this.IconButtons = this.$getPref({
      TabName: "Options",
      PrefID: "IconButtons",
    });
    this.IconButtons = this.IconButtons ? this.IconButtons : false;

    this.SaveAndClose = this.$getPref({
      TabName: "Options",
      PrefID: "SaveAndClose",
    });
    this.AlwaysHideListSelector = this.$getPref({
      TabName: "Options",
      PrefID: "AlwaysHideListSelector",
    });
  },
  methods: {
    async changeSetting(PrefID) {
      await this.$setPref({
        TabName: "Options",
        PrefID: PrefID,
        Value: this[PrefID],
        Save: true,
      }).then(() => {
        this.$root.$emit("changedSetting", PrefID);
      });
    },
    example(Label) {
      return {
        n: Label,
        a: Label,
        r: false,
        d: false,
        v:
          Label === "Date Field"
            ? "20.01.2020"
            : this.AlternativeDetailsLayout
            ? "Now it's on!"
            : "Now it's off!",
        l: 20,
      };
    },
  },
};
</script>