<template>
  <div :class="alternatelayout ? '' : 'mksoutlined'" style="position: relative; max-width: 450px;">
    <v-img v-if="field.d" :src="src" max-height="350"></v-img>

    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on }">
        <div v-on="on" @click="changeImage">
          <div v-if="showhint" class="abso">
            <v-chip label class="ma-2 warning">not saved yet!</v-chip>
          </div>

          <v-img
            :src="src"
            v-if="!hidden"
            @mouseover="showDelete = true"
          ></v-img>

          <div v-if="nophotohintvisible && !showhint" style="margin: 8px">
            (no photo)
          </div>
        </div>
      </template>
      <span>click to select new Photo</span>
    </v-tooltip>

    <v-btn
      v-if="!field.d && hover"
      color="warning"
      fab
      dark
      class="abso right"
      @click.stop="deletePhoto"
    >
      <v-icon>delete_outline</v-icon>
    </v-btn>
    <input
      v-if="!field.d"
      type="file"
      ref="photofile"
      style="display: none"
      @change="changePhoto"
    />
  </div>
</template>

<style>
.abso {
  position: absolute;
  z-index: 5000;
}

.right {
  top: 4px;
  right: 4px;
}
</style>

<script>
export default {
  name: "mksphoto",

  data: () => ({
    src: "",
    photo: [],
    changed: false,
    nophotohintvisible: false,
    hover: false,
    deletestep: 0,
    timeout: null,
    hidden: true,
  }),

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    alternatelayout: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  created() {
    this.subheading = this.field.a;
    this.getPhoto();
  },
  computed: {
    showhint: {
      get() {
        return this.changed; //.id === 0 && this.src !== "";
      },
    },
    returnValue: {
      get() {
        return {
          n: this.field.n,
          v: this.photo,
        };
      },
    },

    showDelete: {
      get() {
        return this.hover;
      },
      set(value) {
        if (value) {
          this.hover = value;
          var tempthis = this;
          if (this.timeout) {
            clearTimeout(this.timeout);
          }
          this.timeout = window.setTimeout(function () {
            tempthis.hover = false;
            tempthis.deletestep = 0;
          }, 2500);
        }
      },
    },
  },

  methods: {
    initialize() {
      this.changed = false;
    },
    forceRerender() {
      this.changed = false;
    },
    getPhoto() {
      if (this.loading) {
        return;
      }

      if (this.editquery.id < 1) {
        this.src = "";
        this.hidden = true;
        this.nophotohintvisible = true;
        return;
      }

      this.loading = true;
      this.$http
        .post("api/photo", {
          query: this.editquery,
          field: this.field,
        })
        .then((response) => {
          this.loading = false;
          if (response.data.error) {
            this.myerror = response.data.error;
            this.loading = false;
            this.hidden = true;
          } else if (response.data !== "") {
            this.src =
              "data:" +
              response.headers["content-type"] +
              ";base64," +
              response.data;
            this.hidden = false;
            this.nophotohintvisible = false;
          } else {
            this.src = "";
            this.nophotohintvisible = true;
            this.hidden = true;
          }
        })
        .catch((e) => {
          this.loading = false;
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
        });
    },
    changeImage() {
      this.$refs.photofile.click();
    },
    changePhoto() {
      if (
        this.$refs.photofile &&
        this.$refs.photofile.files &&
        this.$refs.photofile.files.length === 1
      ) {
        this.photo = this.$refs.photofile.files[0];
        this.src = URL.createObjectURL(this.$refs.photofile.files[0]);
        this.changed = true;
        this.nophotohintvisible = false;
        this.hidden = false;
      }
      return;
    },
    deletePhoto() {
      if (this.showhint) {
        // this.src = "";
        this.changed = false;
        this.nophotohintvisible = true;
        this.showhint = false;
        this.hover = false;
        this.deletestep = 0;
      } else if (this.deletestep === 0) {
        this.deletestep += 1;
        this.$showMessage({
          content: "click again to delete the image",
          color: "warning",
        });
      } else {
        this.$http
          .post("api/deletephoto", {
            query: this.editquery,
            field: this.field,
          })
          .then((response) => {
            if (response.data.error) {
              this.myerror = response.data.error;
              this.loading = false;
            } else {
              this.changed = false;
              this.getPhoto();
              this.deletestep = 0;
              this.$emit("photoChanged");
            }
          })
          .catch((e) => {
            var message =
              e.response && e.response.data
                ? e.response.data
                : e.message
                ? e.message
                : e;
            this.$showMessage({ content: message, color: "error" });
          });

        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
  },
  watch: {
    editquery: {
      handler() {
        this.getPhoto();
      },
      deep: true,
    },
  },
};
</script>
