<template>
  <v-dialog
    v-model="show"
    scrollable
    v-bind:retain-focus="true"
    persistent
    no-click-animation
  >
    <v-card>
      <v-card-title style="position: relative">
        <span class="mksheadline">{{ headline }}</span>

        <v-spacer></v-spacer>
        <v-icon class="pnt" @click="show = false">mdi-close</v-icon>
      </v-card-title>

      <v-card-text>
        <v-data-table
          fixed-header
          :headers="headers"
          :items="items"
          hide-default-footer
          disable-pagination
          disable-sort
          :loading="loading"
          loading-text="Loading... Please wait"
          :no-data-text="nodatatext"
        >
          <template v-slot:item="props">
            <tr>
              <td v-if="props.item['edited'] !== null" v-html="props.item['edited']" :rowspan="props.item['rowspan']"></td>
              <td v-html="props.item['field']"></td>
              <td v-html="props.item['old']"></td>
              <td v-html="props.item['new']"></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" class="mr-2" @click="show = false">close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
TD >>> SPAN.hishighl {
  font-weight: bold;
}
table.dataTable tbody td {
  vertical-align: top;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "mkshistoryglob",
  data: () => ({
    loading: false,
    headline: "History",
    items: [],
    headers: [],
    visibleProps: [],
    nodatatext: "Loading... Please wait",
  }),

  props: {
    editquery: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  watch: {
    visible: {
      handler() {
        if (this.visible) {
          this.getHistory();
        } else {
          this.loading = false;
          this.items = [];
        }
      },
    },
  },

  computed: {
    ...mapState(["currentUser"]),

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    getHistory() {
      this.nodatatext = "Loading... Please wait";
      this.$http
        .post("api/historyGlobal", {
          query: this.editquery,
        })
        .then((response) => {
          if (typeof response.data === "string") {
            throw "malformatted JSON Data";
          }

          if (response.data.error) {
            throw response.data.error;
          } else {
            this.visibleProps = response.data.columns;
            this.items = response.data.data;
            this.headers = [];
            var tmpObject;
            for (var cnt = 1; cnt < this.visibleProps.length; cnt++) {
              tmpObject = {
                text: Object.keys(this.visibleProps[cnt])[0].replace(/_/g, " "),
                value: Object.keys(this.visibleProps[cnt])[0],
                sortable: cnt === 0,
                groupable: cnt === 0,
              };

              this.headers.push(tmpObject);
            }
          }
          this.loading = false;
          this.nodatatext = "No data available";
        })
        .catch((e) => {
          var message =
            e.response && e.response.data
              ? e.response.data
              : e.message
              ? e.message
              : e;
          this.$showMessage({ content: message, color: "error" });
          this.loading = false;
          this.resetting = false;
          this.show = false;
          this.nodatatext = "No data available";
        });
    },
  },
};
</script>